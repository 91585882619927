(function (angular, app) {
    'use strict';
    app.directive('spPromotion', [function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                size: '=',
                product: '=?',
                special: '=?',
                isCart: '=?',
                isList: '=?',
                isProduct: '=?',
                isSpecial: '=?',
                productSpecial: '=?',
                withoutActions: '@?'
            },
            templateUrl: 'template/directives/sp-promotion/index.html',
            controller: ['$scope', '$rootScope', '$state', '$timeout', 'Util', 'Cart', function ($scope, $rootScope, $state, $timeout, Util, Cart) {
               


                $scope.toggleTooltip = function(event) {
                    event.stopPropagation();
                    if (event.currentTarget) {
                        event.currentTarget.blur();
                    }
                    $rootScope.promotionTooltip = $scope.promotionId;

                    if ($scope.isCart) {
                        $scope.bottomTooltip = event.currentTarget.getBoundingClientRect().top < 300;
                        $scope.toolbarHeight = angular.element(document.querySelector('header > div.toolbar'))[0].clientHeight;
                        $scope.tooltipPosition = ($scope.bottomTooltip ? 
                            event.currentTarget.getBoundingClientRect().bottom - ($scope.toolbarHeight > 70 ? $scope.toolbarHeight : 0) : 
                            window.innerHeight - event.currentTarget.getBoundingClientRect().top) + 6 +'px';
                    }

                    $timeout(function () {
                        _setFocusOnElement($scope.promotionId);
                    });
                }

                $scope.closeTooltip = function () {
                    $rootScope.promotionTooltip = null;
                    _setFocusOnElement($scope.isCart ? $scope.promotionTagId : $scope.promotionWrapperId);
                }

                $scope.openSpecial = function (special) {
                    $state.go('app.specials.special', {sid: special.id});
                };

                $scope.showSpecialDetails = function (event) {
                    event.stopPropagation();
                    var showProductsFrom = Cart.specialReminders.onProducts &&
                        Cart.specialReminders.onProducts[$scope.specialToShow.id] &&
                        Cart.specialReminders.onProducts[$scope.specialToShow.id].showProductsFrom;
                    return Util.showSpecialDetailsDialog($scope.specialToShow.id, showProductsFrom);
                }

                function init(){
                    $scope.specials = $scope.product ? Util.filterProductSpecials($scope.product.branch): [$scope.special];
                    $scope.promotionId = 'promotion_tooltip_' + ($scope.product ? $scope.product.id : $scope.special.id) + ($scope.isCart ? '_cart' : '');
                    $scope.promotionWrapperId = 'promotion_wrapper_' + ($scope.product ? $scope.product.id : $scope.special.id) + ($scope.isCart ? '_cart' : '');
                    $scope.promotionTagId = 'promotion_tag_' + ($scope.product ? $scope.product.id : $scope.special.id) + ($scope.isCart ? '_cart' : '');
                    if ($scope.specials && $scope.specials.length) {
                        var highPrioritySpecial = $scope.specials[0];
                        for (var i = 1; i < $scope.specials.length; i++) {
                            var special = $scope.specials[i],
                                specialLoyaltyClubsIds = (special.loyaltyClubsIds && special.loyaltyClubsIds.length) || 0,
                                topPrioritySpecialLoyaltyClubsIds = (highPrioritySpecial.loyaltyClubsIds && highPrioritySpecial.loyaltyClubsIds.length) || 0;

                                if (specialLoyaltyClubsIds > topPrioritySpecialLoyaltyClubsIds) {
                                    highPrioritySpecial = special;
                                } else if (topPrioritySpecialLoyaltyClubsIds === 0) {
                                    if (special.firstLevel.type < highPrioritySpecial.firstLevel.type) {
                                        highPrioritySpecial = special;
                                    }
                                }
                        }

                        $scope.specialToShow = highPrioritySpecial;
                    }

                    $scope.specialToShow = $scope.specialToShow || $scope.productSpecial || $scope.special;
                }

                function _setFocusOnElement(id) {
                    var element =  document.getElementById(id);
                    !!element && element.focus();
                }

                $scope.$watch('product', function(){
                    init();
                });
                // init();
            }]
        };
    }]);
})(angular, app);
