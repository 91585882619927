(function (angular, app) {
    'use strict';

    app.service('ShopListService', [
        '$rootScope', 'Api', 'SP_SERVICES', 'DataLayer',
        function ($rootScope, api, SP_SERVICES, DataLayer) {
            var self = this;

            angular.extend(self, {
                create: createShopList,
                remove: removeShopList,
                get: getShopList,
                update: updateShopList,
                patchItems: patchShopListItems,
                getShopListsWithItems: getShopListsWithItems
            });

            function createShopList(name, lines) {
                var items = [];
                var arr = [];
                if (lines && angular.isArray(lines)) {
                    angular.forEach(lines, function (line) {
                        if (line.isPseudo || line.type !== SP_SERVICES.CART_LINE_TYPES.PRODUCT) return;
                        items.push({
                            productId: line.product.id,
                            comments: line.comments,
                            productPropertyValueId: line.productPropertyValue ? line.productPropertyValue.id : null,
                            quantity: line.quantity,
                            isCase: line.isCase
                        });
                        arr.push(line.product.id);
                    });
                    $rootScope.$emit('cart.lines.added.to.mylist', arr);
                }

                return api.request({
                    method: 'POST',
                    url: '/retailers/:rid/users/:uid/shopLists',
                    data: {
                        name: name,
                        products: items
                    }
                });
            }

            function removeShopList(id) {
                return api.request({
                    method: 'DELETE',
                    url: '/retailers/:rid/users/:uid/shopLists/' + id
                });
            }

            function getShopList(id) {
                if (id) {
                    return api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/shopLists/' + id
                    });
                } else {
                    return api.request({
                        method: 'GET',
                        url: '/retailers/:rid/users/:uid/shopLists'
                    });
                }
            }

            function updateShopList(id, name, products) {
                var items = [];
                if (products && angular.isArray(products)) {
                    angular.forEach(products, function (product) {
                        if (product.isPseudo) return;
                        items.push({
                            productId: product.productId,
                            comments: product.comments,
                            productPropertyValueId: product.productPropertyValue ? product.productPropertyValue.id : null,
                            quantity: product.quantity,
                            isCase: product.isCase
                        });
                    });
                }

                return api.request({
                    method: 'PUT',
                    data: {
                        name: name,
                        items: items
                    },
                    url: '/retailers/:rid/users/:uid/shopLists/' + id
                });
            }

            function patchShopListItems(id, lines) {
                var items = [];
                if (lines && angular.isArray(lines)) {
                    var arr = [];
                    angular.forEach(lines, function (line) {
                        if (line.isPseudo) return;
                        // If product is a coupon don't add it to the shopping list
                        if(line.type !== 3) {
                            items.push({
                                productId: line.product.id,
                                comments: line.comments,
                                quantity: line.quantity,
                                productPropertyValueId: line.productPropertyValueId || (line.productPropertyValue ? line.productPropertyValue.id : null),
                                isCase: line.isCase
                            });
                        }
                        DataLayer.push(DataLayer.EVENTS.ADD_TO_WISH_LIST, {wishListItem: line.product});

                        arr.push(line.product.id);

                    });
                    $rootScope.$emit('cart.lines.added.to.mylist', arr);
                }

                return api.request({
                    method: 'PATCH',
                    data: {
                        products: items
                    },
                    url: '/retailers/:rid/users/:uid/shopLists/' + id + '/products'
                });
            }

            function getShopListsWithItems (productId, isCase) {
                var params = {};
                if(isCase){
                    params.iscase = isCase;
                }
                return api.request({
                    method: 'GET',
                    url: '/v1/retailers/:rid/users/:uid/shopListsWithItems/' + productId,
                    params: params
                });
            }
        }]);

})(angular, app);