(function (angular, app) {
    'use strict';
    app.directive('spSaleIcon', ['$rootScope', 'Util', '$filter', 'SP_SERVICES', function ($rootScope, Util, $filter, SP_SERVICES) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                special: '=?',
                product: '=?',
                productSpecials: '=?', //Product specials after filtering
                productSpecial: '=?', // Single special of product
                withoutActions: '@?'
            },
            templateUrl: 'template/directives/sp-sale-icon/index.html',
            controller: ['$scope', '$rootScope', '$filter', 'Util', 'Cart', function ($scope, $rootScope, $filter, Util, Cart) {
                var nameFilter = $filter('name');

                $scope.showSpecialDetails = showSpecialDetails;

                function showSpecialDetails(event) {
                    event.stopPropagation();
                    var showProductsFrom = Cart.specialReminders.onProducts &&
                        Cart.specialReminders.onProducts[$scope.specialToShow.id] &&
                        Cart.specialReminders.onProducts[$scope.specialToShow.id].showProductsFrom;
                    return Util.showSpecialDetailsDialog($scope.specialToShow.id, showProductsFrom);
                }

                $scope.saleIconMethods = {
                    FIXED_SALE: {
                        id: 1,
                        class: 'fixed-sale',
                    },
                    DISCOUNT: {
                        id: 2,
                        class: 'discount-sale',
                    },
                    BUY_X_FOR_Y: {
                        id: 3,
                        class: 'buy-x-sale',
                    },
                    OVER_AMOUNT: {
                        id: 4,
                        class: 'over-amount-sale',
                    },
                    BUY_X_GET_ONE: {
                        id: 5,
                        class: 'buy-x-get-one-sale',
                    }
                };

                if ($scope.productSpecials && $scope.productSpecials.length) {
                    var highPrioritySpecial = $scope.productSpecials[0];
                    for (var i = 1; i < $scope.productSpecials.length; i++) {
                        var special = $scope.productSpecials[i],
                            specialLoyaltyClubsIds = (special.loyaltyClubsIds && special.loyaltyClubsIds.length) || 0,
                            topPrioritySpecialLoyaltyClubsIds = (highPrioritySpecial.loyaltyClubsIds && highPrioritySpecial.loyaltyClubsIds.length) || 0;

                            if (specialLoyaltyClubsIds > topPrioritySpecialLoyaltyClubsIds) {
                                highPrioritySpecial = special;
                            } else if (topPrioritySpecialLoyaltyClubsIds === 0) {
                                if (special.firstLevel.type < highPrioritySpecial.firstLevel.type) {
                                    highPrioritySpecial = special;
                                }
                            }
                    }

                    $scope.specialToShow = highPrioritySpecial;
                }

                $scope.specialToShow = $scope.specialToShow || $scope.productSpecial || $scope.special;

                if ($scope.specialToShow) {
                    $scope.hasClubSpecial = $scope.specialToShow.loyaltyClubsIds && $scope.specialToShow.loyaltyClubsIds.length;
                    var specialFirstLevel = !!$scope.special ? $scope.specialToShow.levels[0] : $scope.specialToShow.firstLevel;
                    var specialType = specialFirstLevel && specialFirstLevel.type;
                    if (specialType) {
                        var firstPurchaseTotal = !!$scope.special ? specialFirstLevel.purchases[0].total : specialFirstLevel.firstPurchaseTotal,
                            firstGift = !!$scope.special ? specialFirstLevel.gifts[0] : specialFirstLevel.firstGift,
                            giftTotal = !!$scope.special ? specialFirstLevel.gifts[0].total : specialFirstLevel.firstGift.total;
                        switch (specialType) {
                            case SP_SERVICES.PROMOTION_TYPES.BUY_X_GET_Y_IN_PROMOTION:
                                if (firstGift.total === 0) {
                                    $scope.saleIcon = $scope.saleIconMethods.BUY_X_GET_ONE;
                                    $scope.purchaseTotal = firstPurchaseTotal;
                                }
                                break;

                            case SP_SERVICES.PROMOTION_TYPES.BUY_X_IN_Y_PROMOTION:
                                if (!!($scope.product && $scope.product.salePrice) || (!!$scope.special && !!$scope.special.item && !!$scope.special.item.salePrice) || firstPurchaseTotal <= 1) {
                                    $scope.saleIcon = $scope.saleIconMethods.FIXED_SALE;
                                } else {
                                    $scope.saleIcon = $scope.saleIconMethods.BUY_X_FOR_Y;
                                    $scope.purchaseTotal = firstPurchaseTotal + ($scope.product && $scope.product.showWeightUnit ? + ' ' + (nameFilter)($rootScope.defaultWeightUnit.names) : '');
                                }
                                break;

                            case SP_SERVICES.PROMOTION_TYPES.TOTAL_GET_Y_IN_PROMOTION:
                                $scope.saleIcon = $scope.saleIconMethods.OVER_AMOUNT;
                                break;

                            case SP_SERVICES.PROMOTION_TYPES.BUY_X_GET_Y_DISCOUNT_PROMOTION:
                                if (firstGift && firstGift.quantity === 1 && firstGift.total === 1) {
                                    $scope.saleIcon = $scope.saleIconMethods.BUY_X_GET_ONE;
                                    $scope.purchaseTotal = firstPurchaseTotal;
                                }
                                break;

                            case SP_SERVICES.PROMOTION_TYPES.DISCOUNT_TOTAL_PROMOTION:
                            case SP_SERVICES.PROMOTION_TYPES.BUY_X_GET_DISCOUNT_PROMOTION:
                                $scope.saleIcon = $scope.saleIconMethods.DISCOUNT;
                                break;

                            case SP_SERVICES.PROMOTION_TYPES.BUY_X_GET_FIXED_DISCOUNT_PROMOTION:
                            case SP_SERVICES.PROMOTION_TYPES.FIXED_DISCOUNT_TOTAL_PROMOTION:
                            case SP_SERVICES.PROMOTION_TYPES.BUY_X_GET_Y_FIXED_DISCOUNT_PROMOTION:
                                $scope.saleIcon = $scope.saleIconMethods.FIXED_SALE;
                                break;
                            default:
                                $scope.saleIcon = $scope.saleIconMethods.FIXED_SALE;
                                break;
                        }
                    }

                    // set the default sale icon when none was chosen
                    if (!$scope.saleIcon || !specialType) {
                        $scope.saleIcon = $scope.saleIconMethods.FIXED_SALE;
                    }
                }
            }]
        };
    }]);
})(angular, app);