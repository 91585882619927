(function (app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.ordersHistory', {
                url: '/orders-history',
                data: {
                    routeAccess: ROUTE_ACCESS.LOGIN,
                    name: 'history'
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Orders history'));
                    }]
                },
                resolve: {
                    orders: ['Api', function (api) {
                        return api.request({
                            url: '/retailers/:rid/users/:uid/orders',
                            params: {
                                start: 0,
                                max: 20
                            }
                        });
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/orders-history/index.html',
                        controller: 'OrdersHistoryCtrl as ordersHistoryCtrl'
                    }
                }
            });
        }])
        .controller('OrdersHistoryCtrl', [
            '$scope', '$rootScope', '$state', 'Dialog', 'Api', 'Cart', 'Orders', 'Util', 'orders', '$log', 'ORDER_STATUS_STAGES', 'SP_SERVICES', 'ORDER_STATUSES', 'DataLayer',
            function ($scope, $rootScope, $state, dialog, api, cart, Orders, util, orders, log, ORDER_STATUS_STAGES, SP_SERVICES, ORDER_STATUSES, DataLayer) {
                var ordersHistoryCtrl = this;

                util.setCurrentCategory({id: 'myItems'}, $scope);

                ordersHistoryCtrl.orders = orders;
                ordersHistoryCtrl.ordersService = Orders;

                _setShownAmount(ordersHistoryCtrl.orders);
                $rootScope.$on('updateOrderPrice.event', function (event, data) {
                    var orderToUpdate = ordersHistoryCtrl.orders.find(function (order) {
                        return order.id === data.orderId;
                    })
                    if(orderToUpdate) {
                        orderToUpdate.totalAmount = data.price;
                        orderToUpdate.shownAmount = data.price;
                    }
                })

                ordersHistoryCtrl.orderActions = {
                    openOrder: function (order) {
                        $state.go('app.ordersHistory.order', {
                            oid: order.id
                        });
                    },

                    createNewCart: function (order, event) {
                        event && event.stopPropagation();

                        DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Previous Orders - Create New Cart'}});

                        Orders.getOrderDetails(order.id).then(function (order) {
                            if (!cart.total.lines) {
                                return order;
                            }

                            return util.showCommonDialog({
                                title: '{{\'Replace Shopping Cart\' | translate}}',
                                content: '{{\'Are you sure you want to replace your shopping cart with the order from\' | translate}}' +
                                ' <span style="white-space: nowrap">{{order.timePlaced | date:\'dd/MM/yyyy HH:mm\'}}</span>',
                                buttons: [{
                                    text: '{{\'Replace\' | translate}}',
                                    click: '$dialog.hide(true)'
                                }, {
                                    text: '{{\'Cancel\' | translate}}',
                                    click: '$dialog.hide()'
                                }],
                                controller: ['$scope', function ($innerScope) {
                                    $innerScope.order = order;
                                }]
                            }).then(function (is) {
                                return is ? order : null;
                            });
                        }).then(function (order) {
                            if (!order) {
                                return;
                            }

                            var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(order.lines);
                            if (isLimitPassed === false) {
                                cart.clear();
                                cart.addLines(util.getActiveLines(order.lines), SP_SERVICES.SOURCES.ORDER_HISTORY);
                            }
                        });
                    },

                    addToCart: function (order, event) {
                        event && event.stopPropagation();

                        DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Previous Orders - Add To Cart'}});

                        $state.go('app.singleOrder', {
                            oid: order.id
                        });
                    },

                    addToShopList: function (order, event) {
                        event && event.stopPropagation();

                        DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Previous Orders - Add To Shopping List'}});

                        Orders.getOrderDetails(order.id).then(function (order) {
                            return util.getActiveLines(order.lines);
                        }).then(function (lines) {
                            if (lines.length) {
                                dialog.show({
                                    resolve: {
                                        lines: lines
                                    },
                                    controller: 'AddToShopListCtrl as addToShopListCtrl',
                                    templateUrl: 'template/dialogs/add-to-shop-list/index.html',
                                    styleClass: 'add-to-shop-list',
                                    ariaLabelledby: 'add_to_list_dialog_title'
                                });
                            }
                        });
                    },

                    cancelOrder: function(order) {
                        return Orders.cancelOrder(order);
                    }
                };

                function _setShownAmount(orders) {
                    angular.forEach(orders, function(order) {
                        if (util.isOrderInStatuses(order, ORDER_STATUS_STAGES.READY) || util.isOrderInStatuses(order, ORDER_STATUS_STAGES.FINISH)) {
                            order.shownAmount = order.overallCharged || order.amountCharged || 0;
                        } else {
                            order.shownAmount = order.totalAmount - (order.ebtSnapTaxSaved || 0) + (order.ebtWeightDeposit || 0) || 0;
                        }
                    });
                }

                util.currentScopeListener($scope, $scope.$root.$on('orders.cancelOrder', function (event, order) {
                    angular.forEach(orders, function(listOrder) {
                        if (order.id === listOrder.id) {
                            listOrder.statusId = ORDER_STATUSES.CANCELLED;
                        }
                    });
                }));

                var params = {
                    start: ordersHistoryCtrl.orders.length,
                    max: ordersHistoryCtrl.orders.length
                };

                util.currentScopeListener($scope, $scope.$root.$on('document.scroll.end', function (data, event) {
                    if (ordersHistoryCtrl.loadAll) {
                        return;
                    }
                    event.start();
                    api.request({
                        url: '/retailers/:rid/users/:uid/orders',
                        params: params
                    }).then(function (resp) {
                        if (resp.length === 0) {
                            ordersHistoryCtrl.loadAll = true;
                        }

                        params.start += params.max;
                        util.pushAll(ordersHistoryCtrl.orders, resp);
                        _setShownAmount(resp);
                    }).finally(function () {
                        event.done();
                    });
                }));
            }]);
})(app);
