(function (angular, app) {
    'use strict';

    app.directive('spCartLineCount', [function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'template/directives/sp-cart-line-count/index.html',
            scope: {
                line: '=',
                textBefore: '@',
            }
        };
    }]);
})(angular, app);