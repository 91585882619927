(function (angular, app) {
    'use strict';

    app.directive('spKikarInput', function () {
        return {
            restrict: 'C',
            link: function ($scope, $element) {
                var input,
                    hasPlaceHolder;

                var inputWatcher = $scope.$watch(function() {
                    return angular.element($element[0].querySelector('input'));
                }, function(newVal) {
                    if (!newVal) {
                        return;
                    }

                    input = newVal;
                    hasPlaceHolder = input.prop('placeholder');
                    inputWatcher();
                    if (!hasPlaceHolder) {
                        input.bind('focus focusout', toggleClass);

                        $scope.$watch(function () {
                            return input.val();
                        }, toggleClass);
                    } else {
                        toggleClass();
                    }
                });

                function toggleClass() {
                    if (hasPlaceHolder || (input.val() || '').trim() || document.activeElement === input[0] || (input[0] && input[0].type === 'date')) {
                        $element.addClass('floating-label');
                    } else {
                        $element.removeClass('floating-label');
                    }
                }
            }
        };
    });
})(angular, app);