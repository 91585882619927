(function (angular, app) {
  'use strict';

  /**
   * @typedef {Object} AddressComponents
   * @property {string} long_name
   * @property {string} short_name
   * @property {string[]} types // street_number, route, locality, administrative_area_level_2, administrative_area_level_1, country
   */

  /**
   * @typedef {Object} GeoLocation
   * @property {number} lat
   * @property {number} lng
   */

  /**
   * @typedef {Object} GeoViewport
   * @property {number} south
   * @property {number} west
   * @property {number} north
   * @property {number} east
   */

  /**
   * @typedef {Object} Geometry
   * @property {GeoLocation} location
   * @property {GeoViewport} viewport
   */

  /**
   * @typedef {Object} Geocode
   * @property {AddressComponents[]} address_components
   * @property {Geometry} geometry
   * @property {string} place_id
   */

  var DEFAULT_GEOCODE_FIELDS = [
    'formatted_address',
    'geometry',
    'address_components',
    'place_id',
  ];

  app.service('GoogleMapService', [function () {
    var self = this;

    angular.extend(self, {
      initMap: initMap,
      addMarker: addMarker,
      reverseGeocode: reverseGeocode,
      initAutoComplete: initAutoComplete,
      addPopupOnClickingMarker: addPopupOnClickingMarker,
      addDragMarkerEventLister: addDragMarkerEventLister,
      addClickMapMoveMarkerEventListener: addClickMapMoveMarkerEventListener,
      addAutocompleteChangeEventListener: addAutocompleteChangeEventListener,
    });

    /**
     * Subscribe onClick event on map, draw marker and return geocode
     * @param {google.maps.Marker} marker
     * @param {google.maps.Map} map
     * @param {function} setAddress
     */
    function addClickMapMoveMarkerEventListener(marker, map, callback) {
      map.addListener('click', function (event) {
        marker.setPosition(event.latLng);
        reverseGeocode(event.latLng).then(function (geocode) {
          callback(geocode);
        });
      });
    }

    /**
     * Subscribe onDrag marker event, redraw marker and return geocode
     * @param {google.maps.Marker} marker 
     * @param {Function} callback 
     */
    function addDragMarkerEventLister(marker, callback) {
      marker.addListener('dragend', function (event) {
        reverseGeocode(event.latLng).then(function (geocode) {
          callback(geocode);
        });
      });
    }

    /**
     * @param {GeoLocation} location
     * @returns {Promise<Geocode>}
     */
    function reverseGeocode(location) {
      var geocoder = new google.maps.Geocoder();
      var options = {
        location: location,
      };

      return new Promise(function (resolve, reject) {
        geocoder.geocode(options, function (results, status) {
          if (status !== 'OK') {
            return reject('Geocoder failed due to: ' + status);
          }
          if (!results[0]) {
            return reject('No results found');
          }
          return resolve(results[0]);
        });
      });
    }

    /**
     * @param {google.maps.Marker} autocomplete
     * @param {google.maps.Map} map
     * @param {google.maps.Marker} marker
     * @param {Function} callback
     */
    function addAutocompleteChangeEventListener(autocomplete, map, marker, callback) {
      autocomplete.addListener('place_changed', function () {
        marker.setVisible(false);
        var place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(DEFAULT_ZOOM);
        }
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
        callback(place);
      });
    }

    /**
     * @param {Node} elementRef
     * @param {{zoom: number, center: GeoLocation}} mapOptions Google map options like zoom, center, ...
     * @returns {google.maps.Map | null}
     */
    function initMap(elementRef, mapOptions) {
      if (typeof google !== 'undefined' && typeof google.maps !== 'undefined') {
        var map = new google.maps.Map(elementRef, mapOptions);
        return map;
      }

      return null;
    }

    /**
     * Add marker on google Map
     * @param {google.maps.Map} map
     * @param {GeoLocation} position
     * @returns {google.maps.Marker}
     */
    function addMarker(map, position) {
      var marker = new google.maps.Marker({
        map: map,
        position: position,
        draggable: true,
      });

      return marker;
    }

    /**
     * Init autocomplete
     * @param {Node} inputRef 
     * @param {{lat: number, lng: number}} center 
     * @param {google.maps.Map} map 
     * @returns {google.maps.places.Autocomplete}
     */
    function initAutoComplete(inputRef, center, map) {
      // Create a bounding box with sides ~10km away from the center point
      var defaultBounds = {
        north: center.lat + 0.1,
        south: center.lat - 0.1,
        east: center.lng + 0.1,
        west: center.lng - 0.1,
      };
      var options = {
        bounds: defaultBounds,
        fields: DEFAULT_GEOCODE_FIELDS, // MUST limit fields so google NOT CHARGED MUCH
      };
      var autocomplete = new google.maps.places.Autocomplete(inputRef, options);
      autocomplete.bindTo('bounds', map);

      return autocomplete;
    }

    /**
     * @param {google.maps.Map} map 
     * @param {google.maps.Marker} marker 
     * @param {string} content raw text or html text
     * @returns {google.maps.InfoWindow} 
     */
    function addPopupOnClickingMarker(map, marker, content) {
      if (!map || !marker) {
        return;
      }
      var popupWindow = new google.maps.InfoWindow({
        content: content
      });
      marker.addListener('click', function () {
        popupWindow.open(map, marker);
      });
      return popupWindow;
    }
  },
  ]);
})(angular, app);
