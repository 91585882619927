(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.links', {
                url: '/links/:linkId?suffix',
                data: {
                    name: 'links'
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/links/index.html',
                        controller: 'LinksCtrl as linksCtrl'
                    }
                }
            });
        }])
        .controller('LinksCtrl', ['$scope', '$sce', '$state', '$stateParams', 'Config', function ($scope, $sce, $state, $stateParams, Config) {
            var linksCtrl = this;

            if ($stateParams.linkId) {
                angular.forEach(Config.retailer.toolbar, function(link) {
                    if (Number($stateParams.linkId) === link.id) {
                        var url = link.url;
                        if (url[url.length - 1] === '/') {
                            url = url.substring(0, url.length - 1);
                        }

                        var suffix = $stateParams.suffix || '';
                        if (suffix && suffix[0] === '/') {
                            suffix = suffix.substring(1);
                        }

                        linksCtrl.src = $sce.trustAsResourceUrl(url + (suffix ? '/' + suffix : ''));
                    }
                });
            }

            if (!linksCtrl.src) {
                $state.go('app.home');
                console.error('Redirected to home page, link ' + $stateParams.linkId + ' does not exist');
            }
        }]);
})(angular, app);