(function (angular, app) {
    'use strict';

    app.directive('productProperties', [function () {
        return {
            restrict: 'E',
            templateUrl: 'template/directives/product-properties/index.html',
            replace: true,
            scope: {
                item: '=',
            },
            controllerAs: 'productPropertiesCtrl',
            controller: ['$scope', 'Cart', function($scope, Cart) {
                var productPropertiesCtrl = this;
                var item = $scope.item;
                productPropertiesCtrl.item = item;
                    productPropertiesCtrl.showPropertyError = false;
                    productPropertiesCtrl.onPropertyValueChanged = onPropertyValueChanged;

                    _setProductProperties();

                    function onPropertyValueChanged() {
                        productPropertiesCtrl.showPropertyError = null;

                        if (!productPropertiesCtrl.selectedPropertyValue || !productPropertiesCtrl.item) {
                            return;
                        }

                        productPropertiesCtrl.selectedPropertyValue.propertyNames = productPropertiesCtrl.productProperty.names;
                        productPropertiesCtrl.item.productPropertyValue = productPropertiesCtrl.selectedPropertyValue;
                        Cart.productPropertyValueChanged(productPropertiesCtrl.item);
                    }

                    function _setProductProperties() {
                        productPropertiesCtrl.productProperty = productPropertiesCtrl.item.product.productProperties[0];

                        if (productPropertiesCtrl.item.productPropertyValue && productPropertiesCtrl.item.productPropertyValue.id) {
                            productPropertiesCtrl.selectedPropertyValue = productPropertiesCtrl.productProperty.values.find(function (value) {
                                return value.id === productPropertiesCtrl.item.productPropertyValue.id;
                            });
                        } else if (productPropertiesCtrl.item.product.productProperties && productPropertiesCtrl.item.productPropertyValueId) {
                            productPropertiesCtrl.selectedPropertyValue = productPropertiesCtrl.productProperty.values.find(function (value) {
                                return value.id === productPropertiesCtrl.item.productPropertyValueId;
                            });
                        } else {
                            productPropertiesCtrl.selectedPropertyValue = null;
                        }

                        productPropertiesCtrl.item.productPropertyValue = productPropertiesCtrl.selectedPropertyValue;
                    }

            }]
        };
    }]);
})(angular, app);