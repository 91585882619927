(function (angular, app) {
    'use strict';

    app.service('PermanentViewFiltersDialog', ['Dialog', function(Dialog) {
        this.show = show;

        function show() {
            return Dialog.show({
                templateUrl: 'template/dialogs/permanent-view-filters/index.html',
                controller: 'PermanentViewFiltersCtrl as permanentViewFiltersCtrl',
                ariaLabelledby: 'permanent_view_filters_dialog_title',
                styleClass: 'permanent-view-filters-dialog',
                resolve: {
                    PermanentFiltersGroups: ['PermanentFiltersGroups', function (PermanentFiltersGroups) {
                        return PermanentFiltersGroups.create();
                    }]
                }
            });
        }
    }]);

    app.run(['$rootScope', 'PermanentViewFiltersDialog', function ($rootScope, PermanentViewFiltersDialog) {
        $rootScope.PermanentViewFiltersDialog = PermanentViewFiltersDialog;
    }]);

    app.controller('PermanentViewFiltersCtrl', [
        '$q', '$timeout', 'Api', 'SpProductTags', 'Dialog', 'PermanentFilters', 'PermanentFiltersGroups', 'Config', 'User',
        function($q, $timeout, Api, SpProductTags, Dialog, PermanentFilters, PermanentFiltersGroups, Config, User) {
            var permanentViewFiltersCtrl = this;

            permanentViewFiltersCtrl.loadGroups = loadGroups;
            permanentViewFiltersCtrl.clearAll = clearAll;
            permanentViewFiltersCtrl.save = save;
            permanentViewFiltersCtrl.carouselActions = { };
            permanentViewFiltersCtrl.carouselData = {
                selectedProductTags: PermanentFilters.values[PermanentFilters.TYPES.PRODUCT_TAGS].reduce(function(obj, value) {
                  console.log(value)
                    obj[value] = true;
                    return obj;
                }, {})
            };
            console.log(permanentViewFiltersCtrl.carouselData );

            function loadGroups(from, size, callback) {
                PermanentFiltersGroups.get(from, size).then(function(result) {
                  var allowedUsers = Config.retailer.settings.eBTAllowedUsers ? JSON.parse(Config.retailer.settings.eBTAllowedUsers) : [];
                  var filteredResult = result;
                  if (allowedUsers.length && !allowedUsers.includes(User.data.id)){
                    if(filteredResult.groups && filteredResult.groups[1] && filteredResult.groups[1].productTags)
                    filteredResult.groups[1].productTags = filteredResult.groups[1].productTags.filter(function (tag) {
                     if (tag.productTagId !== 2560 && tag.productTagId !== 5364){
                       return tag
                     }
                   });
                  }
                  else if (allowedUsers.length) {
                    if(filteredResult.groups && filteredResult.groups[1] && filteredResult.groups[1].productTags)
                    filteredResult.groups[1].productTags = filteredResult.groups[1].productTags.filter(function (tag)  {
                     if (tag.productTagId !== 5364){
                       return tag
                     }
                   });
                  }
                  result = filteredResult
                    callback(null, result.total, result.groups);
                }).catch(function(err) {
                    callback(err);
                });
            }

            function clearAll() {
                angular.forEach(permanentViewFiltersCtrl.carouselData.selectedProductTags, function(val, productTagId) {
                    delete permanentViewFiltersCtrl.carouselData.selectedProductTags[productTagId];
                });

                return save();
            }

            function save() {
                var productTagIds = [];
                angular.forEach(permanentViewFiltersCtrl.carouselData.selectedProductTags, function(val, productTagId) {
                    if (val) {
                        productTagIds.push(Number(productTagId));
                    }
                });

                permanentViewFiltersCtrl.saving = true;
                return $q.all([
                    PermanentFilters.addProductTagsFilters(productTagIds, true),
                    $timeout(function(){}, 1000)
                ]).then(function() {
                    permanentViewFiltersCtrl.saved = true;

                    if (!productTagIds.length) {
                        Dialog.hide();
                    }
                }).finally(function() {
                    permanentViewFiltersCtrl.saving = false;
                });
            }
        }
    ]);
})(angular, app);
