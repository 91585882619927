(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.userEdit.customerService', {
                url: '/customer-service',
                data: {
                    routeAccess: ROUTE_ACCESS.LOGIN
                },
                views: {
                    'tab': {
                        templateUrl: 'template/views/user-edit/customer-service/index.html',
                        controller: 'CustomerServiceCtrl',
                        controllerAs: 'customerServiceCtrl',
                        resolve: {
                            user: ['User', function (user) {
                                return user.getData();
                            }]
                        }
                    }
                }
            });
        }])
        .controller('CustomerServiceCtrl', ['$scope', '$rootScope', 'User', 'Util', function ($scope, $rootScope, User, Util) {
            var customerServiceCtrl = this,
                userEditCtrl = $scope.userEditCtrl;

            customerServiceCtrl.toggleTicket = toggleTicket;
            customerServiceCtrl.createUserTicketComment = createUserTicketComment;
            customerServiceCtrl.normalizeTime = normalizeTime;

            _setUserTickets();

            var _openedTicket;

            function toggleTicket(ticket) {
                if (_openedTicket && _openedTicket !== ticket) {
                    _openedTicket.opened = false;
                }

                ticket.opened = !ticket.opened;

                _openedTicket = ticket.opened ? ticket : null;
            }

            function createUserTicketComment(ticket) {
                if (!ticket.newComment.comment) return;

                return User.uploadTicketAttachment(ticket.newComment.attachment).then(function (data) {
                    if (data) {
                        ticket.newComment.attachments = [data.url.fullUrl];
                    }

					return User.createUserTicketComment({
						ticketId: ticket.id,
						attachments: ticket.newComment.attachments,
						comment: ticket.newComment.comment
					});
                }).then(function (data) {
                    if (!data.success) return;
					ticket.comments.push({
						createdDate: new Date(),
                        normalizedDate: new Date(),
                        local: true,
						userId: userEditCtrl.user.id,
						commentById: userEditCtrl.user.id,
						comment: ticket.newComment.comment,
						attachments: ticket.newComment.attachments,
					});

                    $rootScope.$emit('contactUs', userEditCtrl.user.email, userEditCtrl.user.firstName + " " + userEditCtrl.user.lastName);
                    delete ticket.newComment;
                });
            }

            function _setUserTickets(evt, data) {
                var getLastData = data && data.getLastData;
                User.getUserTickets(getLastData).then(function (tickets) {
                    angular.forEach(tickets, function (ticket) {
                        ticket.normalizedDate = normalizeTime(ticket.createdDate);
                        angular.forEach(ticket.comments, function (comment) {
                            comment.userId = userEditCtrl.user.id;
                            comment.normalizedDate = normalizeTime(comment.dbDate + 'Z');
                        });
                    });
                    customerServiceCtrl.tickets = tickets
                });
            }

            function normalizeTime(time) {
                var date = new Date(time);
                var timeOffsetInMS = $rootScope.config.retailer.timeZoneOffset * 60000;
                var localTimeOffset = new Date().getTimezoneOffset() * 60000;
                return new Date(date.setTime((date.getTime() + timeOffsetInMS) - localTimeOffset));
            }

            Util.currentScopeListener($scope, $rootScope.$on('new-ticket', _setUserTickets));
        }]);
})(angular, app);
