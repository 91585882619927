(function (angular, app, window) {
    'use strict';

    var LOCAL_STORAGE_KEYS = {
        SESSION: 'session',
        BRANCH_ID: 'branchId',
        AREA_ID: 'areaId',
        AREA_NAME: 'area'
    };

    app.service('LocalStorage', ['$location', '$window', 'SP_SERVICES',
        function ($location, $window, SP_SERVICES) {
            var self = this,
                hasLocalStorage = true,
                frontendLocalStorage = {},
                _searchParams = $location.search(),
                _isLoginAs = _searchParams && _searchParams.userId !== undefined && _searchParams.token !== undefined,
                _loginAsArea;

            angular.extend(self, {
                getItem: getItem,
                setItem: setItem,
                removeItem: removeItem,
                clear: clear,
                isExist: isExist
            });

            try {
                if (!_isLoginAs) {
                    frontendLocalStorage = JSON.parse(window.localStorage.getItem('frontend') || '{}');
                }
            } catch (err) {
                /* localstorage might throw an error when blocked by security or Bug: https://bugzilla.mozilla.org/show_bug.cgi?id=748620 */
                hasLocalStorage = false;
            }

            function save() {
                if (hasLocalStorage && !_isLoginAs) {
                    window.localStorage.setItem('frontend', JSON.stringify(frontendLocalStorage));
                }
            }

            function getItem(key) {
                if (_isLoginAs) {
                    return _handleLoginAs(key);
                } else {
                    return frontendLocalStorage[key];
                }
            }

            function _handleLoginAs(key) {
                if (key === LOCAL_STORAGE_KEYS.SESSION) {
                    var organization;
                    if (_searchParams.organization) {
                        try {
                            organization = JSON.parse(_searchParams.organization);
                        } catch(err) {}
                    }

                    return {
                        token: _searchParams.token,
                        userId: _searchParams.userId,
                        branchKeyOrganization: organization && organization.id,
                        organizationRoleId: organization && organization.roleId,
                        organizationBranches: organization && organization.branches && organization.branches.map(function(branch) {
                            return branch.id;
                        })
                    };
                } else if (key === LOCAL_STORAGE_KEYS.BRANCH_ID) {
                    return Number(_searchParams.branchId) || null;
                }

                //if not an area key return the what is saved on the storage (per window/refresh)
                if (key !== LOCAL_STORAGE_KEYS.AREA_ID && key !== LOCAL_STORAGE_KEYS.AREA_NAME) {
                    return frontendLocalStorage[key];
                }

                //if it is an area key, return the first area of the search query branch
                //no reason to make the retailer choose an area

                //if there is no branch id in the url return whatever is in the fake local storage
                if (_searchParams.branchId === undefined) {
                    return frontendLocalStorage[key];
                }

                if (!_loginAsArea) {
                    //find the branch by the search query branch id
                    var branch = $window.sp.frontendData.retailer.branches.find(function(branch) {
                        return branch.id === Number(_searchParams.branchId);
                    });

                    if (!branch || !branch.areas || !branch.areas.length) {
                        return;
                    }

                    //find the first area with names
                    _loginAsArea = branch.areas.find(function(area) {
                        return area.names && area.names.length && area.deliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICKUP;
                    });
                    var parsedFeLocalStorage = {};
                        var feLocalStorage = window.localStorage.getItem('frontend')
                        if(feLocalStorage) {
                            parsedFeLocalStorage = JSON.parse(feLocalStorage);
                        }

                    // if no area was found get the first one and we have no area in local storage
                    if (!_loginAsArea && !parsedFeLocalStorage.area && !parsedFeLocalStorage.areaId) {
                        _loginAsArea = branch.areas[0];
                    } else if(parsedFeLocalStorage) {
                        // If we have stored areas in local storage
                        var storedArea = branch.areas.find(function(area) {
                            return area.name == parsedFeLocalStorage.area && area.id === parsedFeLocalStorage.areaId && area.deliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICKUP;
                        });

                        if(storedArea) {
                            _loginAsArea = storedArea;
                        } else {
                            _loginAsArea = branch.areas[0];
                        }
                    }
                }

                // if the area has no names - no area could be selected by the user,
                // it is as if there is no area in the local storage
                if (!_loginAsArea.names || !_loginAsArea.names.length) {
                    return;
                }

                if (key === LOCAL_STORAGE_KEYS.AREA_ID) {
                    return _loginAsArea.id;
                }

                return _loginAsArea.names[0].name;
            }

            function setItem(key, val) {
                frontendLocalStorage[key] = val;
                save();
            }

            function removeItem(key) {
                if (key in frontendLocalStorage) {
                    delete frontendLocalStorage[key];
                    save();
                }
            }

            function clear() {
                frontendLocalStorage = {};
                save();
            }

            function isExist(key) {
                return key in frontendLocalStorage;
            }

        }]);

})(angular, app, window);