(function (angular, app) {
    'use strict';

    app.config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.userEdit.premiumLoyaltyClub', {
                url: '/premium-loyalty-club?{loyaltyClubDriverId},{memberToken}',
                data: {
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Membership club'));
                    }]
                },
                views: {
                    'tab': {
                        templateUrl: 'template/views/user-edit/premium-loyalty-club/index.html',
                        controller: 'PremiumLoyaltyClubCtrl',
                        controllerAs: 'premiumLoyaltyCtrl'
                    }
                }
            });
        }])
        .controller('PremiumLoyaltyClubCtrl', [
            '$scope', '$rootScope', '$state', '$q', 'Api', 'Config', 'User', 'LocalStorage', 'Specials', 'PermanentFilters',
            'Cart', 'Util', '$location', '$timeout', '$window', 'SP_SERVICES', 'LOYALTY_CLUB_DRIVERS', 'LOYALTY_PAGE_METHODS',
            'Dialog', 'LOYALTY_PAGE_COMPONENT_TYPES',
            function ($scope, $rootScope, $state, $q, Api, Config, User, LocalStorage, Specials, PermanentFilters,
                    Cart, Util, $location, $timeout, $window, SP_SERVICES, LOYALTY_CLUB_DRIVERS, LOYALTY_PAGE_METHODS,
                    Dialog, LOYALTY_PAGE_COMPONENT_TYPES) {
                var premiumLoyaltyCtrl = this,
                    userEditCtrl = $scope.userEditCtrl,
                    loyaltyClubDriverId = $state.params.loyaltyClubDriverId && Number($state.params.loyaltyClubDriverId),
                    memberToken = $state.params.memberToken

                premiumLoyaltyCtrl.initPointsCalculator = initPointsCalculator;
                premiumLoyaltyCtrl.onSliderValueChange = onSliderValueChange;
                premiumLoyaltyCtrl.goToSignIn = goToSignIn;
                premiumLoyaltyCtrl.redeemPoints = redeemPoints;
                premiumLoyaltyCtrl.unredeemedPoints = unredeemedPoints;
                premiumLoyaltyCtrl.initGeneralComponent = initGeneralComponent;
                premiumLoyaltyCtrl.initExternalServicesComponent = initExternalServicesComponent;
                premiumLoyaltyCtrl.openExternalRedeemCodeDialog = openExternalRedeemCodeDialog;
                premiumLoyaltyCtrl.openExternalServicesListDialog = openExternalServicesListDialog;
                premiumLoyaltyCtrl.defaultTextColor = '#000000';

                _init();

                function _init() {
                    premiumLoyaltyCtrl.userData = null;
                    premiumLoyaltyCtrl.userLoyaltyClub = null;
                    premiumLoyaltyCtrl.userPoints = null;
                    return $q.resolve().then(function() {
                        premiumLoyaltyCtrl.retailerSettings = Config.retailer;
                        premiumLoyaltyCtrl.loyaltyClubPointsName = premiumLoyaltyCtrl.retailerSettings.settings.loyaltyClubPointsName || 'points';

                        premiumLoyaltyCtrl.loyaltyClubDriver = premiumLoyaltyCtrl.retailerSettings.loyaltyClubDrivers.find(function (driver) {
                            return driver.loyaltyClubDriverId === loyaltyClubDriverId;
                        });

                        var params = {
                            isNotIncludeRegistrationClub: true
                        }

                        var options = {
                            isForceNewRequest: true
                        }

                        return User.getData(true, params, options).then(function (resp) {
                            premiumLoyaltyCtrl.userData = resp;
                            premiumLoyaltyCtrl.userLoyaltyClub = premiumLoyaltyCtrl.userData.loyaltyClubs.find(function (userLoyaltyClub) {
                                return userLoyaltyClub.loyaltyClubDriverId === loyaltyClubDriverId;
                            });

                            if (premiumLoyaltyCtrl.userLoyaltyClub && premiumLoyaltyCtrl.userLoyaltyClub.loyaltyClubId) {
                                premiumLoyaltyCtrl.loyaltyClub = premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.loyaltyClubs[premiumLoyaltyCtrl.userLoyaltyClub.loyaltyClubId] || {};
                                premiumLoyaltyCtrl.userType =  premiumLoyaltyCtrl.userLoyaltyClub.loyaltyClubId;
                            } else {
                                premiumLoyaltyCtrl.userType = LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER;
                            }

                            if (premiumLoyaltyCtrl.userType === LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER && !!memberToken) {
                                return _getMemberData({token: memberToken});
                            }
                        }).catch(function () {
                            if (memberToken) {
                                return _getMemberData({token: memberToken});
                            } else {
                                premiumLoyaltyCtrl.userType = LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER;
                            }
                        });
                    }).then(function () {
                        if (premiumLoyaltyCtrl.userType === LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER) {
                            _setSignInComponent()
                        }

                        if (!premiumLoyaltyCtrl.userLoyaltyClub || !premiumLoyaltyCtrl.loyaltyClub || !premiumLoyaltyCtrl.loyaltyClub.pointsToCashback) {
                            return;
                        }

                        return Util.getUserCashbackPoints(premiumLoyaltyCtrl.userLoyaltyClub.id, premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.pointsDecimalRound).then(function (pointsResponse) {
                            premiumLoyaltyCtrl.userPoints = pointsResponse.points;
                            premiumLoyaltyCtrl.userPointsToken = pointsResponse.token;
                        });
                    }).then(function () {
                        if (!premiumLoyaltyCtrl.userLoyaltyClub || !premiumLoyaltyCtrl.loyaltyClub || !premiumLoyaltyCtrl.loyaltyClub.pointsToCashback) {
                            return;
                        }
                        premiumLoyaltyCtrl.loyaltyClub.minPointsNum = premiumLoyaltyCtrl.loyaltyClub.minPointsNum || 0;
                        premiumLoyaltyCtrl.userEligibleToRedeem = !!premiumLoyaltyCtrl.userPoints && premiumLoyaltyCtrl.userPoints >= premiumLoyaltyCtrl.loyaltyClub.minPointsNum;
                        premiumLoyaltyCtrl.minPointsToMoney = (premiumLoyaltyCtrl.loyaltyClub.minPointsNum * premiumLoyaltyCtrl.loyaltyClub.pointsToCashbackRatio).toFixed(0);
                        premiumLoyaltyCtrl.userPointsToMoney = (premiumLoyaltyCtrl.userPoints * premiumLoyaltyCtrl.loyaltyClub.pointsToCashbackRatio).toFixed(1);
                        premiumLoyaltyCtrl.monyeToRedeem = (premiumLoyaltyCtrl.loyaltyClub.minPointsNum - premiumLoyaltyCtrl.userPoints) * premiumLoyaltyCtrl.loyaltyClub.moneyToPointRatio;
                        premiumLoyaltyCtrl.precentsPointsOutOfMin = premiumLoyaltyCtrl.loyaltyClub.isMinPointsRequired && premiumLoyaltyCtrl.userPoints &&
                        premiumLoyaltyCtrl.userPoints < premiumLoyaltyCtrl.loyaltyClub.minPointsNum ? premiumLoyaltyCtrl.userPoints / premiumLoyaltyCtrl.loyaltyClub.minPointsNum * 100 : 0;
                        premiumLoyaltyCtrl.isUsedPoints = premiumLoyaltyCtrl.loyaltyClubDriver && premiumLoyaltyCtrl.loyaltyClub.pointsToCashback && premiumLoyaltyCtrl.userLoyaltyClub && premiumLoyaltyCtrl.userLoyaltyClub.redeemedPointsAmount &&
                            (!premiumLoyaltyCtrl.loyaltyClub.isMinPointsRequired || (premiumLoyaltyCtrl.loyaltyClub.isMinPointsRequired && premiumLoyaltyCtrl.userEligibleToRedeem));


                        if (premiumLoyaltyCtrl.isUsedPoints) {
                            _showAnimation();
                        }

                        if (premiumLoyaltyCtrl.userData) {
                            _getCoupons();
                        }
                    });
                }

                function _getMemberData(params) {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/loyalty/drivers/' + premiumLoyaltyCtrl.loyaltyClubDriver.id + '/memberByToken',
                        params: params
                    }).then(function (resp) {
                        premiumLoyaltyCtrl.userLoyaltyCardId = resp.loyaltyCardId;
                        premiumLoyaltyCtrl.userPoints = resp.points;
                        premiumLoyaltyCtrl.userPointsToken = resp.pointsToken;
                        premiumLoyaltyCtrl.userType = resp.loyaltyClubId;
                        premiumLoyaltyCtrl.loyaltyClub = premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.loyaltyClubs[resp.loyaltyClubId] || {};
                        premiumLoyaltyCtrl.isOneTimeUser = true;
                    });
                }

                function _setSignInComponent() {
                    premiumLoyaltyCtrl.signInComponent = premiumLoyaltyCtrl.loyaltyClubDriver.loyaltyPageDesign[premiumLoyaltyCtrl.userType].find(function (component) {
                        return component.type === LOYALTY_PAGE_COMPONENT_TYPES.BY_NAME.SIGN_IN_COMPONENT;
                    });

                    premiumLoyaltyCtrl.signInComponentTypesObj = !premiumLoyaltyCtrl.signInComponent.content.isDuplicate ? {} : (premiumLoyaltyCtrl.signInComponent.content.duplicateAfter || []).reduce(function(obj, key) {
                        obj[key] = key;
                        return obj;
                    }, {});
                }

                function initPointsCalculator(component) {
                    premiumLoyaltyCtrl.sliderMinValue = component.content.minValue = (component.content.minValue || component.content.minValue === 0) ? component.content.minValue : 400;
                    premiumLoyaltyCtrl.sliderMaxValue = component.content.maxValue = component.content.maxValue || 2500;
                    component.content.sliderIncrements = component.content.sliderIncrements || 100;
                    premiumLoyaltyCtrl.sliderValue = component.content.maxValue/2;
                    var input = document.querySelector('.premium-loyalty-club .components .component .points-calculator .slider-container input[type=range]');
                    var newColor = component.content.sliderColor; // this could be a passed in dynamic value, etc.
                    var newBarColor = component.content.progressBarColor; // this could be a passed in dynamic value, etc.
                    if (input) {
                        input.style.setProperty('--sliderColor', newColor);
                        input.style.setProperty('--sliderBackgroundColor', newBarColor);
                        var value = (premiumLoyaltyCtrl.sliderValue-premiumLoyaltyCtrl.sliderMinValue)/(premiumLoyaltyCtrl.sliderMaxValue-premiumLoyaltyCtrl.sliderMinValue)*100 + '%';
                        input.style.setProperty('--sliderPosition', value);

                    }
                    onSliderValueChange(component);
                }

                function onSliderValueChange(component) {
                    var calculationClub = premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.loyaltyClubs[component.content.calculationClubId], 
                        points = premiumLoyaltyCtrl.sliderValue / calculationClub.moneyToPointRatio,
                        pointsToMoney = (premiumLoyaltyCtrl.sliderValue / calculationClub.moneyToPointRatio) * calculationClub.pointsToCashbackRatio;
                    premiumLoyaltyCtrl.pointsDemo = _roundDecimal(points, premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.pointsDecimalRound);
                    premiumLoyaltyCtrl.pointsToMoneyDemo = _roundDecimal(pointsToMoney, premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.pointsDecimalRound);
                    premiumLoyaltyCtrl.yearlyProfitAmountDemo = _roundDecimal(premiumLoyaltyCtrl.pointsToMoneyDemo * 12, premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.pointsDecimalRound);

                    var input = document.getElementById("range_input");
                    var value = (premiumLoyaltyCtrl.sliderValue-premiumLoyaltyCtrl.sliderMinValue)/(premiumLoyaltyCtrl.sliderMaxValue-premiumLoyaltyCtrl.sliderMinValue)*100 + '%';
                    input.style.setProperty('--sliderPosition', value);
                }

                function _roundDecimal(amount,decimalRoundNumber) {
                    return Number.isInteger(amount) ? amount : amount.toFixed(decimalRoundNumber);
                }

                /**
                 * @param {*} designComponent
                 * @returns {string}
                 */
                function getExternalLinkFromLoyaltyPageDesign(designComponent) {
                  if (
                    !designComponent ||
                    !designComponent.content ||
                    !designComponent.content.externalRegistration
                  ) {
                    return null;
                  }

                  var externalRegistration = designComponent.content.externalRegistration;

                  if (!externalRegistration.isActive) {
                    return null;
                  }

                  return externalRegistration.link;
                }

                function goToSignIn(component) {
                    var externalLink = getExternalLinkFromLoyaltyPageDesign(component)

                    if(premiumLoyaltyCtrl.userData && externalLink){
                        return Util.goToHref(externalLink)
                    }

                    if(!premiumLoyaltyCtrl.userData && 
                        Config.retailer &&
                        Config.retailer.loyaltyClubDrivers &&
                        Config.retailer.loyaltyClubDrivers[0] &&
                        Config.retailer.loyaltyClubDrivers[0].clientConfig &&
                        Config.retailer.loyaltyClubDrivers[0].clientConfig.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty
                    ) {
                        return Util.openLoginDialog()
                    }

                    if (premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.extendedLoyaltyClub) {
                        return $state.go('app.userEdit.extendedLoyalty');
                    }
                    
                    return Util.showLoyaltyClubDialog();
                }

                function redeemPoints() {
                    return Cart.createCart().then(function() {
                        return Api.request({
                            method: 'POST',
                            url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/' + premiumLoyaltyCtrl.userLoyaltyClub.id + '/insertUserRedemptionPoints',
                            data: {
                                redeemedPointsAmount: premiumLoyaltyCtrl.userPoints,
                                pointsToken: premiumLoyaltyCtrl.userPointsToken,
                                cartId: Cart.serverCartId
                            }
                        }).then(function() {
                            premiumLoyaltyCtrl.redeemedPointsAmount = premiumLoyaltyCtrl.userPoints;
                            premiumLoyaltyCtrl.isUsedPoints = true;
                            _showAnimation();
                        });
                    })
                }


                function unredeemedPoints() {
                    return Api.request({
                        method: 'DELETE',
                        url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/'  + premiumLoyaltyCtrl.userLoyaltyClub.id + '/deleteUserRedemptionPoints/' + Cart.serverCartId,
                    }).then(function() {
                        premiumLoyaltyCtrl.isUsedPoints = false;
                        premiumLoyaltyCtrl.showAnimation = false;
                        delete premiumLoyaltyCtrl.redeemedPointsAmount;
                    })
                }

                function initGeneralComponent(currentComponent) {
                    if (premiumLoyaltyCtrl.userType !== LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER) {
                        var generalComponent = premiumLoyaltyCtrl.loyaltyClubDriver.loyaltyPageDesign[LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER].find(function (component) {
                            return component.type === currentComponent.type;
                        });

                        currentComponent.content = generalComponent.content;
                    }
                }

                function initExternalServicesComponent(currentComponent) {
                    if (premiumLoyaltyCtrl.userType !== LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER) {
                        var generalComponent = premiumLoyaltyCtrl.loyaltyClubDriver.loyaltyPageDesign[LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER].find(function (component) {
                            return component.type === currentComponent.type;
                        });

                        currentComponent.content = angular.extend(currentComponent.content, generalComponent.content);
                    }
                }

                function openExternalRedeemCodeDialog(component) {
                    _getMemberExternalRedeemCode().then(function () {
                        return Dialog.show({
                            controller: ['$scope', function ($scope) {
                                $scope.backgroundImage = premiumLoyaltyCtrl.loyaltyClub.banners.mobileLpdExternalCodePopup;
                                $scope.content = component.content;
                                $scope.externalRedeemCode = premiumLoyaltyCtrl.externalRedeemCode;
                                $scope.hide = function () {
                                    Dialog.hide();
                                }

                                var wrapper = angular.element(document.querySelector('.dialog-wrapper'));
                                wrapper.addClass('external-redeem-code-dialog-wrapper');
                            }],
                            templateUrl: 'template/dialogs/external-redeem-code-dialog/index.html',
                            styleClass: 'external-redeem-code-dialog'
                        }).then(function () {
                        });
                    });
                }

                function openExternalServicesListDialog(component) {
                    return Dialog.show({
                        controller: ['$scope', function ($scope) {
                            $scope.html = component.content.externalServicesHtml;
                        }],
                        templateUrl: 'template/dialogs/external-services-list-dialog/index.html',
                        styleClass: 'external-services-list-dialog'
                    });
                }

                function _getMemberExternalRedeemCode() {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/loyalty/drivers/' + premiumLoyaltyCtrl.loyaltyClubDriver.id + '/externalRedeemCode',
                        params: {
                            cardId: premiumLoyaltyCtrl.userLoyaltyCardId || premiumLoyaltyCtrl.userLoyaltyClub.loyaltyCardId
                        }
                    }).then(function (resp) {
                        premiumLoyaltyCtrl.externalRedeemCode = resp;
                    });
                }

                function _showAnimation() {
                    premiumLoyaltyCtrl.showAnimation = true;
                    $timeout(function () {
                        premiumLoyaltyCtrl.showAnimation = false;
                    }, 5000)
                }

                function _getCoupons() {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons',
                        params: { extended: true, countonly: false }
                    }, {
                        fireAndForgot: true
                    }).then(function (couponsData) {
                        premiumLoyaltyCtrl.coupons = couponsData.coupons;
                    });
                }

                premiumLoyaltyCtrl.couponAction = {
                    openCoupon: function(coupon, terms) {
                        Util.showCouponDetailsDialog(coupon, terms);
                    }
                }

                Util.currentScopeListener($scope, $scope.$root.$on('login', function () {
                    $timeout(function () {
                        _init();
                    });
                }));

                Util.currentScopeListener($scope, $scope.$root.$on('logout', _init));
            }
        ]);
})(angular, app);
