(function (angular, app) {
    'use strict';

    app.config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.premiumLoyaltyClub', {
                url: '/premium-loyalty-club?{loyaltyClubDriverId},{memberToken}',
                data: {
                    name: 'loyalty-club'
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('loyalty club'));
                    }]
                },
                views: {
                    '@': {
                        template: 'Redirecting...',
                        controller: ['$state', '$timeout', function($state, $timeout) {
                            $timeout(function() {
                                return $state.go('app.userEdit.premiumLoyaltyClub', $state.params, {inherit: true});
                            }, 500);
                        }]
                    }
                }
            });
        }])

})(angular, app);
