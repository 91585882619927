(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.retailerDetails', {
                url: '/retailer',
                abstract: true,
                data: {
                    name: 'retailer-details',
                    layout: {
                        sidenav: false
                    }
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/retailer-details/index.html',
                        controller: 'RetailerDetailsCtrl as retailerDetailsCtrl',
                        resolve: {
                            branchesData: ['Api', function (api) {
                                return api.request({
                                    method: 'GET',
                                    url: '/v2/retailers/:rid/branches',
                                    params: {
                                        isActive: true
                                    }
                                });
                            }]
                        }
                    }
                }
            });
        }])
        .controller('RetailerDetailsCtrl', [
            '$rootScope', '$state', 'Config', 'Util', 'branchesData', 'BRANCH_AREA_TYPES',
            function ($rootScope, $state, Config, Util, branchesData, BRANCH_AREA_TYPES) {
                var retailerDetailsCtrl = this;

                angular.extend(retailerDetailsCtrl, {
                    openContactUs: Util.openContactUs,
                    branchesInfo: [],
                    menuTabs: {
                        STORE_INFORMATION: 1,
                        TIMES: 2,
                        FAQ: 3,
                        CONTACT_DETAILS: 4,
                        CONTACT_US: 5
                    },
                    deliveryOptions: {1: 'Delivery', 2: 'Pickup'},
                });
                retailerDetailsCtrl.deliveryOptionSelected = $rootScope.SP_SERVICES.DELIVERY_TYPES.PICKUP;
                retailerDetailsCtrl.isHiddenBranchName = Config.retailer.settings.isHiddenBranchName === 'true';
                retailerDetailsCtrl.deliveryOptionsKeys = Object.keys(retailerDetailsCtrl.deliveryOptions);
                retailerDetailsCtrl.$state = $state;

                _setTabs();

                angular.forEach(branchesData.branches, function(branch) {
                    var newBranch = {
                        id: branch.id,
                        name: branch.name,
                        city: branch.city,
                        location: branch.location,
                        phone: branch.phone,
                        email: branch.email,
                        isDefault: branch.isDefault,
                        accessibility: branch.accessibility,
                        openHours: '',
                        storeLogo: branch.logoUrl || sp.frontendData.retailer.logoUrl || '',
                        areas: {
                            pickup: [],
                            delivery: []
                        },
                        mapOptions: {
                            zoom: 17,
							languageId:  $rootScope.config.language.id,
                            icon: 'https://d226b0iufwcjmj.cloudfront.net/global/favicon.ico',
                            showMarker: true,
                            center: branch.location + ' ' + branch.city,
                            storeName: branch.name,
                            storeLogo: branch.logoUrl || sp.frontendData.retailer.logoUrl || '',
                            //protect from themeConfig not set yet (happens on googlebot)
                            polygonColor: (sp.themeConfig && sp.themeConfig.mainColor) || '#207d3f'
                        },
                        mapIframeUrl: 'https://maps.google.com/maps?q=' + (branch.location + ' ' + branch.city).replace(/\s/g, '+') + '&language=' + $rootScope.config.language.culture.replace('he','iw') + '&z=17&ie=UTF8&output=embed'
                    };

                    angular.forEach(Config.retailer.branches, function(branch) {
                        if (branch.id === newBranch.id) {
                            newBranch.openHours = branch.openHours;
                        }
                    });

                    angular.forEach(branch.areas, function (area) {
                        (area.type == BRANCH_AREA_TYPES.PICKUP ? newBranch.areas.pickup : newBranch.areas.delivery).push({
                            id: area.id,
                            type: area.type,
                            name: area.name,
                            polygons: area.polygons,
                            times: _setAreaTimes(area, area.type),
                            mapOptions: {
                                icon: 'https://d226b0iufwcjmj.cloudfront.net/global/favicon.ico',
                                showMarker: area.type === BRANCH_AREA_TYPES.PICKUP || area.polygons === undefined,
                                setBounds: area.polygons !== undefined,
                                zoom: area.type === BRANCH_AREA_TYPES.PICKUP || area.polygons === undefined ? 17 : 9,
                                center: branch.location + ' ' + branch.city,
                                storeName: branch.name,
                                storeLogo: sp.frontendData.retailer.logoUrl || '',
                                //protect from themeConfig not set yet (happens on googlebot)
                                polygonColor: (sp.themeConfig && sp.themeConfig.mainColor) || '#207d3f'
                            },
                            //mapIframeUrl: 'https://maps.google.com/maps?q=' + (branch.location + ' ' + branch.city).replace(/\s/g, '+') + '&language=' + $rootScope.config.language.culture.replace('he','iw') + '&z=17&ie=UTF8&output=embed'

                        });
                    });
                    retailerDetailsCtrl.branchesInfo.push(newBranch);
                });

                function _setTabs() {
                    retailerDetailsCtrl.tabs = [
                        {
                            title: Config.retailer.branches.length > 1 ? 'Stores Info' : 'Store Info',
                            state: 'storeInformation',
                            icon: 'store-location'
                        },
                        {
                            title: Config.retailer.pickup && !Config.retailer.delivery ? 'Pickup Times & Areas' : 'Delivery Times & Areas',
                            state: 'times',
                            icon: 'delivery-times'
                        }
                    ];

                    if (Config.retailer.settings.faq && sp.frontendData.retailer.faqs.length) {
                        retailerDetailsCtrl.tabs.push({
                            title: 'FAQ',
                            state: 'faq',
                            icon: 'question-mark'
                        });
                    }

                    if (Config.retailer.settings.contactUsText && Config.retailer.settings.contactUsText.length) {
                        retailerDetailsCtrl.tabs.push({
                            title: 'Contact Details',
                            state: 'contact-details',
                            icon: 'tooltip'
                        });
                    }
                }

                function _setAreaTimes(area) {
                    var week = [];
                    for (var i = 0; i < 6; i++) {
                        week[i] = [];
                    }
                    angular.forEach(area.times, function (time) {
                        week[time.dayInWeek] = week[time.dayInWeek] || [];
                        week[time.dayInWeek].push(time);
                    });
                    return week;
                }
            }]);
})(angular, app);
