(function (angular, app) {
    'use strict';

    app.component('spTabs', {
        controller: ['$element', 'Config', SpTabsController],
        controllerAs: 'spTabsCtrl',
        transclude: true,
        templateUrl: 'template/components/sp-tabs/index.html'
    });

    function SpTabsController($element, config) {
        var spTabsCtrl = this,
            _addedPanes = {},
            _panesIdCounter = 1;

        spTabsCtrl.panes = [];
        spTabsCtrl.deselectAll = deselectAll;
        spTabsCtrl.addPane = addPane;
        spTabsCtrl.onTabKeyDown = onTabKeyDown;

        function deselectAll() {
            angular.forEach(spTabsCtrl.panes, function(pane) {
                pane.deselect();
            });
        }

        function addPane(pane, element) {
            element = angular.element(element)[0];
            element._spPanesId = _panesIdCounter++;
            _addedPanes[element._spPanesId] = {pane: pane, element: element};

            spTabsCtrl.panes.splice(0);
            angular.forEach($element[0].querySelectorAll('sp-tabs-pane'), function(paneChildElement) {
                if (paneChildElement.hasOwnProperty('_spPanesId')) {
                    spTabsCtrl.panes.push(_addedPanes[paneChildElement._spPanesId].pane);
                }
            });

            spTabsCtrl.panes[0].select();
        }

        function onTabKeyDown(event) {
            var _parentElement = event.currentTarget.parentElement,
            direction = config.language.direction,
            nextElement = event.currentTarget.nextElementSibling || _parentElement.firstElementChild,
            previousElement = event.currentTarget.previousElementSibling || _parentElement.lastElementChild;
            if (event.which === 39) {
                if(direction === 'rtl') {
                    previousElement.focus();
                    selectFocusedElement(previousElement);
                } else {
                    nextElement.focus();
                    selectFocusedElement(nextElement);
                }
                event.preventDefault();
                event.stopImmediatePropagation();
            } else if (event.which === 37) {
                if(direction === 'rtl') {
                    nextElement.focus();
                    selectFocusedElement(nextElement);
                } else {
                    previousElement.focus();
                    selectFocusedElement(previousElement);
                }
                event.preventDefault();
                event.stopImmediatePropagation();
            }

        }

        function selectFocusedElement(element) {
            var id = element.getAttribute('aria-controls');
            spTabsCtrl.panes.find(function (el) {
                return el.paneId === id
            }).select()
        }
    }
})(angular, app);
