(function (angular, app) {
    'use strict';

    app.service('DeliveryItemsLimitDialog', ['Dialog', function(Dialog) {
        this.show = show;

        function show() {
            return Dialog.show({
                controller: 'DeliveryItemsLimitCtrl',
                controllerAs: 'deliveryItemsLimitCtrl',
                templateUrl: 'template/dialogs/delivery-items-limit/index.html',
                styleClass: 'delivery-items-limit-dialog',
                bypass: true,
                resolve: {
                    areas: [
                        'LocalStorage', 'SpDeliveryAreasService', 'SP_SERVICES',
                        function(LocalStorage, SpDeliveryAreasService, SP_SERVICES) {
                            var typedAreaText = LocalStorage.getItem('typedAreaText');
                            if (!typedAreaText) {
                                return [];
                            }

                            return SpDeliveryAreasService.getChooseAreaMode().then(function(mode) {
                                if (mode === SP_SERVICES.CHOOSE_AREA_MODE.CHOOSE_RETAILER) {
                                    return SpDeliveryAreasService.getAreas();
                                }

                                return SpDeliveryAreasService.filterDeliveryAreas(mode, typedAreaText).then(function (resp) {
                                    return resp.areas;
                                });
                            });
                        }
                    ]
                }
            });
        }
    }]);

    app.controller('DeliveryItemsLimitCtrl', [
        '$q', 'Dialog', 'ChooseAreaDialog', 'LocalStorage', 'areas',
        function ($q, Dialog, ChooseAreaDialog, LocalStorage, areas) {
            var deliveryItemsLimitCtrl = this;

            deliveryItemsLimitCtrl.changeDeliveryType = changeDeliveryType;
            deliveryItemsLimitCtrl.showChangeDeliveryMethod = areas.length > 1;
            deliveryItemsLimitCtrl.deliveryMethods = _getAvailableDeliveryMethods();

            function _getAvailableDeliveryMethods() {
                var keys = {},
                    values = [];
                angular.forEach(areas, function(area) {
                    if (keys[area.deliveryTypeId]) {
                        return;
                    }

                    keys[area.deliveryTypeId] = true;
                    values.push(area.deliveryTypeId);
                });
                return values;
            }

            function changeDeliveryType() {
                return $q.resolve(Dialog.hide()).then(function() {
                    return ChooseAreaDialog.show(false, null, LocalStorage.getItem('typedAreaText'));
                });
            }
        }]);
})(angular, app);