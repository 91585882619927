(function (angular, app) {
  'use strict';

  /**
   * @typedef {Object} AddressDetail
   * @property {number} placeId
   * @property {string} text1
   * @property {string} city
   * @property {string} country
   * @property {number} lat
   * @property {number} lng
   */

  app.controller('GoogleMapDialogCtrl', ['Dialog', '$timeout', '$scope', 'GoogleMapService', 'LocationService', 'Toast', 'GOOGLE_MAP_ADDRESS_TYPES',
    function (Dialog, $timeout, $scope, GoogleMapService, LocationService, Toast, GOOGLE_MAP_ADDRESS_TYPES) {
      var googleMapDialogCtrl = this;
      var _DEFAULT_ZOOM = 18;
      var POPUP_MARKER_UI = '<div><svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="13" height="22" viewBox="0 0 13 22" fill="none"><use xlink:href="#sp-icons-small-gg-map-marker"></use></svg><p>{formattedAddress}</p></div>';

      angular.extend(googleMapDialogCtrl, {
        markerPopup: null,
        addressDetail: {
          city: '',
          text1: '',
          zipCode: '',
          country: '',
          geoCoordinates: null,
          externalPlaceId: null,
          hasHouseNumberAndRoute: true,
        },
        formattedAddress: '',
        mapErrorMessage: '',

        handleOnSaveSelectedAddress: handleOnSaveSelectedAddress
      });

      _init();

      //================== PRIVATES FUNCTIONS  ==================//
      /**
       * @param {AddressComponents[]} addressComponents
       * @returns {{houseNumber: string, route: string, city: string, country: string, countryCode: string, zipCode: string}}
       */
      function _extractInfoFromAddrComponents(addressComponents) {
        var initialResult = {
          houseNumber: '',
          route: '',
          city: '',
          country: '',
          zipCode: '',
          countryCode: ''
        };
        return addressComponents.reduce(function (result, component) {
          var types = component.types;
          var longName = component.long_name;
          var shortName = component.short_name;

          types.forEach(function (type) {
            switch (type) {
              case GOOGLE_MAP_ADDRESS_TYPES.STREET_NUMBER:
              case GOOGLE_MAP_ADDRESS_TYPES.PREMISE: {
                result.houseNumber = longName;
                break;
              }
              case GOOGLE_MAP_ADDRESS_TYPES.ROUTE: 
              case GOOGLE_MAP_ADDRESS_TYPES.ESTABLISHMENT: {
                result.route = longName;
                break;
              }
              case GOOGLE_MAP_ADDRESS_TYPES.LOCALITY:
              case GOOGLE_MAP_ADDRESS_TYPES.SUB_LOCALITY:
              case GOOGLE_MAP_ADDRESS_TYPES.NEIGHBORHOOD:
              case GOOGLE_MAP_ADDRESS_TYPES.POSTAL_TOWN:
              case GOOGLE_MAP_ADDRESS_TYPES.ADMIN_AREA_LV2: {
                // avoid cases have both locality and admin_area_lv2. Prioritize locality more
                if(!result.city){
                  result.city = longName;
                }
                break;
              }
              case GOOGLE_MAP_ADDRESS_TYPES.ZIP_CODE: {
                result.zipCode = longName;
                break;
              }
              case GOOGLE_MAP_ADDRESS_TYPES.COUNTRY: {
                result.country = longName;
                result.countryCode = shortName;
                break;
              }
            }
          });
          return result;
        }, initialResult);
      }

      /**
       * Format address based on country code
       * @param {string} countryCode 
       * @param {string} route 
       * @param {string} street 
       * @returns {string}
       */
      function _formatAddressByCountryCode(countryCode, houseNumber, route) {
        var SPAIN_COUNTRY_CODE = 'ES';
        var RUSSIA_COUNTRY_CODE = 'RU';
        var ISRAEL_COUNTRY_CODE = 'IL';
        switch(countryCode) {
          case SPAIN_COUNTRY_CODE:
          case RUSSIA_COUNTRY_CODE: {
            /**
             * In these countries, The address often has the format: [Route], [Street Number]
             * For example: RouteTest, 123
            */
            return [route, houseNumber].join(', ').trim();
          }
          case ISRAEL_COUNTRY_CODE: {
            /**
             * In this country, The address often has the format: [Route] [Street Number]
             * For example: RouteTest 123
            */
            return [route, houseNumber].join(' ').trim();
          }
          default: {
            /**
             * The common address format in the word often has following format: [Street Number] [Route]
             * For example: 123 RouteTest
            */
            return [houseNumber, route].join(' ').trim();
          }
        }
      }

      /**
       * @param {string} countryCode
       * @param {string} houseNumber
       * @param {string} route
       * @param {string} city
       * @returns {{value: string, hasHouseNumberAndRoute: boolean}}
       */
      function _constructText1(countryCode, houseNumber, route, city) {
        var text1Obj = {
          value:'',
          hasHouseNumberAndRoute: true,
        };
        if (city) {
          var replaceText1 = houseNumber || route || city;
          var hasHouseNumberAndRoute = houseNumber && route;
          text1Obj.hasHouseNumberAndRoute = hasHouseNumberAndRoute;
          text1Obj.value = hasHouseNumberAndRoute ? _formatAddressByCountryCode(countryCode, houseNumber, route) : replaceText1;
        }
        return text1Obj;
      }

      function _setAddress(geocode) {
        var extractedInfo = _extractInfoFromAddrComponents(geocode.address_components);
        var text1Obj = _constructText1(extractedInfo.countryCode, extractedInfo.houseNumber, extractedInfo.route, extractedInfo.city);
        googleMapDialogCtrl.formattedAddress = geocode.formatted_address;
        angular.extend(googleMapDialogCtrl.addressDetail, {
          text1: text1Obj.value,
          city: extractedInfo.city,
          country: extractedInfo.country,
          zipCode: extractedInfo.zipCode,
          geoCoordinates: {
            lat: geocode.geometry.location.lat(),
            lng: geocode.geometry.location.lng(),
          },
          externalPlaceId: geocode.place_id,
          hasHouseNumberAndRoute: text1Obj.hasHouseNumberAndRoute
        });
        $scope.$applyAsync();
      }

      /**
       * @param {GeoLocation} 
       * @returns {google.maps.Map}
       */
      function _initMap(defaultLocation) {
        var mapRef = document.getElementById('map');
        if (!mapRef) {
          return;
        }
        var map = GoogleMapService.initMap(mapRef, {
          zoom: _DEFAULT_ZOOM,
          center: defaultLocation,
        });
        return map;
      }

      /**
       * @param {GeoLocation} location refer service/google-map
       */
      function _setAddressFromLocation(location) {
        GoogleMapService.reverseGeocode(location).then(function (geocode) {
          _setAddress(geocode);
        });
      }

      /**
       * @param {google.maps.Map} map 
       * @param {{lat: number, lng: number}} defaultLocation 
       * @returns {google.maps.Marker}
       */
      function _initMarker(map, location) {
        var marker = GoogleMapService.addMarker(map, location);
        return marker;
      }

      /**
       * @param {google.maps.Map} map 
       * @param {google.maps.Marker} marker 
       */
      function _addMarkerEventListener(map, marker) {
        GoogleMapService.addDragMarkerEventLister(marker, _setAddress);
        GoogleMapService.addClickMapMoveMarkerEventListener(marker, map, _setAddress);
        googleMapDialogCtrl.markerPopup = GoogleMapService.addPopupOnClickingMarker(map, marker, null);
      }

      /**
       * @param {GeoLocation} location
       * @param {google.maps.Map} map
       */
      function _initAutoComplete(location, map, marker) {
        var inputRef = document.getElementById('pac-input');
        if (!inputRef) {
          return;
        }
        var autocomplete = GoogleMapService.initAutoComplete(inputRef, location, map);
        GoogleMapService.addAutocompleteChangeEventListener(autocomplete, map, marker, _setAddress);
      }

      function _init() {
        LocationService.getUserDefaultLocation().then(function (defaultLocation) {
          var map = _initMap(defaultLocation);
          $timeout(function () {
            var marker = _initMarker(map, defaultLocation);
            _setAddressFromLocation(defaultLocation);
            _addMarkerEventListener(map, marker);
            _initAutoComplete(defaultLocation, map, marker);
          }, 300);
        }).catch(function (error) {
          googleMapDialogCtrl.mapErrorMessage = error.message;
          Toast.show({
            timeout: 5000,
            content: googleMapDialogCtrl.mapErrorMessage,
          });
        });
      }

      //================== PUBLIC FUNCTIONS  ==================//
      function handleOnSaveSelectedAddress() {
        Dialog.hide(googleMapDialogCtrl.addressDetail);
      }

      //================== SCOPE LISTENER  ==================//
      $scope.$watch('googleMapDialogCtrl.formattedAddress', function () {
        var popupContent = POPUP_MARKER_UI.replace('{formattedAddress}', googleMapDialogCtrl.formattedAddress);
        if (googleMapDialogCtrl.markerPopup) {
          googleMapDialogCtrl.markerPopup.setContent(popupContent);
        }
      });
    },
  ]);
})(angular, app);
