(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.loyaltyTerms', {
                url: '/loyalty-terms',
                data: {
                    name: 'loyalty-terms'
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return $filter('translate')('Loyalty Terms & conditions');
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/loyalty-terms/index.html',
                        controller: 'LoyaltyTermsCtrl'
                    }
                }
            });
        }])
        .controller('LoyaltyTermsCtrl', ['$scope', 'Config', function ($scope, config) {
            $scope.loyaltyTerms = config.retailer.settings.loyaltyPrivacyText;
        }]);

})(angular, app);
