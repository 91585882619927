(function (angular, app) {
  "use strict";

  function config(
    SpDialogUrlManagerProvider,
    SP_URL_DIALOG_QUERY_PARAMS,
    URL_DIALOG_DATA_QUERY_PARAMS,
    RETAILER_STATUSES
  ) {
    SpDialogUrlManagerProvider.dialog({
      queryParams: SP_URL_DIALOG_QUERY_PARAMS.LOGIN,
      dataQueryParams: URL_DIALOG_DATA_QUERY_PARAMS.LOGIN,
      canShow: [
        "User",
        function (User) {
          return !User.session.userId;
        },
      ],
      show: [
        "Dialog",
        "SpDialogUrlManager",
        "Config",
        function (Dialog, SpDialogUrlManager, Config) {
          var templateUrl = "template/dialogs/login-or-register/index.html";
          var styleClass = "login-or-register";
          var disableClosing =
            Config.retailer.status === RETAILER_STATUSES.REGISTERED_ONLY ||
            Config.retailer.status === RETAILER_STATUSES.ORGANIZATION_ONLY;
          if (
            Config.retailer.loyaltyClubDrivers &&
            Config.retailer.loyaltyClubDrivers[0] &&
            Config.retailer.loyaltyClubDrivers[0].clientConfig &&
            Config.retailer.loyaltyClubDrivers[0].clientConfig
              .shouldAllowLoginOrRegistrationToBothSiteAndLoyalty
          ) {
            templateUrl = "template/dialogs/login-or-register-v2/index.html";
            styleClass = "login-or-register-v2";
            disableClosing = true;
          }
          return Dialog.show({
            templateUrl: templateUrl,
            controller: "LoginOrRegisterCtrl",
            controllerAs: "loginOrRegisterCtrl",
            styleClass: styleClass,
            disableClosing: disableClosing,
            ariaLabelledby: "login_or_register_title",
            hide: SpDialogUrlManager.backClose,
          });
        },
      ],
      hide: [
        "$rootScope",
        "$window",
        "Dialog",
        "Config",
        "Util",
        "User",
        "UserVerificationDialog",
        "LOYALTY_CLUB_DRIVERS",
        function (
          $rootScope,
          $window,
          Dialog,
          Config,
          Util,
          User,
          UserVerificationDialog,
          LOYALTY_CLUB_DRIVERS
        ) {
          var hideClass =
              "fadeOut" +
              (Config.language.direction === "rtl" ? "Left" : "Right"),
            regularHideClass = "zoomOut",
            showClass =
              "fadeIn" +
              (Config.language.direction === "rtl" ? "Right" : "Left");

          var tasks = [
            function (isLast) {
              return Dialog.hide(undefined, isLast ? undefined : hideClass);
            },
          ];

          if (
            Config.retailer.settings
              .isExternalLoyaltyClubRegistrationEnabled === "true"
          ) {
            tasks.push(function () {
              return Util.showLoyaltyIFrameDialog(
                Config.retailer.settings.externalLoyaltyClubRegistrationUrl
              );
            });
          }

          if ($window.hideAfterRegister) {
            delete $window.hideAfterRegister;

            if (!User.isVerified()) {
              tasks.push(function (isLast) {
                return UserVerificationDialog.show(true, {
                  show: showClass,
                  hide: isLast ? regularHideClass : hideClass,
                });
              });
            }

            if (
              $window.openLoyaltyAfterRegister &&
              !!_findRegularLoyaltyClub()
            ) {
              tasks.push(function (isLast) {
                return Util.showLoyaltyClubDialog(
                  {
                    show: showClass,
                    hide: isLast ? regularHideClass : hideClass,
                  },
                  true,
                  true
                );
              });
            }
          }

          function _findRegularLoyaltyClub() {
            return $rootScope.config.retailer.loyaltyClubDrivers.find(function (
              loyaltyClubDriver
            ) {
              return (
                !loyaltyClubDriver.clientConfig.extendedLoyaltyClub &&
                loyaltyClubDriver.loyaltyClubDriverId !==
                  LOYALTY_CLUB_DRIVERS.LOOPBACK
              );
            });
          }

          function _runNextTask() {
            if (!tasks.length) {
              return;
            }

            var task = tasks.splice(0, 1);
            return task[0](!tasks.length).then(function () {
              return _runNextTask();
            });
          }

          return _runNextTask();
        },
      ],
    });
  }

  function ctrl(
    $rootScope,
    $controller,
    $timeout,
    $location,
    $q,
    $filter,
    $sce,
    $state,
    SpDialogUrlManager,
    user,
    config,
    HubService,
    RETAILER_STATUSES
  ) {
    var loginOrRegisterCtrl = this,
      search = $location.search(),
      _translate = $filter("translate");

    var shouldAllowLoginOrRegistrationToBothSiteAndLoyalty =
      config.retailer &&
      config.retailer.loyaltyClubDrivers &&
      config.retailer.loyaltyClubDrivers[0] &&
      config.retailer.loyaltyClubDrivers[0].clientConfig &&
      config.retailer.loyaltyClubDrivers[0].clientConfig
        .shouldAllowLoginOrRegistrationToBothSiteAndLoyalty;

    var SIGN_IN_TAB = {
        title: "login_tab_title",
        templateUrl: shouldAllowLoginOrRegistrationToBothSiteAndLoyalty
          ? "template/dialogs/login-or-register-v2/login/index.html"
          : "template/dialogs/login-or-register/login/index.html",
        controller: "LoginCtrl as loginCtrl",
      },
      REGISTER_TAB = {
        title: "register_tab_title",
        templateUrl: shouldAllowLoginOrRegistrationToBothSiteAndLoyalty
          ? "template/dialogs/login-or-register-v2/register/index.html"
          : "template/dialogs/login-or-register/register/index.html",
        controller: "RegisterCtrl as registerCtrl",
      },
      REGISTER_HUB_TAB = {
        title: "register_tab_title",
        templateUrl: shouldAllowLoginOrRegistrationToBothSiteAndLoyalty
          ? "template/dialogs/login-or-register-v2/register-hub/index.html"
          : "template/dialogs/login-or-register/register-hub/index.html",
        controller: "RegisterCtrl as registerCtrl",
      };

    loginOrRegisterCtrl.phoneRegExp = /^(\+\d+|\d+)$/;
    loginOrRegisterCtrl.LoginOrRegisterScreens =
      $rootScope.LOGIN_OR_REGISTER_SCREENS;
    loginOrRegisterCtrl.LoginWithOtpOptions = $rootScope.LOGIN_WITH_OTP_OPTIONS;
    loginOrRegisterCtrl.UserTypeForCompletionForm =
      $rootScope.USER_TYPE_FOR_COMPLETION_FORM;
    loginOrRegisterCtrl.show = show;
    loginOrRegisterCtrl.closeDialog = closeDialog;
    loginOrRegisterCtrl.onSelect = onSelect;
    loginOrRegisterCtrl.getCheckboxForCompletionForm =
      getCheckboxForCompletionForm;

    loginOrRegisterCtrl.showFooter = true;
    loginOrRegisterCtrl.fromLoyaltyDialog = search.loginDialogFromLoyalty;
    var allowSendPromotions = !!config.retailer.allowSendPromotionsDefault;
    var policyApproval = !!config.retailer.allowTermsAndConditionsDefault;
    loginOrRegisterCtrl.user = {
      allowSendPromotions: allowSendPromotions,
      policyApproval: policyApproval
    };

    _initCtrl();

    function _initCtrl() {
      $q.resolve()
        .then(function () {
          if (!config.hubRetailer || !config.hubRetailer.id) {
            return;
          }

          return HubService.getData(config.hubRetailer.id).then(function (hub) {
            loginOrRegisterCtrl.hubData = hub;
          });
        })
        .then(function () {
          var registerTab = loginOrRegisterCtrl.hubData
            ? REGISTER_HUB_TAB
            : REGISTER_TAB;

          if (search.email && search.resetPasswordCode) {
            loginOrRegisterCtrl.firstTitle = getText(
              "title",
              "Let's change your password..."
            );
            SIGN_IN_TAB.title = getText("loginTabTitle", "login_tab_title");
            loginOrRegisterCtrl.tabs = [SIGN_IN_TAB];
            registerTab.title = getText(
              "registerTabTitle",
              "register_tab_title"
            );
            loginOrRegisterCtrl.tabs.push(registerTab);
          } else if (
            config.retailer.status === RETAILER_STATUSES.REGISTERED_ONLY
          ) {
            loginOrRegisterCtrl.firstTitle = getText(
              "title",
              "The site is active only for registered users",
              "please login"
            );
            SIGN_IN_TAB.title = getText("loginTabTitle", "login_tab_title");
            loginOrRegisterCtrl.tabs = [SIGN_IN_TAB];
          } else if (
            config.retailer.status === RETAILER_STATUSES.ORGANIZATION_ONLY
          ) {
            var canRegister =
              config.retailer.settings.registrationDomainsEnabled || "false";
            var text =
              canRegister === "true"
                ? "please login or register"
                : "please login";
            loginOrRegisterCtrl.firstTitle = getText(
              "title",
              "The site is active only for organization users",
              text
            );
            SIGN_IN_TAB.title = getText("loginTabTitle", "login_tab_title");
            registerTab.title = getText(
              "registerTabTitle",
              "register_tab_title"
            );
            loginOrRegisterCtrl.tabs =
              canRegister === "true"
                ? [SIGN_IN_TAB, registerTab]
                : [SIGN_IN_TAB];
          } else {
            loginOrRegisterCtrl.firstTitle = getText(
              "title",
              "Please sign in to continue...",
              "It's easy!"
            );
            SIGN_IN_TAB.title = getText("loginTabTitle", "login_tab_title");
            registerTab.title = getText(
              "registerTabTitle",
              "register_tab_title"
            );
            loginOrRegisterCtrl.tabs = [SIGN_IN_TAB, registerTab];
          }

          show(loginOrRegisterCtrl.tabs[0]);

          $timeout(function () {
            if (search.loginOrRegister === "2") {
              show(loginOrRegisterCtrl.tabs[1]);
            }
          }, 50);

          angular.forEach(loginOrRegisterCtrl.tabs, function (tab) {
            var prevCtrl = tab.controller;
            tab.controller = [
              "$scope",
              function ($scope) {
                $controller(prevCtrl, { $scope: $scope });
              },
            ];
          });
        })
        .then(function () {
          loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty =
            config.retailer.loyaltyClubDrivers &&
            config.retailer.loyaltyClubDrivers[0] &&
            config.retailer.loyaltyClubDrivers[0].clientConfig &&
            config.retailer.loyaltyClubDrivers[0].clientConfig.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty;
            
            //config.retailer.loyaltyClubDriver && config.retailer.loyaltyClubDriver.clientConfig && config.retailer.loyaltyClubDriver.clientConfig.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty;


          getUnifiedAuthConfig();
        });
    }

    function show(tab) {
      loginOrRegisterCtrl.shownTab = tab;

      if (tab === loginOrRegisterCtrl.tabs[1]) {
        loginOrRegisterCtrl.user = {
          allowSendPromotions: allowSendPromotions,
          policyApproval: policyApproval
        };
      }
    }

    function _transformSelectedFormFields() {
      if (loginOrRegisterCtrl.unifiedAuthSettings &&  loginOrRegisterCtrl.unifiedAuthSettings.signupForm) {
        loginOrRegisterCtrl.unifiedAuthSettings.signupForm.selectedRegistrationFormFields = loginOrRegisterCtrl.unifiedAuthSettings.signupForm.selectedRegistrationFormFields.map(function (field) {
          if (field.model === "phoneNumber") {
            field.minLength = 10;
            field.maxLength = 10;
            field.minLengthErrMessage = _translate("Enter valid phone number (10 digits)");
            field.maxLengthErrMessage = _translate("Enter valid phone number (10 digits)");
          }
          if(field.model === 'password') {
            field.minLength = 6;
            field.maxLength = 30;
            field.minLengthErrMessage = _translate('password: minlength error');
            field.maxLengthErrMessage = _translate("password: maxlength error");
            field.match = {
              regexp: /^(?=.*[0-9])(?=.*[a-zA-Z]).*$/,
              errorMessage: _translate('Password must contain at least one numeric digit and one character')
            }
          }
          field.label = (field.title && field.title[config.language.id]) || field.label
          return field
        })
      }
    }

    function getAdditionalRegistrationFields() {
      if (loginOrRegisterCtrl.unifiedAuthSettings && loginOrRegisterCtrl.unifiedAuthSettings.signupForm)
        return loginOrRegisterCtrl.unifiedAuthSettings.signupForm.selectedRegistrationFormFields.filter(
          function (item) {
            return !item.isMandatory;
          }
        )
      return [];
    }

    function getMandatoryFields() {
      if (loginOrRegisterCtrl.unifiedAuthSettings && loginOrRegisterCtrl.unifiedAuthSettings.signupForm)
        return loginOrRegisterCtrl.unifiedAuthSettings.signupForm.selectedRegistrationFormFields.filter(
          function (item) {
            return item.isMandatory;
          }
        );
      return [];
    }

    function getAllRegistrationFields() {
      if (loginOrRegisterCtrl.unifiedAuthSettings && loginOrRegisterCtrl.unifiedAuthSettings.signupForm) return loginOrRegisterCtrl.unifiedAuthSettings.signupForm.selectedRegistrationFormFields
      return [];
    }

    function getAllCheckboxes() {
      var checkboxes = [
        {
          label:
            loginOrRegisterCtrl.termsAndConditionsText ||
            $sce.getTrustedHtml(
              _translate("I agree to the") +
                " <a href='" +
                $state.href("app.terms-and-conditions", {
                  loginOrRegister: null,
                }) +
                "'>" +
                _translate("Terms & Conditions") +
                "</a> " +
                _translate("and the") +
                " <a href='" +
                $state.href("app.policies", { loginOrRegister: null }) +
                "'>" +
                _translate("Privacy Policy") +
                "</a> " +
                _translate("policy_end")
            ),
          model: "policyApproval",
          required: true,
        },
        {
          label:
            loginOrRegisterCtrl.promotionText ||
            _translate("Please let me know about promotions"),
          model: "allowSendPromotions",
          required: false,
        },
        {
          label:
            loginOrRegisterCtrl.loyaltyTermsText ||
            $sce.getTrustedHtml(
              _translate("loyalty_terms_confirm") +
                " <a ui-sref='app.loyaltyTerms'>" +
                _translate("Loyalty Terms") +
                "</a> "
            ),
          model: "byLaws",
          required: true,
        },
      ];

      return checkboxes;
    }

    function getCheckboxForCompletionForm() {
      var selectedCheckboxes = [];
      var checkboxes = getAllCheckboxes();

      if (
        loginOrRegisterCtrl.userTypeForCompletionForm ===
        loginOrRegisterCtrl.UserTypeForCompletionForm.NEW_USER
      ) {
        selectedCheckboxes = checkboxes;
      } else if (
        loginOrRegisterCtrl.userTypeForCompletionForm ===
        loginOrRegisterCtrl.UserTypeForCompletionForm
          .SITE_USER_BUT_NOT_LOYALTY_MEMBER
      ) {
        selectedCheckboxes = [checkboxes[2]];
      } else if (
        loginOrRegisterCtrl.userTypeForCompletionForm ===
        loginOrRegisterCtrl.UserTypeForCompletionForm
          .LOYALTY_MEMBER_BUT_NOT_SITE_MEMBER
      ) {
        selectedCheckboxes = checkboxes.slice(0, 2);
      }

      return selectedCheckboxes;
    }

    function getUnifiedAuthConfig() {
      if (loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty) {
        var selectedClubId = config.retailer.loyaltyClubDrivers[0].clientConfig.selectedClubForUnifiedAuth;
        var loyaltyClubs = config.retailer.loyaltyClubDrivers[0].clientConfig.loyaltyClubs;
        var selectedClub;

        if (selectedClubId) {
          selectedClub = loyaltyClubs[selectedClubId];
        } else {
          selectedClub = loyaltyClubs.find(function (obj) {
            return Boolean(obj.unifiedAuth);
          });
        }

        if (selectedClub && selectedClub.unifiedAuth) {
          loginOrRegisterCtrl.unifiedAuthSettings = selectedClub.unifiedAuth;

          _transformSelectedFormFields()

          loginOrRegisterCtrl.additionalRegistrationFields = getAdditionalRegistrationFields();
          loginOrRegisterCtrl.allRegistrationFields = getAllRegistrationFields();
          loginOrRegisterCtrl.mandatoryFields = getMandatoryFields();

          loginOrRegisterCtrl.loginFormTitle = getTextV2("loginForm", "title", "Log in");
          loginOrRegisterCtrl.loginFormTitleColor = getTextColor("loginForm", "titleColor", "#000000");
          loginOrRegisterCtrl.loginFormSubtitle = getTextV2("loginForm", "subtitle", "");
          loginOrRegisterCtrl.loginFormSubtitleColor = getTextColor("loginForm", "subtitleColor", "#000000");
          loginOrRegisterCtrl.loginFormBtnText = getTextV2("loginForm", "btnText", "Log in");
          loginOrRegisterCtrl.loginFormBtnTextColor = getTextColor("loginForm", "btnTextColor", "#000000");

          loginOrRegisterCtrl.signupFormTitle = getTextV2("signupForm", "title", "New here? Sign up");
          loginOrRegisterCtrl.signupFormTitleColor = getTextColor("signupForm", "titleColor", "#000000");
          loginOrRegisterCtrl.signupFormSubtitle = getTextV2(
            "signupForm",
            "subtitle",
            "Registering to the website automatically registers you to the club without any cost or obligations"
          );
          loginOrRegisterCtrl.signupFormSubtitleColor = getTextColor("signupForm", "subtitleColor", "#000000");
          loginOrRegisterCtrl.signupFormBtnText = getTextV2("signupForm", "btnText", "Sign up");
          loginOrRegisterCtrl.signupFormBtnTextColor = getTextColor("signupForm", "btnTextColor", "#000000");

          loginOrRegisterCtrl.otpFormTitle = getTextV2("otpForm", "title", "Log in or sign up");
          loginOrRegisterCtrl.otpFormTitleColor = getTextColor("otpForm", "titleColor", "#000000");
          loginOrRegisterCtrl.otpFormSubtitle = getTextV2(
            "otpForm",
            "subtitle",
            "A one - time identification by sending a code to your Mobile or Email"
          );
          loginOrRegisterCtrl.otpFormSubtitleColor = getTextColor("otpForm", "subtitleColor", "#000000");
          loginOrRegisterCtrl.otpFormBtnText = getTextV2("otpForm", "btnText", "Send code");
          loginOrRegisterCtrl.otpFormBtnTextColor = getTextColor("otpForm", "btnTextColor", "#000000");

          loginOrRegisterCtrl.completionFormTitle = getTextV2("completionForm", "title", "Few more details and we’re done!");
          loginOrRegisterCtrl.completionFormTitleColor = getTextColor("completionForm", "titleColor", "#000000");
          loginOrRegisterCtrl.completionFormSubtitle = getTextV2(
            "completionForm",
            "subtitle",
            "Registering to the website automatically registers you to the club without any cost or obligations"
          );
          loginOrRegisterCtrl.completionFormSubtitleColor = getTextColor("completionForm", "subtitleColor", "#000000");
          loginOrRegisterCtrl.completionFormBtxText = getTextV2("completionForm", "btnText", "Continue");
          loginOrRegisterCtrl.completionFormBtxTextColor = getTextColor("completionForm", "btnTextColor", "#000000");

          loginOrRegisterCtrl.facebookRegistrationFormTitle = getTextV2(
            "facebookRegistrationForm",
            "title",
            "Just one more step to go"
          );
          loginOrRegisterCtrl.facebookRegistrationFormTitleColor = getTextColor(
            "facebookRegistrationForm",
            "titleColor",
            "#000000"
          );
          loginOrRegisterCtrl.facebookRegistrationFormSubtitle = getTextV2(
            "facebookRegistrationForm",
            "subtitle",
            "Registering to the website automatically registers you to the club without any cost or obligations"
          );
          loginOrRegisterCtrl.facebookRegistrationFormSubtitleColor = getTextColor(
            "facebookRegistrationForm",
            "subtitleColor",
            "#000000"
          );
          loginOrRegisterCtrl.facebookRegistrationFormBtnText = getTextV2("facebookRegistrationForm", "btnText", "Send code");
          loginOrRegisterCtrl.facebookRegistrationFormBtnTextColor = getTextColor(
            "facebookRegistrationForm",
            "btnTextColor",
            "#000000"
          );

          loginOrRegisterCtrl.termsAndConditionsText = (
            loginOrRegisterCtrl.unifiedAuthSettings.signupForm.termsAndConditionsText[config.language.id] ||
            _translate("I agree to the") + ' ' + "{Terms & Conditions}" + ' ' + _translate("and the") + ' ' + "{Privacy Policy}"
          )
            .replace(
              new RegExp("{Terms & Conditions}", "g"),
              $sce.getTrustedHtml(
                "<a ng-click='loginOrRegisterCtrl.openLink('app.termsAndConditions')'>" +
                _translate("Terms & Conditions") +
                "</a>"
              )
            )
            .replace(
              new RegExp("{Privacy Policy}", "g"),
              "<a ng-click='loginOrRegisterCtrl.openLink('app.policies')'>" + _translate("Privacy Policy") + "</a>"
            );
          loginOrRegisterCtrl.promotionText =
            loginOrRegisterCtrl.unifiedAuthSettings.signupForm.promotionText[config.language.id] ||
            _translate("Please let me know about promotions");
          loginOrRegisterCtrl.loyaltyTermsText = (
            loginOrRegisterCtrl.unifiedAuthSettings.signupForm.loyaltyTermsText[config.language.id] ||
            (_translate("loyalty_terms_confirm") + ' ' + "{Loyalty Terms}")
          ).replace(
            new RegExp("{Loyalty Terms}", "g"),
            "<a class='terms-link' ui-sref='app.loyaltyTerms'>" + _translate("Loyalty Terms") + "</a>"
          );
        }

        loginOrRegisterCtrl.chooseUniqueEmailTitle =
          (config.retailer.customSingleEmailAddressesSelectionData &&
            config.retailer.customSingleEmailAddressesSelectionData[config.language.id] &&
            config.retailer.customSingleEmailAddressesSelectionData[config.language.id].title) ||
          _translate("Choose one Email to continue");
        loginOrRegisterCtrl.chooseUniqueEmailSubtitle =
          (config.retailer.customSingleEmailAddressesSelectionData &&
            config.retailer.customSingleEmailAddressesSelectionData[config.language.id] &&
            config.retailer.customSingleEmailAddressesSelectionData[config.language.id].subtitle) ||
          _translate(
            "This number is connected with more than one Email. Please choose one of the following email addresses to continue with."
          );
      }
    }

    function getText(key, defaultValue, additionalText) {
      return config.retailer.isCustomRegistrationActive &&
        config.retailer.customRegistrationData &&
        config.retailer.customRegistrationData[config.language.id] &&
        config.retailer.customRegistrationData[config.language.id][key]
        ? config.retailer.customRegistrationData[config.language.id][key]
        : $filter("translate")(defaultValue) +
            (additionalText ? " " + $filter("translate")(additionalText) : "");
    }

    function getTextV2(form, key, defaultValue) {
      if (
        loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty
      ) {
        if (
          loginOrRegisterCtrl.unifiedAuthSettings &&
          loginOrRegisterCtrl.unifiedAuthSettings[form] &&
          loginOrRegisterCtrl.unifiedAuthSettings[form][key] &&
          loginOrRegisterCtrl.unifiedAuthSettings[form][key][config.language.id]
        ) {
          return loginOrRegisterCtrl.unifiedAuthSettings[form][key][
            config.language.id
          ];
        }
        return _translate(defaultValue);
      }
    }

    function getTextColor(form, key, defaultValue) {
      if (
        loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty
      ) {
        if (
          loginOrRegisterCtrl.unifiedAuthSettings &&
          loginOrRegisterCtrl.unifiedAuthSettings[form] &&
          loginOrRegisterCtrl.unifiedAuthSettings[form][key]
        ) {
          return loginOrRegisterCtrl.unifiedAuthSettings[form][key];
        }
        return defaultValue || "#000000";
      }
    }

    function closeDialog() {
      return SpDialogUrlManager.backClose();
    }

    function onSelect(model, opt) {
      loginOrRegisterCtrl.selectedOption = opt;
      loginOrRegisterCtrl.user[model] = opt.value;
    }
  }

  app
    .config([
      "SpDialogUrlManagerProvider",
      "SP_URL_DIALOG_QUERY_PARAMS",
      "URL_DIALOG_DATA_QUERY_PARAMS",
      "RETAILER_STATUSES",
      config,
    ])
    .controller("LoginOrRegisterCtrl", [
      "$rootScope",
      "$controller",
      "$timeout",
      "$location",
      "$q",
      "$filter",
      "$sce",
      "$state",
      "SpDialogUrlManager",
      "User",
      "Config",
      "HubService",
      "RETAILER_STATUSES",
      ctrl,
    ]);
})(angular, app);
