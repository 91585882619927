(function (angular, app) {
    'use strict';

    app.directive('spReadMoreLink', ['$filter', '$timeout', function($filter, $timeout) {
        return {
            restrict: 'A',
            scope: {
                textOpen: '@moreText',
                textClose: '@lessText',
                listenTo: '<',
                currentHeight: '=?'
            },
            link: function($scope, $element) {
                var isShownMore = false;

				function _run() {
                    var element = $element[0],
                        textOpen = $filter('translate')($scope.textOpen || 'Read more'),
                        textClose = $filter('translate')($scope.textClose || 'Read less'),
                        initialClientHeight = element.scrollHeight ,
                        style = getComputedStyle(element),
                        maxClientHeight = parseInt(style.maxHeight) || 0;

                    $scope.currentHeight = element.clientHeight

                    if(!maxClientHeight && isShownMore) {
                        return;
                    }
                    //== check if the element container fits it's height
                    if(!maxClientHeight || initialClientHeight < maxClientHeight) {
                        _remove();
                        return;
                    }

                    element.classList.add('has-overflow');

                    var link = document.createElement('div');
                    link.innerHTML = textOpen;
                    link.className = 'read-more-link';
                    link.id = 'read-more-link';

                    link.onclick = function() {
                        isShownMore = !isShownMore;
                        if(isShownMore) {
                            link.innerHTML = textClose;
                            element.classList.add('all-shown');
                        } else {
                            link.innerHTML = textOpen;
                            element.classList.remove('all-shown');
                        }

                        $timeout(function () {
                            $scope.currentHeight = element.clientHeight
                        });
                    };

                    _removeOldLinks();
                    $element.append(link);
                }

                function _remove() {
                    $element[0].classList.remove('has-overflow', 'all-shown');
                    _removeOldLinks();
                }

                function _removeOldLinks() {
                    var readMoreLink = document.querySelector('.' + $element[0].classList[0] + ' > #read-more-link');
                    if(readMoreLink) {
                        readMoreLink.remove();
                    }
                }

                $scope.$watch('listenTo', function() {
                    $timeout(_run, 500);
                });


            }
        };
    }]);
})(angular, app);
