(function (angular, app) {
    'use strict';

    app.service('CouponDetailsDialog', ['Dialog', function (Dialog) {
        this.show = show;
        this.hide = hide;

        function show(coupon, terms) {
            return Dialog.show({
                templateUrl: 'template/dialogs/coupon-details/index.html',
                controller: 'CouponDetailsCtrl as couponDetailsCtrl',
                styleClass: 'coupon-details-dialog',
                locals: {
                    coupon: coupon,
                    terms: terms
                }
            });
        }

        function hide() {
            Dialog.hide();
        }
    }]);

    app.run(['$rootScope', 'CouponDetailsDialog', function ($rootScope, CouponDetailsDialog) {
        $rootScope.CouponDetailsDialog = CouponDetailsDialog;
    }]);

    app.controller('CouponDetailsCtrl', [
        '$rootScope', '$scope', '$timeout', 'Api', 'Cart', 'Util', 'coupon', 'terms', 'DataLayer',
        function ($rootScope, $scope, $timeout, Api, Cart, Util, coupon, terms, DataLayer) {
            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Dialog', action: 'Open', label: 'Coupon Details Dialog'}});

            var couponDetailsCtrl = this;
            couponDetailsCtrl.productsChunkLoading = true;
            couponDetailsCtrl.coupon = coupon;
            couponDetailsCtrl.togglePurchase = togglePurchase;
            couponDetailsCtrl.currentPurchase = 0;

            if (terms) {
                couponDetailsCtrl.terms = coupon.terms;
                return;
            }

            couponDetailsCtrl.endlessScrollOptions = {
                load: getProductsChunk
            };
            couponDetailsCtrl.products = {
                total: null,
                products: []
            };

            couponDetailsCtrl.gifts = {
                total: null,
                products: []
            };

            // get first chunk
            getProductsChunk();

            function getProductsChunk() {
                if (couponDetailsCtrl.products.products.length === couponDetailsCtrl.products.total) {
                    return;
                }

                couponDetailsCtrl.productsChunkLoading = true;
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/specials/' + coupon.special.id,
                    params: {
                        from: couponDetailsCtrl.products.products.length,
                        size: 20,
                        showProductsFrom: coupon.special.showProductsFrom || 'gifts'
                    }
                }).then(function (response) {
                    if (couponDetailsCtrl.products.total === null) {
                        _focusFirstItem();
                    }

                    couponDetailsCtrl.special = response.special;
                    couponDetailsCtrl.products.total = response.products.total;
                    Array.prototype.push.apply(couponDetailsCtrl.products.products, Cart.getProducts(response.products.products));

                    if (response.gifts) {
                        if (!response.products || !response.products.total) {
                            couponDetailsCtrl.products.total = response.gifts.total;
                            Array.prototype.push.apply(couponDetailsCtrl.products.products, Cart.getProducts(response.gifts.products));
                        } else {
                            couponDetailsCtrl.isBuyAndGetSpecial = true;
                            couponDetailsCtrl.gifts.total = response.gifts.total;
                            Array.prototype.push.apply(couponDetailsCtrl.gifts.products, Cart.getProducts(response.gifts.products));
                        }
                    }

                    _calculatePurchaseRemainder();
                    _setSpecialInfo();

                    DataLayer.push(DataLayer.EVENTS.VIEW_PROMOTION, {promotion: {id: response.special.id, name: response.special.description, image: response.special.frontendImageUrl}, data: {type: null}});
                    DataLayer.push(DataLayer.EVENTS.VIEW_ITEM_LIST, {products: response.products.products});
                    couponDetailsCtrl.productsChunkLoading = false;
                });
            }

            function togglePurchase(index) {
                couponDetailsCtrl.currentPurchase = couponDetailsCtrl.currentPurchase === index ? null : index;
            }

            function _setSpecialInfo() {
                if (couponDetailsCtrl.special.hasMultipleBuckets) {
                    return;
                }

                var specialProductIds = couponDetailsCtrl.products.products.map(function(product) {
                    return product.id;
                });
                couponDetailsCtrl.quantity = 0;
                couponDetailsCtrl.total = 0;

                var lines = Cart.getLines(couponDetailsCtrl.products.products).filter(function(line) {
                    return specialProductIds.includes(line.product.id);
                });

                couponDetailsCtrl.showUnit = lines.every(function(line) { return Cart.isUnitsWeighable(line); });

                var productsSameType = couponDetailsCtrl.showUnit || lines.every(function(line) { return !line.product.isWeighable; });

                lines.forEach(function(line) {
                    if (specialProductIds.includes(line.product.id)) {
                        if (productsSameType) {
                            couponDetailsCtrl.quantity += line.quantity;
                        }
                        couponDetailsCtrl.total += line.totalPriceForView;
                    }
                });
            }

            function _calculatePurchaseRemainder() {
                if (!couponDetailsCtrl.special.hasMultipleBuckets) {
                    return;
                }

                couponDetailsCtrl.quantity = 0;
                couponDetailsCtrl.total = 0;

                (couponDetailsCtrl.special.levels || []).forEach(function (level) {
                    (level.purchases || []).forEach(function (purchase, index) {
                        couponDetailsCtrl.specilaTotalItems += purchase.total;
                        if (purchase.esProducts && purchase.esProducts.length) {
                            var esProducts = purchase.esProducts;
                            purchase.esProducts = [];
                            Array.prototype.push.apply(purchase.esProducts, Cart.getProducts(esProducts));
                        } else {
                            couponDetailsCtrl.specialInactive = true;
                        }
                        var purchaseProductIds = _getSpecialProductIds(purchase.esProducts || []),
                            lines = _getSpecialCartLines(purchaseProductIds);

                        purchase.showUnit = _showUnit(lines);
                        var productsSameType = purchase.showUnit || _productsSameType(lines);
                        purchase.quantity = 0;
                        lines.forEach(function(line) {
                            if (purchaseProductIds.includes(line.product.id)) {
                                if (productsSameType) {
                                    purchase.quantity += line.quantity;
                                }
                                couponDetailsCtrl.total += line.totalPriceForView;
                            }
                        });

                        purchase.remainder = (purchase.quantity >= 1 && purchase.quantity < purchase.total) ? purchase.total - purchase.quantity : null
                        couponDetailsCtrl.quantity += purchase.quantity;

                        if (couponDetailsCtrl.currentPurchase === index && !purchase.remainder && !!purchase.quantity) {
                            togglePurchase(level.purchases.length - 1 === index ? index : index + 1)
                        }

                    });
                });
            }

            function _showUnit(lines) {
                return lines.every(function(line) { return Cart.isUnitsWeighable(line); });
            }

            function _productsSameType(lines) {
                return lines.every(function(line) { return !line.product.isWeighable; });
            }

            function _getSpecialProductIds(products) {
                return products.map(function(product) {
                    return product.id;
                });
            }

            function _getSpecialCartLines(specialProductIds) {
                return Cart.getLines(couponDetailsCtrl.products.products).filter(function(line) {
                    return specialProductIds.includes(line.product.id);
                });
            }

            function _focusFirstItem() {
                $timeout(function () {
                    if (!$scope.$$destroyed) {
                        angular.element(document.querySelector('.dialog.coupon-details sp-product')).addClass('hover-mode');
                    }
                }, 0, false);
            }

            Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function () {
                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Add Items from Coupon Details Dialog'}});
            }));

            $rootScope.$on('cart.update.complete', function (event, event1) {
                _setSpecialInfo();
                _calculatePurchaseRemainder()
            });

        }
    ]);
})(angular, app);
