(function (angular, app) {
    'use strict';

    /**
     * Common code for handling filters of sp-items directive
     */
    function service($state, $filter, Config, PermanentFilters, SEARCH_SORT_BOOST_TYPES, User) {
        var self = this,
            _productTagFilter = $filter('productTag'),
            _nameFilter = $filter('name');

        self.addOptionsToFilter = addOptionsToFilter;
        self.setFiltersFromUrl = setFiltersFromUrl;
        self.setFiltersToUrl = setFiltersToUrl;

        self.SPFrontendViewFilter = SPFrontendViewFilter;
        self.markFiltersAsFinished = markFiltersAsFinished;
        self.parseFilters = parseFilters;

        self.addProductTagsFilterOptions = addProductTagsFilterOptions;
        self.parsePermanentProductTags = parsePermanentProductTags;

        self.getBrandsFilter = getBrandsFilter;
        self.getProductTagsFilter = getProductTagsFilter;
        self.getFamiliesFilter = getFamiliesFilter;
        self.getCategoriesFilter = getCategoriesFilter;
        self.getSupervisionFilter = getSupervisionFilter;

        self.addBrandsFilterOptions = addBrandsFilterOptions;
        self.addFamiliesFilterOptions = addFamiliesFilterOptions;
        self.addSupervisionsFilterOptions = addSupervisionsFilterOptions;
        self.addCategoriesFilterOptions = addCategoriesFilterOptions;

        self.getCouponTypesFilter = getCouponTypesFilter;
        self.addCouponTypesFilterOptions = addCouponTypesFilterOptions;

        self.getProductTagsSortBoost = getProductTagsSortBoost;
        self.getProductTagNames = getProductTagNames;


        /**
         * Sets the filter options into the filter.options.items
         * Keeps the exist items in filter.value and filter.options.items
         * @private
         *
         * @param {SPFrontendViewFilter} filter - such as familiesFilter or brandFilter
         * @param {Array<Object>} additionalOptions
         * @param {function} [extractOption]
         */
        function addOptionsToFilter(filter, additionalOptions, extractOption) {
            filter.options.items = [];
            var valuesMap = {};
            var allowedUsers = Config.retailer.settings.eBTAllowedUsers ? JSON.parse(Config.retailer.settings.eBTAllowedUsers) : [];
            angular.forEach(filter.value || [], function(valueItem) {
                valuesMap[valueItem.value] = valueItem;
            });
            var optionsMap = {};

            angular.forEach(filter.options.items || [], function(option) {
                optionsMap[option.value] = option;
            });
            angular.forEach(additionalOptions, function (additionalOption) {
              var option = extractOption ? extractOption(additionalOption) : additionalOption;
              if (allowedUsers.length && !allowedUsers.includes(User.data.id)) {
                if (option.value === 2560 || option.value === 5364) {
                  return 
                }
              }
        
              if (valuesMap[option.value]) {
                valuesMap[option.value].name = option.name;
                valuesMap[option.value].count = option.count;
              }

              if (optionsMap[option.value]) {
                optionsMap[option.value].name = option.name;
                optionsMap[option.value].count = option.count;
              } else {
                filter.options.items.push(valuesMap[option.value] || option);
              }
            });
            filter.emitResetValues();
        }

        /**
         * Sets the values from the url filters to the filters value
         * @private
         *
         * @param {Object} [filters] - a map of filters by their state params keys
         * @param {Object} [stateFilters] - the actual filters on the state params
         * @param {Object} [sorter]
         * @param {{name: String, isDesc?: Boolean}} [stateSort] - the actual sort and sort desc on the state params
         */
        function setFiltersFromUrl(filters, stateFilters, sorter, stateSort) {
            if (sorter && stateSort && stateSort.name) {
                var isDesc = stateSort.isDesc === undefined || !!stateSort.isDesc;
                angular.forEach(sorter.options.items, function(item) {
                    if (item.field === stateSort.name && item.desc === isDesc) {
                        sorter.value = item;
                    }
                });
            }

            if (filters) {
                angular.forEach(filters, function(filter, key) {
                    var values = stateFilters && stateFilters[key];
                    if (filter.permanentFiltersType) {
                        var valuesMap = {};
                        angular.forEach(values, function(value) {
                            valuesMap[value] = true;
                        });
                        values = values || [];
                        angular.forEach(PermanentFilters.values[filter.permanentFiltersType], function(value) {
                            if (!valuesMap[value]) {
                                values.push(value);
                            }
                        });
                    }

                    _setFilterFromUrl(values, filter.filter || filter);
                });
            }
        }

        /**
         * Sets the values from the url filters to the filters value
         * @private
         *
         * @param {Array<Number|String>} values
         * @param {SPFrontendViewFilter} filter - such as familiesFilter or brandFilter
         */
        function _setFilterFromUrl(values, filter) {
            filter.value = [];

            if (!values || !values.length) {
                return;
            }

            var optionsMap = {};
            angular.forEach(filter.options.items, function(item) {
                optionsMap[item.value] = item;
            });
            angular.forEach(values, function(value) {
                if (optionsMap[value] || filter.notFinished) {
                    filter.value.push(optionsMap[value] || {value: value});
                }
            });
        }

        /**
         * Set the current filters and sort to the url params
         * @private
         *
         * @param {Object} [filters] - a map of filters by their state params keys
         * @param {Object} [sorter]
         */
        function setFiltersToUrl(filters, sorter) {
            var params = {
                filters: undefined,
                sort: undefined
            };

            angular.forEach(filters || {}, function(filter, key) {
                /*if (filter.permanentFiltersType) {
                    return _setPermanentFilter(filter);
                }*/

                if (!filter.value || !filter.value.length) {
                    return;
                }

                params.filters = params.filters || {};
                params.filters[key] = filter.value.map(function(filterItem) {
                    return filterItem.value;
                });
            });

            if (sorter && sorter.value) {
                params.sort = {
                    name: sorter.value.field,
                    isDesc: sorter.value.desc
                };
            }

            $state.go('.', params, {location: true, inherit: true, notify: false});
        }

        /**
         * Set the selected filters as permanent filter values
         * @private
         *
         * @param {SPFrontendViewFilter} filter
         */
        function _setPermanentFilter(filter) {
            var valuesMap = {};
            angular.forEach(filter.value, function(filterItem) {
                valuesMap[filterItem.value] = true;
            });

            var removeValues = [],
                addValues = [];
            angular.forEach(filter.options.items || [], function(filterItem) {
                if (valuesMap[filterItem.value]) {
                    addValues.push(filterItem.value);
                } else {
                    removeValues.push(filterItem.value);
                }
            });

            PermanentFilters.addFilterValues(filter.permanentFiltersType, addValues);
            PermanentFilters.removeFilterValues(filter.permanentFiltersType, removeValues);
        }

        /**
         * Set 'notFinished' property to false on all given filters
         * @public
         *
         * @param {Array<SPFrontendViewFilter>} filters
         */
        function markFiltersAsFinished(filters) {
            angular.forEach(filters, function(filter) {
                filter.notFinished = false;

                // remove all values who are not in the options
                if (filter.value) {
                    var optionsMap = {};
                    angular.forEach(filter.options.items, function(filterItem) {
                        optionsMap[filterItem.value] = true;
                    });

                    for (var i = filter.value.length - 1; i >= 0; i--) {
                        if (!optionsMap[filter.value[i].value]) {
                            filter.value.splice(i, 1);
                        }
                    }
                }
            });
        }

        /**
         * Run parse on all given filters
         * @public
         *
         * @param {Object} params
         * @param {Array<SPFrontendViewFilter>} filters
         */
        function parseFilters(params, filters) {
            angular.forEach(filters, function(filter) {
                filter.parse(params);
            });
        }

        function _getProductTagLanguagesProperty(id, prop) {
            var productTag = _productTagFilter(id);
            if (productTag) {
                return _nameFilter(productTag.languages, prop);
            }
        }

        function getProductTagNames(filters, languageId){
            var names = [];
            angular.forEach(filters, function(filter) {
                var tagFilter = _productTagFilter(filter.value);
                if (tagFilter) {
                    names.push(tagFilter.languages[languageId].displayName);
                }
            });
            return names;
        }


        /**
         * Returns a new instance of a product tags view filter
         * @public
         *
         * @param {function} onChange
         * @param {Object} $scope
         * @param {boolean} isSpecials
         *
         * @return {SPFrontendViewFilter}
         */
        function getProductTagsFilter(onChange, $scope, isSpecials) {
            var permanentFiltersType = Config.retailer.isPermanentViewFiltersActive ? PermanentFilters.TYPES.PRODUCT_TAGS : undefined;
            var filter = new SPFrontendViewFilter({
                isProductTags: true, // for boosting main product tag
                notInclusive: true,
                title: 'Diet type',
                titleNgFilter: function() {
                    return _nameFilter(Config.retailer.permanentViewFiltersTexts, 'sideFiltersTitle');
                },
                permanentFiltersType: permanentFiltersType,
                options: {
                    namesNgFilter: function(id) {
                        return _getProductTagLanguagesProperty(id, 'displayName');
                    },
                    icon: function(item) {
                        return _getProductTagLanguagesProperty(item.value, 'iconResourceUrl');
                    },
                    orderBy: '(name | productTag).languages | name:\'displayName\''
                },
                onChange: onChange,
                parse: function(params) {
                    return _parseProductTags((this.value || []).map(function(valueItem) {
                        return valueItem.value;
                    }), params, isSpecials);
                }
            });

            _listenForPermanentFiltersChanges(filter, $scope);

            return filter;
        }

        function _listenForPermanentFiltersChanges(filter, $scope) {
            if (!filter.permanentFiltersType) {
                return;
            }

            PermanentFilters.subscribe(function() {
                var oldValue = filter.value,
                    permanentValues = PermanentFilters.values[filter.permanentFiltersType];
                if (!_comparePermanentValues(oldValue, permanentValues)) {
                    _setFilterFromUrl(permanentValues, filter);
                    filter.emitResetValues();
                    return filter.onChange(filter.value, oldValue);
                }
            }, $scope);
        }

        function _comparePermanentValues(filterValues, permanentValues) {
            if ((filterValues || []).length !== (permanentValues || []).length) {
                return false;
            }

            var valuesMap = {};
            angular.forEach(filterValues, function(filterItem) {
                valuesMap[filterItem.value] = true;
            });
            for (var i = 0; i < permanentValues.length; i++) {
                if (!valuesMap[permanentValues[i]]) {
                    return false;
                }
            }
            return true;
        }

        function parsePermanentProductTags(params, isSpecials) {
            if (!Config.retailer.isPermanentViewFiltersActive) {
                return;
            }

            _parseProductTags(PermanentFilters.values[PermanentFilters.TYPES.PRODUCT_TAGS], params, isSpecials);
        }

        function _parseProductTags(values, params, isSpecials) {
            if (values.length) {
                params.filters = params.filters || {};
                params.filters.must = params.filters.must || {};

                params.filters.must.filters = params.filters.must.filters || [];
                angular.forEach(values, function(value) {
                    var term = {},
                        notTerm = {};
                    if (isSpecials) {
                        term['branch.productsData.productTags.id'] = value;
                        notTerm['branch.productsData.viewFilterProductTags'] = value;
                    } else {
                        term['productTags'] = value;
                        term['globalProductTags'] = value;
                        notTerm['viewFilterProductTags'] = value;
                    }
                    params.filters.must.filters.push({
                        should: {
                            term: term,
                            filters: [{
                                mustNot: {
                                    term: notTerm
                                }
                            }]
                        }
                    });
                });
            }
        }

        /**
         * Add a product tag filter options
         * @public
         *
         * @param {SPFrontendViewFilter} filter
         * @param {Array<{id: number}>} productTags
         * @param {boolean} [withCount]
         */
        function addProductTagsFilterOptions(filter, productTags, withCount) {
            addOptionsToFilter(filter, productTags, function(productTag) {
                return {
                    value: productTag.id,
                    name: productTag.id, /*the name will be fetched using the local cache*/
                    count: withCount ? productTag.count : undefined
                };
            });
        }

        /**
         * Returns a new instance of a brands view filter
         * @public
         *
         * @param {function} onChange
         * @param {string} [parseFieldName]
         *
         * @return {SPFrontendViewFilter}
         */
        function getBrandsFilter(onChange, parseFieldName) {
            return new SPFrontendViewFilter({
                title: 'Brand',
                onChange: onChange,
                parse: _defaultParse(parseFieldName || 'brand.id')
            });
        }

        /**
         * Returns a new instance of a brands view filter
         * @public
         *
         * @param {function} onChange
         * @param {string} [parseFieldName]
         *
         * @return {SPFrontendViewFilter}
         */
        function getSupervisionFilter(onChange, parseFieldName) {
            return new SPFrontendViewFilter({
                title: 'Supervisions',
                onChange: onChange,
                parse: _defaultParse(parseFieldName || 'supervisions.id')
            });
        }

        /**
         * Add a brands filter options
         * @public
         *
         * @param {SPFrontendViewFilter} filter
         * @param {Array<{id: number, names: Object}>} brands
         */
        function addBrandsFilterOptions(filter, brands) {
            _addDefaultFilterOptions(filter, brands);
        }

        /**
         * Returns a new instance of a families view filter
         * @public
         *
         * @param {function} onChange
         *
         * @return {SPFrontendViewFilter}
         */
        function getFamiliesFilter(onChange) {
            return new SPFrontendViewFilter({
                title: 'Type',
                onChange: onChange,
                parse: _defaultParse('family.id')
            });
        }

        /**
         * Add a families filter options
         * @public
         *
         * @param {SPFrontendViewFilter} filter
         * @param {Array<{id: number, names: Object}>} families
         */
        function addFamiliesFilterOptions(filter, families) {
            addOptionsToFilter(filter, families, function(family) {
                var names = {};
                angular.forEach(family.names, function (name, languageId) {
                    names[languageId] = name.name;
                });
                return {
                    value: family.id,
                    name: names,
                    count: family.count
                };
            });
        }

        /**
         * Add a supervisions filter options
         * @public
         *
         * @param {SPFrontendViewFilter} filter
         * @param {Array<{id: number, names: Object}>} families
         */
        function addSupervisionsFilterOptions(filter, supervisions) {
            addOptionsToFilter(filter, supervisions, function(supervision) {
                var names = {};

                angular.forEach(supervision.names, function (translation, languageId) {
                    names[languageId] = translation;
                });

                return {
                    value: supervision.id,
                    name: names,
                    imgUrl: supervision.imageUrl,
                    count: supervision.count
                };
            });
        }

        /**
         * Returns a new instance of a categories view filter
         * @public
         *
         * @param {function} onChange
         * @param {string} [parseFieldName]
         *
         * @return {SPFrontendViewFilter}
         */
        function getCategoriesFilter(onChange, parseFieldName) {
            return new SPFrontendViewFilter({
                title: 'Category',
                onChange: onChange,
                parse: _defaultParse(parseFieldName || 'family.categoriesPaths.id')
            });
        }

        /**
         * Add a categories filter options
         * @public
         *
         * @param {SPFrontendViewFilter} filter
         * @param {Array<{id: number, names: Object}>} categories
         */
        function addCategoriesFilterOptions(filter, categories) {
            _addDefaultFilterOptions(filter, categories);
        }

        /**
         * Add a default options filter options
         * @public
         *
         * @param {SPFrontendViewFilter} filter
         * @param {Array<{id: number, names: Object}>} options
         */
        function _addDefaultFilterOptions(filter, options) {
            addOptionsToFilter(filter, options, function(option) {
                return {
                    value: option.id,
                    name: option.names,
                    count: option.count
                };
            });
        }

        function _defaultParse(fieldName) {
            return function toRequest(params) {
                if (!this.value) {
                    return;
                }

                var values = this.value.map(function(valueItem) {
                    return valueItem.value;
                });
                if (values.length) {
                    params.filters = params.filters || {};
                    params.filters.must = params.filters.must || {};
                    params.filters.must.term = params.filters.must.term || {};
                    params.filters.must.term[fieldName] = values;
                }
            };
        }

        /**
         * Returns a new instance of a types view filter
         * @public
         *
         * @param {function} onChange
         * @param {string} [parseFieldName]
         *
         * @return {SPFrontendViewFilter}
         */
        function getCouponTypesFilter(onChange, parseFieldName) {
            return new SPFrontendViewFilter({
                title: 'Types',
                onChange: onChange,
                parse: _defaultParse(parseFieldName || 'type.id')
            });
        }

        /**
         * Add a coupon types filter options
         * @public
         *
         * @param {SPFrontendViewFilter} filter
         * @param {Array<{id: number, name: string}>} types
         */
        function addCouponTypesFilterOptions(filter, types) {
            addOptionsToFilter(filter, types, function(type) {
                var names = {};

                angular.forEach(Object.values(Config.languages), function (language) {
                    names[language.id] = language.translate[type.name.toLowerCase()] || type.name;
                });

                return {
                    value: type.id,
                    name: names,
                    count: type.count
                };
            });
        }

        function getProductTagsSortBoost(productTagsFilter) {
            var values = (productTagsFilter.value || []).map(function(valueItem) {
                return { id: valueItem.value };
            });
            if (!values.length) {
                return;
            }

            return {
                sortType: SEARCH_SORT_BOOST_TYPES.PRODUCT_TAGS,
                topPriority: JSON.stringify(values)
            };
        }
    }

    /**
     * @typedef {Object} SPFrontendViewFilterValue
     *
     * @property {number} value
     * @property {number|string|Object} name
     */

    /**
     * @constructor
     *
     * @property {boolean} notFinished - is didn't finish loading the options
     * @property {boolean} [notInclusive] - the filter related products won't be all of the results
     *          (when a single filter option is available, it will still change the number of products)
     * @property {string} title
     * @property {number} [permanentFiltersType]
     * @property {Array<number>} value - the selected filter values
     * @property {Object} options - the settings of the filter's options (available values)
     * @property {string} options.orderBy - order the options by (angular expression)
     * @property {Array<SPFrontendViewFilterValue>} options.items - the actual loaded options
     * @property {function} [options.namesNgFilter] - transform the option's name
     * @property {function} onChange
     * @property {function} parse - parses the filter's values to the request params filters
     *
     * @param {SPFrontendViewFilter} options
     */
    function SPFrontendViewFilter(options) {
        angular.extend(this, {
            notFinished: true,
            notInclusive: false,
            value: []
        }, options, {
            options: angular.extend({
                items: [],
                orderBy: 'name | name'
            }, options.options)
        });

        var _resetValuesSubscriptions = [],
            self = this;

        self.subscribeResetValues = subscribeResetValues;
        self.emitResetValues = emitResetValues;

        function subscribeResetValues(callback) {
            _resetValuesSubscriptions.push(callback);
        }

        function emitResetValues() {
            angular.forEach(_resetValuesSubscriptions, function(callback) {
                callback(self);
            });
        }
    }

    app.service('FilterManager', ['$state', '$filter', 'Config', 'PermanentFilters', 'SEARCH_SORT_BOOST_TYPES', 'User',service]);
})(angular, app);
