(function (angular, app) {
    'use strict';

    var BIT_IFRAME_WRAPPER_SELECTOR = '#bit-payment-button-container',
        BIT_IFRAME_SELECTOR = BIT_IFRAME_WRAPPER_SELECTOR + ' iframe',
        PAYMENT_IFRAME_ID = 'payment_iframe';

    app
        .config(['$stateProvider', function ($stateProvider) {
            addState('app.update-order.payment');
            addState('app.user-confirmation.payment');

            function addState(stateName) {
                $stateProvider.state(stateName, {
                    url: '/payment',
                    data: {
                        name: 'update-order',
                        layout: {
                            menu: false,
                            search: false,
                            sidenav: false
                        }
                    },
                    metaTags: {
                        title: ['$filter', function ($filter) {
                            return $filter('translate')('Update order');
                        }]
                    },
                    views: {
                        updateOrderTab: {
                            templateUrl: 'template/views/update-order/payment/index.html',
                            controller: 'UpdateOrderPaymentCtrl as updateOrderPaymentCtrl',
                            resolve: {
                                creditCardWindowType: ['Config', function (Config) {
                                    return Config.initPromise.then(function () {
                                        if (Config.branch && Config.branch.creditCardWindowType) {
                                            return Config.branch.creditCardWindowType;
                                        }

                                        return Config.retailer.creditCardWindowType;
                                    });
                                }],
                                paymentMethods: ['SpDeliveryTimesService', 'SP_SERVICES', function (spDeliveryTimesService) {
                                    return spDeliveryTimesService.init().then(function (deliveryMethods) {
                                        return deliveryMethods;
                                    });
                                }]
                            }
                        }
                    }
                });
            }
        }])
        .controller('UpdateOrderPaymentCtrl', ['$scope', '$rootScope', '$window', '$state', '$filter', '$timeout', '$q', 'Dialog', 'Api', 'Config',
            'Util', 'Cart', 'User', 'LocalStorage', 'Loading', 'SpDeliveryAreasService', 'PaymentsService', 'Orders', '$stateParams',
            'creditCardWindowType', 'paymentMethods', 'PHONE_TYPES', 'CREDIT_CARD_MODES', 'SP_SERVICES', 'SP_PAYMENTS',
            'RETAILER_PROMOTION_EVENTS', 'CREDIT_CARD_TYPES', 'ORGANIZATION_TYPES', 'CREDIT_CARD_3DS_MODES', 'UPDATE_ORDER_PAYMENT_TYPE',
            function ($scope, $rootScope, $window, $state, $filter, $timeout, $q, dialog, api, Config,
                      util, cart, user, LocalStorage, loading, SpDeliveryAreasService, PaymentsService, Orders, $stateParams,
                      creditCardWindowType, paymentMethods, PHONE_TYPES, CREDIT_CARD_MODES, SP_SERVICES, SP_PAYMENTS,
                      RETAILER_PROMOTION_EVENTS, CREDIT_CARD_TYPES, ORGANIZATION_TYPES, CREDIT_CARD_3DS_MODES, UPDATE_ORDER_PAYMENT_TYPE) {
                var updateOrderPaymentCtrl = this,
                    updateOrderCtrl = $scope.updateOrderCtrl,
                    _ebtEligibleListener,
                    currentDeliveryTypeId;

                updateOrderCtrl.withoutContent = false
                updateOrderPaymentCtrl.showAddCardDialog = showAddCardDialog;
                updateOrderPaymentCtrl.chooseCreditCard = chooseCreditCard;
                updateOrderPaymentCtrl.showAddEBTCardDialog = showAddEBTCardDialog;
                updateOrderPaymentCtrl.removeCard = removeCard;
                updateOrderPaymentCtrl.openPayPalWindow = openPayPalWindow;
                updateOrderPaymentCtrl.openICreditMasterPassWindow = openICreditMasterPassWindow;
                updateOrderPaymentCtrl.initBitButton = initBitButton;
                updateOrderPaymentCtrl.bindIFrameLoad = bindIFrameLoad;

                updateOrderPaymentCtrl.isShowCheckoutButton = isShowCheckoutButton;
                updateOrderPaymentCtrl.onPaymentMethodChanged = onPaymentMethodChanged;
                updateOrderPaymentCtrl.onEBTAmountChange = onEBTAmountChange;

                updateOrderPaymentCtrl.checkoutCreditCustomer = checkoutCreditCustomer;
                updateOrderPaymentCtrl.checkoutUpdate = checkoutUpdate;
                updateOrderPaymentCtrl.orderInEdit =  Orders.orderInEdit;
                updateOrderPaymentCtrl.remainingEbtPayment = {
                    snap : 0,
                    cash : 0
                };
                updateOrderPaymentCtrl.useEbtBalance = {
                    useSnap: true,
                    disableSnap: true,
                    useCash: true,
                    disableCash: false
                };

                _init();

                function _init() {
                    updateOrderPaymentCtrl.order = updateOrderCtrl.order;
                    currentDeliveryTypeId = updateOrderPaymentCtrl.order && updateOrderPaymentCtrl.order.branchDeliveryTypeId;
                    updateOrderPaymentCtrl.paymentMethods = paymentMethods[currentDeliveryTypeId];
                    updateOrderPaymentCtrl.isRememberCreditCardsMode =
                        Config.creditCardConfig.mode === CREDIT_CARD_MODES.REMEMBER_CARDS && !!angular.filterCollection(Config.retailer.checkoutPaymentMethods, function (method) {
                            return _isCreditCard(method.name) && currentDeliveryTypeId === method.branchDeliveryTypeId;
                        }).length;

                    updateOrderPaymentCtrl.isCreditCard3DSActive = updateOrderPaymentCtrl.isRememberCreditCardsMode && !!Config.creditCardConfig.creditCard3DSModes[CREDIT_CARD_3DS_MODES.ON_PAYMENT];

                    updateOrderPaymentCtrl.hasPaypalOption = !!angular.filterCollection(Config.retailer.checkoutPaymentMethods, function (method) {
                        return method.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.PAYPAL && currentDeliveryTypeId === method.branchDeliveryTypeId;
                    }).length;

                    updateOrderPaymentCtrl.hasICReditMasterPassOption = !!angular.filterCollection(Config.retailer.checkoutPaymentMethods, function (method) {
                        return method.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.I_CREDIT_MASTER_PASS && currentDeliveryTypeId === method.branchDeliveryTypeId;
                    }).length;

                    updateOrderPaymentCtrl.creditCardWindowType = creditCardWindowType;

                    user.getData().then(function () {
                        return _setData();
                    }).then(function () {
                        if (!updateOrderPaymentCtrl.isRememberCreditCardsMode && updateOrderPaymentCtrl.creditCardWindowType !== SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) {
                            _setPaymentIFrameUrl();
                        }
                    });


                }

                /**
                 * Init the data
                 * @private
                 */
                function _setData() {
                    if (!updateOrderCtrl.summaryData) {
                        updateOrderCtrl.summaryData = {
                            paymentMethod: null,
                            storeCredit: '',
                            creditCard: {},
                            paymentsNumber: 1
                        };
                    }

                    if (user.data) {
                        updateOrderPaymentCtrl.userOrganization = user.data.organization;
                        updateOrderPaymentCtrl.userLoyaltyClubCardId = user.data.loyaltyClubCardId;
                        updateOrderPaymentCtrl.userLoyaltyVerificationStatus = user.data.loyaltyVerificationStatus;
                    }

                    if ($rootScope.isCreditCustomer) {
                        updateOrderCtrl.summaryData.paymentMethod = {name: SP_PAYMENTS.PAYMENT_METHODS.NAMES.CUSTOMER_CREDIT};
                        onPaymentMethodChanged();
                    } else if (updateOrderPaymentCtrl.userOrganization && updateOrderPaymentCtrl.userOrganization.organizationType !== ORGANIZATION_TYPES.NO_OBLIGO) {
                        updateOrderCtrl.summaryData.paymentMethod = {name: SP_PAYMENTS.PAYMENT_METHODS.NAMES.ORGANIZATION};
                        onPaymentMethodChanged();
                    } else {
                        var paymentMethods = [],
                            creditCardPayment;

                        angular.forEach(updateOrderPaymentCtrl.paymentMethods, function (paymentType) {
                            if (paymentType.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD) {
                                creditCardPayment = paymentType;
                            } else {
                                paymentMethods.push(paymentType);
                            }
                        });

                        if (creditCardPayment) {
                            paymentMethods.unshift(creditCardPayment);
                        }

                        var currentMethod = updateOrderCtrl.summaryData.paymentMethod && paymentMethods.find(function (method) {
                            return method.name === updateOrderCtrl.summaryData.paymentMethod.name;
                        });
                        if (!currentMethod) {
                            updateOrderCtrl.summaryData.paymentMethod = paymentMethods[0];
                            onPaymentMethodChanged();
                        } else {
                            updateOrderCtrl.summaryData.paymentMethod = currentMethod;
                        }

                        updateOrderPaymentCtrl.paymentMethods = paymentMethods;
                    }
                    updateOrderPaymentCtrl.creditCards = [];
                    updateOrderPaymentCtrl.ebtCards = [];
                    updateOrderPaymentCtrl.paypalAccounts = updateOrderPaymentCtrl.paypalAccounts || [];

                    if (user.data) {
                        updateOrderPaymentCtrl.creditCards = user.data.creditCards || [];
                        updateOrderPaymentCtrl.ebtCards = user.data.ebtCards || [];
                        updateOrderCtrl.summaryData.storeCredit = user.data.storeCreditAccountNumber;
                        updateOrderPaymentCtrl.loyaltyClubCardId = user.data.loyaltyClubCardId;
                        updateOrderPaymentCtrl.loyaltyVerificationStatus = user.data.loyaltyVerificationStatus;

                        var activeCards = user.data.creditCards ? angular.filterCollection(user.data.creditCards, function (x) {
                                return x.isActive;
                            }) : null,
                            defaultCard = activeCards ? angular.filterCollection(activeCards, function (x) {
                                return x.isDefault;
                            }) : null,
                            loyaltyCreditCards = user.data.creditCards ? angular.filterCollection(user.data.creditCards, function (x) {
                                return x.isLoyalty;
                            }) : null;

                        if (loyaltyCreditCards && loyaltyCreditCards.length) {
                            updateOrderCtrl.summaryData.creditCard = loyaltyCreditCards[0];
                        } else {
                            updateOrderCtrl.summaryData.creditCard = defaultCard && defaultCard.length ? defaultCard[0] : (activeCards && activeCards.length ? activeCards[0] : null);
                        }

                        updateOrderPaymentCtrl.hasCard = !!updateOrderPaymentCtrl.creditCards.length;
                        updateOrderPaymentCtrl.hasLoyaltyCreditCards = !!(loyaltyCreditCards && loyaltyCreditCards.length);

                        _setPaypalAccounts(user.data.paypalAccounts);
                    }

                    _setEBTEligible();
                    _setRemainingEBTPayment();
                    _disableCartLoyalty();
                }


                function _setRemainingEBTPayment(){
                    var remainingEbtPayment = cart.getRemainingPayment(updateOrderPaymentCtrl.orderInEdit)
                    var isSnapRefund = remainingEbtPayment.snap < -1;
                    var isCashRefund = remainingEbtPayment.cash < -1;
                    updateOrderPaymentCtrl.useEbtBalance = {
                        useCash: isCashRefund,
                        disableCash: isCashRefund,
                        useSnap: isSnapRefund,
                        disableSnap: isSnapRefund,
                    }
                    updateOrderCtrl.summaryData.ebt = updateOrderCtrl.summaryData.ebt || {}
                    updateOrderCtrl.summaryData.ebt.fsAmount = remainingEbtPayment.snap;
                    updateOrderCtrl.summaryData.ebt.cbAmount = remainingEbtPayment.cash;
                    updateOrderPaymentCtrl.remainingEbtPayment = remainingEbtPayment.ebt;
                    updateOrderPaymentCtrl.isSnapRefund = isSnapRefund;
                    updateOrderPaymentCtrl.isCashRefund = isCashRefund;
                }


                function _setEBTEligible() {
                    if (_ebtEligibleListener) {
                        _ebtEligibleListener();
                    }

                    var ebtPaymentMethod = updateOrderPaymentCtrl.paymentMethods.find(function (method) {
                        return method.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT;
                    });
                    if (!ebtPaymentMethod) {
                        return;
                    }

                    function _set() {
                        updateOrderPaymentCtrl.cartEBTEligibleData = cart.getEBTEligible();
                        _setRemainingEBTPayment()
                        onEBTAmountChange();
                    }

                    _set();
                    _ebtEligibleListener = $rootScope.$on('cart.update.complete', _set);
                }

                function showAddCardDialog() {
                    return PaymentsService.addCreditCard().then(function () {
                        user.getData(true).then(_setData);
                    });
                }

                function chooseCreditCard(creditCard) {
                    updateOrderCtrl.summaryData.creditCard = creditCard;

                    _disableCartLoyalty();
                }

                function _disableCartLoyalty() {
                    cart.disableLoyalty(updateOrderPaymentCtrl.hasLoyaltyCreditCards && (
                        updateOrderCtrl.summaryData.paymentMethod.name !== $root.SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD ||
                        !updateOrderCtrl.summaryData.creditCard.isLoyalty
                    ));
                }

                function showAddEBTCardDialog() {
                    return PaymentsService.addEBTCard().then(function () {
                        user.getData(true).then(_setData);
                    });
                }

                function onPaymentMethodChanged() {
                    _disableCartLoyalty();
                    cart.save({paymentMethodId: SP_PAYMENTS.PAYMENT_METHODS.ID_BY_NAME[updateOrderCtrl.summaryData.paymentMethod.name]});
                }

                function _setPaypalAccounts(paypalAccounts) {
                    updateOrderPaymentCtrl.paypalAccounts = angular.filterCollection(paypalAccounts || [], function (x) {
                        return x.billingAgreement || x.payerId == updateOrderCtrl.summaryData.paypalPayerId;
                    });
                    var defaultPaypalAccount = angular.filterCollection(updateOrderPaymentCtrl.paypalAccounts, function (x) {
                        return x.isDefault;
                    });

                    updateOrderCtrl.summaryData.paypalAccount = defaultPaypalAccount && defaultPaypalAccount.length ? defaultPaypalAccount[0]
                        : (updateOrderPaymentCtrl.paypalAccounts && updateOrderPaymentCtrl.paypalAccounts.length ? updateOrderPaymentCtrl.paypalAccounts[0] : null);
                }

                function isShowCheckoutButton() {
                    if (!updateOrderPaymentCtrl || !updateOrderCtrl.summaryData || !updateOrderCtrl.summaryData.paymentMethod) {
                        return true;
                    }
                    if (updateOrderCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.I_CREDIT_MASTER_PASS ||
                        updateOrderCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.BIT) {
                        return false;
                    }
                    if (updateOrderCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.PAYPAL) {
                        return !!updateOrderPaymentCtrl.paypalAccounts.length;
                    }
                    if (_isCreditCard(updateOrderCtrl.summaryData.paymentMethod.name)) {
                        return updateOrderPaymentCtrl.isRememberCreditCardsMode;
                    }
                    if (updateOrderCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.ORGANIZATION) {
                        return updateOrderPaymentCtrl.userOrganization.isActive;
                    }

                    return true;
                }

                if (!updateOrderPaymentCtrl.isRememberCreditCardsMode) {
                    if (creditCardWindowType !== SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) {
                        util.currentScopeListener($scope, $rootScope.$on('cart.update.complete', function () {
                            _resetUrlOnPaymentIframe();
                        }));
                    }

                    // when creditCardWindowType === WINDOW the payWithCreditCard function is used instead
                    if (creditCardWindowType !== SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) {
                        util.currentScopeListener($scope, $rootScope.$on('external_payment_iframe.finished', function _paymentFinished(data, event) {
                            if (!event || !event.data || (event.data.error && event.data.error.error) || (!event.data.paymentToken && !event.data.actionResponse)) {
                                dialog.show({
                                    template: '<h4>{{error | translate}}</h4>',
                                    controller: ['$scope', function (scope) {
                                        scope.error = event && event.data && event.data.error ? event.data.error.error : 'Please try again';
                                    }]
                                });
                                updateOrderPaymentCtrl.paymentCompleted = false;
                                _resetUrlOnPaymentIframe();

                                return;
                            }

                            var actionResponse;

                            if (event.data.actionResponse) {
                                actionResponse = event.data.actionResponse;
                            } else {
                                updateOrderPaymentCtrl.paymentCompleted = true;
                                updateOrderCtrl.summaryData.paymentToken = event.data.paymentToken;
                                updateOrderCtrl.summaryData.authNum = event.data.authNum;
                                updateOrderCtrl.summaryData.preAuthAmount = event.data.amount;
                                if (event.data.paymentsNumber) {
                                    updateOrderCtrl.summaryData.paymentsNumber = event.data.paymentsNumber;
                                }
                            }
                            return checkoutUpdate({actionResponse: actionResponse});
                        }));
                    }
                }

                if (updateOrderPaymentCtrl.hasPaypalOption) {
                    util.currentScopeListener($scope, $rootScope.$on('paypal.finished', function (data, event) {
                        if (!event || !event.data || (event.data.error && event.data.error.error) || !event.data.paymentToken) {
                            if (event && event.data && event.data.error) {
                                dialog.show({
                                    template: '<h4>{{error | translate}}</h4>',
                                    controller: ['$scope', function (scope) {
                                        scope.error = event.data.error.error;
                                    }]
                                });
                            }
                            updateOrderPaymentCtrl.paymentCompleted = false;
                            return;
                        }

                        updateOrderPaymentCtrl.paymentCompleted = true;
                        updateOrderCtrl.summaryData.paymentToken = event.data.paymentToken;
                        updateOrderCtrl.summaryData.paypalPayerId = event.data.payerId;
                        user.getData(true).then(function () {
                            _setPaypalAccounts(user.data.paypalAccounts);
                        });
                    }));
                }

                if (updateOrderPaymentCtrl.hasICReditMasterPassOption) {
                    util.currentScopeListener($scope, $rootScope.$on('iCreditMasterPass.finished', function (data, event) {
                        if (!event || !event.data || (event.data.error && event.data.error.error) || !event.data.paymentToken) {
                            if (event && event.data && event.data.error) {
                                dialog.show({
                                    template: '<h4>{{error | translate}}</h4>',
                                    controller: ['$scope', function (scope) {
                                        scope.error = event.data.error.error;
                                    }]
                                });
                            }
                            updateOrderPaymentCtrl.paymentCompleted = false;
                            return;
                        }

                            updateOrderPaymentCtrl.paymentCompleted = true;
                            updateOrderCtrl.summaryData.paymentToken = event.data.paymentToken;
                        checkoutUpdate();
                    }));
                }

                function removeCard(cardId) {
                    api.request({
                        method: 'DELETE',
                        url: '/retailers/:rid/users/:uid/removecard',
                        data: {
                            cardId: cardId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function () {
                        return user.getData(true);
                    }).then(function () {
                        _setData();
                    });
                }

                function openPayPalWindow() {
                    PaymentsService.getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.PAYPAL, {
                        orderId: updateOrderCtrl.order.id,
                        deliveryDate: updateOrderPaymentCtrl.order.shippingTimeTo
                    }).then(function (url) {
                        $window.open(url);
                    });
                }

                function openICreditMasterPassWindow() {
                    PaymentsService.getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.I_CREDIT_MASTER_PASS, {
                        orderId: updateOrderCtrl.order.id,
                        deliveryDate: updateOrderPaymentCtrl.order.shippingTimeTo
                    }).then(function (url) {
                        $window.open(url);
                    });
                }

                function initBitButton() {
                    return _addBitSdk().then(function () {
                        window.BitPayment.Buttons({
                            onCreate: _createBitPayment,
                            onApproved: _finishBitPayment,
                            onCancel: function () {
                                dialog.show({template: '<h4>{{\'Payment Cancelled\' | translate}}</h4>'});
                            },
                            onTimeOut: function () {
                                dialog.show({template: '<h4>{{\'Payment Expired\' | translate}}</h4>'});
                            }
                        }).render(BIT_IFRAME_WRAPPER_SELECTOR);

                        loading.counter(null, 1);
                        angular.element(document.querySelector(BIT_IFRAME_SELECTOR)).bind('load', _onBitIFrameLoad);
                    });
                }

                function _onBitIFrameLoad() {
                    loading.counter(null, -1);
                    angular.element(document.querySelector(BIT_IFRAME_SELECTOR)).unbind('load', _onBitIFrameLoad);
                }

                function _createBitPayment(callback) {
                    api.request({
                        method: 'POST',
                        url: '/retailers/' + Config.retailer.id + '/users/' + user.session.userId + '/_init-bit-payment',
                        data: {
                            cartId: cart.serverCartId
                        }
                    }).then(function (resp) {
                        callback(resp);
                    });
                }

                function _finishBitPayment(details) {
                    api.request({
                        method: 'POST',
                        url: '/retailers/' + Config.retailer.id + '/users/' + user.session.userId + '/_bit-completed-payment',
                        data: {
                            paymentInitiationId: details.paymentInitiationId || details.allData && details.allData.orderId
                        }
                    }).then(function (resp) {
                        if (!resp || (resp.error && resp.error.error) || !resp.paymentToken) {
                            if (resp && resp.error) {
                                dialog.show({
                                    template: '<h4>{{error | translate}}</h4>',
                                    controller: ['$scope', function (scope) {
                                        scope.error = resp.error.error;
                                    }]
                                });
                            }
                            updateOrderPaymentCtrl.paymentCompleted = false;
                            return;
                        }

                        updateOrderPaymentCtrl.paymentCompleted = true;
                        updateOrderCtrl.summaryData.paymentToken = resp.paymentToken;
                        checkoutUpdate();
                    });
                }

                function _addBitSdk() {
                    if (window.BitPayment) {
                        return $q.resolve();
                    }

                    loading.counter(null, 1);
                    return new $q(function (resolve, reject) {
                        var script = document.createElement('script');
                        script.setAttribute('id', 'bit-sdk');
                        script.src = 'https://public.bankhapoalim.co.il/bitcom/sdk';
                        script.onload = resolve;
                        script.onerror = function () {
                            document.head.removeChild(script);
                            reject();
                        };
                        document.head.append(script);
                    }).finally(function () {
                        loading.counter(null, -1);
                    });
                }

                function _setRemainingPayment() {
                    var remainingEbtPayment = cart.getRemainingPayment(updateOrderPaymentCtrl.order).ebt;
                    updateOrderPaymentCtrl.remainingEbtPayment = remainingEbtPayment
                    var isSnapRefund = remainingEbtPayment.snap < -1;
                    var isCashRefund = remainingEbtPayment.cash < -1;
                    updateOrderPaymentCtrl.useEbtBalance = {
                        useCash: isCashRefund,
                        disableCash: isCashRefund,
                        useSnap: isSnapRefund,
                        disableSnap: isSnapRefund,
                    }
                }

                function onEBTAmountChange() {
                    updateOrderPaymentCtrl.ebtCalculatedAmounts = PaymentsService.calculateEBTAmounts(updateOrderCtrl.summaryData.ebt, updateOrderPaymentCtrl.cartEBTEligibleData);
                    _setRemainingPayment()
                    if (updateOrderPaymentCtrl.ebtCalculatedAmounts.fs.exceeding) {
                        // use a timeout to escape the current digest
                        // this function runs on ng-change, and sp-inline-error clears errors on $watch of the model
                        // so in order to allow the $watch of the current digest to fire first, the timeout is used
                        $timeout(function () {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        param: 'ebtFSAmount',
                                        msg: 'amount_exceeded_eligibility'
                                    }]
                                }
                            });
                        }, 0);
                        return false;
                    }

                    if (updateOrderPaymentCtrl.ebtCalculatedAmounts.cb.exceeding) {
                        // use a timeout to escape the current digest
                        // this function runs on ng-change, and sp-inline-error clears errors on $watch of the model
                        // so in order to allow the $watch of the current digest to fire first, the timeout is used
                        $timeout(function () {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        param: 'ebtCBAmount',
                                        msg: 'amount_exceeded_eligibility'
                                    }]
                                }
                            });
                        }, 0);
                        return false;
                    } else {
                        var invalidInput = document.querySelector('input[name="ebtCBAmount"].sp-inline-error-invalid');
                        if (invalidInput) {
                            $rootScope.$emit('spInlineError.clear');
                        }
                    }

                    return true;
                }

                function _getEBTTransaction() {
                    if (!updateOrderPaymentCtrl.ebtCards.length) {
                        return $q.reject('no ebt card');
                    }

                    var address = {};
                    if (currentDeliveryTypeId === SP_SERVICES.DELIVERY_TYPES.PICKUP) {
                        address.addressText = Config.branch.location;
                        address.city = Config.branch.city;
                        address.zipCode = Config.branch.zipCode;
                    } else {
                        /* address.addressText = checkoutCtrl.detailsData.address.text1;
                         address.city = checkoutCtrl.detailsData.address.city;
                         address.zipCode = checkoutCtrl.detailsData.address.zipCode;*/
                    }

                    return PaymentsService.initEBTPayment({
                        creditCardId: updateOrderPaymentCtrl.ebtCards[0].id,
                        address: address,
                        isPickup: currentDeliveryTypeId === SP_SERVICES.DELIVERY_TYPES.PICKUP
                    }).then(function (resp) {
                        return resp.paymentToken;
                    });
                }

                /**
                 * Stop loading image for iFrame and unbind on load event
                 * @param {Event} event
                 * @private
                 */
                function _onIFrameLoad(event) {
                    try {
                        var toTryAccessIFrame = event.target.contentWindow.location.href;
                        if (toTryAccessIFrame.indexOf(window.location.origin) === -1) {
                            throw 'Set as loaded';
                        }
                    } catch (e) {
                        loading.counter(null, -1);
                        var iFrameElement = document.getElementById(PAYMENT_IFRAME_ID);
                        iFrameElement && angular.element(iFrameElement).unbind('load', _onIFrameLoad);
                    }
                }

                function _onIFrameLoaded() {
                    if (loading.getCount() === 0) {
                        return;
                    }

                    loading.counter(null, -1);
                    var iFrameElement = document.getElementById(PAYMENT_IFRAME_ID);
                    iFrameElement && angular.element(iFrameElement).unbind('load', _onIFrameLoad);
                }

                /**
                 * Start loading image for iFrame and bind on load event to iFrame
                 */
                function bindIFrameLoad() {
                    if (!updateOrderPaymentCtrl.isRememberCreditCardsMode && !_validateCheckoutDetails()) {
                        return;
                    }

                    loading.counter(null, 1);
                    angular.element(document.getElementById(PAYMENT_IFRAME_ID)).bind('load', _onIFrameLoad);
                    util.currentScopeListener($scope, $rootScope.$on('init_cart_iframe_redirect.loaded', _onIFrameLoaded));
                }

                /**
                 * Will set the paymentIFrameUrl property
                 * @private
                 *
                 * @return {Promise<void>}
                 */
                function _setPaymentIFrameUrl() {
                    return _getPaymentIFrameUrl().then(function (url) {
                        updateOrderPaymentCtrl.paymentIFrameUrl = url;
                    });
                }

                /**
                 * Will reset the src attr on the iframe element
                 * @private
                 */
                function _resetUrlOnPaymentIframe() {
                    if (creditCardWindowType === SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) {
                        return;
                    }

                    var iframe = angular.element(document.getElementById(PAYMENT_IFRAME_ID));
                    if (!iframe.length) {
                        return;
                    }

                    _getPaymentIFrameUrl().then(function (url) {
                        iframe.attr('src', url);
                    });
                }

                /**
                 * Returns the url of the init payment page
                 * @private
                 *
                 * @return {Promise<string>}
                 */
                function _getPaymentIFrameUrl() {
                    var data = {};
                    return _setCheckoutUpdateData(data).then(function () {
                        return PaymentsService.getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD, {
                            orderId: updateOrderCtrl.order.id,
                            deliveryDate: updateOrderPaymentCtrl.order.shippingTimeTo,
                            action: 'checkoutUpdate',
                            actionData: data,
                            cartId: data.newCartId,
                        });
                    });

                }

                /**
                 * Validates form element and show its errors
                 * @param {HTMLElement} formElement
                 * @returns {boolean}
                 * @private
                 */
                function _validateForm(formElement) {
                    formElement = angular.element(formElement);
                    if (formElement && formElement.length) {
                        var form = formElement.data('$formController');
                        util.setServerErrorToForm(form, formElement, {});
                        if (form.$invalid) {
                            util.setFirstErrorInput(formElement);
                            return false;
                        }
                    }
                    return true;
                }

                /**
                 * Validate the checkout data
                 * @returns {boolean}
                 * @private
                 */
                function _validateCheckoutDetails() {
                    if (updateOrderCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT) {
                        if (!updateOrderPaymentCtrl.ebtCards.length) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        msg: 'Please add your EBT card'
                                    }]
                                }
                            });
                            return false;
                        }

                        if (!updateOrderCtrl.summaryData.ebt || (!updateOrderCtrl.summaryData.ebt.fsAmount && !updateOrderCtrl.summaryData.ebt.cbAmount)) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        param: 'ebtFSAmount',
                                        msg: 'Please add your amount'
                                    }]
                                }
                            });
                            return false;
                        }

                        if (!onEBTAmountChange()) {
                            return false;
                        }
                    }

                    if (updateOrderPaymentCtrl.isRememberCreditCardsMode && _isCreditCard(updateOrderCtrl.summaryData.paymentMethod.name)) {
                        if (!updateOrderPaymentCtrl.hasCard) {
                            updateOrderPaymentCtrl.showAddCardDialog();
                            return false;
                        }

                        if (updateOrderPaymentCtrl.isRememberCreditCardsMode && !updateOrderCtrl.summaryData.cvv) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        param: 'cvv',
                                        msg: 'Please enter cvv'
                                    }]
                                }
                            });
                            return false;
                        }

                        if (updateOrderPaymentCtrl.isRememberCreditCardsMode && updateOrderCtrl.summaryData.cvv &&
                            (updateOrderCtrl.summaryData.cvv.length < 3 || updateOrderCtrl.summaryData.cvv.length > 4)) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        param: 'cvv',
                                        msg: 'Invalid cvv'
                                    }]
                                }
                            });
                            return false;
                        }
                    }

                    if (updateOrderCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.PAYPAL) {
                        if (!updateOrderCtrl.summaryData.paypalAccount ||
                            (updateOrderCtrl.summaryData.paypalAccount && !updateOrderCtrl.summaryData.paypalAccount.billingAgreement && !updateOrderCtrl.summaryData.paymentToken)
                        ) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        msg: 'Please select PayPal account.'
                                    }]
                                }
                            });
                            return false;
                        }
                    }

                    return _validateForm(document.querySelector('.summary form'));
                }

                function _isCreditCard(paymentMethod) {
                    return paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD ||
                        paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT;
                }

                function _setServerErrorToForm(serverRes) {
                    var formElement = angular.element(document.querySelector('.checkout .tab > .summary form')),
                        form = formElement.data('$formController');
                    util.setServerErrorToForm(form, formElement, serverRes);

                    if (serverRes && serverRes.response && serverRes.response.errors &&
                        serverRes.response.errors[0] && serverRes.response.errors[0].param) {
                        formElement[0].querySelector('[name="' + serverRes.response.errors[0].param + '"]').focus();
                    }
                }

                function checkLoyaltyCreditCard() {
                    if (updateOrderPaymentCtrl.hasLoyaltyCreditCards &&
                        (!_isCreditCard(updateOrderCtrl.summaryData.paymentMethod.name) ||
                            !updateOrderCtrl.summaryData.creditCard.isLoyalty)) {
                        return dialog.show({
                            templateUrl: 'template/dialogs/loyalty/loyalty-discounts-warning/index.html',
                            controller: ['$scope', function ($scope) {
                                $scope.hide = dialog.hide;
                            }]
                        });
                    } else {
                        return $q.resolve(true);
                    }
                }

                function _setCheckoutUpdateData(data) {
                    data.newCartId = cart.serverCartId;
                    data.skipActiveProductsValidation = true;
                    data.orderId = updateOrderCtrl.order.id;

                    var timeSlotData = util.getNewTimeSlotData(data.orderId, data.newCartId);
                    if(timeSlotData && timeSlotData.newAreaDeliveryTimeId && timeSlotData.newDate) {
                        data.newAreaDeliveryTimeId = timeSlotData.newAreaDeliveryTimeId;
                        data.newDate = timeSlotData.newDate;
                    }
                    
                    return _setCheckoutUpdatePayments(data);
                }

                /**
                 * Get the checkout data payments
                 * @private
                 *
                 * @param {Object} checkoutUpdateData
                 *
                 * @returns {Promise<Array<Object>>}
                 */
                function _setCheckoutUpdatePayments(checkoutUpdateData) {
                    var mainPayment = {
                        methodId: SP_PAYMENTS.PAYMENT_METHODS.ID_BY_NAME[updateOrderCtrl.summaryData.paymentMethod.name],
                        paymentsNumber: updateOrderCtrl.summaryData.paymentsNumber,
                        isMain: true
                    };

                    if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT) {
                        mainPayment.methodId = SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD;
                    }

                    if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD) {
                        if (updateOrderPaymentCtrl.isRememberCreditCardsMode && updateOrderCtrl.summaryData.creditCard) {
                            mainPayment.creditCardId = updateOrderCtrl.summaryData.creditCard.id;
                            mainPayment.cvv = updateOrderCtrl.summaryData.cvv;
                            mainPayment.isLoyalty = updateOrderCtrl.summaryData.creditCard.isLoyalty;
                            if (updateOrderCtrl.summaryData.creditCard.cardType === CREDIT_CARD_TYPES.DIRECT) {
                                mainPayment.paymentsNumber = 1;
                            }
                        } else if (!updateOrderPaymentCtrl.isRememberCreditCardsMode && updateOrderCtrl.summaryData.paymentToken) {
                            mainPayment.token = updateOrderCtrl.summaryData.paymentToken;
                            mainPayment.authNum = updateOrderCtrl.summaryData.authNum;
                            mainPayment.preAuthAmount = updateOrderCtrl.summaryData.preAuthAmount;
                        }
                    } else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.STORE_CREDIT && updateOrderCtrl.summaryData.storeCredit) {
                        mainPayment.token = updateOrderCtrl.summaryData.storeCredit;
                    } else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.PAYPAL &&
                        (updateOrderCtrl.summaryData.paymentToken || updateOrderCtrl.summaryData.paypalAccount)) {
                        if (updateOrderCtrl.summaryData.paymentToken && updateOrderCtrl.summaryData.paypalAccount.payerId === updateOrderCtrl.summaryData.paypalPayerId) {
                            mainPayment.token = updateOrderCtrl.summaryData.paymentToken;
                        } else {
                            mainPayment.billingAgreement = updateOrderCtrl.summaryData.paypalAccount ? updateOrderCtrl.summaryData.paypalAccount.billingAgreement : null;
                        }
                    } else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.ORGANIZATION) {
                        mainPayment.token = updateOrderCtrl.summaryData.organization;
                    } else if ((mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.I_CREDIT_MASTER_PASS ||
                        mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.BIT) && updateOrderCtrl.summaryData.paymentToken) {
                        mainPayment.token = updateOrderCtrl.summaryData.paymentToken;
                        mainPayment.authNum = updateOrderCtrl.summaryData.authNum;
                    }

                    checkoutUpdateData.payments = [];
                    return $q.resolve().then(function () {
                        if (updateOrderCtrl.summaryData.paymentMethod.name !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT || !updateOrderCtrl.summaryData.ebt.fsAmount) {
                            return;
                        }

                        return _getEBTCheckoutPaymentTransaction(SP_PAYMENTS.PAYMENT_TYPES.EBT_SNAP);
                    }).then(function (ebtFSPaymentToken) {
                        if (ebtFSPaymentToken) {
                            checkoutUpdateData.payments.push({
                                methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT,
                                typeId: SP_PAYMENTS.PAYMENT_TYPES.EBT_SNAP,
                                amount: updateOrderPaymentCtrl.ebtCalculatedAmounts.fs.amount,
                                preAuthAmount: updateOrderCtrl.summaryData.ebt.fsAmount,
                                creditCardId: updateOrderPaymentCtrl.ebtCards[0].id,
                                token: ebtFSPaymentToken
                            });
                        }

                        if (updateOrderCtrl.summaryData.paymentMethod.name !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT || !updateOrderCtrl.summaryData.ebt.cbAmount) {
                            return;
                        }

                        return _getEBTCheckoutPaymentTransaction(SP_PAYMENTS.PAYMENT_TYPES.EBT_CASH);
                    }).then(function (ebtCBPaymentToken) {
                        if (ebtCBPaymentToken) {
                            checkoutUpdateData.payments.push({
                                methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT,
                                typeId: SP_PAYMENTS.PAYMENT_TYPES.EBT_CASH,
                                amount: updateOrderPaymentCtrl.ebtCalculatedAmounts.cb.amount,
                                preAuthAmount: updateOrderCtrl.summaryData.ebt.cbAmount,
                                creditCardId: updateOrderPaymentCtrl.ebtCards[0].id,
                                token: ebtCBPaymentToken
                            });
                        }

                        checkoutUpdateData.payments.push(mainPayment);

                        var is3DSCard = updateOrderPaymentCtrl.isCreditCard3DSActive &&
                            updateOrderCtrl.summaryData.creditCard && updateOrderCtrl.summaryData.creditCard.is3DS;
                        if (mainPayment.methodId !== SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD ||
                            (updateOrderPaymentCtrl.isRememberCreditCardsMode && !is3DSCard) || // Saves cards that don`t need 3DS
                            (!updateOrderPaymentCtrl.isRememberCreditCardsMode && creditCardWindowType !== SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) // One time payment cards that not in new window that display inline iframe
                        ) {
                            return;
                        }

                        return PaymentsService.payWithCreditCard({
                            creditCardId: (updateOrderCtrl.summaryData.creditCard || {}).id,
                            cvv: updateOrderCtrl.summaryData.cvv,
                            deliveryDate: updateOrderPaymentCtrl.order.shippingTimeTo,
                            orderId: updateOrderCtrl.order.id,
                            cartId: checkoutUpdateData.newCartId,
                            action: 'checkoutUpdate',
                            actionData: checkoutUpdateData
                        }).catch(function (err) {
                            if (err.statusCode === 409 && err.response &&
                                err.response.error === 'Already checked out' && err.response.orderId) {
                                return {actionResponse: {id: err.response.orderId}};
                            } else {
                                throw err;
                            }
                        });
                    }).then(function (paymentData) {
                        if (!paymentData) {
                            return;
                        }

                        if (paymentData.actionResponse) {
                            checkoutUpdateData.actionResponse = paymentData.actionResponse;
                        } else if (paymentData.skip3DS) {
                            mainPayment.skip3DS = true;
                        } else {
                            mainPayment.token = paymentData.paymentToken;
                            mainPayment.authNum = paymentData.authNum;
                            mainPayment.isPaymentCompleted = paymentData.isPaymentCompleted; // In cases where the 3DSecure cannot be performed separately from J5
                            if (paymentData.paymentsNumber) {
                                mainPayment.paymentsNumber = paymentData.paymentsNumber;
                            }
                        }
                    });
                }

                function _getEBTCheckoutPaymentTransaction(paymentType) {
                    return dialog.show({
                        templateUrl: 'template/dialogs/ebt-pin-message-dialog/index.html',
                        styleClass: 'ebt-pin-message-dialog',
                        controller: ['$scope', function ($scope) {
                            $scope.paymentType = paymentType;
                        }]
                    }).then(function (is) {
                        if (!is) {
                            throw new Error('canceled');
                        }

                        return _getEBTTransaction();
                    });
                }

                function checkoutUpdate(outerData) {
                    updateOrderPaymentCtrl.checkingOut = true;
                    return $q.resolve().then(function () {
                        var data = outerData || {};
                        if (data.actionResponse) {
                            return data.actionResponse;
                        }

                        return _setCheckoutUpdateData(data).then(function () {
                            if (data.actionResponse) {
                                return data.actionResponse;
                            }

                            return _sendUpdate(data);
                        });
                    }).then(function () {
                        if (updateOrderCtrl.updateType.type === UPDATE_ORDER_PAYMENT_TYPE.EDIT_ORDER_UPDATE) {
                            // sideNavCtrl.updatedOrder = updateOrderCtrl.order;
                            //Google analytics listener.
                            //$rootScope.$emit('checkout', order);
                            updateOrderCtrl.oid = updateOrderCtrl.order.id;
                            Orders.orderInEdit = null;
                            cart.setTimeTravel(); // clear time travel before saving cart
                            cart.clear(true);
                            $rootScope.$emit('editOrder.updated');

                            //== refreshing User's Credit data after order update
                            if ($rootScope.isCreditCustomer) {
                                user.getData(true);
                            }
                        }

                        $state.go(updateOrderCtrl.updateType.stateBase + '.finish');
                    }).catch(function (data) {
                        if ('statusCode' in data && data.statusCode !== 400) {
                            Orders.cancelEdit().then(function () {
                                cart.clear();
                            }).catch(function () {
                                Orders.orderInEdit = null;
                                cart.clear(true);
                            });
                        }

                        if ('statusCode' in data && data.statusCode !== 300) {
                            if (data.response && data.response.error) {
                                //== when Credit customer is limited we need to display a custom popup with remaining sum
                                if (data.response.code === 'creditCustomerLimited') {
                                    return cart.checkUserCreditLimited(true, '');
                                } else if (data.response && data.response.xErrorCode && data.response.details) {
                                    util.showEbtErrorDialog(data.response.details)
                                } else {
                                    util.showCommonDialog({
                                        title: '{{\'Something Went Wrong\' | translate}}...',
                                        content: '{{\'Update order failed\' | translate}}.<br/>{{\'' + data.response.error.replace(/'/g, '\\\'') + '\' | translate}}'
                                    });
                                }
                            }
                        }
                    }).finally(function () {
                        updateOrderPaymentCtrl.checkingOut = false;
                    });
                }

                function _sendUpdate(data) {
                    if (updateOrderCtrl.updateType.type === UPDATE_ORDER_PAYMENT_TYPE.USER_CONFIRMATION_UPDATE) {
                        return api.request({
                            method: 'POST',
                            url: '/v2/retailers/:rid/orders/' +  updateOrderCtrl.order.id + '/_finishUserConfirm',
                            data: {
                                userConfirmationToken: $stateParams.userConfirmationToken,
                                payments: data.payments
                            }
                        }, {
                            hideError: true
                        });
                    }

                    return api.request({
                        method: 'POST',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/_checkoutUpdate',
                        data: data
                    }, {
                        hideError: true
                    });
                }

                /**
                 * Make checkout for Credit Customer - check is OK and proceed
                 * @public
                 *
                 * @param {HTMLElement} paymentForm
                 */
                function checkoutCreditCustomer(paymentForm) {
                    return cart.checkUserCreditLimited(true, 'app.cartSummary').then(function (isLimited) {
                        if (!isLimited) {
                            return checkoutUpdate(paymentForm);
                        }
                    });
                }

                if (updateOrderCtrl.updateType.type === UPDATE_ORDER_PAYMENT_TYPE.EDIT_ORDER_UPDATE) {
                    util.currentScopeListener($scope, $rootScope.$on('orders.orderInEdit', function () {
                        _init();
                    }));
                }

                util.currentScopeListener($scope, function () {
                    if (_ebtEligibleListener) {
                        _ebtEligibleListener();
                    }
                });
            }]);

})(angular, app);
