(function (angular, app) {
    'use strict';

    app.directive('spCheckbox', ['$timeout', function ($timeout) {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            scope: {
                model: '=spModel',
                onChange: '&?spChange',
                disabled: '=?spDisabled',
                iName: '@?',
                iSpInlineError: '=?',
                iSpInlineErrorAttachToParent: '@?'
            },
            templateUrl: 'template/directives/sp-checkbox/index.html',
            controllerAs: 'spCheckboxCtrl',
            controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
                var spCheckboxCtrl = this;

                spCheckboxCtrl.hasInlineError = $attrs.hasOwnProperty('iSpInlineError');
                spCheckboxCtrl.onChange = onChange;
                spCheckboxCtrl.onFocus = onFocus;
                spCheckboxCtrl.onBlur = onBlur;

                $timeout(function () {
                    angular.element($element[0].querySelector('input')).bind('keydown', _onKeydown);
                }, 0);

                function onChange() {
                    $timeout(function() {
                        $scope.onChange && $scope.onChange();
                    });
                }

                function onFocus() {
                    $element.addClass('focus');
                }

                function onBlur() {
                    $element.removeClass('focus');
                }

                function _onKeydown(event) {
                    if (event.which === 13) {
                        $scope.model = !$scope.model;
                        onChange();
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    }
                }
            }]
        };
    }]);
})(angular, app);