(function (angular, app) {
  "use strict";

  /**
   * @typedef {Object} MapLocation
   *
   * @property {number} lat
   * @property {number} lng
   */

  app.service("LocationService", ["$window", "$rootScope", "$filter", locationService]);

  function locationService($window, $rootScope, $filter) {
    var self = this,
    _translate = $filter("translate");

    angular.extend(self, {
      getUserDefaultLocation: getUserDefaultLocation,
    });

    /**
     * Get user's location first. If not then get selected branch's location
     * @returns {Promise<MapLocation>}
     */
    function getUserDefaultLocation() {
      return getUserLocation().then(function (userLocation) {
        if (userLocation) {
          return userLocation;
        }
        var branchLocation = getBranchLocation();
        if (branchLocation.lat && branchLocation.lng) {
          return branchLocation;
        }
        throw new Error(_translate("gg_map_alert_need_location"));
      }).catch(function (error) {
        throw error;
      });
    }

    /**
     * @returns {Promise<GeolocationPosition>}
     */
    function getGeoCurPosition() {
      return new Promise(function (resolve, reject) {
        $window.navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    }

    /**
     * @returns {Promise<MapLocation>}
     */
    function getUserLocation() {
      if ($window.navigator.geolocation) {
        return getGeoCurPosition()
          .then(function (location) {
            return {
              lat: location.coords.latitude,
              lng: location.coords.longitude,
            };
          })
          .catch(function (error) {
            var ERROR_USER_DENIED_LOCATION_CODE = 1;
            if (error.code !== ERROR_USER_DENIED_LOCATION_CODE) {
              throw new Error(_translate("gg_map_alert_error"));
            }
          });
      } else {
        throw new Error(_translate("gg_map_alert_unsupport"));
      }
    }

    /**
     * Get all branch's location info
     * @returns {MapLocation}
     */
    function getBranchLocation() {
      var selectedBranch = $rootScope.config.branch;

      // lat lon are saved as string
      return {
        lat: +selectedBranch.lat,
        lng: +selectedBranch.lon,
      };
    }
  }
})(angular, app);
