(function (angular, app) {
    'use strict';

    var COUNTER = 0;

    app.component('spTabsPane', {
        controller: ['$element', '$attrs', SpTabsPaneController],
        controllerAs: 'spTabsPaneController',
        transclude: true,
        require: {
            spTabs: '^spTabs'
        },
        bindings: {
            title: '@',
            selectedBind: '<?selected',
            onSelect: '&?',
            onDeselect: '&?',
            hidden: '<?tabHidden',
            srText: '=?'
        },
        templateUrl: 'template/components/sp-tabs-pane/index.html'
    });

    function SpTabsPaneController($element, $attrs) {
        var spTabsPaneController = this;

        spTabsPaneController.$onInit = init;
        spTabsPaneController.deselect = deselect;
        spTabsPaneController.select = select;

        if ($attrs.hasOwnProperty('selected')) {
            spTabsPaneController.$onChanges = onBindingsChange;
        }

        function onBindingsChange(changes) {
            if (changes.selectedBind.currentValue && !spTabsPaneController.selected) {
                spTabsPaneController.select();
            }
        }

        function deselect() {
            spTabsPaneController.selected = false;

            spTabsPaneController.onDeselect && spTabsPaneController.onDeselect();
        }

        function select() {
            spTabsPaneController.spTabs.deselectAll();
            spTabsPaneController.selected = true;

            spTabsPaneController.onSelect && spTabsPaneController.onSelect();
        }

        function init() {
            spTabsPaneController.paneId = 'sp_tab_pane_' + COUNTER++;
            spTabsPaneController.spTabs.addPane(spTabsPaneController, $element);
        }
    }
})(angular, app);
