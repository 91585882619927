(function (app) {
    'use strict';

    app.controller('InnerPaymentDialogCtrl', [
        '$scope', '$rootScope', '$timeout', 'Config', 'Loading', 'Util', 'options', 'PaymentsService', 'Dialog',
        function($scope, $rootScope, $timeout, Config, Loading, Util, options, PaymentsService, Dialog) {
            var innerPaymentCtrl = this;

            innerPaymentCtrl.paymentMethodId = options.paymentMethodId;
            innerPaymentCtrl.isAddCard = options.isAddCard;
            innerPaymentCtrl.getCardDetails = getCardDetails;

            function getCardDetails() {
                return PaymentsService.getGiftCardBalance(options.paymentMethodId ,innerPaymentCtrl.cardNum)
                    .then(function (result) {
                        Dialog.hide(result);
                    }).catch(function (err) {
                        Dialog.hide({error: err});
                    });
            }
        }
    ]);
})(app);