(function (angular, app) {
    'use strict';
    app.directive('refresher', [
        function () {
            return {
                transclude: true,
                controller: ['$scope', '$transclude', '$attrs', '$element', function ($scope, $transclude, $attrs, $element) {
                    var childScope;
                    $scope.$watch($attrs.refresher, function () {
                        if (childScope) {
                            childScope.$destroy();
                            childScope = null;
                        }
                        $element.empty();

                        $transclude(function (clone, newScope) {
                            childScope = newScope;
                            $element.append(clone);
                        });
                    });
                }]
            };
        }]);
})(angular, app);






