(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.specials.special', {
                url: '/:sid',
                data: {
                    name: 'special'
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return $filter('translate')('Special products');
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/special-products/index.html',
                        controller: 'SpecialProductsCtrl as specialProductsCtrl'
                    }
                }
            });
        }])
        .controller('SpecialProductsCtrl', [
            '$scope', '$rootScope', '$state', '$stateParams', '$q', 'Util', 'Cart', 'Items', 'Products', 'PermanentFilters',
            function ($scope, $rootScope, $state, $stateParams, $q, Util, Cart, Items, Products, PermanentFilters) {
                var specialProductsCtrl = this;

                specialProductsCtrl.openProduct = openProduct;
                specialProductsCtrl.products = [];
                specialProductsCtrl.total = 0;

                function openProduct(item, index) {
                    Util.openProduct(item, {
                        next: _nextProduct,
                        previous: _previousProduct,
                        siblings: [Items.items[index - 1], Items.items[index - 2], Items.items[index + 1], Items.items[index + 2]],
                        total: specialProductsCtrl.total,
                        index: index
                    });
                }

                function _addProductsChunk(isInit) {
                    var totalItems = Items.getItemsLength();
                    if (!isInit && specialProductsCtrl.total == totalItems) return;

                    return Items.getChunkAmount().then(function(size) {
                        return Items.getItems({
                            url: '/v2/retailers/:rid/branches/:bid/specials/' + $stateParams.sid,
                            method: 'GET',
                            params: Products.prepareSearchParams({
                                from: totalItems,
                                size: size
                            })
                        }/*, 50*/);
                    }).then(function (specialsData) {
                        if (!specialsData.products.total) {
                            throw new Error('Special has no products');
                        }

                        specialProductsCtrl.special = specialsData.special;
                        specialProductsCtrl.total = specialsData.products.total;
                        Array.prototype.push.apply(specialProductsCtrl.products, specialsData.products.products);
                    }).catch(function(err) {
                        if (err instanceof Error && err.message === 'Special has no products' || err.status === 404) {
                            $state.go('app.home');
                        }
                    });
                }

                function _nextProduct(index) {
                    var totalItems = Items.getItemsLength(),
                        nextProductData = Items.getNextProductData(index, 1);
                    if (specialProductsCtrl.total != totalItems && !nextProductData.product) {
                        return _addProductsChunk().then(function () {
                            return _nextProduct(index);
                        });
                    }

                    nextProductData.total = specialProductsCtrl.total;
                    return $q.resolve(nextProductData);
                }

                function _previousProduct(index) {
                    var nextProductData = Items.getNextProductData(index, -1);
                    nextProductData.total = Items.getItemsLength();
                    return $q.resolve(nextProductData);
                }

                PermanentFilters.subscribe(function() {
                    Items.resetItems();
                    return _addProductsChunk(true);
                }, $scope);

                Util.currentScopeListener($scope, $rootScope.$on('items.initiated', function () {
                    _addProductsChunk(true);
                }));

                Util.currentScopeListener($scope, $rootScope.$on('document.scroll.end', function (data, event) {
                    event.start();
                    $q.resolve(_addProductsChunk()).finally(function() {
                        event.done();
                    });
                }));

                Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function () {
                    angular.forEach(specialProductsCtrl.products, function (product, index) {
                        specialProductsCtrl.products[index] = Cart.getProduct(product);
                    });
                }));
            }])

        //those controllers should move to their template
        .controller('PrutahTemplateSpecialProductsCtrl', ['Items', function(Items) {
            Items.initPage();
        }])
        .controller('KikarTemplateSpecialProductsCtrl', ['Items', function(Items) {
            Items.initPage({
                defaultWidth: 201 // 235
            });
        }]);
})(angular, app);