(function (angular, app) {
	'use strict';

	app.service('SeniorCitizenVerificationDialog', ['Config', 'User', 'Dialog', function(Config, User, Dialog) {
		this.show = show;

		function show() {
			if (!Config.retailer.isSeniorCitizenActive || !User.session.userId) {
				return;
			}

			return Dialog.show({
				templateUrl: 'template/dialogs/senior-citizen-verification/index.html',
				controller: 'SeniorCitizenVerificationCtrl as seniorCitizenVerificationCtrl',
				ariaLabelledby: 'senior_citizen_dialog_title',
				styleClass: 'senior-citizen-verification-dialog',
				resolve: {
					userData: function() {
						return User.getData();
					}
				}
			});
		}
	}]);

	app.controller('SeniorCitizenVerificationCtrl', [
		'$q', 'Dialog', 'Api', 'User', 'userData',
		function($q, Dialog, Api, User, userData) {
			var seniorCitizenVerificationCtrl = this;

			seniorCitizenVerificationCtrl.initializedSubmit = false;
			seniorCitizenVerificationCtrl.userData = userData;

			seniorCitizenVerificationCtrl.hideDialog = Dialog.hide;
			seniorCitizenVerificationCtrl.verify = verify;

			function verify(form) {
				seniorCitizenVerificationCtrl.initializedSubmit = true;

				if (form.$invalid) {
					return;
				}

				seniorCitizenVerificationCtrl.processing = true;

				return (/*$q.resolve() || */Api.request({
					method: 'POST',
					url: '/v2/retailers/:rid/users/:uid/_verify-senior-citizen',
					data: {
						idNumber: seniorCitizenVerificationCtrl.seniorCitizenId
					}
				})).then(function() {
					return User.getData(true);
				}).then(function(userData) {
					seniorCitizenVerificationCtrl.userData = userData;
					// seniorCitizenVerificationCtrl.userData.isSeniorCitizen = true;
				}).finally(function() {
					seniorCitizenVerificationCtrl.processing = false;
				});
			}
		}
	]);
})(angular, app);