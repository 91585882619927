(function (angular, app) {
    'use strict';

    app.service('Loyalty', [
        '$rootScope', '$state', '$q', 'Config', 'Api', 'Cart', 'User', 'Util', 'Dialog', 'LocalStorage',
        function ($rootScope, $state, $q, Config, Api, Cart, User, Util, Dialog, LocalStorage) {
            var self = this;

            angular.extend(self, {
                getCoupons: getCoupons,
                getSpecials: getSpecials,
                getRetailerClubDriver: getRetailerClubDriver,
                getRetailerClubConfig: getRetailerClubConfig,
                getRetailerDefaultClub: getRetailerDefaultClub,
                getUserLoyaltyClub: getUserLoyaltyClub,
                getLoyaltyPageDesign: getLoyaltyPageDesign,
                getLastTransactions: getLastTransactions
            });

            function getCoupons(extended, countonly) {
                var params = {
                    extended: extended,
                    countonly: countonly
                };

                return Api.request({
                    method: "GET",
                    url: "/v2/retailers/:rid/branches/:bid/users/:uid/coupons",
                    params: params
                });
            }

            function getSpecials(from, size) {
                var retailerClubDriver = getRetailerClubDriver();
                var loyaltyClubsIds = retailerClubDriver.loyaltyClubIds.length > 0 ? retailerClubDriver.loyaltyClubIds : [];
                var startDate, endDate = (new Date()).toISOString();
                var params = {
                    from: from,
                    size: size,
                    filters: {"must":{"term":{"displayOnWeb":true,"loyaltyClubsIds": loyaltyClubsIds},"exists":["loyaltyClubsIds"]}}
                };

                return Api.request({
                    method: "GET",
                    url: "/v2/retailers/:rid/branches/:bid/specials",
                    params: params
                });
            }

            function getRetailerClubDriver() {
                return !!Config.retailer.loyaltyClubDriver && Config.retailer.loyaltyClubDriver;
            }

            function getRetailerClubConfig(clubId) {
                var retailerClubDriver = getRetailerClubDriver(),
                    retailerClub = null;
                    if (retailerClubDriver.loyaltyClubIds.includes(clubId)) {
                        angular.forEach(retailerClubDriver.clientConfig.loyaltyClubs, function (loyaltyClub, loyaltyClubId) { 
                        if (+loyaltyClubId === clubId && loyaltyClub.pointsToCashback) {
                            retailerClub = loyaltyClub;
                        }
                     })
                    return retailerClub;
                }
            }

            function getLoyaltyPageDesign(clubId){
                var retailerClubDriver = getRetailerClubDriver(),
                    pageDesign = null;
                if(retailerClubDriver.loyaltyClubIds.includes(clubId)){
                    pageDesign = retailerClubDriver.loyaltyPageDesign[clubId];
                }
                return pageDesign;
            }

            function getRetailerDefaultClub() {
                var retailerClubDriver = getRetailerClubDriver(),
                    retailerClubs = null;
                if (retailerClubDriver && !!retailerClubDriver.clientConfig.loyaltyClubs) {
                    retailerClubs = Object.values(retailerClubDriver.clientConfig.loyaltyClubs);      
                    return retailerClubs.find(function (retailerClub) {
                        if(!retailerClub) {
                            return null;
                        }
                        return !!retailerClub.pointsToCashback;
                    });
                }
            }

            function getUserLoyaltyClub(user) {
                return user && user.loyaltyClubs && user.loyaltyClubs.find(function (loyaltyClub) {
                    return loyaltyClub.loyaltyClubDriverId === getRetailerClubDriver().loyaltyClubDriverId &&
                        getRetailerClubDriver().loyaltyClubIds &&
                        getRetailerClubDriver().loyaltyClubIds.length &&
                        getRetailerClubDriver().loyaltyClubIds.includes(loyaltyClub.loyaltyClubId);
                })
            }

            function getLastTransactions(loyaltyClubId){
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/' + loyaltyClubId + '/_lastTransactions'
                }).then(function(result){
                    return result;
                });
            }
        }

    ]);

})(angular, app);
