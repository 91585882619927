
(function (angular, app) {
    'use strict';

    app.directive('spFocusManagement', ['$timeout', '$compile', 'Util', function ($timeout, $compile, Util) {
        return {
            restrict: 'A',
            scope: {
                previousElementSelector: '@',
                nextElementSelector: '@'
            },
            link: function ($scope, $element) {
                var focusableElements,
                    previousElement = angular.element(document.querySelector($scope.previousElementSelector)),
                    nextElement = angular.element(document.querySelector($scope.nextElementSelector)),
                    firstFocusTrapElement,
                    lastFocusTrapElement;

                $timeout(function () {
                    focusableElements = $element[0].querySelectorAll('button, [ng-click], [href], input, select, textarea');

                    previousElement && previousElement.bind('keydown', _onPreviousElementKeyDown);
                    nextElement && nextElement.bind('keydown', _onNextElementKeyDown);

                    firstFocusTrapElement = angular.element('<div class="sp-focus-trap" tabindex="0"></div>').bind('focus', _onFirstFocusTrapFocus);
                    lastFocusTrapElement = angular.element('<div class="sp-focus-trap" tabindex="0"></div>').bind('focus', _onLastFocusTrapFocus);

                    $element.prepend($compile(firstFocusTrapElement)($scope)[0]);
                    $element.append($compile(lastFocusTrapElement)($scope)[0]);
                }, 100);

                function _onFirstFocusTrapFocus() {
                    previousElement[0].focus();
                }

                function _onLastFocusTrapFocus() {
                    nextElement[0].focus();
                }

                function _onPreviousElementKeyDown(event) {
                    if (event.which === 9 && !event.shiftKey) {
                        focusableElements[0].focus();
                    }
                }

                function _onNextElementKeyDown(event) {
                    if (event.which === 9 && event.shiftKey) {
                        focusableElements[focusableElements.length -1].focus();
                    }
                }

                Util.currentScopeListener($scope, function() {
                    firstFocusTrapElement.unbind('focus', _onFirstFocusTrapFocus);
                    lastFocusTrapElement.unbind('focus', _onLastFocusTrapFocus);
                    previousElement.unbind('keydown', _onPreviousElementKeyDown);
                    nextElement.unbind('keydown', _onNextElementKeyDown);
                });
            }
        };
    }]);

})(angular, app);