(function (angular, app) {
    'use strict';

    app.service('Specials', [
        'Api', 'Config', 'FilterManager',
        function (Api, Config, FilterManager) {
            var self = this;

            angular.extend(self, {
                getSpecials: getSpecials,
                getFilters: getFilters,
                prepareSearchParams: prepareSearchParams
            });

            var globalParams = {
                filters: {
                    must: {
                        lessThan: {
                            startDate: new Date()
                        },
                        greaterThan: {
                            endDate: new Date()
                        },
                        term: {
                            displayOnWeb: true
                        }
                    },
                },
                sort: [{'priority': 'desc'}, {'sort': 'asc'}, {'id': 'desc'}]
            };
            if (!Config.retailer.settings.showCouponsSpecials) {
                globalParams.filters.must.term.isCoupon = false;
            }

            function getSpecials(additionalFilter){
                var params = angular.merge({}, globalParams, additionalFilter);
                return Api.request({
                    url: '/v2/retailers/:rid/branches/:bid/specials',
                    params: params
                }, {
                    fireAndForgot: true
                });
            }

            function getFilters(){
                return Api.request({
                    url: '/v2/retailers/:rid/branches/:bid/specials/filters',
                    params: globalParams
                }, {
                    fireAndForgot: true
                }).then(function(result) {
                    if (result.categories) {
                        Config.isSpecialCategory = {};
                        angular.forEach(result.categories, function(category) {
                            Config.isSpecialCategory[category.id] = true;
                        });
                    }
                    return result;
                });
            }

            function prepareSearchParams(params) {
                FilterManager.parsePermanentProductTags(params, true);
                return params;
            }
        }]);

})(angular, app);