(function (angular, app) {
    'use strict';

    app.directive('spFormAccessibility', ['$compile', '$timeout', function ($compile, $timeout) {
        return {
            restrict: 'A',
            scope: {
            },
            link: function ($scope, $element) {
                var firstElement = angular.element('<div class="form-required-fields">{{\'Required fields are marked with *\' | translate}}</div>');
                $element.prepend($compile(firstElement)($scope));

                $timeout(function () {
                    var requiredElements = $element[0].querySelectorAll('input[required], textarea[required]');

                    angular.forEach(requiredElements, function(requiredElement) {
                        if (!!requiredElement.placeholder) {
                            angular.element(requiredElement).attr('placeholder', requiredElement.placeholder + ' *');
                        }
                    });
                });
            }
        };
    }]);

})(angular, app);