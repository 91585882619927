
(function (angular, app) {
    'use strict';

    app.directive('spClickEvent', ['$timeout', 'Util', function ($timeout, Util) {
        return {
            restrict: 'A',
            scope: {
                onClick: '&spClickEvent'
            },
            link: function ($scope, $element) {
                $element.bind('click', _onClick);
                $element.bind('keydown', _onKeydown);

                function _onClick() {
                    if (!$scope.onClick) return;

                    $scope.onClick();
                }

                function _onKeydown(event) {
                    console.log(event.which);
                    if (event.which === 13 || event.which === 32) {
                        _onClick();
                    }
                }

                Util.currentScopeListener($scope, function() {
                    $element.unbind('click', _onClick);
                    $element.unbind('keydown', _onKeydown);
                });
            }
        };
    }]);

})(angular, app);