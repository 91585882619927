(function (angular, app) {
    'use strict';
    app.directive('csvReader', [
        function () {
            return {
                restrict: 'A',
                scope: {
                    csvReader: "="
                },
                link: function($scope, element) {
                  element.bind('change', function(changeEvent) {
                    var files = changeEvent.target.files;
                    if (files.length) {
                      var r = new FileReader();
                      r.onload = function(e) {
                          var contents = e.target.result;
                          $scope.$apply(function () {
                            csv({
                                noheader: true,
                                output: "csv"
                            })
                            .fromString(contents)
                            .then(function(csvRow){ 
                                $scope.csvReader = {
                                  users: csvRow,
                                  file: files[0]
                                };
                                $scope.$apply();
                            }).catch(function(err){
                                console.log("Could not parse CSV file", err);
                            });
                          });
                      };
                      r.readAsText(files[0]);
                    }
                  });
                }
              };
        }]);
})(angular, app);