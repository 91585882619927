(function (angular, app) {
	'use strict';

	app
		.config(['$stateProvider', function ($stateProvider) {
			$stateProvider.state('app.retailerDetails.contact-details', {
				url: '/contact-details',
				metaTags: {
					title: ['$filter', function ($filter) {
						return $filter('translate')('Contact details');
					}]
				},
				views: {
					'detailsTab': {
						templateUrl: 'template/views/retailer-details/contact-details/index.html',
						controller: 'RetailerContactDetails as contactDetailsCtrl'
					}
				}
			});
		}])
		.controller('RetailerContactDetails', ['$scope', 'Config', function ($scope, config) {
			var contactDetailsCtrl = this,
				retailerDetailsCtrl = $scope.retailerDetailsCtrl;
			contactDetailsCtrl.content = config.retailer.settings.contactUsText;

			retailerDetailsCtrl.selectedTab = retailerDetailsCtrl.menuTabs.CONTACT_DETAILS;
		}]);
})(angular, app);