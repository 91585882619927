(function (angular, app) {
    "use strict";

    app.service('EN', [
        function () {
            var self = this;

            self.id = 2;
            self.culture = 'en';
            self.title = 'English';
            self.label = 'English';
            self.short = 'Eng';
            self.direction = 'ltr';
            self.icon = 'https://d226b0iufwcjmj.cloudfront.net/localization/en.png';
            self.dateFormat = {
                date: 'MM/dd/yyyy',
                time: 'hh:mm a',
                dateAndTime: 'MM/dd/yyyy' + ' ' + 'hh:mm a'
            };
            self.translate = {
                'floor: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'floor: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'floor: not_editable_locked error': 'To change the address please choose again from postcode list',
                'text1: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'text1: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'text1: not_editable_locked error': 'To change the address please choose again from postcode list',
                'text2: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'text2: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'text2: not_editable_locked error': 'To change the address please choose again from postcode list',
                'street: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'street: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'street: not_editable_locked error': 'To change the address please choose again from postcode list',
                'cityinput: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'cityinput: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'cityinput: not_editable_locked error': 'To change the address please choose again from postcode list',
                'city: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'city: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'city: not_editable_locked error': 'To change the address please choose again from postcode list',
                'countryinput: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'countryinput: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'countryinput: not_editable_locked error': 'To change the address please choose again from postcode list',
                'housenum: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'housenum: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'housenum: not_editable_locked error': 'To change the address please choose again from postcode list',
                'entry: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'entry: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'entry: not_editable_locked error': 'To change the address please choose again from postcode list',
                'entrance: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'entrance: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'entrance: not_editable_locked error': 'To change the address please choose again from postcode list',
                'apartment: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'apartment: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'apartment: not_editable_locked error': 'To change the address please choose again from postcode list',
                'apartmentinput: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'apartmentinput: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'apartmentinput: not_editable_locked error': 'To change the address please choose again from postcode list',
                'address1input: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'address1input: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'address1input: not_editable_locked error': 'To change the address please choose again from postcode list',
                'address2input: not_editable error': 'You cannot edit this field before entering a postcode for your address',
                'address2input: not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
                'address2input: not_editable_locked error': 'To change the address please choose again from postcode list',
                'callcustomer': 'Call Me',
                'creditcard': 'Credit Card',
                'storecredit': 'Credit On File',
                'paypal': 'PayPal',
                'icreditmasterpass': 'Master Pass',
                'noun search': 'Search',
                'received text': 'Your order has been received',
                'charged text': 'Your order has been collected',
                'delivered text': 'Your order has been collected',
                'cancelled text': 'The order was canceled',
                'cancel order': 'Cancel Order',
                'suspended text': 'Your order is currently being collected by our trained shoppers',
                'processing text': 'The order is currently being collected by our trained shoppers',
                'collected text': 'Your order is currently being collected by our trained shoppers',
                'registered text': 'Your order is currently being collected by our trained shoppers',
                'waiting for confirmation text': 'A message has been sent to the number on your order, kindly confirm the replacements and we will complete your order',
                'waiting_for_confirmation': 'WAITING FOR CONFIRMATION',
                'suspended': 'PROCESSING',
                'user_confirmed': 'PROCESSING',
                'email: required error': 'Please enter your email',
                'housenum: max error': 'Please enter a number below 3000',
                'email: email error': 'Please enter a valid email',
                'firstname: required error': 'Please enter your first name',
                'firstname: maxlength error': 'First Name is limited to 50 characters',
                'lastname: required error': 'Please enter your last name',
                'lastname: maxlength error': 'Last Name is limited to 50 characters',
                'password: required error': 'Please enter your password',
                'password: minlength error': 'Password must include at least 6 characters',
                'password: maxlength error': 'Password should not be longer than 30 characters',
                'password: pattern error': 'Password must contain at least one numeric digit and one character',
                'confirmpassword: required error': 'Please re-enter password',
                'confirm_password: required error': 'Please re-enter password',
                'policyapproval: required error': 'Please agree to the Terms & Policy',
                'user not found': 'Username or Password is incorrect',
                'fullnameinput: required error': 'Please enter your full name',
                'addresstext: required error': 'Please enter your address',
                'fullname: required error': 'Please enter your full name',
                'city: required error': 'Please enter your city',
                'city: spautocompleterequirematch error': 'City not found',
                'cityinput: spautocompleterequirematch error': 'City not found',
                'streetinput: spautocompleterequirematch error': 'Street not found',
                'street: required error': 'Please enter your street',
                'street: spautocompleterequirematch error': 'Street not found',
                'housenum: required error': 'Please enter your house number',
                'cityinput: required error': 'Please enter your city',
				'mobile: required error': 'Please enter your mobile phone',
				'mobilephone: required error': 'Please enter your mobile phone',
                'phone: required error': 'Please enter your phone number',
                'loyaltycardid: required error': 'Please enter loyalty card id',
                'membershipapproval: required error': 'Please confirm you own a loyalty club card',
                'birthdate: date error': 'Please enter birth date',
                'phone: minlength error': 'Phone must include at least 7 digits',
                'phone: maxlength error': 'Not a valid phone number',
                'phone: pattern error': 'Not a valid phone number',
                'phone: number error': 'Not a valid phone number',
                'homephone: pattern error': 'Not a valid phone number',
                'homephone: number error': 'Not a valid phone number',
                'workphone: pattern error': 'Not a valid phone number',
                'workphone: number error': 'Not a valid phone number',
                'mobilephone: pattern error': 'Not a valid phone number',
                'mobilephone: number error': 'Not a valid phone number',
                'zipcode: required error': 'Please enter your zip code',
                'cvv: required error': 'Please enter cvv',
                'cvv: invalid cvv error': 'Invalid cvv',
                'cvv: please enter cvv error': 'Please enter your cvv',
                'cvv: minlength error': 'Cvv should be at least 3 digits',
                'pre auth: declined invld acct': 'CVV does not match credit card. please try again',
                'message: required error': 'Please enter a Message',
                'null': '',
                'checkout contact details': 'Contact Details',
                'ordersfromposfieldtitle0': 'Enter your mobile phone number',
                'ordersfromposfielddescription0': 'We will send you a verification text message',
                'phonenumber: required error': 'Please enter your phone number',
                'orderfromposcodesenttitle0': 'We sent a text message with a verification code to <%value%>',
                'confirmcode: required error': 'Please enter verification code',
                'resetnewpassword: required error': 'Please enter a new password',
                'resetconfirmpassword: required error': 'Please enter a password confirmation',
                'resetconfirmpassword: passwords do not match error': 'Please confirm the new password',
                'confirmpassword: passwords do not match error': 'Passwords do not match',
                'confirm_password: Passwords do not match error': 'Passwords do not match',
                'confirmPassword: pattern error': 'Passwords do not match',
                'confirm_password: pattern error': 'Passwords do not match',
                'floor: step error': 'Please enter an absolute number',
                'resetnewpassword: minlength error': 'New password must include at least 6 characters',
                'text1: required error': 'Please enter your address',
                'ebtfsamount: please add your amount error': 'Please enter your amount',
                'ebtfsamount: amount exceeded error': 'Amount exceeded eligibility',
                'amount exceeded eligibility':'Amount exceeded eligibility',
                'ebtcbamount: amount exceeded error': 'Amount exceeded eligibility',
                'substitute_preference_1_label': 'Allow',
                'substitute_preference_1_description': 'We will replace a missing product without contacting you',
                'substitute_preference_2_label': 'Call me',
                'substitute_preference_2_description': 'We will call you before we replace a missing product',
                'substitute_preference_3_label': 'Don\'t allow',
                'substitute_preference_3_description': 'We will not replace a missing product',
                'substitute_preference_4_label': 'Send SMS',
                'substitute_preference_4_description': 'We will send you a text message before we replace a missing product',
                'substitute_preference_5_label': 'WhatsApp',
                'substitute_preference_5_description': 'We will send you a text message in WhatsApp before we replace a missing product',
                'substitute_preference__label': 'to prevent error',
                'substitute_preference__description': 'to prevent error',
                'day0': 'Sun',
                'day1': 'Mon',
                'day2': 'Tue',
                'day3': 'Wed',
                'day4': 'Thu',
                'day5': 'Fri',
                'day6': 'Sat',
                'week_day_0': 'Sun',
                'week_day_1': 'Mon',
                'week_day_2': 'Tue',
                'week_day_3': 'Wed',
                'week_day_4': 'Thu',
                'week_day_5': 'Fri',
                'week_day_6': 'Sat',
                'house_entry_none': 'None',
                'house_entry_a': 'A',
                'house_entry_b': 'B',
                'house_entry_c': 'C',
                'house_entry_d': 'D',
                'house_entry_e': 'E',
                'house_entry_f': 'F',
                'house_entry_g': 'G',
                'house_entry_h': 'H',
                'house_entry_i': 'I',
                'house_entry_j': 'J',
                'entry: spautocompleterequirematch error': 'invalid entrance',
                'ticket_status_1': 'New',
                'ticket_status_2': 'Handling',
                'ticket_status_3': 'Done',
                'pseudo_products_will_not_be_added_to_created_shopping_list': 'pseudo products will not be added to the shopping list you have saved',
                'pseudo_products_will_not_be_added_to_saved_shopping_list': 'Pseudo products will not be added to the shopping list',
                'shoplistname: required error': 'Please enter shop list name',
                'pseudoproductname: required error': 'Please enter product name',
                'pseudoproductname: maxlength error': 'The product name cannot contain more than 200 characters',
                'area: required error': 'Please enter your area',
                'percent daily': 'Percent Daily Values are based on a 2,000 calorie diet. Your daily value may be higher or lower depending on your calorie needs.',
                'product info disclaimer': 'Product information or packaging displayed may not be current or complete. Always refer to the physical product for the most accurate information and warnings. For additional information, contact the retailer or manufacturer.',
                'giftcards.0: gift card must be up to 255 characters error': 'Gift card must be up to 255 characters error',
                'personalid: required error': 'Personal Id is required',
                'personalid: pattern error': 'Personal Id is not valid',
                'customerid: required error': 'Customer Id is required',
                'storecredit: required error': 'Please enter your payment details',
                'delivery_type_1_times': 'Delivery Times',
                'delivery_type_2_times': 'Pickup Times',
                'frombranch': 'From',
                'homepagenavigation': 'Home Page',
                'forpackquantity': 'for',
                'verify_1': 'Please verify',
                'verify_2': 'your age',
                'verify_3': 'You must be at least',
                'verify_4': 'years old to',
                'verify_5': 'purchase this item',
                'verify_6': 'I am',
                'verify_7': 'older',
                'verify_8': 'than',
                'verify_9': 'younger',
                'sorry_2': 'Sorry',
                'login_tab_title': 'Login',
                'register_tab_title': 'Register',
                'membership_club': 'Membership Club',
                'connect_to_loyalty': 'connect',
                'promocode: promo code unidentified error': 'Promo code unidentified',
                'areaquery: spautocompleterequirematch error': 'Please select an address',
                'areaquery: required error': 'Please select an address',
                'password: invalid password error': 'The new password must contain between 6 and 20 characters',
                'register_privacy_policy': 'Privacy Policy',
                'policy_end': '',
                'filters_region': 'filters',
                'cart_total': 'Total',
                'items_in_cart': 'Items',
                'save_1': 'Save',
                'received_items': 'Received',
                'items_marked_with_symbol': 'items marked with this symbol',
                'product name': 'Product Name',
                'prutah_checkout_finish_thank_you_line_1': 'thank you for shopping at',
                'prutah_checkout_finish_thank_you_line_2': '',

                'specials_and_benefits_title': 'Specials',

                'delivery_type_1': 'Delivery',
                'delivery_type_1_fee': 'Delivery fee',
                'includes_delivery_type_1_fee': 'Includes delivery fee',
                'not_includes_delivery_fee': '(not include the delivery fee)',
                'delivery_type_2': 'Pickup',
                'delivery_type_2_fee': 'Pickup fee',
                'includes_delivery_type_2_fee': 'Includes pickup fee',
                'delivery_type_5': 'Express Delivery',
                'delivery_type_5_fee': 'Express Delivery fee',
                'includes_delivery_type_5_fee': 'Includes express delivery fee',

                'chametz': 'Chametz',
                'passoverkosher': 'Kosher for Passover',
                'passoverkoshertemporarily': 'Temporarily Kosher for Passover',
                'passoverkitniyotkosher': 'Kosher for Passover (Kitniyot)',
                'passoverkitniyotkoshertemporarily': 'Temporarily Kosher for Passover (Kitniyot)',
                'meat': 'Kosher Fleischig',
                'milky': 'Kosher Milchig',
                'pareve': 'Kosher Pareve',
                'israelmilk': 'Cholov Yisroel',
                'israelcooking': 'Bishul Yisroel',
                'sabbathobservingfactory': 'Sabbath Observing Factory',
                'noshviitconcern': 'No Shviit Concern',
                'noorlaconcern': 'No Orla Concern',
                'notevelconcern': 'No Tevel Concern',
                'noovernightconcern': 'No Overnight Concern',
                'nogeniza': 'No Geniza',
                'at_hour': 'At',
                'the waiting period will not exceed two hours': 'Delivery within 2 hours',

                'special_reminder_bubble_title': 'SAVE!',
                'special_reminder_awarded_bubble_title': 'You Saved!',
                'special_reminder_button_title': 'Click & Save!',

                'coupon_requirements_error': 'You haven\'t met the requirements',
                'attachment.size - invalid attachment, file bigger then 2mb': 'Invalid attachment size: bigger than 2MB',
                'attachment.originalname - invalid attachment name': 'Error: invalid attachment name',
                'attachment.mimetype - invalid attachment, file type not allowed': 'Error: invalid attachment file type',
                'cardid: required error': 'Card Id is required',

                'clear_all_filters_2': 'Clear all filters',
                'permanent_filters_legal_text': 'When using the various filtering options offered on the site, the Company does not warrant that there will be no malfunctions and/or errors on the site, including in the performance of the particular filtering. The Company does not undertake that the categorization of products will be accurate and exclude products that do not meet the required criteria nor all presented products meet the criteria. Use of the information obtained is in your sole responsibility, including with respect to any damage caused as a result of the use in the Information or reliance on it. Each product, including its components, must be tested individually. You will be responsible for verifying any information against the manufacturer\'s data for that product and, in the event of any discrepancy between the information presented on the site and the information presented by the manufacturer, the information presented by the manufacturer must be followed. The foregoing shall not create liability, whether for indirect or direct damages, of the Company in connection with the information displayed by the manufacturer of any product.',
                'estimated employee savings': 'Est. employee savings',
                'loyalty_terms_confirm': 'I confirm that i have read the',
                'loyaltypolicyapproval: required error': 'Please agree to the loyalty Terms & Policy',
                'personalid: minlength error': 'Please enter a 9-digit number',
                'personalid: maxlength error': 'Please enter a 9-digit number',
                'personalid: number error': 'Only digits should be typed',
                'phonenum: required error': 'Please enter your phone number',
                'phonenum: pattern error': 'Not a valid phone number',
                'shippingcarrier: required error': 'This field is required',

                'accessibility_statement_p1': 'In {retailerName}, we believe that our services should be available to anyone, and are committed to providing a website that is accessible to the widest possible audience, regardless of circumstances and ability. We work hard to make and maintain this site accessible by following conformance level AA of WCAG 2.0 which is the Israeli standard 5568 by law.',
                'accessibility_statement_p2': 'This site audited for accessibility by Migdalor, a multi-service center for blind and visually impaired people and we fixed all issues that found in the audit report. Please notice that although we are working to maintain this site accessible there might be parts of it that are not accessible enough.',
                'accessibility_statement_p3': 'Here is the accessibility we know that we are providing in this site:',
                'accessibility_point_1': '* This site can be view and navigate through different screen sizes and platforms without losing any information.',
                'accessibility_point_2': '* You can enlarge the text keyboard shortcut: "CTRL" and "+" (control plus).  It will enlarge it by 10% each time you press the + (plus) key. To reduce it, use the keyboard shortcut: "CTRL" and "-" (control minus).',
                'accessibility_point_3': '* You can navigate and activate all features on site by using the keyboard only.',
                'accessibility_point_4': '* The pages structure is semantic and conforming to the standard.',
                'accessibility_point_5': '* Assistive technology – this site is accessible and available for screen reader users. For optimal navigation, we recommend using NVDA screen reader or iOS voice over.',
                'accessibility_point_6': '* Hierarchical headings',
                'accessibility_point_7': '* Meaningful description for pictures',
                'accessibility_point_8': '* Visible focus',
                'accessibility_point_9': '* Meaningful and simple focus order',
                'accessibility_point_10': '* Error notification in the contact form',
                'order_organization_confirm: required error': 'Please enter order confirmation number',
                'user_verification_status_1': 'Not verified',
                'user_verification_status_2': 'Not verified',
                'user_verification_status_3': 'Verified',

                'seniorcitizenid: required error': 'Please enter your id',
                'seniorcitizenid: minlength error': 'invalid id',
                'seniorcitizenid: maxlength error': 'invalid id',
                'seniorcitizenid: pattern error': 'invalid id',

                'custom-phone-validation-range': 'Phone number length must be from {min} to {max} characters',
                'custom-phone-validation-min': 'Phone number minimum length must be {min} characters',
                'custom-phone-validation-max': 'Phone number maximum length must be {max} characters',
                'to_login': 'Login',
                'to_register': 'Register',

                'special_exp': 'Ends at',
                'special_exp_new': 'Valid',

                'cancel_order_credit_card' : 'Credit Card',
                'cancel_order_credit_card_sum' : 'Credit card sum',
                'cancel_order_ebt_card' : 'EBT card',
                'cancel_order_ebt_snap_refund' : 'EBT SNAP Refund',
                'cancel_order_ebt_cash_refund' : 'EBT Cash Refund',
                'cancel_order_updated_snap_balance' : 'Updated SNAP Balance',
                'cancel_order_updated_cash_balance' : 'Updated Cash Balance',
                'registration attempt has been blocked, please contact support': 'Registration attempt has been blocked, please contact support',
                'account has been suspended, please contact support': 'Account has been suspended, please contact support',

                'cart_line_added_alert': 'was added to the shopping {cart}',
                'your shopping cart': 'Your shopping {cart}',
                'add to cart': 'Add to {cart}',
                'in cart': 'In {cart}',
                'create new cart': 'Create new {cart}',
                'add cart products': 'Add {cart} products',
                'remove from cart': 'Remove from {cart}',
                'clear cart': 'Clear {cart}',
                'are you sure you want to remove all products from your shopping cart?': 'Are you sure you want to remove all products from your shopping {cart}?',
                'yes, clear cart': 'Yes, clear {cart}',
                'the items in your shopping cart will be cleared if you': 'The items in your shopping {cart} will be cleared if you',
                'shopping cart': 'shopping {cart}',
                'load your shopping cart': 'Load Your Shopping {cart}',
                'items to cart': 'Items to {cart}',
                'edit order & clear cart': 'Edit order & Clear {cart}',
                'the items below will be removed from your cart': 'The items below will be removed from your {cart}',
                'you have an open cart with': 'You have an open {cart} with',
                'would you like to merge the two carts or continue using this cart': 'Would you like to merge the two {cart}s or continue using this {cart}',
                'merge carts': 'Merge {cart}s',
                'continue with this cart': 'Continue with this {cart}',
                'you_can_not_add_more_than': 'You can not add to the shopping {cart} more than',
                'clear cart?': 'clear {cart}?',
                'back to cart': 'Back to {cart}',
                'receipt_option_title_1': 'Digital receipt',
                'receipt_option_title_2': 'Both',
                'receipt_option_title_3': 'Printed receipt',
                'receipt_option_1': 'The receipt will be received via SMS',
                'receipt_option_2': 'The receipt will be received via SMS and a physical copy will be attached to your order',
                'receipt_option_3': 'The receipt will be attached to your order',

                'tavplus': 'Tav Plus+',
                'tavplusamount: max error': 'Amount exceeded order balance',
                'tav_plus_credit_card_disclaimer_short': 'In order to use gift vouchers on the website your credit card must be entered as backup for payment.The final charge for your order will be determined at the time that the products are...',
                'tav_plus_credit_card_disclaimer_long': 'In order to use gift vouchers on the website your credit card must be entered as backup for payment.The final charge for your order will be determined at the time that the products are collected, and may differ in cases of products sold by weight, missing products or substitute products. If the final sum differs from that entered at the time of purchase, or from the remaining value of the gift voucher or gift card at the time the products are collected, the remaining sum will be charged to your credit card.Please save the voucher or gift card until you receive your order and are charged accordingly.Also, when placing the order, the remaining sum in the voucher or gift card will remain unchanged. The final transaction can be carried out only according to the sum remaining in the voucher or gift card at the time of payment.All aspects of the transaction are subject to the details explained in the regulations.Clarification for employees of Electra Group, Yeinot Bitan and Mega: Employees who choose to pay with voucher or gift card will not receive the employee’s discount which they are eligible.',

                'delivery-fee-explain-default': 'The final delivery fee amount is expected to be updated upon checkout and might be subjected to changes due to the delivery area/time or any additional discounts.',
                'we have prepared a list of products you usually purchase': 'We\'ve prepared a list of products you usually purchase',

                'last_minute_recommendations_headline': 'we have recommendations selected especially for you.',
                'last_minute_recommendations_title_coupons': 'Coupons that can save you money',
                'last_minute_recommendations_title_tip_donation': 'Add a Little Extra to Your Order',
                'last_minute_recommendations_title_products_usually': 'Maybe you forgot these products?',
                'last_minute_recommendations_title_products_complementary': 'You may also like...',
                'last_minute_recommendations_title_specials_offers': 'Specials you don\'t want to miss',
                'last_minute_recommendations_title_products_offers': 'You don\'t want to miss these products',
                'can be redeemed x times': 'Can be redeemed {X} times',
                'clip for x points': 'Clip for {X} points',
				'coupon expires today': 'Coupon expires today',
                'coupon expires in {x} days': 'Coupon expires in {x} days',
                'loyalty_club_continue': 'Continue',

                'add_units_special': 'Add {remaining} units to realize the special',
                'add_currency_special': 'Add {remaining} to realize the special',
                'add_measure_special': 'Add {remaining} {unit} to realize the special',
                'add_units_coupon': 'Add {remaining} more to get the Discount',
                'add_units_coupon_no_remaining': 'Add to get the Discount',
                'add_currency_coupon': 'Add {remaining} more to get the Discount',
                'add_measure_coupon': 'Add {remaining} {unit} more to get the Discount',
                'add_units_cart_coupon': 'Add {remaining} Products',
                'add_unit_cart_coupon': 'Add {remaining} Product',
                'add_currency_cart_coupon': 'Add {remaining}',
                'add_measure_cart_coupon': 'Add {remaining} {unit}',
                'add_units_special_purchase': 'Add {remaining} more',
                'buy_units_total': 'Buy {total} products',
                'buy_unit_total': 'Buy {total} product',
                'buy_currency_total': 'Buy for {total}',
                'buy_measure_total': 'Buy {total} {unit}',
                'display video': 'Click to display video and then continue navigating to play the video',
                'cart deleted': 'Card deleted',
                'cant_find_address_error': 'we couldn\'t find any addresses for this country code',
                'otpphone: required error': 'Enter valid phone number (10 digits)',
                'otpphone: minlength error': 'Enter valid phone number (10 digits)',
                'otpemail: required error': 'Enter valid email',
                'otpemail: email error': 'Enter valid email',
                'no_coupons_for_filter': 'Sorry<br>We couldn\'t find any "{coupon_filter}" coupon',
                'search coupon': 'Search coupon',

                'replace_2': 'Replace',
                'suggestions_dialog_header_1': 'Recommendation for Replacements',
                'suggestions_dialog_header_2': 'Please choose a replacement from our variety of similar products',
                'suggestions_dialog_header_3': 'OUT OF STOCK',
                'suggestions_dialog_header_4': 'RECOMMENDATION FOR REPLACEMENTS:',
                'ok, continue' : 'OK, CONTINUE',
                'change chosen time': 'CHANGE CHOSEN TIME',
                'hover description for cvv': 'The CVV Number (“Card Verification Value”) on your credit card or debit card is a digit number on VISA, Mastercard and Discover branded credit card and debit card. on your American Express branded credit or debit card is a 4 digit numeric code.',
                'coupon clipped': 'coupon clipped',
                'add product': 'Add product',
                'product in cart': 'Product in cart',
                'clip coupon': 'Clip coupon',
                'gift_card_error_message': 'We couldn\'t process your GIFT CARD, please try again or try a different card ',
                'wrong_gift_card_amount_error': 'Redemption is impossible, the amount you entered is higher than the shopping cart/ gift card balance',
                'customercredit': 'Customer Credit',
                gg_map_dialog_title: 'Choose an Address',
                gg_map_dialog_hint: "We're having trouble pinpointing your exact location. Please ensure you position the pin with great precision. This will help us determine your precise address",
                gg_map_alert_need_location: 'Please allow access to your location in order to continue selecting Google Maps',
                gg_map_alert_error: 'There are some problems when showing Google Map',
                gg_map_alert_unsupport: 'Geolocation is not supported by this browser',
                autocomplete_adrs_didnt_find: 'Didn\'t find your address?',
                autocomplete_adrs_choose_location: 'Choose your location on the map',
                'country: required error': 'Please enter your country',
                'country_of_origin_title': 'Country of Origin',
                'origin_country_legal_text': 'Please be aware that while the country of origin listed is accurate as of the ordering date, the actual country of origin for the delivered product may vary based on the stock available at the collection point during delivery',
                'searchtext_storename_placeholder': '{Store name}',
                'contact_us': 'Contact us',
                'how_can_we_help_you_today': 'How can we help you today?',
                'leave_us_a_message': 'Leave us a message, and we\'ll reach out to you soon',
                'fullname': 'Full name',
                'phone': 'Phone number',
                'email': 'Email',
                'message': 'How can we help you?',
                'send': 'Send',
                'your_message_has_been_sent': 'Your message has been sent',
                'thank_you_for_contact_us': 'Thank you for contacting us',
                'nofshonit': 'Nofshonit',
                'max gift card': 'Max Gift Card',
                'customer support phone number': 'Customer Support Phone Number',
				'please wait, we are loading the products': 'Please wait, we are loading the products',
				'loading coupons, please wait...': 'Loading coupons, please wait...',
                'complete_and_proceed_to_checkout': 'Complete & Proceed to Checkout',
                'on_day': 'On',
                'wish_to_change_delivery':'Wish to change the delivery time?',
                'wish_to_change_pickup':'Wish to change the pickup time?',

                'checkout_finish_last_update_time_delivery': 'You can update your order delivery time until',
                'checkout_finish_last_update_time_pickup': 'You can update your order pickup time until',
                'forgot_sth_want_to_change_delivery': 'Want to change the delivery time?',
                'forgot_sth_want_to_change_pickup': 'Want to change the pickup date?',

                'update_order_popup_title_def': 'You can update your order items or the {delivery method} time',
                'update_order_popup_order_item_def': 'Update Order Items',
                'update_order_popup_delivery_time_def': 'Update Delivery Time',
                'update_order_popup_pickup_time_def': 'Update Pickup Time',
                'update_order_popup_cancel_def': 'Want to cancel your order? Click here to cancel it {cancel order}',
                'update_order_popup_lastest_time_def': 'You can update your order {delivery method} time until {time calculate}',
                'update_order_popup_err_cant_update_time': 'Updating your {delivery method} time isn\'t available',

                'irre_sales_collect_one': 'This {saleType} will not be valid for this updated time and will be charged at its regular price',
                'irre_sales_collect_many': 'These {count} {saleType} will not be valid for this updated time and will be charged at their regular price',
                'irre_sales_checkout_one': 'By updating this order, the following {saleType} will no longer be valid, and the item will be charged at its regular price',
                'irre_sales_checkout_many': 'By updating this order, the following {count} {saleType} will no longer be valid, and the items will be charged at their regular price',
                'irre_sales_oos_one': 'This product is currently unavailable and will be removed from your cart',
                'irre_sales_oos_many': 'These products are currently unavailable and will be removed from your cart',
                'irre_sales_btn_continue': 'ok, update',
                'irre_sales_btn_change_slot': 'change {delivery method} date',
                'irre_sales_btn_cancel_update': 'cancel update',
                'confirm_full_credit_card_charge_title': 'Confirm Full Credit Card Charge',
                'confirm_full_credit_card_charge_content': 'EBT payment amount is set to $0. Are you sure you want to charge the full amount to your credit card?',
                'confirm_full_credit_card_charge_content_btn_confirm': 'Yes, Charge Credit Card',
                'confirm_full_credit_card_charge_content_btn_back': 'Go Back',
                'thank you for your order!': 'Thank you for your order!',
            };
        }]);

})(angular, app);
