
(function (angular, app) {
    'use strict';

    app.directive('endlessScroll', [
        '$rootScope', '$timeout', 'Util',
        function ($rootScope, $timeout, util) {
            function _getScrollElement(element) {
                while (element && element !== document.body) {
                    var overflow = util.getCurrentStyleProp(element, 'overflow-y');
                    if (overflow === 'auto' || overflow === 'scroll') {
                        return element;
                    }

                    element = element.parentNode || element.parentElement;
                }
            }

            return {
                restrict: 'A',
                link: function ($scope, $element, attrs) {
                    var options = $scope.$eval('scope.' + attrs.endlessScroll, {scope: $scope}) || {},
                        prevScrollTop = 0,
                        _prevScrollHeight = 0,
                        _listener;

                    options.lengthThreshold = options.lengthThreshold || 500;

                    _bindScroll();
                    $rootScope.$on('ios_iframe_height', function() {
                        $timeout(_bindScroll);
                    });

                    function _onScroll(scrollElement) {
                        if (!$rootScope.endlessScrollCounter) {
                            if (_prevScrollHeight !== scrollElement.scrollHeight) {
                                prevScrollTop = 0;
                            }
                            _prevScrollHeight = scrollElement.scrollHeight;

                            var currentScrollTop = scrollElement.scrollTop + $rootScope.windowSize.height,
                                scrollHeight = scrollElement.scrollHeight - options.lengthThreshold;
                            if (prevScrollTop < currentScrollTop && currentScrollTop > scrollHeight && prevScrollTop <= scrollHeight) {
                                var functions = {
                                    start: function () {
                                        $rootScope.endlessScrollCounter = ($rootScope.endlessScrollCounter || 0) + 1;
                                    },
                                    done: function () {
                                        $rootScope.endlessScrollCounter = ($rootScope.endlessScrollCounter || 0) - 1;
                                        if ($rootScope.endlessScrollCounter < 0) {
                                            $rootScope.endlessScrollCounter = 0;
                                        }
                                    }
                                };

                                if (options.load) {
                                    functions.start();
                                    var promise = options.load();
                                    if (promise) { // if "load" didn't return a promise it means the all list has been loaded
                                        promise.finally(functions.done);
                                    }
                                    return;
                                }

                                $rootScope.$emit('document.scroll.end', functions);
                            }
                            prevScrollTop = currentScrollTop;
                        }
                    }

                    function _bindScroll() {
                        if (_listener) {
                            _listener();
                        }

                        var scrollElement = _getScrollElement($element[0]);

                        function _scrollBind() {
                            _onScroll(scrollElement);
                        }

                        function _unbind() {
                            angular.element(scrollElement).unbind('scroll', _scrollBind);
                        }

                        if (scrollElement) {
                            $rootScope.endlessScrollCounter = 0;
                            angular.element(scrollElement).bind('scroll', _scrollBind);
                            _listener = _unbind;
                        } else {
                            _listener = $rootScope.$on('document.scroll', function (angularEvent, event) {
                                _onScroll(util.getDocumentScrolledElement(event));
                            });
                        }

                        util.currentScopeListener($scope, _listener);
                    }
                }
            };
        }]);

})(angular, app);