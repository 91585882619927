(function (angular, app) {
    'use strict';

    app.service('BranchesService', [
        'Api',
        function (api) {
            var self = this;

            angular.extend(self, {
                getAreaTimes: getAreaTimes
            });

            function getAreaTimes(branchId, areaId) {
                return api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/' + (branchId || ':bid') + '/areas/' + areaId + '/delivery-times'
                }).then(function (resp) {
                    return resp;
                });
            }

        }]);

})(angular, app);