(function (angular, app) {
    'use strict';

    app.config([
        'SpDialogUrlManagerProvider', 'SP_URL_DIALOG_QUERY_PARAMS', 'SP_URL_DIALOG_DATA_QUERY_PARAMS',
        function (SpDialogUrlManagerProvider, SP_URL_DIALOG_QUERY_PARAMS, SP_URL_DIALOG_DATA_QUERY_PARAMS) {
            SpDialogUrlManagerProvider.dialog({
                queryParams: SP_URL_DIALOG_QUERY_PARAMS.RECIPE,
                dataQueryParams: SP_URL_DIALOG_DATA_QUERY_PARAMS.RECIPE,
                canShow: ['SpRecipeService', 'paramValue', function (SpRecipeService, paramValue) {
                    return SpRecipeService.isValidRecipeQuery(decodeURIComponent(paramValue));
                }],
                paramResolve: ['SpRecipeService', 'paramValue', function (SpRecipeService, paramValue) {
                    return SpRecipeService.parseRecipeFromQuery(decodeURIComponent(paramValue));
                }],
                show: ['Dialog', 'SpDialogUrlManager', 'value', function (Dialog, SpDialogUrlManager, value) {
                    return Dialog.show({
                        templateUrl: 'template/dialogs/recipe/index.html',
                        controller: 'RecipeCtrl as recipeCtrl',
                        styleClass: 'recipe',
                        ariaLabelledby: 'recipe_dialog_title',
                        locals: {
                            recipe: value
                        },
                        hide: SpDialogUrlManager.backClose
                    });
                }]
            });
        }
    ]);

    var TEMPLATE_URL = 'template/dialogs/recipe/partials/print/index.html';

    app.controller('RecipeCtrl', [
        '$rootScope', '$scope', '$compile', '$http', '$templateCache', '$timeout', '$q', '$location', '$stateParams', 'recipe', 'Cart', 'Util', 'ADVERTISEMENT_CONVERSION_TYPE', 'SpRecipeService',
        function ($rootScope, $scope, $compile, $http, $templateCache, $timeout, $q, $location, $stateParams, recipe, cart, util, ADVERTISEMENT_CONVERSION_TYPE,  SpRecipeService) {
            window.prerenderReady = true;

            var recipeCtrl = this,
                showProductsOnly = $stateParams.showProductsOnly === 'true' || $stateParams.showProductsOnly === '1';

            recipeCtrl.ingredientCarouselFunctions = {
                addToCart: addIngredientProductToCart,
                addQuantity: addIngredientProductQuantity
            };
            recipeCtrl.addAllIngredientsToCart = addAllIngredientsToCart;
            recipeCtrl.printRecipe = printRecipe;
            recipeCtrl.saveRecipe = saveRecipe;
            recipeCtrl.fbShareRecipe = fbShareRecipe;
            recipeCtrl.itemsWithQuantity = 0;
            $scope.recipe = recipeCtrl.recipe = recipe;
            recipeCtrl.events = $stateParams.events;
            $location.search('events', null);

            $scope.$watch('recipe.groups', function(newGroups) {
                var uniqProductIds = [], productId, currentChunk;
                angular.forEach(newGroups, function (group) {
                    angular.forEach(group.ingredients, function (ingredient) {
                        currentChunk = ingredient.carouselCurrentChunk;
                        if (currentChunk && currentChunk.length > 0) {
                            // in case of sliding items more than 1
                            angular.forEach(currentChunk, function (chunkProduct) {
                                if (chunkProduct.quantity) {
                                    productId = chunkProduct.product.id;
                                    if (uniqProductIds.indexOf(productId) === -1) {
                                        uniqProductIds.push(productId);
                                    }
                                }
                            });
                        }
                    });
                });
                recipeCtrl.itemsWithQuantity = uniqProductIds.length;
            }, true);

            _initGroupsAndShowDetailsSection();

            function addIngredientProductQuantity(ingredientProduct, toAdd) {
                var nextVal = (ingredientProduct.quantity || 0) + cart.quantityInterval(ingredientProduct) * (toAdd || 0);
                if (nextVal >= 0) {
                    ingredientProduct.quantity = nextVal;
                }

                return true;
            }

            function addIngredientProductToCart(ingredientProduct) {
                SpRecipeService.addIngredientProductToCart(ingredientProduct);
            }

            function addAllIngredientsToCart() {
                angular.forEach(recipe.groups, function (group) {
                    angular.forEach(group.ingredients, function (ingredient) {
                        if (ingredient.carouselCurrentChunk && ingredient.carouselCurrentChunk[0] && ingredient.carouselCurrentChunk[0].quantity) {
                            addIngredientProductToCart(ingredient.carouselCurrentChunk[0]);
                        }
                    });
                });
            }

            function printRecipe() {
                _getIframe().then(function (iframe) {
                    iframe.contentWindow.print();
                    _cleanupIframe(iframe);
                });
            }

            function saveRecipe() {
                _getIframe('width: 21cm').then(function (iframe) {
                    var images = iframe.contentDocument.querySelectorAll('img'),
                        promises = {};

                    angular.forEach(images, function (image) {
                        promises[image.src] = _getDataUri(image.src);
                    });

                    return $q.all(promises).then(function (results) {
                        angular.forEach(images, function (image) {
                            image.src = results[image.src];
                        });

                        return html2canvas(iframe.contentDocument.body);
                    }).then(function (canvas) {
                        pdfMake.createPdf({
                            content: [{
                                image: canvas.toDataURL(),
                                width: 500
                            }]
                        }).download(recipe.name.substring(0, 25));
                    }).finally(function () {
                        _cleanupIframe(iframe);
                    });
                });
            }

            function _getIframe(style) {
                return $http.get(TEMPLATE_URL, {cache: $templateCache}).then(function (resp) {
                    var compiled = $compile(resp.data)($scope);
                    return $timeout(function () {
                        var iframe = document.createElement('iframe');
                        iframe.width = '0';
                        iframe.height = '0';
                        iframe.src = 'about:blank';
                        window.document.body.appendChild(iframe);
                        iframe.contentDocument.open('text/html', 'replace');
                        iframe.contentDocument.write('<!DOCTYPE html><html><body style="' + (style || '') + '">' + compiled[0].innerHTML + '</body></html>');
                        iframe.contentDocument.close();
                        return iframe;
                    }, 0, false);
                });
            }

            function _cleanupIframe(iframe) {
                iframe.parentNode.removeChild(iframe);
            }

            function _getDataUri(url) {
                var deferred = $q.defer(),
                    image = new Image();
                image.setAttribute('crossOrigin', 'anonymous');
                image.src = url;
                image.onload = function () {
                    var canvas = document.createElement('canvas');
                    canvas.width = this.naturalWidth;
                    canvas.height = this.naturalHeight;
                    canvas.getContext('2d').drawImage(this, 0, 0);
                    deferred.resolve(canvas.toDataURL());
                };
                image.onerror = deferred.reject;
                return deferred.promise;
            }

            function _initGroupsAndShowDetailsSection() {
                recipeCtrl.showDetailsSection = !showProductsOnly && !!recipe.description;

                angular.forEach(recipe.groups, function (group) {
                    if (!showProductsOnly && group.steps) {
                        recipeCtrl.showDetailsSection = true;
                    }

                    angular.forEach(group.ingredients, function (ingredient) {
                        if (!showProductsOnly && ingredient.name) {
                            recipeCtrl.showDetailsSection = true;
                        }
                        SpRecipeService.fixIngredientQuantity(ingredient);
                    });
                });
            }

            function fbShareRecipe() {
                var url = window.location.href;
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
                    'facebook-share-dialog',
                    'width=800,height=600'
                );
            }

            util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function (event, data) {
                if (recipeCtrl.events) {
                    util.firePromotionEvent(recipeCtrl.events[ADVERTISEMENT_CONVERSION_TYPE.ADD_TO_CART]);
                    if (data.lines && data.lines.length && data.lines[0].conversionCount) {
                        data.lines[0].adConversionUrl = recipeCtrl.events[ADVERTISEMENT_CONVERSION_TYPE.CHARGE];
                    }
                }
            }));
        }]);
})(angular, app);
