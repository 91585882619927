(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.retailerDetails.faq', {
                url: '/faq',
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return $filter('translate')('Faq');
                    }]
                },
                views: {
                    'detailsTab': {
                        templateUrl: 'template/views/retailer-details/faq/index.html',
                        controller: 'RetailerFaqCtrl as faqCtrl',
                        resolve: {
                            faq: ['Api', function (api) {
                                return api.request({
                                    method: 'GET',
                                    url: '/retailers/:rid/faq'
                                });
                            }]
                        }
                    }
                }
            });
        }])
        .controller('RetailerFaqCtrl', ['$scope', 'faq', function ($scope, faq) {
            var faqCtrl = this,
                retailerDetailsCtrl = $scope.retailerDetailsCtrl;

            faqCtrl.faq = faq.faq;

            retailerDetailsCtrl.selectedTab = retailerDetailsCtrl.menuTabs.FAQ;
        }]);
})(angular, app);