(function (angular, app) {
    'use strict';

    app.service('Products', [
        'FilterManager',
        function(FilterManager) {
            var self = this;

            self.prepareSearchParams = prepareSearchParams;

            function prepareSearchParams(params) {
                FilterManager.parsePermanentProductTags(params, false);
                return params;
            }
        }
    ]);
})(angular, app);