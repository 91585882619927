(function (angular, app) {
    'use strict';

    app.directive('backgroundImg', ['Config', 'Api', '$filter', 'ADVERTISEMENT_AD_TYPE', 'ADVERTISEMENT_GROUP_TYPE', function (Config, Api, $filter, ADVERTISEMENT_AD_TYPE, ADVERTISEMENT_GROUP_TYPE) {
        var images = Config.retailer.homeCarouselImages;

        Api.request({
            method: 'GET',
            url: '/v2/retailers/:rid/native-promotion',
            params: {
                groupType: ADVERTISEMENT_GROUP_TYPE.PLASMA,
            }
        }).then(function (resp) {
            if (resp && resp.advertisements && resp.advertisements.plasma && resp.advertisements.plasma.length) {
                var image = {
                    href: resp.advertisements.plasma[0].url,
                    url: resp.advertisements.plasma[0].pictureUrl,
                    adId: resp.advertisements.plasma[0].adId
                };
                if (image.url) {
                    images.splice(1, 0, image);
                }
            }
        });

        return {
            restrict: 'E',
            replace: true,
            scope: {
                images: '='
            },
            templateUrl:'template/directives/background-img/index.html',
            controller: ['$timeout', function ($timeout) {
                var backgroundImgCtrl = this,
                    _translateFilter = $filter('translate');

                backgroundImgCtrl.images = images;
                backgroundImgCtrl.changeImage = changeImage;
                backgroundImgCtrl.pauseAutoChange = pauseAutoChange;
                backgroundImgCtrl.isAutoChangePaused = false;

                changeImage(0);

                var _timeout;
                function changeImage(changeToIndex, manually) {
                    if (backgroundImgCtrl.isAutoChangePaused && !manually) {
                        return;
                    }

                    _timeout && $timeout.cancel(_timeout);

                    backgroundImgCtrl.active = changeToIndex !== undefined ? changeToIndex : _nextImage();

                    _timeout = $timeout(changeImage, (Config.retailer.settings.homePageImagesSliderInterval || 10) * 1000);

                    if (manually) {
                        _setAlertElement(changeToIndex);
                    }
                }

                function pauseAutoChange() {
                    backgroundImgCtrl.isAutoChangePaused = !backgroundImgCtrl.isAutoChangePaused;

                    if (!backgroundImgCtrl.isAutoChangePaused){
                        changeImage();
                    }
                }

                function _nextImage() {
                    var ret = backgroundImgCtrl.active + 1;

                    if (ret === backgroundImgCtrl.images.length) {
                        ret = 0;
                    }

                    return ret;
                }

                function _setAlertElement($index) {
                    var $oldElement = angular.element(document.querySelector('.choose-alert'));
                    if (!!$oldElement) {
                        $oldElement.remove();
                    }

                    var $alertElement = angular.element(document.createElement('div'))
                        .addClass('choose-alert sr-only-element');

                    $alertElement[0].setAttribute('aria-live', 'assertive');
                    $alertElement[0].setAttribute('role', 'alert');
                    $alertElement[0].setAttribute('aria-relevant', 'additions');
                    $alertElement.text(_translateFilter('displays image') + ' ' + ($index + 1) + ' ' + _translateFilter('of') + ' ' + backgroundImgCtrl.images.length);

                    document.body.appendChild($alertElement[0]);
                }
            }],
            controllerAs: 'backgroundImgCtrl'
        };
    }]);
})(angular, app);
