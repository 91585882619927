(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.smartList', {
                url: '/smart-list',
                data: {
                    routeAccess: ROUTE_ACCESS.LOGIN,
                    name: 'smart-list'
                },
                metaTags: {
                    title: [ '$filter', function ($filter) {
                        return $filter('translate')('The products I usually purchase');
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/smart-list/index.html',
                        controller: 'SmartListCtrl as smartListCtrl',
                        resolve: {
                            smartList: ['Api', function (Api) {
                                return Api.request({
                                    method: 'GET',
                                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/smart-list'
                                }).catch(function(err) {
                                    return {
                                        items: [],
                                        error: (err.response || {}).error
                                    };
                                });
                            }]
                        }
                    }
                }
            });
        }])
        .controller('SmartListCtrl', [
            '$scope', '$rootScope', '$filter', 'Cart', 'Util', 'smartList', 'SP_SERVICES', 'Config',
            function ($scope, $rootScope, $filter, cart, util, smartList, SP_SERVICES, Config) {
                var smartListCtrl = this,
                    _listeners = [];

                var _productNameFilter = $filter('productName');
                smartListCtrl.smartList = smartList;
                smartListCtrl.addToCart = addToCart;
                smartListCtrl.createNewCart = createNewCart;
                smartListCtrl.checkAll = checkAll;
                smartListCtrl.sortByCategories = Config.retailer.settings.sortCartByCategories === 'true';
                smartListCtrl.sortedListByTree = [];

                if (smartListCtrl.sortByCategories) {
                    var sortedCartByCategoriesData = cart.sortCartByCategories(smartListCtrl.smartList.items, [], null, false);
                    smartListCtrl.sortedListByTree = sortedCartByCategoriesData.sortedObjByCategories;
                }

                smartListCtrl.actions = {
                    setQuantity: function (item, toAdd) {
                        smartListCtrl.hasChanges = true;
                        item.quantity = _fixQuantity(item.quantity + _getQuantityInterval(item.product) * toAdd);
                        if (item.product.quantityLimit && item.quantity > item.product.quantityLimit) {
                            item.quantity = item.product.quantityLimit;
                        }
                        if (item.quantity <= 0) {
                            item.quantity = 0;
                        }

                        // to prevent the sp-quantity directive changing the quantity
                        return true;
                    },
                    openSuggestions: function(item) {
                        // possibly use item.quantity - in future for reference of amount ordered
                        util.openSuggestionsDialog(item.product);
                    },
                    onWeightClick: function (item) {
                        if (item.product.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name) {
                            var cartLine = _getLine(item.product);
                            item.product.soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                            item.soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                            /* if need to re-calculate the quantity from unit to weight when switching to weight, uncomment this code */
                            // item.quantity = +(item.quantity * item.product.weight).toFixed(2);
                            if (cartLine) {
                                /* if need to re-calculate the quantity from unit to weight when switching to weight, uncomment this code */
                                // cartLine.quantity = +(cartLine.quantity * cartLine.product.weight).toFixed(2);
                                cartLine.soldBy = item.soldBy;
                            }
                        }
                    },
                    onUnitClick: function (item) {
                        if (item.product.soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name) {
                            var cartLine = _getLine(item.product);
                            item.product.soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;
                            item.soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;
                            /* if need to re-calculate the quantity from weight to unit when switching to unit, uncomment this code */
                            // item.quantity = Math.round(item.quantity / item.product.weight);
                            item.quantity = Math.ceil(item.quantity);
                            if (cartLine) {
                                /* if need to re-calculate the quantity from weight to unit when switching to unit, uncomment this code */
                                // cartLine.quantity = Math.round(cartLine.quantity / cartLine.product.weight);
                                cartLine.quantity = Math.ceil(cartLine.quantity);
                                cartLine.soldBy = item.soldBy;
                            }
                        }
                    }
                };

                //== If Cart already finished an API call we make init
                if(cart.sendingSucceeded) {
                    _init();
                }

                //== Subscribing to Cart event will sincronize data in cart withe data shown on Smart List page
                _listeners.push($rootScope.$on('cart.update.complete', function () {
                    _init();
                }));
                
                function _init() {
                    _setSoldBy();
                    _setTotalAmount();
                }

                function checkAll() {
                    angular.forEach(smartListCtrl.smartList.items, function (item) {
                        item.actionsChecked = smartListCtrl.checkAllIsActive;
                    });
                }

                function _getQuantityInterval(item) {
                    // use product when exits
                    var product = item.product || item;

                    return  product.soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name || (product.isWeighable && !product.weight) ? (product.weight || product.unitResolution ? product.weight || product.unitResolution : 0.5) : 1;
                }

                function _setTotalAmount() {
                    var cartProducts = _getCartProductsQuantity();

                    smartListCtrl.smartList.totalAmount = 0;
                    var _replacementLines = [];
                    angular.forEach(smartListCtrl.smartList.items, function(item) {
                        smartListCtrl.smartList.totalAmount += (item.quantity || 0) * (item.product && util.getRegularPrice(item.product, item.isCase));
                        item.inCart = !!cartProducts[item.product.id];
                        item.inCartQuantity = cartProducts[item.product.id] || 0;
                        item.type = SP_SERVICES.CART_LINE_TYPES.PRODUCT;
                        item.isProductOutOfStock = util.isProductOutOfStock(item);
                        item.isNeedToShowOutOfStockLabel = util.isNeedToShowOutOfStockLabel(item);
                        // cart.setDebugIsForStock(item, count);
                        if (cart.isEligibleForReplacementSuggestions(item)) {
                            _replacementLines.push(item);
                        }
                        item.actionsDisabled = item.isProductOutOfStock ? true : item.inCart;

                        // set weighable units for suitable products
                        if (item.product.isWeighable && item.product.weight && !item.weightQuantity) {
                            item.weightQuantity = item.quantity;

                            if(!item.product.soldBy || item.product.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name) {
                                item.quantity = Math.round(item.weightQuantity / item.product.weight);
                            }
                        }

                        item.quantity = _fixQuantity(item.quantity);
                    });
                    cart.setReplacements(_replacementLines);

                    $scope.$broadcast('smartList.items.checkedChanged');
                }

                function _getSelectedProducts() {
                    var lines = angular.filterCollection(smartListCtrl.smartList.items, function(item) {
                        item.weighableProductUnits = item.quantity;
                        return item.actionsChecked;
                    });

                    if (!lines || lines && !lines.length) {
                        util.showCommonDialog('{{\'No products were selected\' | translate}}');
                    }

                    return lines;
                }

                function addToCart() {
                    var lines = _getSelectedProducts();

                    if(!lines)return;

                    var linesWithoutPropertySelected = [];

                    angular.forEach(lines, function (line) {
                        if(line.product && line.product.productProperties && line.product.productProperties.length && !line.productPropertyValue) {
                            linesWithoutPropertySelected.push(_productNameFilter(line.product, line.product.isCaseMode));
                        }
                    });

                    if(linesWithoutPropertySelected.length !== 0) {
                        util.showCommonDialog(
                            '{{\'Product properties need to be selected for\' | translate}}' + ': ' + linesWithoutPropertySelected.join(', ')
                        );

                        return;
                    }

                    angular.forEach(lines, function (line) {
                        line.actionsChecked = false;
                    });

                    var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(lines);
                    if (isLimitPassed === false) {
                        if (cart.lines && Object.keys(cart.lines).length === 0 &&
                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                            cart.addDeliveryFeeLineIfNeeded();
                        }
                        cart.addLines(util.getActiveLines(lines), SP_SERVICES.SOURCES.SMART_LIST);
                    }
                }

                function createNewCart () {
                    var lines = _getSelectedProducts();
                    if (lines && lines.length) {
                        var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(lines);
                        if (isLimitPassed === false) {
                            cart.clear();
                            if (cart.lines && Object.keys(cart.lines).length === 0 &&
                                $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                                cart.addDeliveryFeeLineIfNeeded();
                            }
                            cart.addLines(util.getActiveLines(lines), SP_SERVICES.SOURCES.SMART_LIST);
                        }
                    }
                }

                function _fixQuantity(quantity) {
                    return Number(Number(quantity).toFixed(2));
                }

                function _getCartProductsQuantity() {
                    var cartProducts = cart.getLines(),
                        products = {};

                    if(cartProducts && Array.isArray(cartProducts) && cartProducts.length) {
                        angular.forEach(cartProducts, function (cartLine) {
                            if(cartLine && cartLine.product && cartLine.product.id) {
                                products[cartLine.product.id] = !cartLine.removed && cartLine.quantity || 0;
                            }
                        });
                    }

                    return products;
                }

                function _setSoldBy() {
                    angular.forEach(smartListCtrl.smartList.items, function (item) {
                        item.soldBy = null;
                        if(!item.product){
                            return;
                        }
                        item.product.soldBy = null;
                        if (item.product && util.isUnitsWeighable(item.product)) {
                            var cartLine = _getLine(item.product);
                            if (!cartLine) {
                                var soldBy;
                                if (!!item.product.productDisplayModeId) {
                                    switch (item.product.productDisplayModeId) {
                                        case $rootScope.PRODUCT_DISPLAY.UNIT.id:
                                            soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;
                                            break;
                                        case $rootScope.PRODUCT_DISPLAY.WEIGHT.id:
                                            soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                                            break;
                                        default:
                                            soldBy = item.product.isWeighable ? (item.product.weight ? $rootScope.PRODUCT_DISPLAY.UNIT.name : $rootScope.PRODUCT_DISPLAY.WEIGHT.name) : null;
                                    } 
                                } else {
                                    soldBy = item.product.isWeighable ? (item.product.weight ? $rootScope.PRODUCT_DISPLAY.UNIT.name : $rootScope.PRODUCT_DISPLAY.WEIGHT.name) : null;
                                }
                                item.soldBy = soldBy;
                            } else {
                                item.soldBy = cartLine.soldBy;
                            }
                            item.product.soldBy = item.soldBy;
                        }
                    });
                }

                function _getLine(product) {
                    var cartLines = cart.getLines(),
                        cartLine;
                    if (!cartLines) return;
                    angular.forEach(cartLines, function (line) {
                        if (line.product && line.product.id === product.id) {
                            cartLine = line;
                        }
                    })
                    return cartLine;
                }

                _listeners.push($rootScope.$on('setSoldBy', function (event, data) {
                    if (!data.cartLine) return;
                    angular.forEach(smartListCtrl.smartList.items, function (item) {
                        if (item.product.id === data.cartLine.product.id) {
                            item.soldBy = data.cartLine.soldBy;
                            item.product.soldBy = data.cartLine.soldBy;
                            item.inCartQuantity = data.cartLine.quantity;
                        }
                    })
                }));

                util.destroyListeners($scope, _listeners);
            }]);
})(angular, app);
