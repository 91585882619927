(function (angular, app) {
    'use strict';

    var DIRECTIONS = {
        VERTICAL: 'vertical',
        HORIZONTAL: 'horizontal'
    };

    app.directive('spSliderPickers', [
        '$compile', '$timeout', '$rootScope', 'Util', 'Config',
        function ($compile, $timeout, $rootScope, util, config) {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'template/directives/sp-slider-pickers/index.html',
                scope: {
                    /**
                     * @example
                     * {
                     *  key: 'day',
                     *  default: 0,//index
                     *  templateUrl: 'optional',
                     *  items: [{
                     *   value: '31',
                     *   next: {
                     *    key: 'hour',
                     *    items: [{
                     *     label: '10:',
                     *     value: '10',
                     *     next: {
                     *      key: 'minute',
                     *      items: [{
                     *       label: '00',
                     *       value: '00'
                     *      }]
                     *     },
                     *     dropDown: {
                     *      key: 'hour',
                     *      default: 0,
                     *      items: [{
                     *          label: '10:00',
                     *          value: '10'
                     *      }]
                     *     }
                     *    }]
                     *   }
                     *  }]
                     * }
                     */
                    picker: '=',

                    /**
                     * {Object} Like ngModel
                     */
                    spModel: '=',

                    /**
                     * {Object} Like ngChange
                     */
                    spChange: '&',

                    /**
                     * @values
                     * 'horizontal'
                     * or
                     * 'vertical'
                     *
                     * @default is 'vertical'
                     */
                    direction: '=?',

                    items: '=?',

                    spDisableItems: '<?',

                    spDisabledSelected: '&?',

                    spOnLastOption: '&?',

                     /**
                     * @example
                     * {
                     *  time: 'day',
                     *  default: 0,//index
                     *  templateUrl: 'optional',
                     * }
                     */
                    spDefaultTime: '=?',
                    isFromEditOrder: '=?',
                },
                controllerAs: 'sliderPickerCtrl',
                controller: [
                    '$scope',
                    function ($scope) {
                        var sliderPickerCtrl = this;

                        $scope.direction = $scope.direction === DIRECTIONS.HORIZONTAL ? $scope.direction : DIRECTIONS.VERTICAL;
                        $scope.spModel = $scope.spModel || {};
                        $scope.spDefaultTime = $scope.spDefaultTime || {};
                        $scope.items = $scope.items || 6;
                        sliderPickerCtrl.checkAddressTimeOut = null;
                        sliderPickerCtrl.timer = null;
                        sliderPickerCtrl.isFromEditOrder = $scope.isFromEditOrder || false;
                        
                        sliderPickerCtrl.selectItem = selectItem;
                        sliderPickerCtrl.selectPrimaryItem = selectPrimaryItem;
                        sliderPickerCtrl.selectDropDownItem = selectDropDownItem;
                        sliderPickerCtrl.getCorrectMeasuresNames = getCorrectMeasuresNames;
                        sliderPickerCtrl.getActualVisibleItems = getActualVisibleItems;
                        sliderPickerCtrl.setDropDownItem = setDropDownItem;
                        sliderPickerCtrl.isDefaultTime = isDefaultTime;
                        sliderPickerCtrl.isDefaultTimeDropDown = isDefaultTimeDropDown;
                        sliderPickerCtrl.isDisableTimeSlot = isDisableTimeSlot;

                        
                        /**
                         * @function
                         * @name isDefaultTime
                         * @description
                         *      Check if the index is the same as in spDefaultTime
                         *      and if the model is empty.
                         *
                         * @param {Number} index 
                         * @param {String} key
                         * @returns {Boolean} True if the index is the same as in spDefaultTime and the model is empty.
                         */
                        function isDefaultTime (index, key){
                            return !$scope.spModel[key] && $scope.spDefaultTime && $scope.spDefaultTime.index == index;
                        };

                        /**
                         * @function
                         * @name isDefaultTimeDropDown
                         * @description
                         *      Check if the index and date of dayTimes is the same as in spDefaultTime
                         *      and if the model is empty.
                         *
                         * @param {Number} index 
                         * @param {String} date 
                         * @param {String} key
                         * @returns {Boolean} True if the index and date is the same as in spDefaultTime and the model is empty.
                         */
                        function isDefaultTimeDropDown (index, date, key){
                            return !$scope.spModel[key] && $scope.spDefaultTime && $scope.spDefaultTime.indexDropDown == index && $scope.spDefaultTime.dayTimes.date == date;
                        };
                        
                        function isDisableTimeSlot (item, isFromItemContent){
                            var checkhaveItemDropDown = item.dropDown && (!item.dropDown.items || !item.dropDown.items.length);

                            if ($scope.isFromEditOrder) {
                                return checkhaveItemDropDown && isFromItemContent;
                            } 

                            return checkhaveItemDropDown;
                        };
                        
                        function selectItem(item, index) {
                            if (_checkDisabled(item)) {
                                return false;
                            }

                            sliderPickerCtrl.setDropDownItem(item, index);
                            sliderPickerCtrl.selectDropDownItem();
                            return true;
                        }

                        function selectPrimaryItem(item, event) {
                            event && event.preventDefault();
                            if (_checkDisabled(item)) {
                                return;
                            }

                            sliderPickerCtrl.dropDownItem = item;
                            sliderPickerCtrl.selectDropDownItem();
                        }

                        function setDropDownItem(item, index) {
                            sliderPickerCtrl.dropDownItem = item;
                            sliderPickerCtrl.dropDownItemIndex = index;
                        }

                        function selectDropDownItem(newItem, event) {
                            event && event.stopPropagation();

                            $scope.currentItem = sliderPickerCtrl.dropDownItem;
                            $scope.spModel[$scope.picker.key] = $scope.currentItem && $scope.currentItem.value ? $scope.currentItem.value : $scope.currentItem;
                            if ($scope.currentItem && $scope.currentItem.dropDown) {
                                if (!newItem) {
                                    if ($scope.spModel[$scope.currentItem.dropDown.key]) {
                                        newItem = angular.filterCollection($scope.currentItem.dropDown.items, function (item) {
                                            return $scope.spModel[$scope.currentItem.dropDown.key] == (item.value || item);
                                        })[0];
                                    }
                                    if (!newItem && 'default' in $scope.currentItem.dropDown) {
                                        newItem = $scope.currentItem.dropDown.items[$scope.currentItem.dropDown.default];
                                    }
                                    if (!newItem) {
                                        $scope.currentItem = null;
                                        return;
                                    }
                                }
                                if (newItem) {
                                    $scope.spModel[$scope.currentItem.dropDown.key] = newItem && newItem.value ? newItem.value : newItem;
                                }
                            }

                            if ($rootScope.cart && $rootScope.cart.total && $rootScope.cart.total.deliveryCost && $rootScope.cart.total.deliveryCost.giftsWithTax && newItem && typeof newItem.price !== 'undefined') {
                                if(newItem.specials && newItem.specials.length) {
                                    // if the delivery item have specials/discounts, then we can not show delivery price from the item itself
                                    // because this price will be calculated by Promo Engine and probably will be different, see ECOM-4063
                                } else {
                                    $rootScope.deliveryFeeFromTimeslot = newItem.price;
                                }
                            }
                            $scope.$eval($scope.spChange);
                        }

                        function getCorrectMeasuresNames() {
                            if ($scope.direction == DIRECTIONS.HORIZONTAL) {
                                var isRtl = config.language.direction == 'rtl';
                                return {
                                    sizeName: 'Width',
                                    marginName: isRtl ? 'margin-right' : 'margin-left',
                                    otherMarginName: isRtl ? 'margin-left' : 'margin-right',
                                    isRtl: isRtl
                                };
                            } else {
                                return {sizeName: 'Height', marginName: 'margin-top'};
                            }
                        }

                        function _setIsScrollButtons() {
                            $scope.isScrollers = $scope.items < (($scope.picker && $scope.picker.items.length) || 0);
                        }

                        function getActualVisibleItems() {
                            return $scope.isScrollers ? $scope.items : (($scope.picker && $scope.picker.items.length) || 0);
                        }

                        function _setItemSize() {
                            _setIsScrollButtons();
                            var style = {};
                            style[$scope.direction == DIRECTIONS.HORIZONTAL ? 'width' : 'height'] = (100 / getActualVisibleItems()) + '%';
                            $scope.itemStyle = style;
                        }

                        function _checkDisabled(item) {
                            if (!item || !item.disabled && !$scope.spDisableItems) {
                                return false;
                            }

                            if ($scope.spDisabledSelected) {
                                var sendData;
                                if (!$scope.spDisableItems) {
                                    sendData = {$item: item};
                                }
                                $scope.spDisabledSelected(sendData);
                            }
                            return true;
                        }

                        util.currentScopeListener($scope, $scope.$watch('picker', function () {
                            if ($scope.picker) {
                                if ($scope.spModel[$scope.picker.key]) {
                                    var currentItem,
                                        currentItemIndex;
                                    if ($scope.picker.primaryItem && $scope.picker.primaryItem.value == $scope.spModel[$scope.picker.key]) {
                                        currentItem = $scope.picker.primaryItem;
                                    } else {
                                        angular.forEach($scope.picker.items, function (item, index) {
                                            if ((item && item.value ? item.value : item) == $scope.spModel[$scope.picker.key]) {
                                                currentItem = item;
                                                currentItemIndex = index;
                                            }
                                        });
                                    }

                                    if (!currentItem && 'default' in $scope.picker && angular.isNumber($scope.picker.default)) {
                                        currentItem = $scope.picker.items[$scope.picker.default];
                                        currentItemIndex = $scope.picker.default;
                                    }
                                    sliderPickerCtrl.selectItem(currentItem, currentItemIndex);
                                } else {
                                    sliderPickerCtrl.selectItem();
                                }

                                _setItemSize();
                            } else {
                                $scope.spModel = {};
                                $scope.currentItem = null;
                            }
                            sliderPickerCtrl.timer = $timeout(_setIsScrollButtons);
                        }));

                        util.currentScopeListener($scope, $scope.$watch('direction', _setItemSize));
                        util.currentScopeListener($scope, $scope.$watch('items', _setItemSize));

                        util.currentScopeListener($scope, function () {
                            sliderPickerCtrl.checkAddressTimeOut && $timeout.cancel(sliderPickerCtrl.checkAddressTimeOut);
                            sliderPickerCtrl.timer && $timeout.cancel(sliderPickerCtrl.timer);
                        });
                    }],
                link: function ($scope, element) {
                    $scope.hasNext = false;
                    angular.forEach($scope.picker ? $scope.picker.items : [], function (item) {
                        $scope.hasNext = item.next ? true : $scope.hasNext;
                    });
                    if ($scope.hasNext) {
                        var nextPickerElement = angular.element('<sp-slider-pickers direction="direction" picker="currentItem.next" sp-model="spModel"></sp-slider-pickers>');
                        element.append(nextPickerElement);
                        $compile(nextPickerElement)($scope);
                    }
                }
            };
        }])

    //todo: move this controller to prutah template
        .controller('SliderPickerPrutahCtrl', ['$scope', '$element', '$timeout', 'Util', '$rootScope', function ($scope, $element, $timeout, util, $rootScope) {
            var sliderPickerCtrl = $scope.sliderPickerCtrl;
            var scrollItemIndex = 0,
                sliderItemsWrapper = $element[0].querySelector('.slider-items-wrapper'),
                sliderItems = angular.element(sliderItemsWrapper.children[0]),
                dropDownOptions = angular.element($element[0].querySelector('.dropdown-options')),
                slideButton = $element[0].querySelector('.slide-btn-wrapper'),
                _baseSetDropDownItem = sliderPickerCtrl.setDropDownItem,
                _baseSelectDropDownItem = sliderPickerCtrl.selectDropDownItem,
                _baseSelectItem = sliderPickerCtrl.selectItem,
                _closeDropDownItemTimeout;
            closeDropDownItem();
            sliderPickerCtrl.selectItem = selectItem;
            sliderPickerCtrl.setDropDownItem = setDropDownItem;
            sliderPickerCtrl.selectDropDownItem = selectDropDownItem;
            sliderPickerCtrl.openDropDownItem = openDropDownItem;
            sliderPickerCtrl.removeDropDownItem = removeDropDownItem;
            sliderPickerCtrl.closeDropDownItem = closeDropDownItem;
            sliderPickerCtrl.checkForPrimaryItem = checkForPrimaryItem;
            sliderPickerCtrl.scroll = scroll;

            function selectItem(item, index) {
                if (!_baseSelectItem(item, index)) {
                    return;
                }

                if (index < scrollItemIndex) {
                    sliderPickerCtrl.scroll(index - scrollItemIndex);
                } else {
                    var visibleItems = sliderPickerCtrl.getActualVisibleItems(),
                        lastVisibleIndex = scrollItemIndex + visibleItems - 1;
                    if (lastVisibleIndex < index) {
                        sliderPickerCtrl.scroll(index - lastVisibleIndex);
                    }
                }
            }

            function setDropDownItem(item, index) {
                _baseSetDropDownItem(item, index);

                $timeout(function() {
                    var measuresNames = sliderPickerCtrl.getCorrectMeasuresNames(),
                        btnSize = sliderItemsWrapper['client' + measuresNames.sizeName] / sliderPickerCtrl.getActualVisibleItems(),
                        dropDownSize = util.getCurrentStyleProp(dropDownOptions, measuresNames.sizeName, true),
                        slideButtonSize = slideButton['client' + measuresNames.sizeName],
                        nextDistance = checkForPrimaryItem(index,btnSize) + slideButtonSize - scrollItemIndex * btnSize - ((dropDownSize - btnSize) / 2);
                    dropDownOptions.css(measuresNames.marginName, nextDistance + 'px');
                });
            }

            function checkForPrimaryItem(index,btnSize) {
                if(!angular.isUndefined($scope.picker.primaryItem)) {
                    return (btnSize / 2)+(index+1) * btnSize;
                } else {
                    return index * btnSize;
                }

            }

            function selectDropDownItem(newItem, event) {
                _baseSelectDropDownItem(newItem, event);

                if (newItem) {
                    sliderPickerCtrl.closeDropDownItem('mouse');
                }
            }

            function openDropDownItem(eventName, item, index) {
                if (_closeDropDownItemTimeout) {
                    $timeout.cancel(_closeDropDownItemTimeout);
                }

                if (item) {
                    if (sliderPickerCtrl.dropDownItem && sliderPickerCtrl.dropDownItem !== item &&
                        sliderPickerCtrl.dropDownItem._dropDownOpen && sliderPickerCtrl.dropDownItem._dropDownOpen.focus) {
                        return;
                    }

                    angular.forEach($scope.picker ? $scope.picker.items : [], function(otherItem) {
                        if (otherItem !== item) {
                            delete otherItem._dropDownOpen;
                        }
                    });
                    sliderPickerCtrl.setDropDownItem(item, index);
                }
                sliderPickerCtrl.dropDownItem._dropDownOpen = sliderPickerCtrl.dropDownItem._dropDownOpen || {};
                sliderPickerCtrl.dropDownShown = sliderPickerCtrl.dropDownItem._dropDownOpen[eventName] = true;
            }

            function closeDropDownItem(eventName) {
                if (!sliderPickerCtrl.dropDownShown) {
                    return;
                }

                sliderPickerCtrl.dropDownItem._dropDownOpen[eventName] = false;
                var shownEvents = angular.filterCollection(Object.keys(sliderPickerCtrl.dropDownItem._dropDownOpen), function(key) {
                    return key !== eventName && sliderPickerCtrl.dropDownItem._dropDownOpen[key];
                });
                if (shownEvents.length) {
                    return;
                }

                if (_closeDropDownItemTimeout) {
                    $timeout.cancel(_closeDropDownItemTimeout);
                }
                _closeDropDownItemTimeout = $timeout(function () {
                    sliderPickerCtrl.dropDownShown = false;
                }, 50);
            }

            function removeDropDownItem() {
                if (sliderPickerCtrl.dropDownItem) {
                    delete sliderPickerCtrl.dropDownItem._dropDownOpen;
                }

                sliderPickerCtrl.dropDownItem = null;
            }

            function scroll(toAdd, event) {
                event && event.preventDefault();
                var maxIndex = ($scope.picker ? $scope.picker.items.length : 0) - sliderPickerCtrl.getActualVisibleItems(),
                    emitLastEvent = false;

                scrollItemIndex += angular.isUndefined(toAdd) ? 1 : Number(toAdd);
                if (scrollItemIndex < 0) {
                    scrollItemIndex = 0;
                } else if (scrollItemIndex > maxIndex) {
                    scrollItemIndex = maxIndex;
                    emitLastEvent = true;
                }

                var measuresNames = sliderPickerCtrl.getCorrectMeasuresNames(),
                    btnSize = sliderItemsWrapper['client' + measuresNames.sizeName] / sliderPickerCtrl.getActualVisibleItems();

                if (measuresNames.otherMarginName) {
                    sliderItems.css(measuresNames.otherMarginName, '0');
                }

                sliderItems.css(measuresNames.marginName, (scrollItemIndex * btnSize * -1) + 'px');

                // reset the scroll on the wrapper element - it can change when focusing on elements
                sliderItemsWrapper.scrollLeft = measuresNames.isRtl ? btnSize * (maxIndex + 1) : 0;

                if (emitLastEvent && $scope.spOnLastOption) {
                    var pickerLength = $scope.picker.items.length;
                    $scope.spOnLastOption({scroll: true}).then(function(){
                        // wait for directive bindings
                        return $timeout(angular.noop, 100);
                    }).then(function() {
                        if (pickerLength < $scope.picker.items.length) {
                            scroll(1);
                        }
                    });
                }
            }

            util.currentScopeListener($scope, $rootScope.$on('config.language.change', function () {
                var measuresNames = sliderPickerCtrl.getCorrectMeasuresNames();
                if (measuresNames.otherMarginName) {
                    sliderPickerCtrl.scroll(0);
                }
            }));

            $scope.$watch('picker', function () {
                if (sliderPickerCtrl.dropDownItem) {
                    sliderPickerCtrl.setDropDownItem(sliderPickerCtrl.dropDownItem, sliderPickerCtrl.dropDownItemIndex);
                    return;
                }

                for (var i = 0; i < $scope.picker.items.length; i++) {
                    if ($scope.picker.items[i].dropDown.items.length > 0) {
                        setDropDownItem($scope.picker.items[i], i);
                        break;
                    }
                }
            });

            $scope.$watch('picker.primaryItem', function () {
                $timeout(function() {
                    sliderPickerCtrl.scroll(0);
                }, 0);
            });

            util.currentScopeListener($scope, $rootScope.$on('picker.backToStart', function () {
                $timeout(function() {
                    sliderPickerCtrl.scroll(scrollItemIndex * -1);
                }, 0);
            }));

            function _onBodyTouch(event) {
                var target = angular.element(event.target || event.srcElement || (event.path || [])[0]);
                while (!target.hasClass('sp-slider-picker') && target && target[0]) {
                    target = angular.element(target.prop('offsetParent'));
                }
                if (!target || !target[0]) {
                    removeDropDownItem();
                    closeDropDownItem();
                    $scope.$applyAsync();
                }
            }

            var body = angular.element(document.body);
            body.bind('touchstart', _onBodyTouch);
            util.currentScopeListener($scope, function () {
                body.unbind('touchstart', _onBodyTouch);
            });
        }])

        //todo: move this controller to kikar template
        .controller('SliderPickerKikarCtrl', ['$scope', '$element', 'Util', '$rootScope', '$timeout',
            function ($scope, $element, util, $rootScope, $timeout) {
                var sliderPickerCtrl = $scope.sliderPickerCtrl,
                    scrollItemIndex = 0,
                    sliderItemsWrapper = $element[0].querySelector('.slider-items-wrapper'),
                    sliderItems = angular.element(sliderItemsWrapper.children[0]),
                    slideButton = $element[0].querySelector('.slide-btn-wrapper'),
                    _baseSetDropDownItem = sliderPickerCtrl.setDropDownItem,
                    _baseSelectItem = sliderPickerCtrl.selectItem;

                sliderPickerCtrl.existArea = false;

                sliderPickerCtrl.selectItem = selectItem;
                sliderPickerCtrl.setDropDownItem = setDropDownItem;
                sliderPickerCtrl.checkForPrimaryItem = checkForPrimaryItem;
                sliderPickerCtrl.scroll = scroll;

                function selectItem(item, index) {
                    if (!_baseSelectItem(item, index)) {
                        return;
                    }

                    if (index < scrollItemIndex) {
                        sliderPickerCtrl.scroll(index - scrollItemIndex);
                    } else {
                        var lastVisibleIndex = scrollItemIndex + sliderPickerCtrl.getActualVisibleItems() - 1;
                        if (lastVisibleIndex < index) {
                            sliderPickerCtrl.scroll(index - lastVisibleIndex);
                        }
                    }
                }

                function setDropDownItem(item, index) {
                    _baseSetDropDownItem(item, index);
                    $timeout(function() {
                        _setArrowDistance(index);
                    });
                }

                function scroll(toAdd) {
                    var maxIndex = ($scope.picker ? $scope.picker.items.length : 0) - sliderPickerCtrl.getActualVisibleItems(),
                        emitLastEvent = false;

                    scrollItemIndex += angular.isUndefined(toAdd) ? 1 : Number(toAdd);
                    if (scrollItemIndex < 0) {
                        scrollItemIndex = 0;
                    } else if (scrollItemIndex > maxIndex) {
                        scrollItemIndex = maxIndex;
                        emitLastEvent = true;
                    }

                    var measuresNames = sliderPickerCtrl.getCorrectMeasuresNames(),
                        btnSize = sliderItemsWrapper['client' + measuresNames.sizeName] / sliderPickerCtrl.getActualVisibleItems();

                    if (measuresNames.otherMarginName) {
                        sliderItems.css(measuresNames.otherMarginName, '0');
                    }

                    sliderItems.css(measuresNames.marginName, (scrollItemIndex * btnSize * -1) + 'px');

                    // reset the scroll on the wrapper element - it can change when focusing on elements
                    sliderItemsWrapper.scrollLeft = measuresNames.isRtl ? btnSize * (maxIndex + 1) : 0;

                    if (emitLastEvent && $scope.spOnLastOption) {
                        var pickerLength = $scope.picker.items.length;
                        $scope.spOnLastOption({scroll: true}).then(function(){
                            if (pickerLength < $scope.picker.items.length) {
                                scroll(1);
                            }
                        });
                    }

                    $timeout(function () {
                        _setArrowDistance(sliderPickerCtrl.dropDownItemIndex);
                    }, 200);
                }

                function _setArrowDistance(index) {
                    var measuresNames = sliderPickerCtrl.getCorrectMeasuresNames(),
                        arrow = angular.element($element[0].querySelector('.arrow')),
                        btnSize = sliderItemsWrapper['client' + measuresNames.sizeName] / sliderPickerCtrl.getActualVisibleItems(),
                        dropDownSize = util.getCurrentStyleProp(arrow, measuresNames.sizeName, true),
                        slideButtonSize = slideButton['client' + measuresNames.sizeName],
                        nextDistance = checkForPrimaryItem(index,btnSize) + slideButtonSize - scrollItemIndex * btnSize - ((dropDownSize - btnSize) / 2);
                    arrow.css(measuresNames.marginName, nextDistance + 'px');
                }

                function checkForPrimaryItem(index,btnSize) {
                    if(!angular.isUndefined($scope.picker.primaryItem)) {
                        return (index+1) * btnSize;
                    } else {
                        return index * btnSize;
                    }

                }

                util.currentScopeListener($scope, $rootScope.$on('config.language.change', function () {
                    var measuresNames = sliderPickerCtrl.getCorrectMeasuresNames();
                    if (measuresNames.otherMarginName) {
                        sliderPickerCtrl.scroll(0, util.getCurrentStyleProp(sliderItems, measuresNames.otherMarginName, true));
                    }
                }));

                $scope.$watch('picker', function () {
                    if (sliderPickerCtrl.dropDownItem) {
                        $timeout(function() {
                            _setArrowDistance(sliderPickerCtrl.dropDownItemIndex);
                        });
                        return;
                    }
                    
                    if($scope.spDefaultTime && $scope.spDefaultTime.content && $scope.spDefaultTime.index) {
                        setDropDownItem($scope.spDefaultTime.content, $scope.spDefaultTime.index);
                        return;
                    }

                    for (var i = 0; i < $scope.picker.items.length; i++) {
                        if ($scope.picker.items[i].dropDown.items.length > 0) {
                            setDropDownItem($scope.picker.items[i], i);
                            break;
                        }
                    }
                });

                $scope.$watch('picker.primaryItem', function () {
                    $timeout(function() {
                        sliderPickerCtrl.scroll(0);
                    }, 0);
                });

                util.currentScopeListener($scope, $rootScope.$on('picker.backToStart', function () {
                    $timeout(function() {
                        sliderPickerCtrl.scroll(scrollItemIndex * -1);
                    }, 0);
                }));
            }]);
})(angular, app);
