(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.about', {
                url: '/about',
                data: {
                    name: 'about'
                },
                metaTags: {
                    title: [ '$filter', function ($filter) {
                        return $filter('translate')('About us');
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/about/index.html',
                        controller: 'AboutCtrl as aboutCtrl'
                    }
                }
            });
        }])
        .controller('AboutCtrl', ['$scope', 'Config', function ($scope, config) {
            var aboutCtrl = this;
            aboutCtrl.about = config.retailer.settings.aboutUsText;
        }]);

})(angular, app);
