(function (angular, app) {
    'use strict';

    app.directive('checkoutBottomBanner', ['$state', function ($state) {
        return {
            restrict: 'E',
            templateUrl: 'template/directives/checkout-bottom-banner/index.html',
            scope: {
                btnText: '@',
                nextAction: '&',
                hasBackAction: '=?',
                withoutNextAction: '=?',
                withoutContent: '=',
                withoutTotal: '=',
                nextDisabled: '=',
                extraBanner: '=',
                faqText: '@?'
            },
            controller: ['$scope', '$rootScope', 'User', 'Util', 'Cart', 'DataLayer', function ($scope, $rootScope, user, util, cart, DataLayer) {

                var htmlElement = angular.element(document.querySelector('html'));

                $scope.$watch('extraBanner', function (extraBanner) {
                    if (extraBanner){
                        htmlElement.addClass('extra-banner-shown');
                    } else {
                        if (htmlElement.hasClass('extra-banner-shown')){
                            htmlElement.removeClass('extra-banner-shown');
                        }
                    }
                });

                $scope.deliveryCheckoutOrServiceFee = util.checkForDeliveryPickupServiceFee(cart.lines);
                $rootScope.$on('store.changed', function () {
                    cart.forceUpdate().then(function () {
                        $scope.deliveryCheckoutOrServiceFee = util.checkForDeliveryPickupServiceFee(cart.lines);
                    });
                });
                user.getData().then(function(userData){
                    $scope.userData = userData;
                });

                $scope.continueShopping = function () {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Continue Shopping'}});
                    $rootScope.$emit('continueShopping', {});
                    $state.go('app.home');
                };

                $scope.back = function () {
                    history.go(-1);
                };
            }]
        };
    }]);
})(angular, app);
