(function (angular, app) {
    'use strict';

    app.directive('spVerticalCarousel', ['$q', '$rootScope', '$timeout', 'Config', 'Util', 'Loading', '$filter', 'DataLayer', function ($q, $rootScope, $timeout, config, util, loading, $filter, DataLayer) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'template/directives/sp-vertial-carousel/index.html',
            scope: {
                items: '=?',
                loadMoreItems: '=?',
                shownSize: '=?',
                itemHeight: '=?',
                functions: '=?',
                data: '=?',
                templateUrl: '@',
                itemClass: '@',
                rows: '=?',
                spClick: '=?',
                spMouseEnter: '=?',
                watchers: '=?',
                currentChunk: '=?',
            },
            controller: [
                '$scope', '$element', '$attrs',
                function ($scope, $element, attrs) {
                    if(!$scope.data){ $scope.data = {}};
                    function _defaultLoadMoreItems(length, shownSize, callback) {
                        callback(null, $scope.items.length, []);
                    }

                    function _setCurrentPage(setTo) {
                        $scope.currentPage = setTo;
                        $scope.currentChunk = $scope.chunks[Math.max(0, Math.min($scope.currentPage * $scope.shownSize, $scope.total - $scope.shownSize))];
                    }

                    function init() {
                        $scope.hidePrevNext = false;
                        $scope.swipe = function (side) {
                            if ((config.language.direction === 'ltr' && side === 'right') || (config.language.direction !== 'ltr' && side === 'left')) {
                                $scope.previous();
                            } else {
                                $scope.next();
                            }
                        };

                        $scope.config = config;
                        $scope.items = $scope.items && angular.isArray($scope.items) ? $scope.items : [];
                        $scope.total = $scope.items ? $scope.items.length + 1 : null;
                        _setCurrentPage(0);
                        setRowsNum();
                        resetItemActualHeight();
                        setIsStartAndEnd();
                        if (!$scope.items.length || $scope.items.length < $scope.shownSize) {
                            callLoadChunkAndMargin();
                        }
                    }

                    if ($scope.functions) {
                        $scope.functions.reset = function () {
                            $scope.items = [];
                            setChunks([], true);
                            init();
                        };
                    }

                    function _getItemHeight() {
                        var itemHeight = $scope.itemHeight;

                        // if use em unit, convert to px
                        if((typeof(itemHeight)==='string'|| itemHeight instanceof String )&&  itemHeight.indexOf('em') > -1){
                            var tmpHeight = parseFloat(itemHeight),
                                itemsEl = $element.children()[1],
                                fontSize = parseFloat(getComputedStyle(itemsEl).fontSize);
                            itemHeight = fontSize * tmpHeight;
                        }

                        // This fix ruins the high DPI screens, and it does not seem to solve anything
                        // If it is still commented here at the end of October 2018, feel free to delete it
                        /*if (itemHeight && window.devicePixelRatio && !$rootScope.iPad/!* && window.devicePixelRatio < 1*!/) {
                            itemHeight *= 1 / window.devicePixelRatio;
                        }*/
                        return itemHeight;
                    }

                    var $loadingElement = angular.element(angular.element($element.children()[2]).children()[0]);

                    function setIsStartAndEnd() {
                        $scope.isEnd = $scope.items.length ? Math.ceil($scope.items.length / $scope.shownSize) - 1 == $scope.currentPage : true;
                        $scope.isStart = $scope.currentPage === 0;
                        // console.log($scope.isEnd, $scope.isStart);
                        if($scope.isEnd && $scope.isStart){
                            $scope.hidePrevNext = true;
                        }
                    }

                    function loadChunk() {
                        var defer = $q.defer();
                        if ($scope.items.length < $scope.total && loading.getCount($loadingElement) < 1) {
                            var currentItemsCount = $scope.items.length;
                            loading.counter($loadingElement, 1);
                            $scope.isLoadingItems = true;
                            ($scope.loadMoreItems || _defaultLoadMoreItems)($scope.items.length, $scope.shownSize, function (err, total, chunk) {
                                if (err) {
                                    defer.reject(err);
                                } else {
                                    $scope.total = total;
                                    util.pushAll($scope.items, chunk);
                                    setChunks(chunk);
                                    setIsStartAndEnd();
                                    if ($scope.isEnd) {
                                        callLoadChunkAndMargin();
                                    }
                                    if (attrs.refreshEvent) {
                                        $scope.$root.$emit(attrs.refreshEvent, {items: $scope.items});
                                    }
                                    defer.resolve();
                                }
                                $scope.isLoadingItems = false;
                                loading.counter($loadingElement, -1);

                                if (chunk && chunk.length && (chunk[0].hasOwnProperty('item') || chunk[0].hasOwnProperty('names') || chunk[0].hasOwnProperty('barcode'))) {
                                    DataLayer.push(DataLayer.EVENTS.VIEW_ITEM_LIST, {products: chunk, data: {productsStartingPosition: currentItemsCount}});
                                }
                            });
                        } else {
                            defer.resolve();
                            // defer.reject({ended: true});
                        }
                        return defer.promise;
                    }

                    function setItemsMargin(toAdd) {
                        setItemsMargin_Vertical(toAdd);
                        return;
                    }

                    function setItemsMargin_Vertical(toAdd){
                        var chunksAmount = $scope.total / $scope.shownSize,
                            lastChunk = chunksAmount - parseInt(chunksAmount),
                            itemsElementData = _itemsElementData();
                        _setCurrentPage($scope.currentPage + (toAdd || 0));
                        setIsStartAndEnd();
                        var leftOvers = chunksAmount > 1 && parseInt(chunksAmount) == $scope.currentPage ? 1 - lastChunk : 0;
                        var scrollElement = angular.element(itemsElementData.element.children()[0]),
                            transformElement = angular.element(scrollElement.children()[0]);
                      

                        scrollElement.css('height', itemsElementData.height + 'px');

                        var translate = (($scope.currentPage * itemsElementData.height * -1) + (itemsElementData.height * leftOvers));
                        transformElement.css('transform', 'translateY(' + translate + 'px)');

                        if ($scope.isEnd) {
                            loadChunk();
                        }
                    }

                    var _visibleScrollHeight = undefined;

                    function _itemsElementData() {
                        var itemsElement = angular.element($element.children()[1]),
                            prevScrollHeight = _visibleScrollHeight;

                        _visibleScrollHeight = itemsElement.prop('clientHeight') - 1;

                        if (prevScrollHeight !== _visibleScrollHeight) {
                            resetItemActualHeight();
                        }

                        return {
                            element: itemsElement,
                            height: _visibleScrollHeight
                        };
                    }

                    function resetItemActualHeight() {
                        var itemHeight = _getItemHeight();
                        if (itemHeight) {
                            // will set the scroll width and call this function again
                            if (_visibleScrollHeight === undefined) {
                                return _itemsElementData();
                            }

                            var fitIn = Math.max(1, Math.floor(_visibleScrollHeight / itemHeight));
                            $scope.shownSize = fitIn * $scope.rowsNum;
                        }

                        $scope.shownSize = $scope.shownSize || 1;
                        $scope.itemActualHeight = (100 * $scope.rowsNum / $scope.shownSize) + '%';
                    }

                    $scope.next = function () {
                        if ($scope.isEnd) return;

                        setItemsMargin(1);

                        $rootScope.$emit('spCarousel.event', {
                            category: 'Button',
                            action: 'Click',
                            label: $scope.data.analyticsLabel + ' - Next'
                        });
                    };

                    $scope.previous = function () {
                        if ($scope.isStart) return;

                        setItemsMargin(-1);

                        $rootScope.$emit('spCarousel.event', {
                            category: 'Button',
                            action: 'Click',
                            label: $scope.data.analyticsLabel + ' - Previous'
                        });
                    };

                    function setChunks(items, clear) {
                        $scope.chunks = $scope.chunks || [];
                        if (clear) {
                            $scope.chunks.splice(0);
                        }

                        var lastChunk = $scope.chunks[$scope.chunks.length - 1];
                        if (lastChunk && lastChunk.length >= $scope.rowsNum) {
                            lastChunk = undefined;
                        }

                        var newChunks = [],
                            currentChunk = lastChunk || [];
                        angular.forEach(items, function(item, index) {
                            currentChunk.push(item);

                            if ((index + 1) % $scope.rowsNum === 0) {
                                if (currentChunk !== lastChunk) {
                                    newChunks.push(currentChunk);
                                }

                                currentChunk = [];
                            }
                        });
                        if (currentChunk.length) {
                            newChunks.push(currentChunk);
                        }

                        util.pushAll($scope.chunks, newChunks);
                    }

                    function setRowsNum() {
                        var rows = $scope.rows || {default: 1},
                            currentRowsNum = $scope.rowsNum;
                        if (angular.isNumber(rows)) {
                            rows = {default: rows};
                        }
                        $scope.rowsNum = rows.default;
                        if (rows.breakingPoints && rows.breakingPoints.length) {
                            angular.forEach(rows.breakingPoints, function (point) {
                                if ($rootScope.windowSize.height <= point.height) {
                                    $scope.rowsNum = point.value;
                                }
                            });
                        }
                        if (currentRowsNum != $scope.rowsNum) {
                            setChunks($scope.items, true);
                        }
                    }

                    setRowsNum();

                    function setMargin() {
                        setItemsMargin();
                    }

                    function callLoadChunkAndMargin() {
                        loadChunk().finally(function () {
                            setItemsMargin();
                        });
                    }

                    util.currentScopeListener($scope, $rootScope.$on('config.language.change', setMargin));
                    var resizeTimeout;
                    util.currentScopeListener($scope, $rootScope.$on('resize', function () {
                        if (resizeTimeout) {
                            $timeout.cancel(resizeTimeout);
                        }
                        resizeTimeout = $timeout(function() {
                            setRowsNum();
                            if ($scope.itemHeight) {
                                var currentItem = $scope.currentPage * $scope.shownSize;
                                _itemsElementData();
                                _setCurrentPage(Math.floor(currentItem / $scope.shownSize));
                                if (!$scope.items.length || $scope.items.length < $scope.shownSize) {
                                    return callLoadChunkAndMargin();
                                }
                            }
                            setItemsMargin();
                        }, 50);
                    }));

                    if ($scope.watchers) {
                        angular.forEach($scope.watchers, function (callback, eventName) {
                            util.currentScopeListener($scope, $rootScope.$on(eventName, function (event, data) {
                                callback($scope.items, event, data);
                            }));
                        });
                    }

                    // init();

                    util.currentScopeListener($scope, $rootScope.$on('checkout', function () {
                        init();
                    }));
                    $scope.$watch('items', function(){
                        $scope.rowsNum = 0;
                        setChunks([], true);
                        init();
                        setItemsMargin(0)
                    })
                }]
        };
    }]);

})(angular, app);
