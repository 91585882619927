
(function (angular, app) {
    'use strict';

    app.directive('spAccessibilityCaptcha', [
        '$timeout', '$rootScope', '$interval',
        function ($timeout, $rootScope, $interval) {
            return {
                restrict: 'E',
                scope: {
                    showCaptcha: '&',
                    isInvalid: '&'
                },
                template: '<div><sp-captcha ng-if="showCaptcha()" is-invalid="isInvalid()"></sp-captcha>' +
                    '<div class="sr-only-element" ng-if="errorToggle" aria-live="assertive" aria-hidden="false" role="alert" aria-relevant="additions">' +
                    'Verification challenge expired. Check the checkbox again.' +
                    '</div>',
                link: function($scope, $element, $attrs) {
                    function retryBindingRender() {
                        if (!window.grecaptcha) {
                            $timeout(retryBindingRender, 500);
                            return;
                        }
                        var interval;

                        $scope.startInterval = function() {
                            console.log('start interval');
                            interval = $interval(function() {
                                console.log('checking');
                                var response = window.grecaptcha.getResponse();
                                if (response.length === 0) {
                                    $scope.errorToggle = true;
                                } else {
                                    $scope.errorToggle = false;
                                }
                            }, 1000);
                        }

                        $scope.stopInterval = function () {
                            if (interval) {
                                $interval.cancel(interval);
                                console.log('stop interval');
                            }
                        };

                        $scope.startInterval();

                        // Clean up interval
                        $scope.$on('$destroy', function () {
                            $scope.stopInterval();
                        });
                    }

                    retryBindingRender();
                }
            };
    }]);

})(angular, app);