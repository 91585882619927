(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.retailerDetails.storeInformation', {
                url: '/information',
                metaTags: {
                    title: ['$filter', 'Config', function ($filter, Config) {
                        return $filter('translate')(Config.retailer.branches.length > 1 ? 'Stores Info' : 'Store Info');
                    }]
                },
                views: {
                    'detailsTab': {
                        templateUrl: 'template/views/retailer-details/store-information/index.html',
                        controller: 'RetailerStoreInformationCtrl as informationCtrl'
                    }
                }
            });
        }])
        .controller('RetailerStoreInformationCtrl', ['$scope', function ($scope) {
            var informationCtrl = this,
                retailerDetailsCtrl = $scope.retailerDetailsCtrl;

            informationCtrl.toggleBranchInfo = toggleBranchInfo;

            retailerDetailsCtrl.selectedTab = retailerDetailsCtrl.menuTabs.STORE_INFORMATION;

            function toggleBranchInfo(branch) {
                angular.forEach(retailerDetailsCtrl.branchesInfo, function(branchItem) {
                    if (branch == branchItem) return;
                    branchItem._openInfo = false;
                });

                branch._openInfo = !branch._openInfo;
            }
        }]);
})(angular, app);