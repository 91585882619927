(function (angular, app) {
  "use strict";
  app.directive("spUnitWeight", [
    "$rootScope",
    'spUnits',
    function ($rootScope, spUnits) {
      return {
        restrict: "E",
        replace: true,
        scope: {
          product: '=',
          productId: '=?',
          cartLine: '=?',
          onWeight: '&?',
          onUnit: '&?',
        },
        templateUrl: "template/directives/sp-unit-weight/index.html",
        controller: ["$scope", "User", "Cart", "$q", "Api", "Util", "Config", "$timeout", "$element", "$attrs",
          function ( $scope, user, cart, $q, api, Util, Config, $timeout, $element, $attrs
          ) {
            var spUnitWeightCtrl = this,
              _listeners = [];

            spUnitWeightCtrl.$onInit = _init;
            spUnitWeightCtrl.onUnitClick = onUnitClick;
            spUnitWeightCtrl.onWeightClick = onWeightClick;
            spUnitWeightCtrl.language = Config.language.id;
            spUnitWeightCtrl.user = user;
            spUnitWeightCtrl.unitOfMeasure = $rootScope.defaultWeightUnit.names[spUnitWeightCtrl.language];
            
            _listeners.push(
              $scope.$watch(
                function () {
                  return Config.language.id;
                },
                function (newCulture, oldCulture) {
                  spUnitWeightCtrl.language = newCulture;
                }
              )
            );

            function onUnitClick() {
              if ($scope.onUnit && $scope.onUnit()) return;
              if ($scope.product.soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name) {
                if (!$scope.cartLine) {
                  $scope.product.soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;
                  return;
                }
                $scope.product.soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;
                $scope.cartLine.soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;
                $scope.cartLine.product.soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;

                /* if need to re-calculate the quantity from weight to unit when switching to unit, uncomment this code */
                // $scope.cartLine.oldQuantity = Math.round($scope.cartLine.oldQuantity / $scope.product.weight);
                // $scope.cartLine.quantity = Math.round($scope.cartLine.quantity / $scope.product.weight);

                $scope.cartLine.oldQuantity = Math.ceil($scope.cartLine.oldQuantity);
                $scope.cartLine.quantity = Math.ceil($scope.cartLine.quantity);
                $rootScope.$emit('setSoldBy', {cartLine: $scope.cartLine});
              }
            }

            function onWeightClick() {
              if ($scope.onWeight && $scope.onWeight()) return;
              if ($scope.product.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name) {
                if (!$scope.cartLine) { 
                  $scope.product.soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                  return;
                }

                $scope.product.soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                $scope.cartLine.soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                $scope.cartLine.product.soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;

                spUnitWeightCtrl.unitOfMeasure = $rootScope.defaultWeightUnit.names;

                /* if need to re-calculate the quantity from unit to weight when switching to weight, uncomment this code */
                // $scope.cartLine.oldQuantity = +($scope.cartLine.oldQuantity * $scope.product.weight).toFixed(2);
                // $scope.cartLine.quantity = +($scope.cartLine.quantity * $scope.product.weight).toFixed(2);

                $rootScope.$emit('setSoldBy', {cartLine: $scope.cartLine});
              }
            }

            function _init() {
              spUnitWeightCtrl.unitOfMeasure = $rootScope.defaultWeightUnit.names;
            }

            function _getLine(product) {
              var cartLines = $scope.cart.lines,
                  cartLine;
              if (!cartLines) return;
              angular.forEach(cartLines, function (line) {
                  if (line.product.id === product.id) {
                      cartLine = line;
                  }
              })
              return cartLine;
            }
            
            _listeners.push($scope.$watch('cartLine.soldBy', function (newSoldBy, oldSoldBy) {
              if (!!newSoldBy && $scope.product.soldBy !== newSoldBy) {
                $scope.product.soldBy = newSoldBy;
              }
            }));

            _listeners.push($scope.$watch('product.soldBy', function (newSoldBy, oldSoldBy) {
              if (!!newSoldBy && $scope.product.soldBy !== newSoldBy) {
                $scope.product.soldBy = newSoldBy;
              }
            }));

            Util.destroyListeners($scope, _listeners);
          },
        ],
        controllerAs: 'spUnitWeightCtrl'
      };
    },
  ]);
})(angular, app);