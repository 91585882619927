(function (angular, app) {
    'use strict';

    app.service('FullCreditCardChargeConfirmationDialog', ['Dialog', function(Dialog) {
        this.show = show;
        this.hide = hide;

        function show() {
            return Dialog.show({
                templateUrl: 'template/dialogs/full-credit-card-charge-confirmation/index.html',
                controller: 'FullCreditCardChargeConfirmationDialogCtrl as creditCardConfirmationDialogCtrl',
                ariaLabelledby: 'full_credit_card_charge_confirmation_dialog_title',
                styleClass: 'full-credit-card-charge-confirmation-dialog'
            });
        }

        function hide(data) {
            return Dialog.hide(data);
        }
    }]);

    app.run(['$rootScope', 'FullCreditCardChargeConfirmationDialog', function ($rootScope, FullCreditCardChargeConfirmationDialog) {
        $rootScope.FullCreditCardChargeConfirmationDialog = FullCreditCardChargeConfirmationDialog;
    }]);


    app.controller('FullCreditCardChargeConfirmationDialogCtrl', ['FullCreditCardChargeConfirmationDialog',
        function (fullCreditCardChargeConfirmationDialog) {
            var creditCardConfirmationDialogCtrl = this;
            creditCardConfirmationDialogCtrl.onClickConfirmButton = onClickConfirmButton;

            function onClickConfirmButton(isChargeConfirmed) {
                fullCreditCardChargeConfirmationDialog.hide({ isChargeConfirmed: isChargeConfirmed });
            }
    }]);
})(angular, app);