(function (angular, app) {
    'use strict';

    app.controller('OutOfStockCtrl', [
        '$rootScope', '$scope', 'Dialog', 'Api', 'User', 'Util', '$timeout', 'Config', 'SpCaptcha', 'DataLayer', 'lines', 'Cart', '$q',
        function ($rootScope, $scope, Dialog, api, user, util, $timeout, Config, SpCaptcha, DataLayer, lines, Cart, $q) {
            var outOfStockCtrl = this;
            outOfStockCtrl.lines = lines;
            outOfStockCtrl.isSentData = false;
            outOfStockCtrl.productsCount = 0;
            outOfStockCtrl.couponsCount = 0;

            angular.forEach(outOfStockCtrl.lines, function(line) {
                if (line.isProductOutOfStock) {
                    ++outOfStockCtrl.productsCount;
                }
                if (line.isCouponActive === false) {
                    ++outOfStockCtrl.couponsCount;
                }
            });

            outOfStockCtrl.removeLines = function () {
                outOfStockCtrl.isSentData = true;
                var promises = [];
                angular.forEach(outOfStockCtrl.lines, function(line) {
                    if (line.isProductOutOfStock || line.isCouponActive === false) {
                        promises.push(Cart.removeLine(line, true, true));
                    }
                });

                $q.all(promises).then(function() {
                    Dialog.hide({isConfirmed: true});
                });
            };
            outOfStockCtrl.backToCart = function () {
                Dialog.hide({isConfirmed: false});
            };
        }]);
})(angular, app);