(function (angular, app) {
    'use strict';

    app.directive('spProductPrice', ['$rootScope', '$filter', 'Config', 'spUnits', function ($rootScope, $filter, Config, spUnits) {
        var _nameFilter = $filter('name');

        // check currency for default
        var _isIsraeliRetailer = Config.retailer.currencySymbol === '₪';
        // when a country iso exists, use it instead
        if (Config.retailer.countryIso) {
            _isIsraeliRetailer = Config.retailer.countryIso === 'IL';
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                showWeightUnit: '=',
                product: '<?',
                packQuantity: '=?',
                hideNormalizePrice: '@?',
                regularPriceBind: '<regularPrice',
                salePriceBind: '<salePrice',
                type: '<?',
                showDisclaimerOnCartPages: '=?'
            },
            controller: ['$scope', function ($scope) {
                $scope.$watchGroup(['product', 'regularPriceBind', 'salePriceBind'], setNormalizedPrices);
                $scope.packPrice = null;
                $scope.disclaimerOnCartPages = Config.retailer.settings.disclaimerOnCartPages;

                function setNormalizedPrices() {
                    $scope.regularPrice = $scope.regularPriceBind;
                    $scope.salePrice = $scope.salePriceBind;
                    $scope.packPrice = $scope.product && $scope.product.branch && $scope.product.branch.packPrice;
                    //override default price behavior for coupons
                    if ($scope.type === $rootScope.SP_SERVICES.CART_LINE_TYPES.COUPON) {
                        $scope.regularPrice = $scope.salePrice;
                    }
                    $scope.isFlexibleUnitNormalization = $rootScope.config.retailer.settings.isFlexibleUnitNormalization === 'true';
                    if ($scope.product) {
                        $scope.unitOfMeasure = !$scope.product.isWeighable && $scope.product.unitOfMeasure && $scope.product.unitOfMeasure.names;
                        if ($scope.product.isWeighable) {
                            $scope.unitOfMeasure = $rootScope.defaultWeightUnit.names;
                        }

                        if ($scope.product && $scope.product.isWeighable && ($scope.product.unitResolution || 1) <= 0.1) {
                            var normalizedPrice = spUnits.normalizePrice($scope.regularPrice, 1, _nameFilter($scope.unitOfMeasure));
                            if (normalizedPrice) {
                                $scope.unitOfMeasure = normalizedPrice.normalizer + ' ' + _nameFilter(normalizedPrice.names);

                                if ($scope.salePrice) {
                                    $scope.salePrice /= $scope.regularPrice / normalizedPrice.price;
                                }

                                $scope.regularPrice = normalizedPrice.price;
                            }
                        }
                    }

                    $scope.priceToNormalize = $scope.regularPrice;
                    // by law in israel, the normalized price must be calculated by the original price
                    if ($scope.salePrice && !_isIsraeliRetailer) {
                        $scope.priceToNormalize = $scope.salePrice;
                    }

                    if (Config.retailer.settings.showPriceWithoutBottleDeposit && $scope.product && $scope.product.branch && $scope.product.branch.linkedProductPrice && !$scope.showDisclaimerOnCartPages) {                        
                        var numberOfItems = $scope.product.isCaseMode ? $scope.product.branch.case && $scope.product.branch.case.items : 1;
                        var linkedPrice = $scope.product.branch.linkedProductPrice;
                        $scope.regularPrice = ($scope.regularPrice - (numberOfItems * linkedPrice))
                        $scope.priceToNormalize = ($scope.priceToNormalize - (numberOfItems * linkedPrice))
                        var priceDecimal = ($scope.regularPrice % 1) != 0 ? $scope.regularPrice.toString().split(".")[1].length : 0;
                        $scope.regularPrice = $scope.regularPrice.toFixed(priceDecimal);
                        $scope.priceToNormalize = $scope.priceToNormalize.toFixed(priceDecimal);
                        
                        $scope.disclaimerOnCartPages = Config.retailer.settings.disclaimerOnCartPages;
                        $scope.showDisclaimer = true;
                    }
                }
            }],
            templateUrl: 'template/directives/sp-product-price/index.html'
        };
    }]);
})(angular, app);
