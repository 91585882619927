(function (angular, app) {
    'use strict';
    app.directive('csvExample', [
        function () {
            return {
                restrict: 'A',
                link: function($scope, element) {
                  element.bind('click', function(changeEvent) {
                    var data = [
                      {"email": "test1@somedomain.com", "phone": "111-1111111", },
                      {"email": "test2@somedomain.com", "phone": "222-2222222", },
                      {"email": "test3@somedomain.com", "phone": "333-3333333", },
                      {"email": "test4@somedomain.com", "phone": "444-4444444", },
                      {"email": "test5@somedomain.com", "phone": "555-5555555", }
                    ];

                    var a         = document.createElement('a');
                    a.href        = 'data:attachment/csv,' +  encodeURIComponent(convertToCSV(data));
                    a.target      = '_blank';
                    a.download    = 'users_upload_example.csv';
                    
                    document.body.appendChild(a);
                    a.click();

                    function convertToCSV(objArray) {
                      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                      var str = '';
          
                      for (var i = 0; i < array.length; i++) {
                          var line = '';
                          for (var index in array[i]) {
                              if (line != '') line += ','
                              line += array[i][index];
                          }
                          str += line + '\r\n';
                      }
                      return str;
                  }
                  });
                }
              };
        }]);
})(angular, app);