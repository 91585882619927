(function (angular, app) {
    'use strict';

    app.directive('productTagLine', function () {
        return {
            restrict: 'E',
            scope: {
                productTag: '<',
                textProp: '@'
            },
            templateUrl: 'template/directives/product-tag-line/index.html'
        };
    });
})(angular, app);