(function (angular, app) {
    'use strict';

    var WIDTH_NAME = 'width',
        HEIGHT_NAME = 'height';

    app.directive('spImageSizeSetter', ['$rootScope', 'Util',
        function ($rootScope, Util) {
            return {
                restrict: 'A',
                scope: {
                    src: '@',
                    sizeName: '@spImageSizeSetter'
                },
                link: function($scope, $element, $attrs) {
                    var _imageOriginalHeight,
                        _imageOriginalWidth;

                    $scope.$watch('src', _setImageOriginalSize);
                    $scope.$watch('sizeName', _setElementSize);
                    $rootScope.$on('resize', _setElementSize);

                    function _setImageOriginalSize() {
                        var img = new Image();
                        img.onload = _onImageLoad;
                        img.src = $scope.src;
                    }

                    function _onImageLoad() {
                        _imageOriginalHeight = this.height;
                        _imageOriginalWidth = this.width;
                        _setElementSize();
                    }

                    function _setElementSize() {
                        if (_imageOriginalHeight === undefined && _imageOriginalWidth === undefined) {
                            return;
                        }

                        var calculateFrom = $scope.sizeName === WIDTH_NAME ? HEIGHT_NAME : WIDTH_NAME,
                            calculateFromOriginal = $scope.sizeName === WIDTH_NAME ? _imageOriginalWidth : _imageOriginalHeight,
                            setToOriginal = $scope.sizeName === WIDTH_NAME ? _imageOriginalHeight : _imageOriginalWidth;

                        $element.css($scope.sizeName, (Util.getCurrentStyleProp($element, calculateFrom, true) * (calculateFromOriginal / setToOriginal)) + 'px');
                    }
                }
            };
        }]);
})(angular, app);