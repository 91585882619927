(function (angular, app) {
    'use strict';

    app.filter('translate', [ 'Config', 'EN', '$log',
        function (config, en, log) {
            function filter (input) {
                var lowerCase = String(input).toLowerCase();

                if (lowerCase) {
                    var languages = [config.language],
                        i;

                    // add fallback languages when the current one is not english
                    // english language does not have any fallback languages, it is the master language
                    if (config.language.translate && config.language !== en) {
                        // the first fallback is the retailer's language
                        if (config.languages[config.retailer.culture] && config.languages[config.retailer.culture] !== config.language) {
                            languages.push(config.languages[config.retailer.culture]);
                        }

                        // the second fallback is english
                        languages.push(en);
                    }

                    for (i = 0; i < languages.length; i++) {
                        if (languages[i].countriesTranslate && languages[i].countriesTranslate[config.retailer.countryIso] &&
                            languages[i].countriesTranslate[config.retailer.countryIso][lowerCase] !== undefined) {
                            return languages[i].countriesTranslate[config.retailer.countryIso][lowerCase];
                        }

                        if (languages[i].translate && languages[i].translate[lowerCase] !== undefined) {
                            return languages[i].translate[lowerCase];
                        }

                        if (languages[i] === config.language && config.language.translate && config.language !== en) {
                            log.warn(input + ' (' + lowerCase + ')', config.language.title);
                        }
                    }
                }

                return input;
            }
            filter.$stateful = true;
            return filter;
        }]);

})(angular, app);