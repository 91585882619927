(function (angular, app) {
    'use strict';

    app.controller('AgeRestrictionCtrl', [
        '$scope', 'ageLimit', '$timeout',
        function ($scope, ageLimit, $timeout) {
            $scope.ageLimit = ageLimit;
            $scope.cantPurchaseTrue = function() {
                $scope.cantPurchase = true;
                $timeout(function() {
                    $scope.$dialog.hide();
                }, 3000);
            };
        }]);
})(angular, app);