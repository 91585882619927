(function (angular, app) {
    'use strict';

    app.directive('spCartLineComments', ['$timeout', 'Util', 'Cart', function ($timeout, util, cart) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'template/directives/sp-cart-line-comments/index.html',
            scope: {
                spLine: '=',
                spMode: '@?',
                spHide: '=?',
                spReadonlyComments: '@?',
                spTextArea: '=?',
                spBlur: '&?',
                setFocus: '<?spSetFocus',
                showProductProperty: '=?'
            },
            controller: ['$scope', '$element', function ($scope, $element) {
                var ctrlData = {};
                $scope.ctrlData = ctrlData;
                ctrlData.editMode = false;
                ctrlData.isInFocus = false;
                ctrlData.onKeyUp = onKeyUp;
                ctrlData.setFocusState = setFocusState;
                ctrlData.setToEditMode = setToEditMode;

                function setFocusState(isFocus) {
                    if (!!isFocus != ctrlData.isInFocus) {
                        ctrlData.isInFocus = !!isFocus;
                        if (!ctrlData.isInFocus && $scope.spBlur) {
                            $scope.spBlur();
                        }
                    }
                    ctrlData.editMode = ctrlData.editMode && ctrlData.isInFocus || !ctrlData.editableComments;
                    !ctrlData.isInFocus && _setModel();
                }

                function onKeyUp(event) {
                    if (_isInlineNode()) {
                        return _setModel();
                    }

                    if ((event.which != 13 || $scope.spTextArea) && event.which != 27) return;

                    event.which == 13 ? _setModel() : _setFromModel();
                    setFocusState(false);
                }

                function setToEditMode() {
                    ctrlData.editMode = true;
                    $timeout(function() {
                        var input = $element[0].querySelector('input, textarea');
                        input && input.focus();
                    }, 200);
                }

                function _setFromModel() {
                    ctrlData.editableComments = $scope.spLine.comments;
                    ctrlData.editMode = ctrlData.editMode || !ctrlData.editableComments;
                }
                _setFromModel();

                function _setModel() {
                    $scope.spLine.comments = ctrlData.editableComments;
                }

                function _isInlineNode() {
                    return $scope.spMode === 'inline';
                }

                util.currentScopeListener($scope, $scope.$watch('spHide', function(newVal, oldVal) {
                    if (!newVal && $scope.setFocus) {
                        setToEditMode();
                    }
                }));

                util.currentScopeListener($scope, $scope.$watch('spLine.comments', function(newVal, oldVal) {
                    var newComment = newVal || undefined;
                    var oldComment = oldVal || undefined;

                    if (newComment === oldComment) return;


                    cart.commentsChanged($scope.spLine);
                    if (ctrlData.isInFocus) return;
                    _setFromModel();
                    ctrlData.editMode = !ctrlData.editableComments;
                }));
            }]
        };
    }]);
})(angular, app);