(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.checkout', {
                url: '/checkout',
                abstract: true,
                data: {
                    routeAccess: [ROUTE_ACCESS.NOT_EMPTY_CART, ROUTE_ACCESS.USER_VERIFIED],
                    name: 'checkout',
                    layout: {
                        menu: false,
                        search: false,
                        sidenav: false
                    }
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/checkout/index.html',
                        controller: 'CheckoutCtrl as checkoutCtrl'
                    }
                }
            });
        }])
        .controller('CheckoutCtrl', [
            '$scope', '$state', 'Config', 'Util', 'Dialog', 'Cart', function ($scope, $state, Config, Util, Dialog, Cart) {
                var checkoutCtrl = this;

                checkoutCtrl.showDidYouMeanDialog = showDidYouMeanDialog;
                checkoutCtrl.waitForSaveCart = false;//used for synchronization between delivery fee calculation in detailsCtrl
                //and call for external payment url in summaryCtrl. Only after the cart was save after the delivery calculation
                //we can ask for url for external payment provider

                /**
                 * Open retailer's alert before checkout
                 */
                var orderFinishText = Util.getMessagesAlertText(Config.retailer.settings.orderFinishedText);
                if (orderFinishText) {
                    Util.showRetailerMessageDialog(orderFinishText);
                }

                function showDidYouMeanDialog(addresses, deliveryTimesOptions, currentAddress) {
                    return Dialog.show({
                        templateUrl: 'template/dialogs/did-you-mean/index.html',
                        controller: 'CheckoutDidYouMeanCtrl',
                        controllerAs: 'didYouMeanCtrl',
                        styleClass: 'did-you-mean',
                        ariaLabelledby: 'did_you_mean_dialog_title',
                        locals: {
                            addresses: addresses,
                            currentAddress: currentAddress
                        }
                    }).then(function (result) {
                        if (result) {
                            angular.extend(checkoutCtrl.detailsData.address, result.address);
                            $scope.$broadcast('checkoutDetails.setDeliveryTimes', angular.extend({
                                ignoreAddressInProgress: true,
                                externalPlaceId: result.externalPlaceId,
                                geoCoordinates: result.geoCoordinates
                            }, deliveryTimesOptions));
                        }

                        return result;
                    });
                }

                // reset time travel on exiting checkout
                $scope.$on('$destroy', function () {
                    Cart.disableLoyalty(false);

                    if (Cart.timeTravel) {
                        Cart.setTimeTravel();
                        Cart.save();
                    }
                });
            }]);

})(angular, app);


