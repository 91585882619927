(function (angular, app) {
    'use strict';

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('app.productTagProducts', {
            url: '/product-tags/:productTagId/products?{events:json}',
            data: {
                name: 'product-tag-products'
            },
            reloadOnSearch: false,
            resolve: {
                productTag: ['$q', '$stateParams', '$state', '$location', 'Api', function ($q, $stateParams, $state, $location, Api) {
                    return $q.resolve().then(function() {
                        var productTagId = Number($stateParams.productTagId);
                        if (!productTagId) {
                            return;
                        }

                        return Api.request({
                            method: 'GET',
                            url: '/v2/retailers/:rid/product-tags/' + productTagId
                        });
                    }).then(function (productTag) {
                        if (!productTag) {
                            throw new Error('Page not found');
                        }

                        return productTag;
                    }).catch(function(err) {
                        $state.go('app.home', $location.search());
                        return $q.reject(err);
                    });
                }],
                productTagBanner: ['$q', '$stateParams', '$state', '$location', 'Api', function ($q, $stateParams, $state, $location, Api) {
                    return $q.resolve().then(function() {
                    var productTagId = Number($stateParams.productTagId);

                        return Api.request({
                            method: 'GET',
                            url: '/v2/retailers/:rid/landing-page-banner/' + productTagId,
                        });
                    }).then(function (results) {
                        var returnedResults;
                        angular.forEach(results, function (value, key) {
                            if (results[key].isMobile == 2 && results[key].isActive)
                                returnedResults = value
                        });
                        return returnedResults;
                    }).catch(function(err) {
                        $state.go('app.home', $location.search());
                        return $q.reject(err);
                    });
                }]
            },
            metaTags: {
                title: ['$filter', 'productTag', function ($filter, productTag) {
                    var nameFilter = $filter('name');
                    return productTag && nameFilter(productTag.languages, 'displayName');
                }]
            },
            views: {
                '@': {
                    templateUrl: 'template/views/product-tag-products/index.html',
                    controller: 'ProductTagProductsCtrl as productTagProductsCtrl'
                }
            }
        });
    }]).controller('ProductTagProductsCtrl', [
        '$scope', '$rootScope', '$state', '$stateParams', '$q', '$location', 'Config', 'Util', 'Cart','Api',
        'Items', 'Products', 'PermanentFilters','FilterManager', 'productTag', 'ADVERTISEMENT_CONVERSION_TYPE', 'productTagBanner',
        function ($scope, $rootScope, $state, $stateParams, $q, $location, Config, Util, Cart, Api,
                  Items, Products, PermanentFilters, FilterManager, productTag, ADVERTISEMENT_CONVERSION_TYPE, productTagBanner) {
            var productTagProductsCtrl = this,
                isParentTag = !!productTag.retailerId && productTag.retailerId !== Config.retailer.id,
                loadingSpecials = false;

            productTagProductsCtrl.openProduct = openProduct;
            productTagProductsCtrl.productTag = productTag;
            productTagProductsCtrl.productTagBanner = productTagBanner;
            productTagProductsCtrl.products = [];
            productTagProductsCtrl.specials = [];
            productTagProductsCtrl.total = 0;
            productTagProductsCtrl.specialsTotal = 0;
            productTagProductsCtrl.currentSpecialsFrom = 0;
            productTagProductsCtrl.openSpecial = openSpecial;

            productTagProductsCtrl.events = $stateParams.events;
            $location.search('events', null);

            _getSpecials()
            _getSpecials()

            function openProduct(item, index) {
                Items.items = productTagProductsCtrl.products;
                Util.openProduct(item, {
                    next: _nextProduct,
                    previous: _previousProduct,
                    siblings: [Items.items[index - 1], Items.items[index - 2], Items.items[index + 1], Items.items[index + 2]],
                    total: productTagProductsCtrl.total,
                    index: index
                });
            }

            function openSpecial (special, index) {
                Items.items = productTagProductsCtrl.specials;
                if (special.item) {
                    var siblings = [];
                    Util.pushAll(siblings, Items.getNextProductData(index, 1).siblings);
                    Util.pushAll(siblings, Items.getNextProductData(index, -1).siblings);
                    return Util.openProduct(special.item, {
                        next: _nextProduct,
                        previous: _previousProduct,
                        siblings: siblings,
                        total: $scope.total,
                        index: index
                    });
                }
                $state.go('app.specials.special', {sid: special.id});
            }

            function _getSpecials() {
                if (productTagProductsCtrl.productTag.specialsCount > 0 && !loadingSpecials){
                    loadingSpecials = true;
                    // var totalItems = productTagProductsCtrl.specialsTotal;
                    // if (productTagProductsCtrl.productTag.specialsCount === totalItems) {
                    //     return;
                    // }
                    var specialsESFilters = {
                        must: {
                            lessThan: {
                                startDate: new Date()
                            },
                            greaterThan: {
                                endDate: new Date()
                            },
                            term: {
                                displayOnWeb: true,
                                productTags: [productTagProductsCtrl.productTag.id]
                            }
                        }
                    };
                    var params = {
                        from: productTagProductsCtrl.currentSpecialsFrom,
                        size: 20,
                        filters: angular.copy(specialsESFilters)
                    };
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/specials',
                        params:params
                    }).then(function (response) {
                         angular.forEach(response.specials,function (special){
                             productTagProductsCtrl.specials.push(special);
                         })
                        productTagProductsCtrl.currentSpecialsFrom += response.specials.length;
                        productTagProductsCtrl.specialsTotal = productTagProductsCtrl.specials.length;
                        loadingSpecials = false;
                    });
                }

            }

            function _addProductsChunk(isInit) {
                var totalItems = productTagProductsCtrl.products.length;
                if (!isInit && productTagProductsCtrl.total === totalItems) {
                    return;
                }

                var term = {
                    'branch.isActive': true,
                    'branch.isVisible': true
                };
                term[(isParentTag ? 'parentProductTags' : 'productTags')] = productTag.id;

                return Items.getChunkAmount().then(function(size) {
                    return Items.getItems({
                        url: '/v2/retailers/:rid/branches/:bid/products',
                        method: 'GET',
                        params: Products.prepareSearchParams({
                            from: totalItems,
                            size: size,
                            filters: {
                                must: {
                                    exists: ['family.id', 'family.categoriesPaths.id'],
                                    term: term
                                }
                            }
                        })
                    });
                }).then(function (res) {
                    productTagProductsCtrl.total = res.total;
                    Util.pushAll(productTagProductsCtrl.products, Items.insertBumpersToChunk(Cart.getProducts(res.products)));
                });
            }

            function _nextProduct(index) {
                var totalItems = Items.getItemsLength(),
                    nextProductData = Items.getNextProductData(index, 1);
                if (productTagProductsCtrl.total !== totalItems && !nextProductData.product) {
                    return _addProductsChunk().then(function () {
                        return _nextProduct(index);
                    });
                }

                nextProductData.total = productTagProductsCtrl.total;
                return $q.resolve(nextProductData);
            }

            function _previousProduct(index) {
                var nextProductData = Items.getNextProductData(index, -1);
                nextProductData.total = Items.getItemsLength();
                return $q.resolve(nextProductData);
            }

            PermanentFilters.subscribe(function() {
                Items.resetItems();
                productTagProductsCtrl.products.splice(0, productTagProductsCtrl.products.length);
                return _addProductsChunk(true);
            }, $scope);

            Util.currentScopeListener($scope, $rootScope.$on('items.initiated', function () {
                _addProductsChunk(true);
            }));

            Util.currentScopeListener($scope, $rootScope.$on('document.scroll.end', function (data, event) {
                event.start();
                _getSpecials()
                $q.resolve(_addProductsChunk()).finally(function () {
                    event.done();
                });
            }));

            Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function (event, data) {
                if (productTagProductsCtrl.products.length && productTagProductsCtrl.events) {
                    util.firePromotionEvent(productTagProductsCtrl.events[ADVERTISEMENT_CONVERSION_TYPE.ADD_TO_CART]);
                    if (data.lines && data.lines.length) {
                        data.lines[0].adConversionUrl = productTagProductsCtrl.events[ADVERTISEMENT_CONVERSION_TYPE.CHARGE];
                    }
                }
                angular.forEach(productTagProductsCtrl.products, function (product, index) {
                    productTagProductsCtrl.products[index] = Cart.getProduct(product);
                });
            }));
        }
    ])
    //those controllers should move to their template
        .controller('PrutahTemplateProductTagProductsCtrl', ['Items', function (Items) {
            Items.initPage();
        }])
        .controller('KikarTemplateProductTagProductsCtrl', ['Items', function (Items) {
            Items.initPage({
                defaultWidth: 213.75
            });
        }])
    .controller('PrutahTemplateSpecialsCtrl', ['$rootScope', 'Items', function($rootScope, Items) {
        Items.initPage($rootScope.config.retailer.settings.isNewPromotionDesignEnabled === 'true' ? {} : {
            defaultWidth: $rootScope.windowSize.width <= 768 ? 260 : 310
        });
    }])
        .controller('KikarTemplateSpecialsCtrl', ['Items', function(Items) {
            Items.initPage({
                defaultWidth: 235 // 235
            });
        }]);
})(angular, app);
