(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.policies', {
                url: '/policies',
                data: {
                    name: 'policies'
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Privacy policy'));
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/policies/index.html',
                        controller: 'PoliciesCtrl'
                    }
                }
            });
        }])
        .controller('PoliciesCtrl', ['$scope', 'Config', function ($scope, config) {
            $scope.policies = config.retailer.settings.privacyText;
        }]);
})(angular, app);
