(function (angular, app) {
    'use strict';

    app.service('DeliveryWithinDaysWarningDialog', [
        '$injector', 'Config', 'Dialog', 'SpDeliveryAreasService', 'DELIVERY_TIMES_TYPES', 'PRODUCT_TAG_TYPES',
        function ($injector, Config, Dialog, SpDeliveryAreasService, DELIVERY_TIMES_TYPES, PRODUCT_TAG_TYPES) {
            var Cart;

            this.show = show;

            function show() {
                // when an area was not yet selected, do nothing
                if (!Config.branchAreaId) {
                    return;
                }

                // when the feature is off, do nothing
                if (!Config.retailer.isDeliveryWithinDaysByTag) {
                    return;
                }

                Cart = Cart || $injector.get('Cart');

                // when there are no products in the cart, do nothing
                if (!Cart.total || !Cart.total.crossProductTagTypes || !Cart.total.crossProductTagTypes.none) {
                    return;
                }

                // when the cart has a cross cart product tag type of delivery within days, do nothing
                if (Cart.total.crossProductTagTypes[PRODUCT_TAG_TYPES.DELIVERY_WITHIN_DAYS]) {
                    return;
                }

                return SpDeliveryAreasService.isAreaDeliveryWithinDaysOnly(Config.branchAreaId, Config.retailer.id)
                    .then(function(isDeliveryWithinDaysOnly) {
                        if (!isDeliveryWithinDaysOnly) {
                            return;
                        }

                        return Dialog.show({
                            templateUrl: 'template/dialogs/delivery-within-days-product-warning/index.html',
                            styleClass: 'delivery-within-days-product-warning',
                            disableClosing: true,
                            controller: 'DeliveryWithinDaysWarningDialog as warningCtrl'
                        });
                    });
            }
        }
    ]);

    app.controller('DeliveryWithinDaysWarningDialog', [
        'Dialog', 'ChooseAreaDialog', 'Cart', 'SpProductTags', 'PRODUCT_TAG_TYPES',
        function (Dialog, ChooseAreaDialog, Cart, SpProductTags, PRODUCT_TAG_TYPES) {
            var warningCtrl = this;

            SpProductTags.getDeliveryWithinDaysTag().then(function(tag) {
                warningCtrl.deliveryWithinDaysTag = tag;
            });

            warningCtrl.removeLines = removeLines;
            warningCtrl.chooseArea = chooseArea;

            function removeLines() {
                angular.forEach(Cart.lines, function(line) {
                    if (!line.removed && (!line.productTagTypes ||
                        !line.productTagTypes[PRODUCT_TAG_TYPES.DELIVERY_WITHIN_DAYS])) {
                        Cart.removeLine(line);
                    }
                });

                Dialog.hide();
            }

            function chooseArea() {
                ChooseAreaDialog.show(false, true, null);

                Dialog.hide();
            }
        }]);
})(angular, app);