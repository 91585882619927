(function (angular, app) {
    'use strict';

    /*
    https://experienceleague.adobe.com/docs/analytics/implementation/home.html?lang=en
     */

    app.service('AdobeAnalytics', [
        '$filter', '$window', 'LocalStorage', 'Config', 'User', 'MetaTags',
        function ($filter, $window, localStorageService, config, user, MetaTags) {
            var self = this,
                _defaultEmptyValue = null;

            angular.extend(self, {
                newPageEvent: newPageEvent,
            });

            /**
             * Event - new page
             * @public
             *
             */
            function newPageEvent() {
                if (!window.digitalData) return;

                var pageType = 'Page';
                if (window.location.href.includes('catalogProduct=')) {
                    pageType = 'Product page';
                } else if (window.location.href.includes('/categories/')) {
                    pageType = 'Category page';
                } else if (window.location.href.includes('/specials')) {
                    pageType = 'Specials page';
                } else if (location.pathname.substr(1) === '') {
                    pageType = 'Main page';
                } else if (window.location.href.includes('/user-edit')) {
                    pageType = 'User edit page';
                } else if (window.location.href.includes('/user/coupons')) {
                    pageType = 'User coupons page';
                } else if (window.location.href.includes('orders-history')) {
                    pageType = 'Order history page';
                } else if (window.location.href.includes('/shop-lists')) {
                    pageType = 'Shop list page';
                } else if (window.location.href.includes('/retailer')) {
                    pageType = 'Retailer info page';
                } else if (window.location.href.includes('/search')) {
                    pageType = 'Search page';
                } else if (window.location.href.includes('/cart/summary')) {
                    pageType = 'Cart page';
                } else if (window.location.href.includes('/checkout')) {
                    pageType = 'Checkout page';
                } else if (window.location.href.includes('/smart-list')) {
                    pageType = 'Smart list';
                }

                // if exist product data
                var productName = MetaTags.productTitle || MetaTags.title;
                if (productName && MetaTags.familyName) {
                    var product = MetaTags.familyName + ';' + productName;
                }

                var channelVal;	
                if (isMobile()) {
                    channelVal = "Mobile Site";
                } else {
                    channelVal = "Brand Site";
                }

                $window.digitalData.siteInfo.channel = channelVal;
                $window.digitalData.page.category.primaryCategory = channelVal;
                $window.digitalData.page.category.pageType = pageType;
                $window.digitalData.trackingInfo = {};
                $window.digitalData.trackingInfo.tool = [ {} ];
                $window.digitalData.trackingInfo.tool[0] = {};
                $window.digitalData.trackingInfo.tool[1] = {};
                $window.digitalData.privacy = {};                    //Optional
                $window.digitalData.page.attributes = {};
                $window.digitalData.privacy.accessCategories = [ {} ]; //Optional
                $window.digitalData.privacy.accessCategories[0].domains = [];     //Optional
                $window.digitalData.event = [];
                $window.digitalData.page.pageInfo.pageName = pageType;
                $window.digitalData.page.pageInfo.language = config.language.culture;
                $window.digitalData.page.pageInfo.destinationURL = window.location.href;
                $window.digitalData.page.attributes.contentType = pageType;
                $window.digitalData.trackingInfo.tool[0].id = "G-123";  //Pass a dummy ID if you do not want any Google Tracking
                $window.digitalData.trackingInfo.tool[1].id = "unilever-shopo-il,unilever-global-allbrands";
                $window.digitalData.page.attributes.country = "Israel";
                $window.digitalData.page.attributes.globalBrand = "Shopo";
                $window.digitalData.page.attributes.localBrand = "Shopo";
                $window.digitalData.siteInfo.internalDomain = function(t){try{return t.match(/www.(.+)/)[1]}catch(n){return t}}(window.location.hostname);

                _sendData($window.digitalData);
            }

            /**
             * Send data
             * @private
             */
            function _sendData(digitalData) {
                var userId = null;
                user.getData().then(function(userData){
                    userId = user && userData.id || userSession && userSession.userId || _defaultEmptyValue;
                }).catch(function(){
                    userId = _defaultEmptyValue;
                }).finally(function () {
                    digitalData.user[0].profile[0].profileInfo.crmID = userId;
                    
                    // send event to Adob
                    var ev = {};
                    ev.eventInfo={
                        'type': 'ctConstants.trackAjaxPageLoad',
                        'eventLabel': digitalData.page.pageInfo.pageName,
                        'eventValue': 1 
                    };
                    ev.category = {
                        'primaryCategory': MetaTags.familyName || null
                    }; 
                    ev.subcategory = 'Read';
                    digitalData.event.push(ev);
                });
            }

            function isMobile() {
                var check = false;
                (function(a) {
                    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
                            .test(a)
                            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
                                .test(a.substr(0, 4))) {
                        check = true;
                    }
                })(navigator.userAgent || navigator.vendor || window.opera);
                return check;
            }
    }]);

})(angular, app);
