(function (angular, app) {
    'use strict';

    app.filter('filterCollection', [
        'Config',
        function () {
            return function (input, fitlers) {
                if (fitlers && angular.isObject(fitlers)) {
                    return angular.filterCollection(input, function (item) {
                        var is = false;
                        angular.forEach(fitlers, function (value, key) {
                            is = item[key] && item[key] == value || is;
                        });
                        return is;
                    });
                } else {
                    return input;
                }
            };
        }]);

})(angular, app);