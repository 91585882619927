(function (angular, app) {
    'use strict';

    app.config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.myCoupons', {
                url: '/user/coupons?fromAccount&{filters:json}',
                data: {
                    routeAccess: ROUTE_ACCESS.LOGIN,
                    name: 'my-coupons'
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return $filter('translate')('My coupons');
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/my-coupons/index.html',
                        controller: 'MyCouponsCtrl as myCoupons'
                    }
                }
            });
        }])
        .run(['$rootScope', 'Cart', 'Util', 'SP_SERVICES', 'Config', function ($rootScope, Cart, Util, SP_SERVICES, Config) {
            $rootScope.$on('cart.update.complete', function (event, requestDirtyLines) {
                if ($rootScope.couponsData) {
                    var couponTagType = "NotClipped";
                    var coupons = $rootScope.couponsData.coupons;
                    if (Cart.specialReminders.onProducts) {
                        var products = Object.values(Cart.specialReminders.onProducts);
                        for (var cindex = 0; cindex < coupons.length; cindex++ ) {
                            var foundInAwarded = false;
                            for (var pindex = 0; pindex < products.length; pindex++ ) {
                                if (coupons[cindex].special.localId == products[pindex].localId && !products[pindex].awarded) {
                                    foundInAwarded = true;
                                }
                            }
                            var tags = coupons[cindex].tags || [];
                            if (foundInAwarded) {
                                if (!tags.includes(couponTagType)) {
                                    tags.push(couponTagType);
                                }
                            } else {
                                if (tags.includes(couponTagType)) {
                                    tags.splice(tags.indexOf(couponTagType), 1);
                                }
                            }
                        }
                    } else {
                        // No coupons in specialReminders, clear 'NotClipped' tag from all
                        for (var cindex = 0; cindex < coupons.length; cindex++ ) {
                            var tags = coupons[cindex].tags || [];
                            if (tags.includes(couponTagType)) {
                                tags.splice(tags.indexOf(couponTagType), 1);
                            }
                        }
                    }

                    var totalNotClipped = 0;
                    for (var cindex = 0; cindex < coupons.length; cindex++ ) {
                        var tags = coupons[cindex].tags || [];
                        if (tags.includes(couponTagType)) {
                            totalNotClipped++;
                        }
                    }

                    var items = $rootScope.myCoupons.typeFilter.options.items;
                    if (totalNotClipped > 0) {
                        var found = false;
                        for (var index = 0; index < items.length; index++) {
                            if (items[index].value == "4") {
                                items[index].count = totalNotClipped;
                                found = true;
                            }
                        }
                        if (!found) {
                            var names = {};
                            var name = 'Not clipped but in cart';
                            angular.forEach(Object.values(Config.languages), function (language) {
                                names[language.id] = language.translate[name] || name;
                            });
                            var newItem = {
                                value: "4",
                                name: names,
                                count: totalNotClipped
                            };
                            items.push(newItem);
                            $rootScope.couponsData = null;
                            $rootScope.$emit('reloadCoupons');
                        }
                    } else {
                        // Remove it
                        for (var index = 0; index < items.length; index++) {
                            if (items[index].value == "4") {
                                items.splice(index, 1);
                            }
                        }
                        // Remove it from filter as well
                        var foundInFilters = false;
                        if ($rootScope.myCoupons.typeFilter.value) {
                            for (var index = 0; index < $rootScope.myCoupons.typeFilter.value.length; index++) {
                                if ($rootScope.myCoupons.typeFilter.value[index].value == "4") {
                                    $rootScope.myCoupons.typeFilter.value.splice(index, 1);
                                    foundInFilters = true;
                                }
                            }
                        }

                        if (foundInFilters) {
                            $rootScope.$emit('reloadCoupons');
                        }
                    }
                }

                // ignore force update
                if ($rootScope.forceUpdateCart) {
                    $rootScope.forceUpdateCart = false;
                    return;
                }

                if (Cart.specialReminders.onCoupons && !angular.element(document.getElementsByClassName('coupon-details-dialog')[0]).length) {
                    angular.forEach(Object.keys(requestDirtyLines), function (lineKey) {
                        var line = Cart.lines[lineKey];
                        if (!line || line.removed || line.type !== SP_SERVICES.CART_LINE_TYPES.COUPON) {
                            return;
                        }

                        var specialId = line.product && (
                                (line.product.special && line.product.special.id) || // from my-coupons page
                                (line.product.branch && line.product.branch.specials && line.product.branch.specials[0].id)
                            ),
                            specialReminder = Cart.specialReminders.onCoupons[specialId];
                        if (specialId && specialReminder && specialReminder.showProductsFrom && !specialReminder.awarded) {
                            Util.showCouponDetailsDialog(line.product);
                        }
                    });
                }
            });
        }])
        .controller('MyCouponsCtrl', ['$rootScope', '$scope', '$stateParams', 'User', 'Loyalty', 'Api', 'Items', 'FilterManager', 'Util', 'LOYALTY_CLUB_DRIVERS', 'Cart', 'SP_SERVICES', 'Config',
            function ($rootScope, $scope, $stateParams, User, Loyalty, Api, Items, FilterManager, Util, LOYALTY_CLUB_DRIVERS, Cart, SP_SERVICES, Config) {
                var myCoupons = this;

                $rootScope.couponsData = null;
                $rootScope.myCoupons = this;

                myCoupons.noResultMessage = "No coupons available at this time. Check again later or check our Specials!";
                _initFilters();

                var localData = _getCouponsDataLocal();
                if (localData) {
                    var totalClipped = 0;
                    if (Cart.specialReminders.onCoupons) {
                        var cartCoupons = Cart.specialReminders.onCoupons;
                        for (var coupon in cartCoupons) {
                            if (!cartCoupons[coupon].awarded) {
                                totalClipped++;
                            }
                        }
                        if (!totalClipped) {
                            if (localData.tags && localData.tags.length > 0) {
                                localData.tags = localData.tags.filter(function (tag) {
                                    return tag.id !== "5";
                                })
                            }
                        } else {
                            if (localData.tags && localData.tags.length > 0) {
                                localData.tags.forEach(function (tag) {
                                    if (tag.id === "5") {
                                        tag.count = totalClipped
                                    }
                                })
                            }
                        }
                    } else {
                        if (localData.tags && localData.tags.length > 0) {
                            localData.tags = localData.tags.filter(function (tag) {
                                return tag.id !== "5";
                            })
                        }
                    }
                }

                if (localData) {
                    _initPage(localData);
                } else {
                    _getCouponsData().then(function (couponsData) {
                        _initPage(couponsData);
                    });
                }

                Util.currentScopeListener($scope, $rootScope.$on('cart.update.complete', function (event, requestDirtyLines) {
                    if ($rootScope.couponsData) {
                        var couponTagType = "Clipped";
                        var coupons = $rootScope.couponsData.coupons;
                        if (Cart.specialReminders.onCoupons) {
                            var cartCoupons = Object.values(Cart.specialReminders.onCoupons),
                                cartCouponLocalIds = {};
                            for (var i = 0; i < cartCoupons.length; i++) { 
                                if (!cartCoupons[i].awarded) { 
                                    cartCouponLocalIds[cartCoupons[i].localId] = {awarded: cartCoupons[i].awarded}
                                }

                            }
                            for (var cindex = 0; cindex < coupons.length; cindex++ ) {
                                var foundInAwarded = false;
                                if (cartCouponLocalIds[coupons[cindex].special.localId]) {
                                    foundInAwarded = true;
                                }
                                var tags = coupons[cindex].tags || [];
                                if (foundInAwarded) {
                                    if (!tags.includes(couponTagType)) {
                                        tags.push(couponTagType);
                                    }
                                } else {
                                    if (tags.includes(couponTagType)) {
                                        tags.splice(tags.indexOf(couponTagType), 1);
                                    }
                                }
                            }
                        } else {
                            // No coupons in specialReminders, clear 'Clipped' tag from all
                            for (var cindex = 0; cindex < coupons.length; cindex++ ) {
                                var tags = coupons[cindex].tags || [];
                                if (tags.includes(couponTagType)) {
                                    tags.splice(tags.indexOf(couponTagType), 1);
                                }
                            }
                            if ($rootScope.couponsData.tags && $rootScope.couponsData.tags.length > 0) {
                                $rootScope.couponsData.tags = $rootScope.couponsData.tags.filter(function (couponTag) {
                                    return couponTag.id !== "5";
                                })
                            }
                        }
    
                        var totalClipped = 0;
                        for (var cindex = 0; cindex < coupons.length; cindex++ ) {
                            var tags = coupons[cindex].tags || [];
                            if (tags.includes(couponTagType)) {
                                totalClipped++;
                            }
                        }
    
                        var items = $rootScope.myCoupons.typeFilter.options.items;
                        if (totalClipped > 0) {
                            var found = false;
                            for (var index = 0; index < items.length; index++) {
                                if (items[index].value == "5") {
                                    items[index].count = totalClipped;
                                    found = true;
                                }
                            }
                            if (!found) {
                                var names = {};
                                var name = 'Clipped but not in cart';
                                angular.forEach(Object.values(Config.languages), function (language) {
                                    names[language.id] = language.translate[name] || name;
                                });
                                var newItem = {
                                    value: "5",
                                    name: names,
                                    count: totalClipped
                                };
                                items.push(newItem);
                                $rootScope.couponsData = null;
                                $rootScope.$emit('reloadCoupons');
                            }
                        } else {
                            // Remove it
                            var foundInFilters = false;
                            for (var index = 0; index < items.length; index++) {
                                if (items[index].value == "5") {
                                    items.splice(index, 1);
                                    foundInFilters = true;
                                }
                            }
                            // Remove it from filter as well
                            if ($rootScope.myCoupons.typeFilter.value) {
                                for (var index = 0; index < $rootScope.myCoupons.typeFilter.value.length; index++) {
                                    if ($rootScope.myCoupons.typeFilter.value[index].value == "5") {
                                        $rootScope.myCoupons.typeFilter.value.splice(index, 1);
                                        foundInFilters = true;
                                    }
                                }
                            }
                            if ($rootScope.couponsData.tags && $rootScope.couponsData.tags.length > 0) {
                                $rootScope.couponsData.tags = $rootScope.couponsData.tags.filter(function (couponTag) {
                                    return couponTag.id !== "5";
                                })
                            }
                            if (foundInFilters) {
                                $rootScope.$emit('reloadCoupons');
                            }
                        }
                    }
    
                    // ignore force update
                    if ($rootScope.forceUpdateCart) {
                        $rootScope.forceUpdateCart = false;
                        return;
                    }
                }));

                Util.currentScopeListener($scope, $rootScope.$on('reloadCoupons', function (event, points) {
                    _onChangeFilters();
                }));

                Util.currentScopeListener($scope, $rootScope.$on('reloadUserBalancePoints', function () {
                    if ($rootScope.clippedCouponReceived) {
                        _getCouponsFiltersOnly().then(function (data) {
                            _getUserpoints();

                            if ($rootScope.clippedCouponReceived) {
                                _resetFilters(data);
                            }
                        });
                    } else {
                        _getBalancePointsOnly().then(function (data) {
                            _getUserpoints();

                            if (myCoupons.coupons) {
                                for (var index = 0; index < myCoupons.coupons.length; index++) {
                                    if (myCoupons.coupons[index].pointrequirement) {
                                        myCoupons.coupons[index].notEnoughPoints = myCoupons.coupons[index].pointrequirement > myCoupons.points;
                                    }
                                }
                            }

                        });
                    }
                }));

                function _initFilters() {
                    myCoupons.typeFilter = FilterManager.getCouponTypesFilter(_onChangeFilters);
                    myCoupons.categoriesFilter = FilterManager.getCategoriesFilter(_onChangeFilters, 'category.id');

                    myCoupons.typeFilter.notInclusive = true;
                    myCoupons.categoriesFilter.notInclusive = true;

                    FilterManager.setFiltersFromUrl({
                        type: myCoupons.typeFilter,
                        category: myCoupons.categoriesFilter
                    }, $stateParams.filters);
                }

                function _resetFilters(data) {
                    var removedTypeFilterIds = myCoupons.typeFilter.options.items.filter(function(item) {
                        return !data.tags.map(function(tag) {
                            return tag.id;
                        }).includes(item.value);
                    }).map(function(item) {
                        return item.value;
                    });
                    if (removedTypeFilterIds && removedTypeFilterIds.length) {
                        myCoupons.typeFilter.options.items = myCoupons.typeFilter.options.items.filter(function(item) {
                            return !removedTypeFilterIds.includes(item.value);
                        });
                        myCoupons.typeFilter.value = myCoupons.typeFilter.value.filter(function(item) {
                            return !removedTypeFilterIds.includes(item.value);
                        });
                        if (!myCoupons.typeFilter.value.length) {
                            delete myCoupons.typeFilter.value.selectedItems;
                        }
                    } else {
                        myCoupons.typeFilter.options.items.forEach(function(item) {
                            item.count = data.tags.find(function(tag) {
                                return tag.id === item.value;
                            }).count;
                        });
                    }
                    //_onChangeFilters();
                }

                function _categoryInCoupon(coupon, categories) {
                    if (!categories || categories.length == 0) return true;
                    if (!coupon.special || !coupon.special.branch || !coupon.special.branch.productsData || !coupon.special.branch.productsData.categories) return false;
                    var couponCategories = coupon.special.branch.productsData.categories;
                    for (var index = 0; index < couponCategories.length; index++ ) {
                        if (categories.includes(couponCategories[index].id)) return true;
                    }
                    return false;
                }

                function _typesInCoupon(coupon, types) {
                    if (!types || types.length == 0) return true;
                    if (!coupon.tags) return false;
                    for (var index = 0; index < coupon.tags.length; index++ ) {
                        if (types.includes(coupon.tags[index])) return true;
                    }
                    return false;
                }

                function _filterData(couponData, types, categories) {
                    var coupons = [];
                    var translatedTypes = [];
                    if (types) {
                        for (var index = 0; index < types.length; index++ ) {
                            if (types[index] == 1) {
                                translatedTypes.push('New');
                            }
                            if (types[index] == 2) {
                                translatedTypes.push('AlmostExpired');
                            }
                            if (types[index] == 3) {
                                translatedTypes.push('WithPoints');
                            }
                            if (types[index] == 4) {
                                translatedTypes.push('NotClipped');
                            }
                            if (types[index] == 5) {
                                translatedTypes.push('Clipped');
                            }
                            if (types[index] == 6) {
                                translatedTypes.push('ClippedExternally');
                            }
                        }
                    }
                    // Run thru filters now
                    for (var index = 0; index < couponData.coupons.length; index++ ) {
                        if (_categoryInCoupon(couponData.coupons[index], categories)) {
                            coupons.push(couponData.coupons[index]);
                        }
                    }
                    var couponsTypes = [];
                    for (var index = 0; index < coupons.length; index++ ) {
                        if (_typesInCoupon(coupons[index], translatedTypes)) {
                            couponsTypes.push(coupons[index]);
                        }
                    }
                    return  {
                        categories: couponData.categories,
                        points: couponData.points,
                        tags: couponData.tags,
                        totalCoupons: couponData.totalCoupons,
                        coupons: couponsTypes
                    }
                }

                function _getCouponsDataLocal() {
                    if (!$rootScope.couponsData) return null;
                    
                    myCoupons.noResultMessage = "";
                    myCoupons.couponFilter = $rootScope.couponFilter;
                    $scope.params = { extended: true, countonly: false, searchFilter: $rootScope.couponFilter };
                    FilterManager.parseFilters($scope.params, [myCoupons.typeFilter, myCoupons.categoriesFilter]);

                    if ($scope.params.filters && $scope.params.filters.must && $scope.params.filters.must.term) {
                        // Filter data now
                        var couponsData = _filterData($rootScope.couponsData, $scope.params.filters.must.term["type.id"], $scope.params.filters.must.term["category.id"]);
                        myCoupons.total = $rootScope.couponsData.length;
                        $scope.coupons = couponsData.coupons;
                        if (!$rootScope.couponFilter || $rootScope.couponFilter === "") {
                            $rootScope.$emit('numberOfCouponsChanged', couponsData.totalCoupons);
                        }
                        return couponsData;
                    } else {
                        var couponsData = JSON.parse(JSON.stringify($rootScope.couponsData));
                        myCoupons.total = $rootScope.couponsData.length;
                        $scope.coupons = couponsData.coupons;
                        if (!$rootScope.couponFilter || $rootScope.couponFilter === "") {
                            $rootScope.$emit('numberOfCouponsChanged', couponsData.totalCoupons);
                        }
                        return couponsData;
                    }
                }

                function _getCouponsData() {
                    myCoupons.noResultMessage = "";
                    myCoupons.couponFilter = $rootScope.couponFilter;
                    $scope.params = { extended: true, countonly: false, searchFilter: $rootScope.couponFilter };
                    var params = {};
                    //FilterManager.parseFilters($scope.params, [myCoupons.typeFilter, myCoupons.categoriesFilter]);
                    FilterManager.parseFilters(params, [myCoupons.typeFilter, myCoupons.categoriesFilter]);
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons',
                        params: $scope.params
                    }).then(function (couponsData) {
                        $scope.coupons = couponsData.coupons;
                        $rootScope.couponsData = JSON.parse(JSON.stringify(couponsData));

                        Util.setGlobalCouponRedemptions(couponsData.coupons);

                        if (params.filters && params.filters.must && params.filters.must.term) {
                            var couponsDataFiltered = _filterData($rootScope.couponsData, params.filters.must.term["type.id"], params.filters.must.term["category.id"]);
                            couponsData = couponsDataFiltered;
                            $scope.coupons = couponsData.coupons;
                        }

                        if ($rootScope.couponFilter) {
                            myCoupons.noResultMessage = 'no_coupons_for_filter';
                        } else {
                            myCoupons.noResultMessage = "No coupons available at this time. Check again later or check our Specials!";
                        }

                        myCoupons.total = couponsData.coupons && couponsData.coupons.length;
                        if (!$rootScope.couponFilter || $rootScope.couponFilter === "") {
                            $rootScope.$emit('numberOfCouponsChanged', couponsData.totalCoupons);
                        }
                        return couponsData;
                    });
                }

                function _getBalancePointsOnly() {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons',
                        params: { extended: true, balanceonly: true}
                    }).then(function (data) {
                        return data;
                    });
                }

                function _getCouponsFiltersOnly() {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons',
                        params: { extended: true, countonly: false, filterOnly: true}
                    }).then(function (data) {
                        return data;
                    });
                }

                function _initPage(couponsData) {
                    if ($rootScope.config.retailer.settings.couponsPageDisplay == $rootScope.COUPONS_PAGE_DISPLAY.GROUPED) {
                        myCoupons.groupBy = {
                            titleStyleClass: 'coupon-group-title',
                            by: 'isExternal',
                            value: 'isExternal',
                            isOneColumn: 'true',
                            templateUrl: 'template/views/my-coupons/partials/coupon-group/index.html'
                        }
                    }
    
                    myCoupons.coupons = [];
                    myCoupons.displayTermsInHeader = _displayTermsInHeader();

                    _getUserpoints();

                    FilterManager.addCouponTypesFilterOptions(myCoupons.typeFilter, couponsData.tags);
                    FilterManager.addCategoriesFilterOptions(myCoupons.categoriesFilter, couponsData.categories);
                    FilterManager.markFiltersAsFinished([myCoupons.typeFilter, myCoupons.categoriesFilter]);
    
                    Items.initPage();
                }
                
                function _onChangeFilters() {
                    FilterManager.setFiltersToUrl({
                        type: myCoupons.typeFilter,
                        category: myCoupons.categoriesFilter
                    });
                    Items.resetItems();

                    var localData = _getCouponsDataLocal();
                    if (localData) {
                        _initPage(localData);
                    } else {
                        _getCouponsData().then(function (couponsData) {
                            _initPage(couponsData);
                        });
                    }
                }

                function _renderChunkCoupons() {
                    if (myCoupons.total === myCoupons.coupons.length) {
                        return;
                    }

                    Items.getChunkAmount().then(function(size) {
                        if($scope.coupons) {
                            var chunk = $scope.coupons.splice(0, size);
                            Util.pushAll(myCoupons.coupons, Items.insertBumpersToChunk(chunk));
                            $rootScope.$emit('items.added', { chunk: { coupons: chunk } });
                        }
                    });
                }

                function _displayTermsInHeader() {
                    return ($rootScope.config.retailer.loyaltyClubDrivers || []).find(function (driver) {
                        return driver.loyaltyClubDriverId === LOYALTY_CLUB_DRIVERS.BIRDZI;
                    });

                }

                function _getUserpoints() {
                    var loyaltyClubDriver = $rootScope.config.retailer.loyaltyClubDrivers[0];
                    if (!loyaltyClubDriver || (loyaltyClubDriver && loyaltyClubDriver.length === 0)) return Promise.resolve(null);

                    var p = User.getData().then(function(data) {
                      if (!data) return;
                      var userLoyaltyClub = Loyalty.getUserLoyaltyClub(data);
                      if (!userLoyaltyClub) return;
                      $scope.activeCashbackLoyalty = Loyalty.getRetailerClubConfig(userLoyaltyClub.loyaltyClubId);
                      if ($scope.activeCashbackLoyalty.pointsToCashback) {
                        var userCashbackLoyalty = data.loyaltyClubs.length && data.loyaltyClubs.find(function (club) {
                          return club.loyaltyClubDriverId === loyaltyClubDriver.loyaltyClubDriverId;
                        });

                        if(userCashbackLoyalty) {
                            return Util.getUserCashbackPoints(userCashbackLoyalty.id).then(function (response) {
                                myCoupons.points = response.points || 0;
                            })
                          }
                      }
                    }).catch(function(){
                      return;
                    })
                    return p;
                }

                $scope.openCoupon = function(item) {
                    Util.showCouponDetailsDialog(item);
                }

                Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function (event, ev1, ev2) {
                    $rootScope.clippedCouponReceived = false;
                    $rootScope.withPointsCouponReceived = false;
                    if (ev1 && ev1.lines && ev1.lines.length > 0 && ev1.lines[0].type == 3) {
                        if (ev1.lines[0].product.tags && ev1.lines[0].product.tags.includes('Clipped')) {
                            $rootScope.clippedCouponReceived = true;
                        }
                        if (ev1.lines[0].product.tags && ev1.lines[0].product.tags.includes('WithPoints')) {
                            $rootScope.withPointsCouponReceived = true;
                        }
                    }
                }));

                Util.currentScopeListener($scope, $rootScope.$on('cart.lines.remove', function (event, ev1, ev2) {
                    $rootScope.clippedCouponReceived = false;
                    $rootScope.withPointsCouponReceived = false;
                    if (ev1 && ev1.lines && ev1.lines.length > 0 && ev1.lines[0].type == 3) {
                        if (ev1.lines[0].product.tags && ev1.lines[0].product.tags.includes('WithPoints')) {
                            $rootScope.withPointsCouponReceived = true;
                        }
                    }
                }));

                Util.currentScopeListener($scope, $rootScope.$on('cart.update.complete', function () {
                    if ($rootScope.clippedCouponReceived || $rootScope.withPointsCouponReceived) {
                        $rootScope.$emit('reloadUserBalancePoints');
                    }
                }));

                Util.currentScopeListener($scope, $rootScope.$on('items.initiated', function () {
                    _renderChunkCoupons();
                }));

                Util.currentScopeListener($scope, $rootScope.$on('document.scroll.end', function () {
                    _renderChunkCoupons();
                }));

                Util.currentScopeListener($scope, $rootScope.$on('items.add', _renderChunkCoupons));
            }
        ]);
})(angular, app);
