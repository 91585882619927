(function (angular, app) {
    'use strict';

    var SCROLL_JUMP = 300,
        elementWatcher = new ElementPropWatcher();
    var SCROLL_KEYS = {
        horizontal: {
            scrollSize: 'scrollWidth',
            scrollDistance: 'scrollLeft',
            clientSize: 'clientWidth'
        },
        vertical: {
            scrollSize: 'scrollHeight',
            scrollDistance: 'scrollTop',
            clientSize: 'clientHeight'
        }
    };

    app.directive('spScrollActionsContainer', [
        function () {
            return {
                restrict: 'A',
                transclude: true,
                templateUrl: 'template/directives/sp-scroll-actions-container/index.html',
                scope: {},
                bindToController: {
                    direction: '@spScrollActionsContainer'
                },
                controllerAs: 'scrollContainerCtrl',
                controller: ['$scope', '$element', '$timeout', function($scope, $element, $timeout) {
                    var scrollContainerCtrl = this,
                        $transcludeElement = angular.element($element[0].querySelector('[ng-transclude]')),
                        $backwardElement = angular.element($element[0].querySelector('.backward')),
                        $forwardElement = angular.element($element[0].querySelector('.forward')),
                        _scrollTimeout,
                        _activeScrollPromise,
                        _lastScroll,
                        _scrollSizeWatchers = [];

                    scrollContainerCtrl.scroll = scroll;

                    $element.addClass('sp-scroll-actions-container');
                    $transcludeElement.bind('scroll', _setVisibilityWrapper);
                    $scope.$watch('scrollContainerCtrl.direction', function() {
                        _watchScrollSize();
                        _setVisibility();
                    });
                    $scope.$on('$destroy', function() {
                        _unsubscribeScrollSizeWatcher();
                        $transcludeElement.unbind('scroll', _setVisibilityWrapper);
                    });

                    function _unsubscribeScrollSizeWatcher() {
                        angular.forEach(_scrollSizeWatchers, function(scrollSizeWatcher) {
                            scrollSizeWatcher();
                        });
                    }

                    function _watchScrollSize() {
                        _unsubscribeScrollSizeWatcher();

                        var scrollKeys = _getScrollKeys();
                        _scrollSizeWatchers.push(elementWatcher.subscribe($transcludeElement, scrollKeys.scrollSize, _scrollSizeWatcherHandler));
                        _scrollSizeWatchers.push(elementWatcher.subscribe($transcludeElement, scrollKeys.clientSize, _scrollSizeWatcherHandler));
                    }

                    function scroll(isForward) {
                        var scrollKeys = _getScrollKeys(),
                            buttonsSize = Math.max($backwardElement.prop(scrollKeys.clientSize) || 0, $forwardElement.prop(scrollKeys.clientSize) || 0),
                            visibleSize = $transcludeElement.prop(scrollKeys.clientSize) - (buttonsSize * 2) - 10,
                            scrollJump = Math.min(SCROLL_JUMP, visibleSize),
                            maxScroll = $transcludeElement.prop(scrollKeys.scrollSize) - $transcludeElement.prop(scrollKeys.clientSize);

                        _lastScroll = _lastScroll === undefined ? $transcludeElement.prop(scrollKeys.scrollDistance) : _lastScroll;
                        _lastScroll = Math.min(Math.max(_lastScroll + scrollJump * (isForward ? 1 : -1), 0), maxScroll);

                        _setVisibility();
                        _scrollTo(scrollKeys, _lastScroll);

                    }

                    function _scrollTo(scrollKeys, to) {
                        if (_scrollTimeout) {
                            $timeout.cancel(_scrollTimeout);
                        }
                        _scrollTimeout = $timeout(function() {
                            var promise = $transcludeElement[scrollKeys.scrollDistance + 'Animated'](to).catch(function() {
                                if (_activeScrollPromise === promise) {
                                    _scrollTo(scrollKeys, to);
                                }
                            }).finally(function() {
                                if (_activeScrollPromise === promise) {
                                    _activeScrollPromise = undefined;
                                }
                            });
                            _activeScrollPromise = promise;
                        }, 50);
                    }

                    function _scrollSizeWatcherHandler() {
                        // wait for the current scroll to finish, to reset the last scroll when it ends
                        if (_activeScrollPromise) {
                            _activeScrollPromise.finally(function() {
                                _scrollSizeWatcherHandler();
                            });
                            return;
                        }

                        _lastScroll = $transcludeElement.prop(_getScrollKeys().scrollDistance);
                        _setVisibilityWrapper();
                    }

                    function _setVisibilityWrapper() {
                        _setVisibility();
                        $scope.$apply();
                    }

                    function _setVisibility() {
                        var scrollKeys = _getScrollKeys();

                        scrollContainerCtrl.backward = scrollContainerCtrl.forward = false;
                        var scrollDistance = _lastScroll || $transcludeElement.prop(scrollKeys.scrollDistance),
                            clientSize = $transcludeElement.prop(scrollKeys.clientSize),
                            scrollSize = $transcludeElement.prop(scrollKeys.scrollSize) - 2;
                        if (scrollDistance && clientSize < scrollSize) {
                            scrollContainerCtrl.backward = true;
                        }
                        if ((scrollDistance + clientSize) < scrollSize) {
                            scrollContainerCtrl.forward = true;
                        }
                    }

                    function _getScrollKeys() {
                        var scrollKeys = SCROLL_KEYS[scrollContainerCtrl.direction || 'vertical'];

                        if (!scrollKeys) {
                            throw new Error('Unsupported scroll direction \'' + scrollContainerCtrl.direction + '\'');
                        }

                        return scrollKeys;
                    }
                }]
            };
        }
    ]);

    function ElementPropWatcher() {
        var self = this,
            _watchers = [];

        self.subscribe = subscribe;

        setInterval(_emit, 200);

        function subscribe($element, watchProp, callback) {
            var watcher = {
                element: $element,
                callback: callback,
                prop: watchProp,
                prevValue: $element.prop(watchProp)
            };

            _watchers.push(watcher);

            function unsubscribe() {
                _watchers.splice(_watchers.indexOf(watcher), 1);
            }

            return unsubscribe;
        }

        function _emit() {
            angular.forEach(_watchers, function(_watcher) {
                if (!_watcher.element || !_watcher.callback || !_watcher.prop) {
                    return;
                }

                var newValue = _watcher.element.prop(_watcher.prop);
                if (newValue !== _watcher.prevValue) {
                    _watcher.callback(newValue, _watcher.prevValue);
                    _watcher.prevValue = newValue;
                }
            });
        }
    }
})(angular, app);