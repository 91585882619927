(function (angular, app) {
  'use strict';

  app.service('SidenavService', ['$timeout',
    function ($timeout) {
      var self = this;

      // Exports
      angular.extend(self, {
        scrollToAffectedLine: scrollToAffectedLine
      });

      function _getLineKey(line) {
        return (line.product.id ? line.product.id.toString() : '') + (!!line.isCase ? '1' : '0');
      }

      function _isDirtyAffectedData(affectedData) {
        return affectedData && affectedData.lines && affectedData.lines.length;
      }

      function _scrollToAddedProduct(item, scrollableWrapperSelector, mainColor, scrollToArray, remainingElementsToEnd) {
        if (item && item.lines && item.lines.length) {
          $timeout(function () {
            var productsWrapper = document.querySelector(scrollableWrapperSelector),
              elementId = 'sidenav_line_' + _getLineKey(item.lines[0]),
              incompleteElementId = 'sidenav_line_{{line.$key}}',
              productElement = document.getElementById(elementId) || document.getElementById(incompleteElementId);
            if (productsWrapper && productElement) {
              var BASE_CLIENT_HEIGHT = 140; // height: 7.5em + $padding
              // var parentWrapper = productElement.parentElement;
              // var scrollTo = {
              //     1: parentWrapper.clientHeight - productsWrapper.clientHeight - (remainingElementsToEnd * productElement.clientHeight),
              //     2: ((parentWrapper.offsetTop + parentWrapper.clientHeight) - (productsWrapper.offsetTop + productsWrapper.clientHeight) - (remainingElementsToEnd * productElement.clientHeight)),
              //     3: (productElement.offsetTop - productsWrapper.offsetTop) - (remainingElementsToEnd * productElement.clientHeight)
              // };
              // if (parentWrapper && scrollToArray) {
              //     angular.element(productsWrapper).duScrollTopAnimated(scrollTo[scrollToArray], 500);
              // }
              angular.element(productsWrapper).duScrollTopAnimated(productElement.offsetTop - BASE_CLIENT_HEIGHT, 500);
              angular.element(productElement).addClass('highlight')
              angular.element(productElement).css("background-color", mainColor);
              setTimeout(function () {
                angular.element(productElement).removeClass('highlight')
                angular.element(productElement).css("background-color", '#fff');
              }, 4000);
            }
          }, 0);
        }
      }
      
      /**
       * @param {{
       *  lineId: number | string,
       *  affectedData: string,
       *  sortedCartByCategoriesData: any,
       *  sortCartByCategories: any,
       *  scrollableWrapperSelector: string,
       *  mainColor: string,
       * }} params 
       * @returns 
       */
      function scrollToAffectedLine(params) {
        var isDirtyAll = _isDirtyAffectedData(params.affectedData) && params.sortedCartByCategoriesData;
        if(!params.sortCartByCategories || !isDirtyAll) {
            return;
        }
        var affectedLineId = params.affectedData.lines[0].id;
        var scrollToCategory = params.sortedCartByCategoriesData.scrollToCategory;
        var remainingElementsToEnd = params.sortedCartByCategoriesData.remainingElementsToEnd;
        if(affectedLineId === params.lineId && scrollToCategory) {
          _scrollToAddedProduct(params.affectedData, params.scrollableWrapperSelector, params.mainColor,  scrollToCategory, remainingElementsToEnd);
        }
    }
    }]);
})(angular, app);
