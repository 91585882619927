(function (angular, app) {
    'use strict';

    app.controller('PromotionDialogCtrl', ['$q', 'Config', 'Api', 'Util', 'User', function ($q, Config, Api, Util, User) {
        var promotionDialogCtrl = this;

        promotionDialogCtrl.sendEmail = sendEmail;
        promotionDialogCtrl.promotion = Config.retailer.promotion;
        promotionDialogCtrl.copyToClipboard = copyToClipboard;

        if (promotionDialogCtrl.promotion.isReference) {
            promotionDialogCtrl.promoCode = promotionDialogCtrl.promotion.id + '-' + User.session.userId;
        }

        /**
         * Send Email
         * @public
         *
         * @returns {Promise}
         */
        function sendEmail(event) {
            var formElement = event.target || event.srcElement || event.path[0];
            if (promotionDialogCtrl.form.$invalid) {
                return Util.setFirstErrorInput(formElement);
            }
            if (promotionDialogCtrl.sending || promotionDialogCtrl.sentSuccessfully) return $q.resolve();

            promotionDialogCtrl.sending = true;

            return Api.request({
                method: 'POST',
                url: '/v2/retailers/:rid/users/:uid/promotions/' + promotionDialogCtrl.promotion.id,
                data: {to: promotionDialogCtrl.email}
            }).then(function () {
                promotionDialogCtrl.sentSuccessfully = true;
            }).finally(function () {
                promotionDialogCtrl.sending = false;
            });
        }

        function copyToClipboard(event) {
            promotionDialogCtrl.copyCompleted = Util.copyToClipboard(promotionDialogCtrl.promoCode, event);
        }

    }]);
})(angular, app);