(function (angular, app) {
    'use strict';

    app.directive('spFileInput', [function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                spModel: '=',
                placeholder: '@',
                spDisabled: '=?'
            },
            templateUrl: 'template/directives/sp-file-input/index.html',
            controller: ['$scope', '$element', function ($scope, $element) {
                var $inputElement = angular.element($element[0].querySelector('input')),
                    model;
                $inputElement.bind('change', function () {
                    model = $inputElement[0].files[0];
                    $scope.spModel = model;
                    $scope.$applyAsync();
                });
            }]
        };
    }]);
})(angular, app);