(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.userEdit.contactUs', {
                url: '/contact-us',
                data: {
                    routeAccess: ROUTE_ACCESS.NOT_ORGANIZATION_MEMBER
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return $filter('translate')('Contact Us');
                    }]
                },
                views: {
                    'tab': {
                        templateUrl: 'template/views/user-edit/contact-us/index.html',
                        controller: 'ContactUsCtrl',
                        controllerAs: 'contactUsCtrl',
                    }
                }
            });
        }])
})(angular, app);