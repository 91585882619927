(function (angular, app) {
    'use strict';

    app.filter('orderObjectBy',
        function () {
            return function (items, field, reverse) {
                var filtered = [];
                angular.forEach(items, function (item, key) {
                    filtered.push(item);
                    item.$key = key;
                });
                function index(obj, i) {
                    return obj[i];
                }

                filtered.sort(function (a, b) {
                    var comparator;
                    try {
                        var reducedA = field.split('.').reduce(index, a),
                            reducedB = field.split('.').reduce(index, b);
                        if (reducedA === reducedB) {
                            comparator = 0;
                        } else {
                            comparator = (reducedA > reducedB ? 1 : -1);
                        }
                    } catch (e) {
                    }
                    return comparator;
                });
                if (reverse) {
                    filtered.reverse();
                }
                return filtered;
            };
        });

})(angular, app);
