(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.userEdit.paymentMethods', {
                url: '/payment-methods',
                data: {
                    routeAccess: ROUTE_ACCESS.LOGIN
                },
                views: {
                    'tab': {
                        templateUrl: 'template/views/user-edit/payment-methods/index.html',
                        controller: 'PaymentMethodsCtrl',
                        controllerAs: 'paymentMethodsCtrl',
                        resolve: {
                            user: ['User', function (user) {
                                return user.getData();
                            }]
                        }
                    }
                }
            });
        }])
        .controller('PaymentMethodsCtrl', ['$scope', '$rootScope', '$q', '$window', 'Config', 'Util', 'Api', 'Dialog',
            'User', 'Cart', 'PaymentsService', 'ORGANIZATION_TYPES', 'Dialog',
            function ($scope, $rootScope, $q, $window, Config, Util, Api, Dialog,
                      user, Cart, PaymentsService, ORGANIZATION_TYPES, dialog) {
            var paymentMethodsCtrl = this,
                userEditCtrl = $scope.userEditCtrl;

            paymentMethodsCtrl.removeSelectedCard = removeSelectedCard;
            paymentMethodsCtrl.removeSelectedPaypalAccount = removeSelectedPaypalAccount;
            paymentMethodsCtrl.addCreditCard = addCreditCard;
            paymentMethodsCtrl.compiledUserAddress = Util.compiledUserAddress();

            paymentMethodsCtrl.disableAddCardButton = userEditCtrl.user.organization && userEditCtrl.user.organization.id && userEditCtrl.user.organization.organizationType !== ORGANIZATION_TYPES.NO_OBLIGO;

            _init(true);

                function addCreditCard() {
                    if(Config.retailer.settings.useDeliveryAddressAsBilling && paymentMethodsCtrl.compiledUserAddress) {
                        return dialog.show({
                            templateUrl: 'template/dialogs/use-delivery-address-as-billing/index.html',
                            controller: ['$scope', function ($dialogScope) {
                                var dialogCtrl = this;
                                dialogCtrl.sendUserAddress = false;
                                dialogCtrl.compiledUserAddress = paymentMethodsCtrl.compiledUserAddress;
                                var userAddress = user.data.addresses[0];
                                var userCountryName = userAddress && userAddress.country;
                                if (userCountryName) {
                                    userAddress.countryCode = Util.getCountryCode(userCountryName);
                                }
                                $dialogScope.continue = function () {
                                    dialog.hide();
                                    return PaymentsService.addCreditCard({
                                        sendUserAddress: dialogCtrl.sendUserAddress,
                                        address: userAddress.text1 ? userAddress.text1 : userAddress.text2 ? userAddress.text2 : '',
                                        city: userAddress.city ? userAddress.city : '',
                                        country: userAddress.country ? userAddress.country : '',
                                        countryCode: userAddress.countryCode ? userAddress.countryCode : '',
                                    }).then(function () {
                                        setTimeout(function () {
                                            _init()
                                        }, 1000);
                                    });

                                };
                            }],
                            controllerAs: 'dialogCtrl',
                            styleClass: 'clear-cart-popup'
                        });
                    } else {
                        return PaymentsService.addCreditCard().then(function () {
                            setTimeout(function () {
                                _init()
                            }, 1000);
                        });
                    }
                }

            function removeSelectedCard() {
                return Util.showCommonDialog({
                    title: '{{\'Remove Credit Card Info\' | translate}}',
                    content: ' {{\'Are you sure you want to remove Credit Card information\' | translate}}? ',
                    buttons: [{
                        text: '{{\'Yes\' | translate}}',
                        click: '$dialog.hide(true)'
                    }, {
                        text: '{{\'No\' | translate}}',
                        click: '$dialog.hide()'
                    }]
                }).then(function (remove) {
                    if (!remove) return;

                    return Api.request({
                        method: 'DELETE',
                        url: '/retailers/:rid/users/:uid/removecard',
                        data: {
                            cardId: paymentMethodsCtrl.creditCard.id
                        }
                    }).then(function () {
                        setTimeout(function(){_init()},1000);
                    });
                });
            }

            function removeSelectedPaypalAccount() {
                return Util.showCommonDialog({
                    title: '{{\'Remove PayPal Account\' | translate}}',
                    content: ' {{\'Are you sure you want to remove this PayPal account\' | translate}}? ',
                    buttons: [{
                        text: '{{\'Yes\' | translate}}',
                        click: '$dialog.hide(true)'
                    }, {
                        text: '{{\'No\' | translate}}',
                        click: '$dialog.hide()'
                    }]
                }).then(function (remove) {
                    if (!remove) return;
                    return Api.request({
                        method: 'DELETE',
                        url: '/retailers/:rid/users/:uid/removepaypalaccount',
                        data: {
                            accountId: paymentMethodsCtrl.paypalAccount.id
                        }
                    }).then(function () {
                        return _init();
                    });
                });
            }

            function _init(dontGetUserData) {
                return (dontGetUserData ? $q.resolve() : user.getData(true)).then(function () {
                    paymentMethodsCtrl.creditCard = _getDefault(userEditCtrl.user.creditCards);
                    paymentMethodsCtrl.paypalAccount = _getDefault(userEditCtrl.user.paypalAccounts);
                });
            }

            /**
             * Get default from array
             * @private
             *
             * @param {Array} arr
             *
             * @returns {*}
             */
            function _getDefault(arr) {
                return arr.find(function (x) {
                    return x.isDefault;
                }) || arr[0];
            }
        }]);
})(angular, app);
