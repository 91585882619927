(function (angular, app) {
    'use strict';

    app.config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.userEdit.organizationManagement', {
                url: '/organization-management',
                data: {
                    routeAccess: ROUTE_ACCESS.ORGANIZATION_ADMIN
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return $filter('translate')('Organization Management');
                    }]
                },
                views: {
                    'tab': {
                        templateUrl: 'template/views/user-edit/organization-management/index.html',
                        controller: 'OrganizationManagementCtrl',
                        controllerAs: 'organizationManagementCtrl',
                        resolve: {
                            organization: ['Api', 'User', function (Api, User) {
                                return User.getData().then(function(user) {
                                    return Api.request({
                                        method: 'GET',
                                        url: '/v2/retailers/:rid/organizations/' + user.organization.id,
                                    });
                                });
                            }],
                            associatedBranches: ['Api', 'User', function(Api, User) {
                                return User.getData().then(function(user) {
                                    return Api.request({
                                        method: 'GET',
                                        url: '/v2/retailers/:rid/organizations/' + user.organization.id + '/branches',
                                    });
                                }).then(function(result) {
                                    return result && result.branches;
                                });
                            }]
                        }
                    }
                }
            });
        }])
        .controller('OrganizationManagementCtrl',
            ['$scope', '$rootScope', '$state', '$filter', '$http', '$templateCache', '$compile', '$timeout', 'Blob', 'FileSaver',
                'Config', 'ORGANIZATION_TYPES', 'USER_CREDIT_LOG_ACTION_TYPES', 'User', 'Api', 'organization', 'associatedBranches', 'Util', 'Dialog', 'Toast',
            function ($scope, $rootScope, $state, $filter, $http, $templateCache, $compile, $timeout, Blob, FileSaver,
                      Config, ORGANIZATION_TYPES, USER_CREDIT_LOG_ACTION_TYPES, User, Api, organization, associatedBranches, Util, Dialog, toast) {
                var organizationManagementCtrl = angular.extend(this, {
                    regexExp: new RegExp('^[-+]?[0-9]*\.?[0-9]+$'),
                    associatedBranches: associatedBranches,
                    updateUserBranch: updateUserBranch,
                    setUserValidity: setUserValidity,
                    exportToExcel: exportToExcel,
                    searchUser: searchUser,
                    save: save,
                    removeUser: removeUser,
                    getUserStatusKeyById: getUserStatusKeyById,
                    showUsersUploadDialog: showUsersUploadDialog,
                    obligoAssigned: 0,
                    organizationWithObligo: organization.organizationTypeId === ORGANIZATION_TYPES.OBLIGO
                }), _originalUsers, logTypeMap = _generateLogMap();

                _init(organization);

                function updateUserBranch(user, branch) {
                    if (user.branchId === branch.id) {
                        return;
                    }

                    return Api.request({
                        method: 'POST',
                        url: '/v2/retailers/:rid/organizations/' + organizationManagementCtrl.organization.id +'/users/' + user.id + '/branch',
                        data: {
                            branchId: branch.id || null
                        }
                    }).then(_setCurrentOrganization);
                }

                function _setAllUserObligoFieldsValid() {
                    if(!organizationManagementCtrl.form) {
                        return;
                    }

                    angular.forEach(organizationManagementCtrl.organization.users, function (user) {
                        organizationManagementCtrl.form['obligoUserSum_' + user.id].$setValidity('pattern', true);
                    });
                }

                function searchUser() {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/organizations/' + organizationManagementCtrl.organization.id +'/users/search',
                        params: {
                            search: organizationManagementCtrl.search
                        },
                    }).then(function(users){
                        organizationManagementCtrl.organization.users = users;
                    });
                }

                function setUserValidity(userId) {
                    _setAllUserObligoFieldsValid();
                    organizationManagementCtrl.obligoAssigned = 0;
                    angular.forEach(organizationManagementCtrl.organization.users, function (user) {
                        organizationManagementCtrl.obligoAssigned += user.remainingSum || 0;
                    });

                    var isRemainingObligoValid = organizationManagementCtrl.obligoAssigned <= (organizationManagementCtrl.organization.obligoRemainingSum || 0);
                    if (userId && organizationManagementCtrl.form) {
                        organizationManagementCtrl.form['obligoUserSum_' + userId].$setValidity('pattern', isRemainingObligoValid);
                    }
                }

                function exportToExcel() {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/organizations/' + organizationManagementCtrl.organization.id + '/logs',
                        params: {
                            page: 1,
                            max: 1000,
                            start: 0
                        }
                    }).then(function(results) {
                        var logs = [];
                        angular.forEach(results.data, function(result) {
                            var log = angular.copy(result);
                            log.date = $filter('date')(log.date, Config.language.dateFormat.dateAndTime);
                            log.action = logTypeMap[log.actionTypeId];
                            logs.push(log);
                        });

                        _downloadTemplate('OrganizationLogsExport', 'template/exports/organization-logs/index.html',
                            {OrganizationLogsExport: logs});
                    });
                }

                function _downloadTemplate(mainScopeDataName, template, dataToPrint) {
                    return $http.get(template, {
                        cache: $templateCache
                    }).then(function(result) {
                        var $newScope = $rootScope.$new();
                        //angular.extend($newScope, dataToPrint);
                        $newScope[mainScopeDataName] = dataToPrint[mainScopeDataName];
                        var compiled = $compile(result.data)($newScope);
                        $timeout(function () {
                            var blob = new Blob([compiled.text()], {
                                type: 'text/csv;charset=utf-8;'
                            });
                            FileSaver.saveAs(blob, mainScopeDataName + '.csv');
                            $newScope.$destroy();
                        }, 0, false);
                    });
                }

                function save() {
                    var users = _getUsersToSend();

                    if (!users.length) {
                        return;
                    }

                    return Api.request({
                        method: 'PATCH',
                        url: '/v2/retailers/:rid/organizations/' + organizationManagementCtrl.organization.id + '/users',
                        data: {
                            users: users
                        }
                    }).then(_setCurrentOrganization);
                }

                function showUsersUploadDialog(mode) {
                    return Util.showUsersUploadDialog(mode, organization.id);
                }

                function removeUser(user) {
                     return Dialog.show({
                        templateUrl: 'template/dialogs/user-delete-confirm/index.html',
                        controller: 'UserDeleteConfirmationCtrl',
                        controllerAs: 'userDeleteConfirmationCtrl',
                        locals: {
                            user: user
                        }
                    }).then(function (result) {
                        if (result && result.isNeedToDelete === true) {
                            return Api.request({
                                method: 'DELETE',
                                url: '/v2/retailers/:rid/organizations/' + organizationManagementCtrl.organization.id +'/users/' + result.userId
                            }).then(function(){
                                angular.forEach(organizationManagementCtrl.organization.users, function(user, key) {
                                    if (user.id === result.userId) {
                                        delete organizationManagementCtrl.organization.users[key];
                                    }
                                });

                                toast.show({
                                    timeout: 5000,
                                    content: '{{\'User removed from the organization\'| translate}}'
                                });
                            });
                        }
                    });
                }

                function _generateLogMap() {
                    var typeMap = {};
                    angular.forEach(USER_CREDIT_LOG_ACTION_TYPES, function(value, key) {
                        typeMap[value.id] = value.name;
                    });
                    return typeMap;
                }
                
                function _init(organization) {
                    _initBranchesMap();
                    _setOrganizationData(organization);
                    setUserValidity();
                }

                function _initBranchesMap() {
                    organizationManagementCtrl.branchesMap = {};
                    angular.forEach(organizationManagementCtrl.associatedBranches, function(branch) {
                        organizationManagementCtrl.branchesMap[branch.id] = branch;
                    });
                }
                
                function _setOrganizationData(organization) {
                    organizationManagementCtrl.organization = organization;
                    _originalUsers = {};

                    angular.forEach(organizationManagementCtrl.organization.users, function(user) {
                        _originalUsers[user.id] = angular.copy(user);
                    });

                    if (organizationManagementCtrl.organization.organizationTypeId === undefined) {
                        organizationManagementCtrl.organization.organizationTypeId = ORGANIZATION_TYPES.OBLIGO;
                    }
                    if (organizationManagementCtrl.organization.isActive === undefined) {
                        organizationManagementCtrl.organization.isActive = false;
                    }
                }

                function _setCurrentOrganization() {
                    _setOrganizationData(organizationManagementCtrl.organization);
                }

                function getUserStatusKeyById(id) {
                    if (id === 1) {
                        return 'user_verification_status_1';
                    } else if (id === 2) {
                        return 'user_verification_status_2';
                    } else if (id === 3) {
                        return 'user_verification_status_3';
                    } else {
                        return 'user_verification_status_1';
                    }
                }

                function _getUsersToSend() {
                    var users = [];
                    angular.forEach(organizationManagementCtrl.organization.users, function(newUser) {
                        if (!angular.equals(newUser, _originalUsers[newUser.id])) {
                            users.push(newUser);
                        }
                    });

                    return users;
                }
            }
        ]);
})(angular, app);
