(function (angular, app) {
    'use strict';

    app.config(['$provide', function ($provide) {
        var provider = $provide.service('Toast', ['$rootScope', '$compile', '$timeout', function ($rootScope, $compile, $timeout) {
            var self = this;

            self.show = show;

            function show(options) {
                var element = _createElement();
                document.body.appendChild(element[0]);
                $timeout(function () {
                    element.addClass('shown').html(options.content || '');
                    $compile(element.contents())($rootScope);
                    $timeout(function () {
                        element.addClass('fadeOutDown');
                        $timeout(function () {
                            element.remove();
                        }, 400);
                    }, options.timeout || 3000);
                }, 500);
            }

            function _createElement() {
                return angular.element(provider.defaults.template);
            }
        }]);

        provider.defaults = {
            template: '<div class="toast fadeInUp" aria-live role="alert"></div>'
        };
    }]);
})(angular, app);