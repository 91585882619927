(function (angular, app) {
  'use strict';

  app.service('AddressService', [
    'Api', 'SP_SERVICES', 'SpDeliveryAreasService',
    function (Api, SP_SERVICES, SpDeliveryAreasService) {
      var self = this;
      angular.extend(self, {
        getInternalCities: getInternalCities,
        getAddressesFromGoogleMapApi: getAddressesFromGoogleMapApi,
      });

      /**
       * Get all interal cities which come from Cities table in the database
       * @param {string} cityQuery
       * @returns Promise<any[]>
       */
      function getInternalCities(cityQuery) {
        return Api.request({
          method: 'GET',
          url: '/v2/addresses/cities',
          params: {
            term: cityQuery,
            size: 8,
          },
        });
      }

      /**
       * Get full addresses from Google Map API
       * @param {string} addressQuery 
       * @returns Promise<any[]>
       */
      function getAddressesFromGoogleMapApi(addressQuery) {
        var GG_ADDRESS_MODE = SP_SERVICES.CHOOSE_AREA_MODE.TYPE_GOOGLE_ADDRESS;
        return SpDeliveryAreasService.autoCompleteDeliveryAreasWithFullData(GG_ADDRESS_MODE, addressQuery);
      }
    }
  ]);

})(angular, app);