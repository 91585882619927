(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.webPage', {
                url: '/web-pages/{id:int}',
                data: {
                    name: 'web-page'
                },
                metaTags: {
                    title: ['$stateParams', 'Config', function ($stateParams, config) {
                        if (!config.retailer.publishedWebPages) {
                            return;
                        }

                        for (var i = 0; i < config.retailer.publishedWebPages.length; i++) {
                            if (config.retailer.publishedWebPages[i].id === $stateParams.id) {
                                return config.retailer.publishedWebPages[i].name;
                            }
                        }
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/web-page/index.html',
                        controller: 'WebPageCtrl as webPageCtrl',
                        resolve: {
                            webPage: ['$stateParams', 'Api', function ($stateParams, api) {
                                return api.request({
                                    method: 'GET',
                                    url: '/v2/retailers/:rid/web-pages/' + $stateParams.id
                                });
                            }]
                        }
                    }
                }
            });
        }])
        .controller('WebPageCtrl', ['$sce', 'spHtmlComponentsRenderer', 'webPage', function ($sce, spHtmlComponentsRenderer, webPage) {
            var webPageCtrl = this;

            webPageCtrl.webPage = webPage;
            webPageCtrl.html = $sce.trustAsHtml(spHtmlComponentsRenderer.generateHtml(webPageCtrl.webPage.components));
        }]);

})(angular, app);
