(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.singleOrder', {
                url: '/orders/:oid',
                data: {
                    routeAccess: ROUTE_ACCESS.LOGIN,
                    name: 'single-order'
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/single-order/index.html',
                        controller: 'SingleOrderItemCtrl as singleOrderItemCtrl',
                        resolve: {
                            order: ['$stateParams', 'Api', function ($stateParams, api) {
                                return api.request({
                                    method: 'GET',
                                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/orders/' + $stateParams.oid
                                });
                            }],
                        }
                    }
                }
            });
        }])
        .controller('SingleOrderItemCtrl', [
            '$scope', '$rootScope', '$filter', 'Cart', 'Util','order', 'SP_SERVICES', 'DataLayer',
            function ($scope, $rootScope, $filter, cart, util, order, SP_SERVICES, DataLayer) {
                var smartListCtrl = this,
                    _listeners = [];
                var _productNameFilter = $filter('productName');
                $scope.order = angular.copy(order);
                smartListCtrl.addToCart = addToCart;
                smartListCtrl.createNewCart = createNewCart;

                smartListCtrl.actions = {
                    setQuantity: function (item, toAdd) {
                        smartListCtrl.hasChanges = true;
                        item.quantity = _fixQuantity(item.quantity + _getQuantityInterval(item.product) * toAdd);
                        if (item.product.quantityLimit && item.quantity > item.product.quantityLimit) {
                            item.quantity = item.product.quantityLimit;
                        }
                        if (item.quantity <= 0) {
                            item.quantity = 0;
                        }

                        // to prevent the sp-quantity directive changing the quantity
                        return true;
                    },
                    onWeightClick: function (item) {
                        if (item.product.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name) {
                            var cartLine = _getLine(item.product);
                            item.product.soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                            item.soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                             /* if need to re-calculate the quantity from unit to weight when switching to weight, uncomment this code */
                            // item.quantity = +(item.quantity * item.product.weight).toFixed(2);
                            if (cartLine) {
                                /* if need to re-calculate the quantity from unit to weight when switching to weight, uncomment this code */
                                // cartLine.quantity = +(cartLine.quantity * cartLine.product.weight).toFixed(2);
                                cartLine.soldBy = item.soldBy;
                            }
                        }
                    },
                    onUnitClick: function (item) {
                        if (item.product.soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name) {
                            var cartLine = _getLine(item.product);
                            item.product.soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;
                            item.soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;
                            /* if need to re-calculate the quantity from weight to unit when switching to unit, uncomment this code */
                            // item.quantity = Math.round(item.quantity / item.product.weight);
                            item.quantity = Math.ceil(item.quantity);
                            if (cartLine) {
                                /* if need to re-calculate the quantity from weight to unit when switching to unit, uncomment this code */
                                // cartLine.quantity = Math.round(cartLine.quantity / cartLine.product.weight);
                                cartLine.quantity = Math.ceil(cartLine.quantity);
                                cartLine.soldBy = item.soldBy;
                            }
                        }
                    },
                    openSuggestions: function(item) {
                        util.openSuggestionsDialog(item.product);
                    }
                };

                //== If Cart already finished an API call we make init
                if(cart.sendingSucceeded) {
                    _init();
                } else {
                    _setTotalAmount();
                }

                //== Subscribing to Cart event will synchronize data in cart
                _listeners.push($rootScope.$on('cart.update.complete', function () {
                    _init();
                }));

                function _init() {
                    _setSoldBy();
                    _setTotalAmount();
                }

                function _getQuantityInterval(item) {
                    // use product when exits
                    var product = item.product || item;

                    return product.soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name || (product.isWeighable && !product.weight) ? (product.unitResolution || 0.5) : 1;
                }

                function _setTotalAmount() {
                    var cartProducts = _getCartProductsQuantity();
                    var _replacementLines = [];

                    smartListCtrl.totalAmount = 0;
                    angular.forEach($scope.order.lines , function(item) {
                        if (item.product) {
                            if(item.productId) {
                                item.caseProductId = cart.checkIsCaseProduct(item) ? item.productId.toString() + '1' : item.productId.toString() + '0';
                            }
                            smartListCtrl.totalAmount += (item.quantity || 0) * (item.product && util.getRegularPrice(item.product, item.isCase));
                            item.inCart = item.type === SP_SERVICES.CART_LINE_TYPES.COUPON ? !!cartProducts[item.product.id] : !!cartProducts[item.caseProductId];
                            item.inCartQuantity = cartProducts[item.product.id] || 0;
                            item.actionsChecked = item.inCart;
                            item.isProductOutOfStock = util.isProductOutOfStock(item);
                            item.isNeedToShowOutOfStockLabel = util.isNeedToShowOutOfStockLabel(item);
                            if (cart.isEligibleForReplacementSuggestions(item)) {
                                _replacementLines.push(item);
                            }
                            item.actionsDisabled = !!item.isProductOutOfStock ||
                                !!(item.product && item.product.isCoupon) ||
                                !!(item.product && item.type === 2);

                            // set weighable units for suitable products
                            if (item.product.isWeighable && item.product.weight && !item.weightQuantity) {
                                item.weightQuantity = item.quantity;

                                if(!item.product.soldBy || item.product.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name) {
                                    item.quantity = Math.round(item.weightQuantity / item.product.weight);
                                }
                            }
                            item.quantity = _fixQuantity(item.quantity);
                        }
                    });

                    cart.setReplacements(_replacementLines);
                    $scope.$broadcast('singleOrder.lines.checkedChanged');
                }

                function _getSelectedProducts() {
                    var lines = angular.filterCollection($scope.order.lines, function(item) {
                        item.weighableProductUnits = item.quantity;
                        return item.actionsChecked;
                    });

                    if (!lines || lines && !lines.length) {
                        util.showCommonDialog('{{\'No products were selected\' | translate}}');
                    }

                    return lines;
                }

                function addToCart() {
                    var lines = _getSelectedProducts();
                    if(!lines)return;

                    var linesWithoutPropertySelected = [];

                    angular.forEach(lines, function (line) {
                        if(line.product && line.product.productProperties && line.product.productProperties.length && !line.productPropertyValue) {
                            linesWithoutPropertySelected.push(_productNameFilter(line.product, line.product.isCaseMode));
                        }
                    });

                    if(linesWithoutPropertySelected.length !== 0) {
                        util.showCommonDialog(
                            '{{\'Product properties need to be selected for\' | translate}}' + ': ' + linesWithoutPropertySelected.join(', ')
                        );

                        return;
                    }

                    var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(lines);
                    if (isLimitPassed === false) {
                        if (cart.lines && Object.keys(cart.lines).length === 0 &&
                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                            cart.addDeliveryFeeLineIfNeeded();
                        }
                        cart.addLines(util.getActiveLines(lines), SP_SERVICES.SOURCES.ORDER);
                    }
                }
                
                function createNewCart () {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Clear Cart'}});
                    var lines = _getSelectedProducts();
                    if (lines && lines.length) {
                        var linesWithoutPropertySelected = [];

                        angular.forEach(lines, function (line) {
                            if(line.product && line.product.productProperties && line.product.productProperties.length && !line.productPropertyValue) {
                                linesWithoutPropertySelected.push(_productNameFilter(line.product, line.product.isCaseMode));
                            }
                        });

                        if(linesWithoutPropertySelected.length !== 0) {
                            util.showCommonDialog(
                                '{{\'Product properties need to be selected for\' | translate}}' + ': ' + linesWithoutPropertySelected.join(', ')
                            );

                            return;
                        }

                        var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(lines);
                        if (isLimitPassed === false) {
                            cart.clear();
                            if (cart.lines && Object.keys(cart.lines).length === 0 &&
                                $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                                cart.addDeliveryFeeLineIfNeeded();
                            }
                            cart.addLines(util.getActiveLines(lines), SP_SERVICES.SOURCES.ORDER);
                        }
                    }
                }

                function _fixQuantity(quantity) {
                    return Number(Number(quantity).toFixed(2));
                }

                function _getCartProductsQuantity() {
                    var cartProducts = cart.getLines(),
                        products = {};

                    if(cartProducts && Array.isArray(cartProducts) && cartProducts.length) {
                        angular.forEach(cartProducts, function (cartLine) {
                            if(cartLine && cartLine.product && cartLine.product.id) {
                                products[cartLine.id] = !cartLine.removed && cartLine.quantity || 0;
                            }
                        });
                    }

                    return products;
                }

                function _setSoldBy() {
                    angular.forEach($scope.order.lines, function (item) {
                        item.soldBy = null;
                        if(!item.product){
                            return;
                        }
                        item.product.soldBy = null;
                        if (item.product && util.isUnitsWeighable(item.product)) {
                            var cartLine = _getLine(item.product);
                            if (!cartLine) {
                                item.soldBy = !!item.weighableProductUnits && item.weighableProductUnits > 0 ? 'Unit' : 'Weight';
                            } else {
                                item.soldBy = cartLine.soldBy;

                                /* if need to re-calculate the quantity from unit to weight when switching to weight, uncomment this code */
                                // if (item.soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name && !item.weighableProductUnits) {
                                //     item.quantity = +(item.quantity * item.product.weight).toFixed(2);
                                // } 

                                if (item.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name && item.weighableProductUnits) {
                                    item.quantity = Math.ceil(item.quantity);
                                }
                            }
                            item.product.soldBy = item.soldBy;
                        }
                    });
                }

                function _getLine(product) {
                    var cartLines = cart.getLines(),
                        cartLine;
                    if (!cartLines) return;
                    angular.forEach(cartLines, function (line) {
                        if (line.product && line.product.id === product.id) {
                            cartLine = line;
                        }
                    })
                    return cartLine;
                }
                
                _listeners.push($rootScope.$on('setSoldBy', function (event, data) {
                    if (!data.cartLine) return;
                    angular.forEach($scope.order.lines, function (line) {
                        if (line.product.id === data.cartLine.product.id) {
                            line.soldBy = data.cartLine.soldBy;
                            line.product.soldBy = data.cartLine.soldBy;
                            line.product.soldBy = data.cartLine.soldBy;

                            /* if need to re-calculate the quantity from weight to unit when switching to unit and vice-versa, uncomment this code */
                            // line.quantity = line.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name ? Math.round(line.quantity / line.product.weight) : +(line.quantity * line.product.weight).toFixed(2);

                            line.quantity = line.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name ? Math.ceil(line.quantity) : line.quantity;
                        }
                    })
                }));
               
                util.destroyListeners($scope, _listeners);
            }]);
})(angular, app);
