(function (angular, app) {
	"use strict";

	app.directive("spInput", function () {
		return {
			restrict: "E",
			require: "^form",
			link: function ($scope, $element) {
				var input, hasPlaceHolder, showPassword;

				var inputWatcher = $scope.$watch(
					function () {
						return angular.element($element[0].querySelector("input"));
					},
					function (newVal) {
						if (!newVal) {
							return;
						}

						input = newVal;
						hasPlaceHolder = input.prop("placeholder");
						showPassword = false

						inputWatcher();

						if(input.prop('type') === 'password') {
							var eyeBtn = angular.element($element[0].querySelector("button.ico-btn"))
							if(eyeBtn) {
								eyeBtn.on("click", function() {
									showPassword = !showPassword
									if(showPassword) input.prop('type', 'text')
									else input.prop('type', 'password')
								})
							}
						}

						if (!hasPlaceHolder) {
							input.bind("focus focusout", toggleClass);

							$scope.$watch(function () {
								return input.val();
							}, toggleClass);
						} else {
							toggleClass();
						}
					}
				);

				function toggleClass() {
					if (hasPlaceHolder || (input.val() || "").trim() || document.activeElement === input[0] || (input[0] && input[0].type === "date")) {
						$element.addClass("floating-label");
					} else {
						$element.removeClass("floating-label");
					}
				}
			},
		};
	});
})(angular, app);
