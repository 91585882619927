(function (angular, app) {
    'use strict';

    app.controller('EditOrderPaymentChooseCtrl', ['Dialog', '$location', 'orderPaymentMethods',
        function (Dialog, $location, orderPaymentMethods) {
            var editOrderPaymentChooseCtrl = this;
            console.log(orderPaymentMethods);
            editOrderPaymentChooseCtrl.options = [
                {
                    digits: _setPaymentMethodLastFourDigits(orderPaymentMethods.ebtPayment),
                    icon: '#sp-icons-ebt-card',
                    name: 'EBT',
                    route: '/update-order/ebt-payment'
                },
                {
                    digits: _setPaymentMethodLastFourDigits(orderPaymentMethods.mainPayment),
                    icon: '#sp-icons-ebt-card',
                    name: 'Credit Card',
                    route: '/'
                }
            ];

            editOrderPaymentChooseCtrl.chosenPaymentMethod = null;
            editOrderPaymentChooseCtrl.onChooseClicked = onChooseClicked;

            function onChooseClicked() {
                var paymentChoose = editOrderPaymentChooseCtrl.chosenPaymentMethod;

                $location.path(paymentChoose.route);
                Dialog.hide();
            }

            function _setPaymentMethodLastFourDigits(paymentMethod) {
                return (paymentMethod && paymentMethod.lastFourDigits) ? paymentMethod.lastFourDigits : null
            }

            console.log('EditOrderPaymentChooseCtrl workk!');
        }]);
})(angular, app);