(function (angular, app) {
    'use strict';

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('app.userEdit', {
            abstract: true,
            url: '/user-edit',
            data: {
                name: 'user-edit',
                layout: {
                    sidenav: false
                }
            },
            metaTags: {
                title: ['$filter', function ($filter) {
                    return $filter('translate')('My account');
                }]
            },
            views: {
                '@': {
                    templateUrl: 'template/views/user-edit/index.html',
                    controller: 'UserEditCtrl',
                    controllerAs: 'userEditCtrl',
                    resolve: {
                        userData: ['User', function (user) {
                            return user.getData(true).catch(function () {
                                return null; // catch if not logged in
                            });
                        }]
                    }
                }
            }
        });
    }]).controller('UserEditCtrl', [
        '$scope', '$rootScope', '$state', 'Config', 'Api', 'Util', 'User', 'SP_PAYMENTS', 'PHONE_TYPES', 'ORGANIZATIONS_ROLES', 'ORGANIZATION_TYPES', 'userData',
        'Dialog', 'GOOGLE_AUTO_COMPLETE_TYPES', 'spGoogleMapsUtil', 'Specials', 'Cart', 'Loyalty', 'LOYALTY_PAGE_COMPONENT_TYPES', '$sce',
        function ($scope, $rootScope, $state, Config, Api, Util, User, SP_PAYMENTS, PHONE_TYPES, ORGANIZATIONS_ROLES, ORGANIZATION_TYPES, userData,
                  Dialog, GOOGLE_AUTO_COMPLETE_TYPES, spGoogleMapsUtil, Specials, Cart, Loyalty, LOYALTY_PAGE_COMPONENT_TYPES, $sce) {
            var userEditCtrl = this;


            userEditCtrl.getCityAutoCompleteOptions = getCityAutoCompleteOptions;
            userEditCtrl.resetUser = resetUser;
            userEditCtrl.validateCountryCode = validateCountryCode;
            userEditCtrl.validateAreaCode = validateAreaCode;
            userEditCtrl.phoneEditMode = phoneEditMode;
            userEditCtrl.setPhones = _setPhones;
            userEditCtrl.setPhoneInputs = _setPhoneInputs;
            userEditCtrl.compileCustomValidatedPhone = compileCustomValidatedPhone;
            userEditCtrl.openAutocompleteAddress = openAutocompleteAddress;
            userEditCtrl.filterCountries = filterCountries;
            userEditCtrl.loadSpecials = loadSpecials;

            userEditCtrl.$state = $state;
            userEditCtrl.isAvailableOrganization = false;
            userEditCtrl.organizationTitle = null;
            userEditCtrl.isAvailablePayments = false;
            userEditCtrl.isAvailableMembershipClub = false;
            userEditCtrl.membershipClubState = null;
            userEditCtrl.isAvailableEmployeeDiscount = false;
            userEditCtrl.customPhoneValidation = Config.retailer.customPhoneValidation && Config.retailer.customPhoneValidation.isActive;
            userEditCtrl.phoneRegExp = new RegExp(/^\(?\+?(\d[,\-,(,),,,]*){7,15}$/);
            userEditCtrl.customPhoneRegExp = new RegExp(/^\(?\+?(\d[0-9\)\(-]*)$/);
            userEditCtrl.formSubmited = false;
            userEditCtrl.editPhonesMode = {};
            userEditCtrl.customValidatedPhoneNumbers = {};
            userEditCtrl.countryNames = Util.getCountryAutocompleteOptions();
            userEditCtrl.switchTab = switchTab;
            userEditCtrl.openAccumulationRedemptionDialog = openAccumulationRedemptionDialog;
            userEditCtrl.currentState = $state.current;
            userEditCtrl.accumulationDefaultText =  {
                btnText: {
                    he: 'היסטוריית צבירה ומימוש',
                    en: 'Accumulation and redemption history',
                    ru: 'История накопления и погашения',
                    es: 'Historial de acumulación y redención',
                    ar: 'تاريخ التراكم والاسترداد',
                    fr: 'Historique de cumul et d\’utilisation'
                },
                popupTitleText: {
                    he: 'היסטוריית צבירה ומימוש נקודות',
                    en: 'Points accumulation and redemption history',
                    ru: 'История накопления и погашения баллов',
                    es: 'Historial de puntos acumulados y redimidos',
                    ar: 'تراكم النقاط وتاريخ الاسترداد',
                    fr: 'Historique de cumul et d\’utilisation des points'
                },
                markTitleText: {
                    he: 'סך הנקודות הנוכחי שלכם הוא {Total Points Amount}',
                    en: 'Your Current Point total is {Total Points Amount}',
                    ru: 'Ваши баллы: {Total Points Amount}',
                    es: 'Su puntaje actual total es {Total Points Amount}',
                    ar: 'مجموع نقاطك الحالية هو {Total Points Amount}',
                    fr: 'Total de points actuel est de {Total Points Amount}'
                },
                notAccumulateText: {
                    he: 'נראה שעדיין לא צברתם נקודות, המשיכו בקנייה על מנת לצבור נקודות',
                    en: 'Looks like you haven\'t earned any points yet, continue shopping to earn points',
                    ru: 'Похоже, у вас пока что нет баллов: продолжайте покупки, чтобы получить баллы.',
                    es: 'Al parecer usted no ha ganado puntos aún, continúe comprando para ganar puntos',
                    ar: 'يبدو أنك لم تكسب أي نقاط بعد، تابع التسوق لكسب النقاط',
                    fr: 'Il semblerait que vous n\'ayez pas encore gagné le moindre point, continuez à acheter pour gagner des points.'
                }
            }
            userEditCtrl.savedUserAddress = null;
            
            if (userEditCtrl.customPhoneValidation) {
                userEditCtrl.areaCodesAvailable = Config.retailer.customPhoneValidation.areaCodes && Config.retailer.customPhoneValidation.areaCodes.length
                userEditCtrl.countryCodesAvailable = Config.retailer.customPhoneValidation.countryCodes && Config.retailer.customPhoneValidation.countryCodes.length
            }
            userEditCtrl.specialsActions = {
                openCarouselItem: openSpecial
            };
            userEditCtrl.specialsCarouselWatchers = {
                'cart.lines.add': onCartLinesAdd
            };
            userEditCtrl.externalLoginSettings = (Config.retailer.externalLoginSettings) ? Object.assign({}, Config.retailer.externalLoginSettings) :  {isActive: false, updateUserIframeURL:null};
            userEditCtrl.updateUserIframeURL = userEditCtrl.externalLoginSettings.isActive ? $sce.trustAsResourceUrl(userEditCtrl.externalLoginSettings.updateUserIframeURL) : '';

            resetUser(userData);
            _setTabs();

            function _setTabs() {
                userEditCtrl.tabs = [{
                    title: 'Account Info',
                    state: 'accountInfo',
                    icon: 'user'
                }];

                userEditCtrl.isAvailableOrganization = (_isOrganizationObligoType() || _isOrganizationRegularPaymentType()) && userEditCtrl.user && userEditCtrl.user.organization.roleId === ORGANIZATIONS_ROLES.ADMIN;
                userEditCtrl.organizationTitle = _isOrganizationObligoType() ? 'Organization Budget' : 'Organization Management';
                if (userEditCtrl.isAvailableOrganization) {
                    userEditCtrl.tabs.push({
                        title: userEditCtrl.organizationTitle,
                        state: 'organizationManagement',
                        icon: 'credit-cards'
                    });
                }

                userEditCtrl.isAvailablePayments = userEditCtrl.hasCreditCardMethod || userEditCtrl.hasPayPalMethod;
                if (userEditCtrl.isAvailablePayments) {
                    userEditCtrl.tabs.push({
                        title: 'Payment Methods',
                        state: 'paymentMethods',
                        icon: 'credit-cards'
                    });
                }

                userEditCtrl.tabs.push({
                    title: 'Customer Service',
                    state: 'customerService',
                    icon: 'customer-service'
                });

                if (Config.retailer.settings.enablePersonalizedCoupons) {
                    userEditCtrl.tabs.push({
                        title: 'My Coupons',
                        state: 'myCoupons',
                        icon: 'coupon-page'
                    });
                }

                userEditCtrl.isAvailableMembershipClub = Config.retailer.loyaltyClub && !_isOrganizationObligoType();
                var loyaltyClubDriver = !!Config.retailer.loyaltyClubDrivers && Config.retailer.loyaltyClubDrivers.length === 1 && Config.retailer.loyaltyClubDrivers[0],
                    isPremiumLoyaltyClub = Util.isLoyaltyPremiumPackageEnabled() && !!loyaltyClubDriver;
                userEditCtrl.membershipClubState = isPremiumLoyaltyClub ? 'premiumLoyaltyClub' : ((!!loyaltyClubDriver && loyaltyClubDriver.clientConfig.extendedLoyaltyClub) ? 'extendedLoyalty' : 'loyalty');
                if (userEditCtrl.isAvailableMembershipClub) {
                    userEditCtrl.tabs.push({
                        title: 'Membership club',
                        state: userEditCtrl.membershipClubState,
                        icon: 'club',
                        stateParams: isPremiumLoyaltyClub ? JSON.stringify({loyaltyClubDriverId: loyaltyClubDriver.loyaltyClubDriverId}) : null
                    });
                }

                userEditCtrl.isAvailableEmployeeDiscount = Config.retailer.settings.discountClubActive === 'true' && !_isOrganizationObligoType();
                if (userEditCtrl.isAvailableEmployeeDiscount) {
                    userEditCtrl.tabs.push({
                        title: 'Employee discount',
                        state: 'specialDiscount',
                        icon: 'club'
                    });
                }
                if (Config.retailer.settings.isUseContactPage === 'true') {
                    userEditCtrl.tabs.push({
                        title: 'Contact Us',
                        state: 'contactUs',
                        icon: 'contact-us'
                    });
                }

                filterCountries();

                setTimeout(function() {
                    if (userEditCtrl.externalLoginSettings.isActive) {
                        disableFormElements('user-edit');
                    }
                }, 100);
            }

            function disableFormElements(formId) {
                var form = document.getElementById(formId);
                if (form) {
                    var elements = form.elements;
                    for (var i = 0; i < elements.length; i++) {
                        elements[i].disabled = true;
                        elements[i].style.background = '#eeeeee';
                    }
                }
            }

            function _setLoyaltyUserData(userData){
                userEditCtrl.retailerAccumulation = null;
                if (!userData) return;
                var userLoyaltyClub = Loyalty.getUserLoyaltyClub(userData);
                if (!userLoyaltyClub) return;
                Loyalty.getLastTransactions(userLoyaltyClub.id).then(function(transactions){
                    if(transactions) {
                        transactions.forEach(function(transaction){
                            var date = new Date(transaction.Date);
                            transaction.Month = date.toLocaleString('en-US', { month: 'long' });
                            transaction.Year = date.getFullYear();
                            transaction.TotalPointsTotalLoad = transaction.TotalPointsTotalLoad && transaction.TotalPointsTotalLoad.toFixed(1);
                            transaction.TotalApprovedSum = transaction.TotalApprovedSum && transaction.TotalApprovedSum.toFixed(1);
                        })
                        userEditCtrl.dataTransactions = transactions;
                    }
                })
                if (userData.loyaltyClubs.length > 0) { 
                    if (!Loyalty.getRetailerClubConfig(userLoyaltyClub.loyaltyClubId)) return;
                    userEditCtrl.retailerLoyaltyClubBanner = Loyalty.getRetailerClubConfig(userLoyaltyClub.loyaltyClubId).banners;
                    var loyaltyPageDesigns = Loyalty.getLoyaltyPageDesign(userLoyaltyClub.loyaltyClubId);
                    angular.forEach(loyaltyPageDesigns, function(pageDesign){
                        if(pageDesign.type === LOYALTY_PAGE_COMPONENT_TYPES.BY_NAME.ACCUMULATION_REDEMPTION){
                            userEditCtrl.retailerAccumulation = pageDesign;
                        }
                    })
                    Util.getUserCashbackPoints(userLoyaltyClub.id,1).then(function(response){
                        for (var lang in userEditCtrl.retailerAccumulation.content.markTitleText) {
                            if (userEditCtrl.retailerAccumulation.content.markTitleText.hasOwnProperty(lang)) {
                                userEditCtrl.retailerAccumulation.content.markTitleText[lang] = userEditCtrl.retailerAccumulation.content.markTitleText[lang].replace(/\{Total Points Amount\}/g, response.points);
                            }
                        }
                    });
                }
            }

            function _isOrganizationObligoType() {
                return userEditCtrl.user && userEditCtrl.user.organization && userEditCtrl.user.organization.organizationType === ORGANIZATION_TYPES.OBLIGO;
            }

            function _isOrganizationRegularPaymentType() {
                return userEditCtrl.user && userEditCtrl.user.organization && userEditCtrl.user.organization.organizationType === ORGANIZATION_TYPES.NO_OBLIGO;
            }

            function _setPhones(isCancel) {
                var phonesObj = {};

                if (!userEditCtrl.user) {
                    return;
                }

                if (userEditCtrl.user.phones) {
                    angular.forEach(userEditCtrl.user.phones, function (phone) {
                        if (phone.typeVal) {
                            phonesObj[phone.typeVal] = phone;
                            if (userEditCtrl.customPhoneValidation) {
                                phonesObj[phone.typeVal].areaCode = userEditCtrl.areaCodesAvailable ? phonesObj[phone.typeVal].areaCode : '';
                                phonesObj[phone.typeVal].countryCode = userEditCtrl.countryCodesAvailable ? phonesObj[phone.typeVal].countryCode : '';
                            } else {
                                phonesObj[phone.typeVal].areaCode = phone.areaCode ? phone.areaCode : '';
                                phonesObj[phone.typeVal].countryCode = phone.countryCode ? phone.countryCode : '';
                            }
                            compileCustomValidatedPhone(phone);
                        }
                    });
                }

                angular.forEach([PHONE_TYPES.HOME, PHONE_TYPES.WORK, PHONE_TYPES.MOBILE], function (type) {

                    phonesObj[type] = userEditCtrl.user.phonesObj && userEditCtrl.user.phonesObj[type] ? userEditCtrl.user.phonesObj[type] : phonesObj[type] || {};

                    phonesObj[type].typeVal = type;

                    if (userEditCtrl.areaCodesAvailable && Config.retailer.customPhoneValidation.areaCodes.length === 1) {
                        phonesObj[type].areaCode = Config.retailer.customPhoneValidation.areaCodes[0]
                    }

                    if (userEditCtrl.countryCodesAvailable && Config.retailer.customPhoneValidation.countryCodes.length === 1) {
                        phonesObj[type].countryCode = Config.retailer.customPhoneValidation.countryCodes[0]
                    }

                    if (userEditCtrl.customPhoneValidation && !phonesObj[type].phoneNumber) {
                        phonesObj[type].customValidated = true;
                    }

                    if (userEditCtrl.user.phonesObj) {
                        userEditCtrl.compileCustomValidatedPhone(userEditCtrl.user.phonesObj[type])
                    }
                });
                userEditCtrl.user.phonesObj = angular.copy(phonesObj);
                if (!isCancel) {
                    userEditCtrl.mobilePhone = userEditCtrl.user.phonesObj[PHONE_TYPES.MOBILE].customPhoneNumber
                    userEditCtrl.homePhone = userEditCtrl.user.phonesObj[PHONE_TYPES.HOME].customPhoneNumber
                    userEditCtrl.workPhone = userEditCtrl.user.phonesObj[PHONE_TYPES.WORK].customPhoneNumber

                    if (userEditCtrl.customPhoneValidation) {
                        userEditCtrl.editPhonesMode = {
                            1: !userEditCtrl.user.phonesObj[PHONE_TYPES.HOME].phoneNumber,
                            2: !userEditCtrl.user.phonesObj[PHONE_TYPES.WORK].phoneNumber,
                            3: !userEditCtrl.user.phonesObj[PHONE_TYPES.MOBILE].phoneNumber
                        }
                    }
                }
            }

            function _setValidatedPhoneNumbers(phone) {
                userEditCtrl.customValidatedPhoneNumbers[phone.typeVal] = [
                    phone.countryCode ? phone.countryCode : '',
                    phone.areaCode ? phone.areaCode : '',
                    phone.customPhoneNumber ? phone.customPhoneNumber : ''].join('-');
            }

            function compileCustomValidatedPhone(phone) {
                if (phone.customValidated) {
                    if (phone.phoneNumber && phone.customPhoneNumber !== '') {
                        _setValidatedPhoneNumbers(phone);
                    } else {
                        userEditCtrl.customValidatedPhoneNumbers[phone.typeVal] = '';
                    }


                } else {
                    userEditCtrl.customValidatedPhoneNumbers[phone.typeVal] = phone.phoneNumber;
                }
            }

            function phoneEditMode(phoneType, editMode) {
                if (!userEditCtrl.customPhoneValidation) {
                    return;
                }

                if (userEditCtrl.editPhonesMode[phoneType] && userEditCtrl.user.phonesObj[phoneType].customPhoneNumber === '' && editMode) {
                    return;
                }
                userEditCtrl.editPhonesMode[phoneType] = editMode;
                if (userEditCtrl.editPhonesMode[phoneType]) {
                    angular.forEach(userEditCtrl.user.phones, function (phone) {
                        if (phone.typeVal === phoneType) {
                             compileCustomValidatedPhone(phone);
                        }
                    })

                } else {
                    _setPhoneInputs(phoneType);
                    _setPhones(true);
                }
            }

            function _setPhoneInputs(phoneType) {
                switch (phoneType) {
                    case PHONE_TYPES.HOME : {
                        userEditCtrl.user.phonesObj[phoneType].customPhoneNumber = userEditCtrl.homePhone;

                        if(!userEditCtrl.user.phonesObj[phoneType].customPhoneNumber) {
                            userEditCtrl.user.phonesObj[phoneType] = '';
                            userEditCtrl.editPhonesMode[phoneType] = true;
                        }
                        break;
                    }
                    case PHONE_TYPES.WORK : {
                        userEditCtrl.user.phonesObj[phoneType].customPhoneNumber = userEditCtrl.workPhone;

                        if(!userEditCtrl.user.phonesObj[phoneType].customPhoneNumber) {
                            userEditCtrl.user.phonesObj[phoneType] = '';
                            userEditCtrl.editPhonesMode[phoneType] = true;
                        }
                        break;
                    }
                    case PHONE_TYPES.MOBILE : {
                        userEditCtrl.user.phonesObj[phoneType].customPhoneNumber = userEditCtrl.mobilePhone;

                        if(!userEditCtrl.user.phonesObj[phoneType].customPhoneNumber) {
                            userEditCtrl.user.phonesObj[phoneType] = '';
                            userEditCtrl.editPhonesMode[phoneType] = true;
                        }
                        break;
                    }
                    default:
                        return;
                }
            }

            function _checkHasCreditCardMethod() {
                return !!angular.filterCollection(Config.retailer.checkoutPaymentMethods, function (paymentMethod) {
                    return (paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD && Config.creditCardConfig && Config.creditCardConfig.mode === 'TOKEN');
                }).length;
            }

            function _checkHasPayPalMethod() {
                return !!userEditCtrl.user && !!userEditCtrl.user.paypalAccounts && !!userEditCtrl.user.paypalAccounts.length && !!angular.filterCollection(Config.retailer.checkoutPaymentMethods, function (paymentMethod) {
                    return paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.PAYPAL;
                }).length;
            }

            function validateCountryCode(countryCode, phoneNumber) {
                if (userEditCtrl.formSubmited && phoneNumber) {
                    return Config.retailer.customPhoneValidation.countryCodes.some(function (code) {
                        return code == countryCode;
                    });
                }
                return true;
            }

            function validateAreaCode(areaCode, phoneNumber) {
                if (userEditCtrl.formSubmited && phoneNumber) {
                    return Config.retailer.customPhoneValidation.areaCodes.some(function (code) {
                        return code == areaCode;
                    });
                }
                return true;
            }


            function openAutocompleteAddress(evt) {
                evt.preventDefault();
                return Dialog.show({
                    templateUrl: 'template/dialogs/choose-address/index.html',
                    controller: ['$scope', function ($dialogScope) {
                        var dialogCtrl = this;
                        Util.getAddressByZipCode(userEditCtrl.user.addresses[0].zipCode).then(function (result) {
                            dialogCtrl.addresses = result;
                            dialogCtrl.errorMessage = !result || !result.length;
                        })

                        $dialogScope.continue = function () {
                            if (dialogCtrl.addresses && dialogCtrl.addresses.length && !dialogCtrl.address) {
                                return;
                            }

                            delete dialogCtrl.address.description;
                            userEditCtrl.user.addresses[0] = Object.assign(userEditCtrl.user.addresses[0], dialogCtrl.address);
                            _checkAddressFields();
                            Util.removeErrorsFromInputs();
                            Dialog.hide();
                        };
                        $dialogScope.cancel = function () {
                            Dialog.hide();
                        };
                    }],
                    controllerAs: 'dialogCtrl',
                    styleClass: 'choose-address',
                    ariaLabelledby: 'choose_address_dialog_title',
                })
            }

            function filterCountries() {
                if(userEditCtrl.user && userEditCtrl.user.addresses && userEditCtrl.user.addresses.length && userEditCtrl.user.addresses[0].country) {
                    userEditCtrl.countryNames = Util.getCountryAutocompleteOptions().filter(function (country) {
                        return country.toLowerCase().indexOf(userEditCtrl.user.addresses[0].country.toLowerCase()) !== -1;
                    });
                }
            }

            function loadSpecials(from, size, callback) {
                Specials.getSpecials(Specials.prepareSearchParams({
                    from: from,
                    size: size,
                    filters: {
                        must: {
                            exists: ['loyaltyClubsIds']
                        }
                    }
                })).then(function (specials) {
                    angular.forEach(specials.specials, function (special) {
                        if (special.item) {
                            special.item = Cart.getProduct(special.item);
                        }
                    });

                    callback(null, specials.total, specials.specials);
                }).catch(function (err) {
                    callback(err);
                });
            }

            function openSpecial(special) {
                if (special.item) {
                    return Util.openProduct(special.item);
                }

                $state.go('app.specials.special', {sid: special.id});
            }

            function onCartLinesAdd(carouselSpecials) {
                angular.forEach(carouselSpecials, function (special) {
                    special.item && (special.item = Cart.getProduct(special.item));
                });
            }

            function _checkAddressFields() {
                if (userEditCtrl.user && userEditCtrl.user.addresses && userEditCtrl.user.addresses.length) {
                    userEditCtrl.user.hasAddressDetails = Util.checkAddressFields(userEditCtrl.user.addresses[0]);
                }
            }

            function getCityAutoCompleteOptions(city) {
                if (!city) {
                    return $q.resolve();
                }

                return Api.request({
                    method: 'GET',
                    url: '/v2/addresses/cities',
                    params: {
                        term: city,
                        size: 8
                    }
                }).then(function (data) {
                    userEditCtrl.citiesObject = {};
                    return data.cities.map(function (city) {
                        userEditCtrl.citiesObject[city.name] = city;
                        return city.name;
                    });
                });
            }

            function resetUser(userData) {
                userEditCtrl.user = userData;
                userEditCtrl.hasCreditCardMethod = _checkHasCreditCardMethod();
                userEditCtrl.hasPayPalMethod = _checkHasPayPalMethod();
                if (userData && userData.address) {
                    userEditCtrl.savedUserAddress = angular.copy(userData.address, {});
                }

                _setPhones();
                _checkAddressFields();
                _setLoyaltyUserData(userEditCtrl.user);
            }

            Util.currentScopeListener($scope, $rootScope.$on('login', function(){
                User.getData(true).then(function (userData) {
                    resetUser(userData);
                });
            }));

            Util.currentScopeListener($scope, $rootScope.$on('logout', function(){
                resetUser(null);
            }))

            Util.currentScopeListener($scope, $rootScope.$on('changeLanguage', function(){
                if (userEditCtrl.externalLoginSettings.isActive) {
                    disableFormElements('user-edit');
                }
            }));

            function switchTab() {
                // this functionality was implemented to support accessbility technology
                // Target: Focus to active screen when switching tab
                setTimeout(function() {
                    var mainDisplayTab = document.querySelector('[name="tab"] #page_title');
                    if (mainDisplayTab) {
                        mainDisplayTab.focus();
                    }
                }, 200);
            }

            function openAccumulationRedemptionDialog(){
                return Dialog.show({
                    templateUrl: 'template/dialogs/accumulation-redemption-history/index.html',
                    controller: ['$scope', function ($dialogScope) {
                        var dialogCtrl = this;

                        dialogCtrl.content = userEditCtrl.retailerAccumulation.content;
                        dialogCtrl.banner = userEditCtrl.retailerLoyaltyClubBanner;
                        dialogCtrl.dataTransactions = userEditCtrl.dataTransactions;
                        dialogCtrl.accumulationDefaultText = userEditCtrl.accumulationDefaultText;
                        $dialogScope.cancel = function () {
                            Dialog.hide();
                        };
                    }],
                    controllerAs: 'dialogCtrl',
                    styleClass: 'accumulation-redemption-history-dialog',
                    ariaLabelledby: 'accumulation-redemption-title',
                });
            }

            var stateChangeWatcher = $scope.$watch(function() {
                return $state.current;
            }, function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    userEditCtrl.currentState = newVal;
                }
            }, true);
        
            $scope.$on('$destroy', function() {
                stateChangeWatcher();
            });
        }
    ]);
})(angular, app);
