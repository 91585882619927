(function (angular, app) {
    'use strict';

    app.controller('AddToShopListCtrl', [
        '$scope', 'Dialog', 'ShopListService', 'lines',
        function ($scope, dialog, shopListService, lines) {
            var addToShopListCtrl = this;

            addToShopListCtrl.createNewList = false;
            addToShopListCtrl.chooseList = chooseList;
            addToShopListCtrl.createList = createList;

            shopListService.get().then(function (res) {
                addToShopListCtrl.shopLists = res.shopLists;
            });

            function chooseList() {
                shopListService.patchItems(addToShopListCtrl.shopList.id, lines).then(function () {
                    dialog.hide();
                });
            }

            function createList() {
                shopListService.create(addToShopListCtrl.name, lines).then(function () {
                    dialog.hide();
                });
            }
        }]);
})(angular, app);