(function (angular, app) {
    'use strict';

    app.controller('UserDeleteConfirmationCtrl', [
        '$scope', 'user', 
        function ($scope, user) {
            var UserDeleteConfirmationCtrl = this;
            UserDeleteConfirmationCtrl.user = user;

            UserDeleteConfirmationCtrl.ok = function () {
                $scope.$dialog.hide({
                    isNeedToDelete: true,
                    userId: UserDeleteConfirmationCtrl.user.id
                });
            };

            UserDeleteConfirmationCtrl.cancel = function () {
                $scope.$dialog.hide({
                    isNeedToDelete: false,
                    userId: UserDeleteConfirmationCtrl.user.id
                });
            };
        }]);
})(angular, app);
