
(function (angular, app) {
    'use strict';

    app.directive('spPlaceholder', [
        '$timeout',
        function ($timeout) {
            /**
             * Determine if a given type (string) is a password field
             *
             * @param {string} type
             * @return {boolean}
             */
            function isPasswordType(type) {
                return type && type.toLowerCase() === 'password';
            }

            return {
                restrict: 'A',
                require: '^ngModel',
                scope: {
                    spPlaceholder: '@',
                    ngModel: '='
                },
                link: function ($scope, $element, $attributes, $controller) {
                    var currentValue;

                    // This does the class toggling depending on if there
                    // is a value or not.
                    $scope.$watch('ngModel', function (newVal) {
                        currentValue = newVal || '';

                        if (!currentValue && document.activeElement != $element[0]) {
                            $element.addClass('placeholder');
                        } else {
                            $element.removeClass('placeholder');
                        }
                    });

                    $element.bind('focus', function () {
                        if (currentValue === '') {
                            // Remove placeholder text
                            $element.val('');
                            $element.removeClass('placeholder');
                        }
                    });
                    $element.bind('blur', function () {
                        if ($element.val() === '') {
                            // Add placeholder text
                            $element.val($scope.spPlaceholder);
                            $element.addClass('placeholder');
                        }
                    });

                    $scope.$root.$on('config.language.change', function() {
                        if ($element.hasClass('placeholder')) {
                            $timeout(function() { $element.val($scope.spPlaceholder); });
                        }
                    });

                    // This determines if we show placeholder text or not
                    // when there was a change detected on scope.
                    $controller.$formatters.unshift(function (val) {
                        /* Do nothing on password fields, as they would
                         * be filled with asterisks.  Issue #2.
                         */
                        if (isPasswordType($element.prop('type'))) {
                            return val;
                        }

                        // Show placeholder text instead of empty value
                        return val || $scope.spPlaceholder;
                    });
                }
            };
        }]);

})(angular, app);