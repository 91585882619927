(function (angular, app) {
    'use strict';

    var COUNTER = 0;

    app.directive('spRadio', [
        '$parse', '$sce', '$compile', 'Config', 'Util', '$timeout', '$rootScope',
        function ($parse, $sce, $compile, config, util, $timeout, $rootScope) {
            return {
                restrict: 'E',
                replace: true,
                scope: true,
                template: function (element, attrs) {
                    var html = util.trim(element.html());
                    return '' +
                        '<div class="sp-radio-buttons" tabindex="0" role="radiogroup" aria-label="'+(attrs.ariaLabel || '')+'">' +
                        '   <label class="sp-radio-option" ' +
                        '       ng-repeat="option in ' + (attrs.options || '_innerSpRadioData.singleOption') + '" ' +
                        '       ng-class="{ checked:_innerSpRadioData.value == ' + (attrs.valueKey || 'option.value') + ' }"><!--' +
                        '       --><span class="input-wrapper">' +
                        '           <input type="radio" role="radio" ' +
                        '               ng-focus="_innerSpRadioData.onFocus($event)" ' +
                        '               ng-blur="_innerSpRadioData.onBlur($event)" ' +
                        '               ng-model="_innerSpRadioData.value" ' +
                        '               ng-disabled="' + attrs.spDisabled + '" ' +
                        '               ng-value="' + (attrs.valueKey || 'option.value') + '"' +
                        '               tabindex="{{((_innerSpRadioData.value == ' + (attrs.valueKey || 'option.value') + ') || (!_innerSpRadioData.value && $index === 0)) ? 0 : -1}}" ' +
                        '               aria-checked="{{_innerSpRadioData.value == ' + (attrs.valueKey || 'option.value') + ' ? true : false}}" ' +
                        '               aria-label="' + ( attrs.spAriaLabel || '{{(option.label || \'\') | translate}}') + '"'+
                        '               name="' + (attrs.spName || 'sp-radio-' + (COUNTER++)) + '"/>' +
                        '       </span><!--' +
                        '       --><span class="label">' + (html || '{{(option.label || \'\') | translate}}') + '</span><!--' +
                        '       --><div class="help-text"' + (attrs.helpText ? '' : ' ng-if="option.helpText"') + '>' +
                        '           <div class="arrow"><img src="https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/full-arrow-down.png" alt=""/></div>' +
                        '           <div class="text">' + (attrs.helpText || '{{option.helpText | translate}}') + '</div>' +
                        '       </div>' +
                        '   </label>' +
                        '</div>';
                },
                link: function link($scope, element, attrs) {
                    var model = $parse(attrs.spModel),
                        _valueOuterChange = false;

                    $scope._innerSpRadioData = {
                        value: model($scope.$parent),
                        onFocus: onFocus,
                        onBlur: onBlur
                    };

                    if (!attrs.options && attrs.spValue) {
                        $scope._innerSpRadioData.singleOption = [{value: $scope.$eval(attrs.spValue)}];
                    }

                    $scope.$parent.$watch(attrs.spModel, function (newValue) {
                        if ($scope._innerSpRadioData.value !== newValue) {
                            $scope._innerSpRadioData.value = newValue;
                            _valueOuterChange = true;
                        }
                    });

                    $scope.$watch('_innerSpRadioData.value', function(newVal, oldVal) {
                        if (_valueOuterChange) {
                            _valueOuterChange = false;
                            return;
                        }

                        model.assign($scope.$parent, $scope._innerSpRadioData.value);
                        if (attrs.spChange && newVal !== oldVal) {
                            $scope.$eval(attrs.spChange, {$value: newVal});
                        }
                    });

                    function onFocus(event) {
                        angular.element(event.currentTarget.parentElement.parentElement).addClass('focus');
                    }

                    function onBlur(event) {
                        angular.element(event.currentTarget.parentElement.parentElement).removeClass('focus');
                    }
                }
            };
        }]);
})(angular, app);