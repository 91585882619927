(function (angular, app) {
    'use strict';

    app.directive('kikarMenuChange', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
        return {
            restrict: 'A',
            scope: {
                showLinksCount: '<showLinksCount'
            },
            link: function($scope, $element, $attrs) {

                var menuElement = $element[0],
                    navigationBarElem = document.getElementById('menu-navigation-bar'),
                    nextDeliveryWrapperElem  = document.getElementById('next-delivery-wrapper'),
                    noAvailableSlotsWrapperElem  = document.getElementById('no-available-slots-wrapper'),
                    categoriesTitleElem  = document.getElementById('categories-title'),
                    hamburgerOuterElem  = document.getElementById('menu-hamburger-out'),
                    menuHiddenLinksElem  = document.getElementById('menu-hidden-links'),
                    hiddenLinksCountElem  = document.getElementById('hidden-links-count'),
                    nextDeliveryWrapperWidth = 0,
                    hamburgerWidth = 70,
                    widthOffset = -50,
                    inProgress = false,
                    showLinksCount = $attrs.showLinksCount === 'true';

                init();

                function init() {
                    if(showLinksCount) {
                        hamburgerOuterElem.classList.add("show-links-count");
                    }

                    $scope.$watch(function () {
                        onNextDeliveryElemChange();
                        return menuElement.offsetWidth;
                    }, function(newElementWidth, oldElementWidth) {
                        onWidthChange(newElementWidth, oldElementWidth);
                    });
                }

                //== When next delivery item changed we also need to re-calculate
                function onNextDeliveryElemChange() {
                    var newWidth = getNextDeliveryWrapperWidth();
                    if(newWidth !== nextDeliveryWrapperWidth) {
                        changeMenu();
                    }
                    nextDeliveryWrapperWidth = newWidth;
                }

                //== triggered when menu width been changed (links added to menu on login, location changed, etc.)
                function onWidthChange(newElementWidth, oldElementWidth) {
                    if(newElementWidth !== oldElementWidth) {
                       changeMenu();
                    }
                }

                function changeMenu() {
                    if(inProgress) {
                        return;
                    }

                    inProgress = true;
                    checkAndChange();
                    inProgress = false;
                }

                function checkAndChange() {
                    resetMenuItems();
                    createHamburgerMenu();
                }

                //== check is menu and all elements is bigger than container
                function checkIsNeededToHide(offset) {
                    return  menuElement.offsetWidth - getCategoriesTitleWidth() - getMenuNavigationBarWidth() - getNextDeliveryWrapperWidth() - getAvailableSlotsWrapperWidth() - offset <= 0;
                }

                function getNextDeliveryWrapperWidth() {
                    return nextDeliveryWrapperElem.offsetWidth || 0;
                }

                function getAvailableSlotsWrapperWidth() {
                    return noAvailableSlotsWrapperElem && noAvailableSlotsWrapperElem.offsetWidth || 0;
                }

                function getMenuNavigationBarWidth() {
                    return navigationBarElem.offsetWidth || 0;
                }

                function getCategoriesTitleWidth() {
                    return categoriesTitleElem.offsetWidth || 0;
                }

                //== reset menu items to default state
                function resetMenuItems() {

                    //== hide hamburger menu icon
                    hamburgerOuterElem.style.display = 'none';

                    //== remove previously added elements, have to do it by ID and not by saved pointer
                    document.getElementById('menu-hidden-links').innerHTML = '';

                    //== get all menu elements
                    var menuItems = [].slice.call(navigationBarElem.children, 0);

                    //== set all menu elements visible
                    for(var i = 0; i < menuItems.length; i++) {
                        if( menuItems[i].classList.contains('menu-hidden') ){
                            menuItems[i].style.display = '';
                        }
                    }
                }

                function createHamburgerMenu() {
                    //== get menu items in reversed order - we hiding them from the last
                    var menuItems = [].slice.call(navigationBarElem.children, 0).reverse(),
                        toHideItems = [];

                    //== start hiding items one by one until they fit, saving every hidden item
                    for(var i = 0; i < menuItems.length && checkIsNeededToHide(widthOffset + hamburgerWidth); i++) {
                        if( !menuItems[i].classList.contains('menu-hamburger-out') ) {
                            toHideItems.push(menuItems[i].cloneNode(true));
                            menuItems[i].classList.add('menu-hidden');
                            menuItems[i].style.display = 'none';
                        }
                    }

                    //== create a hamburger
                    if(toHideItems.length) {
                        var reversedItems = toHideItems.reverse();

                        for(var j = 0; j < reversedItems.length; j++) {
                            menuHiddenLinksElem.appendChild(reversedItems[j]);
                        }

                        hiddenLinksCountElem.innerText = reversedItems.length;
                        hamburgerOuterElem.style.display = 'inline-block';
                    }
                }

                //== page load trigger - when delivery times been loaded into top menu
                $rootScope.$on('header.times.finished', function() {
                    $timeout(function() {
                        changeMenu();
                    }, 100);
                });

                //== when language changed need to rebuild menu - length of the titles are different
                $rootScope.$on('config.language.change', function() {
                    $timeout(function() {
                        changeMenu();
                    }, 100);
                });

                //$rootScope.$on('login',
                $rootScope.$on('logout', function() {
                    $timeout(function() {
                        changeMenu();
                    }, 100);
                });
            }
        };
    }]);

})(angular, app);