(function (angular, app) {
    'use strict';

    app.filter('twoDigits', [function () {
        return function (input) {
            if (!angular.isNumber(input)) {
                return input;
            }
            return (input < 9 ? '0' + input : input.toString());
        };
    }]);

})(angular, app);
