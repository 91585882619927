(function (angular, app) {
    'use strict';

    var CAROUSEL_PRODUCT_FILTERS = {
        "must": {
            "exists": ["family.id", "family.categoriesPaths.id", "branch.regularPrice"],
            "term": {
              "branch.isActive": true,
              "branch.isVisible": true,
            }
        },
        mustNot: {
            term: {
                'branch.regularPrice': 0
            }
        }
    };

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.home', {
                url: '/',
                data: {
                    name: 'home'
                },
                metaTags: {
                    title: ''
                },
                views: {
                    '@': {
                        controller: 'HomeCtrl as homeCtrl',
                        templateUrl: 'template/views/home/index.html'
                    }
                }
            });
        }])
        .controller('HomeCtrl', [
            '$scope', '$rootScope', '$q', '$timeout', '$state', 'Config', 'Api', 'Util', 'Cart', 'User', 'Orders',
            'Bubble', 'Specials', 'Products', 'PermanentFilters', 'SpHomePageComponents', 'ORDER_STATUS_STAGES',
            'HOME_PAGE_CAROUSEL_DISPLAY_TYPES', 'BANNER_HEIGHT', 'SP_HOME_PAGE_COMPONENT_TYPES', 'Loyalty',
            function ($scope, $rootScope, $q, $timeout, $state, Config, Api, Util, Cart, User, Orders,
                Bubble, Specials, Products, PermanentFilters, SpHomePageComponents, ORDER_STATUS_STAGES,
                HOME_PAGE_CAROUSEL_DISPLAY_TYPES, BANNER_HEIGHT, SP_HOME_PAGE_COMPONENT_TYPES, Loyalty) {
                    var homeCtrl = this,
                    _componentsScroll = SpHomePageComponents.scroll();

                var _replacement_premium_id = 14,
                    _isRetailerPremiumReplacementsEnabled = Config.retailer.premiumFeaturesEnabled.includes(_replacement_premium_id);

                    
                homeCtrl.user = User;
                homeCtrl.components = [];
                homeCtrl.SpHomePageComponents = SpHomePageComponents;
                homeCtrl.loadComponents = loadComponents;
                homeCtrl.initVideo = initVideo;
                homeCtrl.videoIsPlaying = false;
                homeCtrl.numberOfCoupons = 0;
                homeCtrl.language = Config.language.culture;
                homeCtrl.retailerLoyaltyDriverConfig = Loyalty.getRetailerClubDriver();
                homeCtrl.retailerLoyaltyClubConfig = null;
                homeCtrl.userLoyaltyClub = null;
                homeCtrl.loyaltyClubDriver = Loyalty.getRetailerClubDriver();
                homeCtrl.pointRedemptionCPNIndex = null;
                homeCtrl.slidePrev = slidePrev;
                homeCtrl.slideNext = slideNext;

                _addSuggestionsBackwardsComponent();
                loadComponents();
                _showHomePageBubble();

                $rootScope.$on(
                  "numberOfCouponsHomepage",
                  function (event, data) {
                    homeCtrl.numberOfCoupons = data.numberOfCoupons;
                  }
                );

                $scope.$watch(
                    function () {
                      return Config.language.culture;
                    },
                    function (newCulture, oldCulture) {
                        homeCtrl.language = newCulture;
                    }
                );

                $rootScope.$on('finishLoadHomeComponent', function(){
                    // query  what is first compnent will show
                    for(var i = 0; i < homeCtrl.components.length; i++){
                        var cpn = homeCtrl.components[i];
                        if((!cpn.hasNoItems) && homeCtrl.SpHomePageComponents.isCarouselComponent(cpn.data)){
                            if(i !== homeCtrl.pointRedemptionCPNIndex){
                                homeCtrl.pointRedemptionCPNIndex = i;
                            }
                            break;
                        }
                    }
                })

                // TODO: Better to switch from px to em for consistency
                var homePageImageHeight = Config.retailer.settings.homePageImageHeight ? parseInt(Config.retailer.settings.homePageImageHeight, 10) : 2
                homeCtrl.bannerHeight = homePageImageHeight === 1 ? BANNER_HEIGHT.LARGE : homePageImageHeight === 2 ? BANNER_HEIGHT.MEDIUM : BANNER_HEIGHT.SMALL
                homeCtrl.bannerHeight = homeCtrl.bannerHeight + 'px';

                if (homeCtrl.retailerLoyaltyDriverConfig) {
                    homeCtrl.retailerLoyaltyClubConfig = Loyalty.getRetailerDefaultClub() && Loyalty.getRetailerDefaultClub().loyaltyPages;
                }

                User.getData().then(function (data) {
                    if (!data) return;
                    homeCtrl.userLoyaltyClub = Loyalty.getUserLoyaltyClub(data);
                    if (!homeCtrl.userLoyaltyClub) return;
                    if (data.loyaltyClubs.length > 0 && homeCtrl.loyaltyClubDriver) { 
                        if (!Loyalty.getRetailerClubConfig(homeCtrl.userLoyaltyClub.loyaltyClubId)) return;
                        homeCtrl.retailerLoyaltyClubConfig = Loyalty.getRetailerClubConfig(homeCtrl.userLoyaltyClub.loyaltyClubId).loyaltyPages;
                    }
                });

                Util.currentScopeListener($scope, $rootScope.$on('document.scroll.end', function(data, event) {
                    loadComponents().finally(function() {
                        event.done();
                    });
                }));

                function loadComponents() {
                    if (homeCtrl.loadingComponentsPromise) {
                        return homeCtrl.loadingComponentsPromise;
                    }

                    return homeCtrl.loadingComponentsPromise = _componentsScroll.next().then(function(components) {
                        angular.forEach(components, _addComponent);
                    }).finally(function() {
                        $scope.$broadcast('homePage.components.loaded');
                        delete homeCtrl.loadingComponentsPromise;
                    });
                }

                function initVideo() {
                    if (!Config.retailer.homePageActiveVideo || !Config.retailer.homePageActiveVideo.url) {
                        return;
                    }

                    _loadAMPLibrary().then(function() {
                        homeCtrl.videoIsPlaying = false;
                        var player = amp(
                            //this is needed for dynamic html load do NOT just sent id (rather send element itself)
                            document.getElementById('azuremediaplayer'), {
                                "nativeControlsForTouch": false,
                                autoplay: true,
                                fluid: true,
                                enabled: false
                                //controls: false,
                            });

                        player.ready(function() {
                            this.addEventListener('play', function() {
                                $timeout(function() {
                                    homeCtrl.videoIsPlaying = true;
                                }, 200);
                            });
                        });

                        player.src([
                            {
                                "src": Config.retailer.homePageActiveVideo.url,
                                "type": "application/vnd.ms-sstr+xml"
                            }
                        ]);
                    });
                }

                function slidePrev(componentId) {
                    $scope.$broadcast('home.carousel.prev', {
                        componentId: componentId
                    });
                }
                
                function slideNext(componentId) {
                    $scope.$broadcast('home.carousel.next', {
                        componentId: componentId
                    });
                }

                function _loadAMPLibrary() {
                    return $q.all([
                        _loadTag('script', {
                            src: 'https://d226b0iufwcjmj.cloudfront.net/azure-media-player/azuremediaplayer.min.js',
                            id: 'amp-azure-media-scripts'
                        }),
                        _loadTag('link', {
                            href: 'https://d226b0iufwcjmj.cloudfront.net/azure-media-player/azuremediaplayer.min.css',
                            id: 'amp-azure-media-styles',
                            rel: 'stylesheet'
                        })
                    ]);
                }

                function _loadTag(type, attributes) {
                    if (attributes.id && document.getElementById(attributes.id)) {
                        return $q.resolve();
                    }

                    var deferred = $q.defer(),
                        tag = document.createElement(type);

                    angular.forEach(attributes, function(value, key) {
                        tag.setAttribute(key, value);
                    });

                    document.head.appendChild(tag);
                    tag.addEventListener('load', deferred.resolve);
                    return deferred.promise;
                }

                function _addSuggestionsBackwardsComponent() {
                    if (!Config.retailer.isHomePageSuggestionsActive) {
                        return;
                    }

                    var component = {
                        id: 0,
                        names: Config.retailer.homePageSuggestionsDisplayName ? {
                            2: { title: Config.retailer.homePageSuggestionsDisplayName }
                        } : {
                            1: { title: 'מבצעים מובילים' },
                            2: { title: 'Today\'s Special Deals' },
                            3: { title: 'ofertas especiales para hoy' },
                            4: { title: 'Лучшие предложения' },
                            5: { title: 'Meilleures offres' },
                            6: { title: 'صفقات اليوم الخاصة' }
                        },
                        items: [{
                            href: $state.href('app.specials'),
                            names: {
                                1: { name: 'לכל המבצעים' },
                                2: { name: 'Go To Specials' },
                                3: { name: 'A todas las ofertas' },
                                4: { name: 'Ко всем скидкам' },
                                5: { name: 'Vers toutes les promotions' },
                                6: { name: 'انتقل إلى العروض الخاصة' }
                            }
                        }]
                    };
                    if (Config.retailer.homePageCarousel === HOME_PAGE_CAROUSEL_DISPLAY_TYPES.PRODUCTS) {
                        component.productsFilters = JSON.stringify({ filters: { must: { term: { isOnHomePageCarousel: true } } } });
                        component.typeId = SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL;
                    } else {
                        component.typeId = SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL;
                    }
                    _addComponent(component);
                }

                function _addComponent(component) {
                    homeCtrl.components.push(new HomePageComponent(component, _loadOnItemsLessComponent));
                }

                function _loadOnItemsLessComponent(component) {
                    if (!component.hasNoItems) {
                        return;
                    }

                    // if a component with items does not exist, load more
                    if (!homeCtrl.components.find(function(component) {
                        return !component.hasNoItems;
                    })) {
                        loadComponents();
                    }
                }

                function _showHomePageBubble() {

                    return _getLastOrder().then(function(order) {
                        var messages = _getHomePageMessages();

                        //== no order and no messages
                        if( !order && !Object.keys(messages).length) {
                            return;
                        }

                        //== there some messages but the bubble was previously closed
                        if(!order && $rootScope.homePageBubbleClosed) {
                            return;
                        }

                        return $timeout(angular.noop, 0).then(function() {
                            return Bubble.show({
                                id: 'home-page-bubble',
                                controller: 'HomePageBubbleCtrl as homePageBubbleCtrl',
                                templateUrl: 'template/views/home/partials/home-page-bubble/index.html',
                                showClass: 'home-page-bubble',
                                nextTo: document.querySelector('body > header'),
                                resolve: {
                                    hpMessages: messages,
                                    hasOrder: !!order
                                },
                                position: {
                                    bottom: 20
                                }
                            });
                        })
                    });
                }

                function _getLastOrder() {
                    if (!User.session.userId) {
                        return $q.resolve(false);
                    }

                    return Orders.getLastOrder().then(function (order) {
                        if ($scope.$$destroyed || !order) {
                            return;
                        }

                        var shippingTimeTo = new Date(order.shippingTimeTo);
                        shippingTimeTo.setMinutes(shippingTimeTo.getMinutes() + shippingTimeTo.getTimezoneOffset());
                        if (Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.FINISH) || Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.CANCELLED) || shippingTimeTo < new Date()) {
                            return;
                        }

                        Orders.lastOrder = order;
                        return order;
                    });
                }

                function _getHomePageMessages() {
                    if( !(Config && Config.retailer && Config.retailer.homePageMessages) ) {
                        return {};
                    }

                    var response = {};

                    //== Run for all messages and fill data for each message's language
                    angular.forEach(Config.retailer.homePageMessages, function(message) {
                        response[message.languageId] = message;
                    });

                    return response;
                }

                Util.currentScopeListener($scope, $rootScope.$on('login', function () {
                    User.getData().then(function (data) {
                        if (!data) return;
                        homeCtrl.userLoyaltyClub = Loyalty.getUserLoyaltyClub(data);
                        if (!homeCtrl.userLoyaltyClub) return;
                        if (data.loyaltyClubs.length > 0 && homeCtrl.loyaltyClubDriver) { 
                            if (!Loyalty.getRetailerClubConfig(homeCtrl.userLoyaltyClub.loyaltyClubId)) return;
                            homeCtrl.retailerLoyaltyClubConfig = Loyalty.getRetailerClubConfig(homeCtrl.userLoyaltyClub.loyaltyClubId).loyaltyPages;
                        }
                    });
                }));

                Util.currentScopeListener($scope, $rootScope.$on('login', _showHomePageBubble));

                function HomePageComponent(dbComponent, onload) {
                    var self = this,
                        _productsGetter;

                    self.data = dbComponent;

                    if (SpHomePageComponents.isSingleItemComponent(self.data)) {
                        self.isLoadingComponent = true;
                    } else {
                        $timeout(function() {
                            _finishLoadingComponent(self.data.items && self.data.items.length);
                        });
                    }

                    self.carousel = {
                        loadItems: _getCarouselLoadItems(),
                        actions: _getCarouselActions(),
                        watchers: _getCarouselWatchers(),
                        data: _getCarouselData(),
                        templateUrl: _getCarouselTemplateUrl()
                    };

                    PermanentFilters.subscribe(_resetCarousel, $scope);

                    function _getCarouselLoadItems() {
                        if (self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL ||
                            self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL) {
                            return _loadProducts;
                        } else if (self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL) {
                            return _loadSpecials;
                        } else if (self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.LINKS_CAROUSEL) {
                            return _loadLinks;
                        }
                    }

                    function _loadProducts(from, size, callback) {
                        _productsGetter = _productsGetter || SpHomePageComponents.componentProductsGetter(self.data);

                        if (Config.retailer.settings.showUnavailableProducts !== 'true') {
                            CAROUSEL_PRODUCT_FILTERS.mustNot.term['branch.isOutOfStock'] = true;
                        }
                        else {
                            if (Config.retailer.settings.isOutOfStockDuration === 'true') {
                                CAROUSEL_PRODUCT_FILTERS.bool = {
                                    "should": [
                                        {
                                            "bool": {
                                                "must_not": {
                                                    "exists": {
                                                        "field": "branch.outOfStockShowUntilDate"
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "bool": {
                                                "must": [
                                                    {
                                                    "range": {
                                                        "branch.outOfStockShowUntilDate": {
                                                        "gt": "now"
                                                        }
                                                    }
                                                    },
                                                    {
                                                    "term": {
                                                        "branch.isOutOfStock": true
                                                    }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            "bool": {
                                                "must": [
                                                    {
                                                    "term": {
                                                        "branch.isOutOfStock": false
                                                    }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        }
                        var params = Products.prepareSearchParams({ filters: angular.copy(CAROUSEL_PRODUCT_FILTERS) });
                        _productsGetter.get(from, size, params.filters).then(function(resp) {
                            _finishLoadingComponent(resp.total);
                            Util.setProductIndexPosition(resp.products);
                            var chunks = resp.products;
                            if (_isRetailerPremiumReplacementsEnabled) {
                                var linesForSuggestions = [];
                                
                                var count = 0;
                                angular.forEach(chunks, function (chunk) {
                                    count++;
                                    if (chunk && chunk.branch && chunk.branch.isOutOfStock) {
                                        linesForSuggestions.push(chunk);
                                    }
                                });

                                if (linesForSuggestions.length) {
                                    Util.setReplacements(linesForSuggestions);
                                }
                            }
                            callback(null, resp.total, _setProducts(chunks));
                        }).catch(function(err) {
                            callback(err);
                        });
                    }

                    function _setProducts(products) {
                        angular.forEach(products, function(product) {
                            Cart.getProduct(product);
                            Util.getCategoryActualTypeByProductData(product).then(function(type) {
                                product.categoryType = type;
                            });
                        });
                        return products;
                    }

                    function _loadSpecials(from, size, callback) {
                        Specials.getSpecials(Specials.prepareSearchParams({
                            from: from,
                            size: size
                        })).then(function (specials) {
                            _finishLoadingComponent(specials.total);
                            $rootScope.$emit('totalSpecialsCount', specials.total);

                            _setSpecialsCartProducts(specials.specials);
                            if (_isRetailerPremiumReplacementsEnabled && specials.specials) {
                                specials.specials.forEach(function(sub){
                                    if (sub.item && sub.item.branch.isOutOfStock == true) {
                                        User.getReplacementSuggestions([sub.item.id], []).then(function(data) {
                                            if (data.length > 0 && data[0].suggestions) {
                                                sub.item._suggestions = data[0].suggestions;
                                            }
                                        })
                                    }
                                });
                            }
                            callback(null, specials.total, specials.specials);
                        }).catch(function(err) {
                            callback(err);
                        });
                    }

                    function _loadLinks(from, size, callback) {
                        callback(null, self.data.items.length, self.data.items.slice(from, from + size));
                    }

                    function _finishLoadingComponent(total) {
                        if (!total) {
                            self.hasNoItems = true;
                        }

                        if (self.isLoadingComponent !== false) {
                            self.isLoadingComponent = false;
                            onload(self);
                        }
                        $rootScope.$emit('finishLoadHomeComponent', self);
                    }

                    function _resetCarousel() {
                        if (!SpHomePageComponents.isSingleItemComponent(self.data)) {
                            return;
                        }

                        if (self.carousel.actions.reset) {
                            if (self.hasNoItems) {
                                self.hasNoItems = false;
                                self.isLoadingComponent = true;
                            }

                            if (_productsGetter) {
                                _productsGetter.reset();
                            }

                            self.carousel.actions.reset();
                        }
                    }

                    function _getCarouselActions() {
                        var actions = {};

                        if (self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL ||
                            self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL) {
                            actions.openCarouselItem = Util.openProduct;
                        } else if (self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL) {
                            actions.openCarouselItem = function(special) {
                                if (special.item) {
                                    return Util.openProduct(special.item);
                                }
                                return Util.showSpecialDetailsDialog(special.id);
                                // $state.go('app.specials.special', {sid: special.id});
                            };
                        }

                        return actions;
                    }

                    function _getCarouselWatchers() {
                        var watchers = {};

                        if (self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL ||
                            self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL) {
                            watchers['cart.lines.add'] = _setProducts;
                        } else if (self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL) {
                            watchers['cart.lines.add'] = _setSpecialsCartProducts;
                        }

                        return watchers;
                    }

                    function _setSpecialsCartProducts(specials) {
                        angular.forEach(specials, function(special) {
                            if (special.item) {
                                special.item = Cart.getProduct(special.item);
                            }
                        });
                    }

                    function _getCarouselData() {
                        return {
                            isLargeProducts: self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL
                        };
                    }

                    function _getCarouselTemplateUrl() {
                        switch (self.data.typeId) {
                            case SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL:
                                return 'template/views/home/partials/special-carousel/index.html';
                            case SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL:
                            case SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL:
                                return 'template/views/home/partials/carousel-component-product-item/index.html';
                            case SP_HOME_PAGE_COMPONENT_TYPES.LINKS_CAROUSEL:
                                return 'template/views/home/partials/carousel-component-link/index.html';
                        }
                    }
                }
            }])
        .controller('PrutahHomeCtrl', ['Util', 'SP_HOME_PAGE_COMPONENT_TYPES', '$rootScope', '$scope',
            function(Util, SP_HOME_PAGE_COMPONENT_TYPES, $rootScope, $scope) {
            var prutahHomeCtrl = this,
                _twoRowsRowsNumber = 2,//{default:2,breakingPoints:[{height:900,value:1}]};
                _BASE_MULTIPLY = $rootScope.config.retailer.settings.homePageImageHeight === '2' ? 0.315 : 0.467;

            prutahHomeCtrl.getCarouselItemWidth = getCarouselItemWidth;
            prutahHomeCtrl.getCarouselRowsNumber = getCarouselRowsNumber;

            _setHomeBackgroundHeight();

            function getCarouselItemWidth(component) {
                switch (component.data.typeId) {
                    case SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL:
                    case SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL:
                        return 256;
                    case SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL:
                        return 354;
                    case SP_HOME_PAGE_COMPONENT_TYPES.LINKS_CAROUSEL:
                        return 280;
                    case SP_HOME_PAGE_COMPONENT_TYPES.LINKS:
                        return 304;
                }
            }

            function getCarouselRowsNumber(component) {
                switch (component.data.typeId) {
                    case SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL:
                        return $rootScope.config.retailer.settings.isNewPromotionDesignEnabled === 'true' ? 1 : _twoRowsRowsNumber;
                    default:
                        return 1;
                }
            }

            function _setHomeBackgroundHeight() {
                var multiplier = _BASE_MULTIPLY;
                if ($rootScope.config.retailer.homePageActiveVideo) {
                    var boost = 0.25;
                    if ($rootScope.windowSize.width < 1300) {
                        boost = 0.1;
                    }
                    if ($rootScope.windowSize.width < 1000) {
                        boost = 0;
                    }
                    multiplier = multiplier + boost;
                }

                prutahHomeCtrl.backgroundHeight = $rootScope.windowSize.height * multiplier;
            }

            Util.currentScopeListener($scope, $rootScope.$on('resize', _setHomeBackgroundHeight));
        }])
        .controller('KikarHomeCtrl', [
            '$scope', '$rootScope', 'SpHomePageComponents', 'SP_HOME_PAGE_COMPONENT_TYPES',
            function($scope, $rootScope, SpHomePageComponents, SP_HOME_PAGE_COMPONENT_TYPES) {
                var homeCtrl = $scope.homeCtrl,
                    kikarHomeCtrl = this;

                kikarHomeCtrl.getCarouselItemWidth = getCarouselItemWidth;
                kikarHomeCtrl.getCarouselRowsNumber = getCarouselRowsNumber;
                kikarHomeCtrl.isOldDesign = false;

                $scope.$on('homePage.components.loaded', function() {
                    var newIsOldDesign = _isOldDesign();
                    if (newIsOldDesign !== kikarHomeCtrl.isOldDesign) {
                        kikarHomeCtrl.isOldDesign = newIsOldDesign;

                        // force carousels to reset sizes
                        $rootScope.$emit('resize');
                    }
                });

                function getCarouselItemWidth(component) {
                    switch (component.data.typeId) {
                        case SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL:
                        case SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL:
                            return 256;
                        case SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL:
                            return 354;
                        case SP_HOME_PAGE_COMPONENT_TYPES.LINKS_CAROUSEL:
                            return 280;
                        case SP_HOME_PAGE_COMPONENT_TYPES.LINKS:
                            return 304;
                    }
                }

                function getCarouselRowsNumber() {
                    return 1;
                }

                function _isOldDesign() {
                    return homeCtrl && homeCtrl.components && homeCtrl.components.length === 1 && (
                        homeCtrl.components[0].data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL ||
                        homeCtrl.components[0].data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL
                    ) && !SpHomePageComponents.isTitleAsItem(homeCtrl.components[0].data);
                }
            }
        ]);
})(angular, app);
