(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.termsAndConditions', {
                url: '/terms-and-conditions',
                data: {
                    name: 'terms-and-conditions'
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return $filter('translate')('Terms & conditions');
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/terms-and-conditions/index.html',
                        controller: 'TermsAndConditionsCtrl'
                    }
                }
            });
        }])
        .controller('TermsAndConditionsCtrl', ['$scope', 'Config', function ($scope, config) {
            $scope.terms = config.retailer.settings.agreementText;
        }]);

})(angular, app);
