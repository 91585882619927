(function (angular, app) {
  "use strict";

  /**
   * @typedef {Record<LanguageEnum, string>} ConfigText
   */

  /**
   * @typedef {Object} ConfigButton
   * @property {ConfigText} text
   * @property {string} icon
   */

  /**
   * @typedef {Object} OrderUpdatePopup
   * @property {string} icon
   * @property {ConfigText} title
   * @property {ConfigButton} updateOrderBtn
   * @property {ConfigButton} updateDeliveryBtn
   * @property {ConfigButton} updatePickupBtn
   * @property {ConfigText} cancelOrderText
   * @property {ConfigText} latestTimeToUpdateText
   */

  /**
   * @typedef {Object} ConfigButtonDisplay
   * @property {string} text
   * @property {string} icon
   */

  /**
   * @typedef {Object} OrderUpdatePopupDisplay
   * @property {string} icon
   * @property {string} title
   * @property {ConfigButtonDisplay} updateOrderBtn
   * @property {ConfigButtonDisplay} updateSlotBtn
   * @property {string} cancelOrderText
   * @property {string} latestTimeToUpdateText
   * @property {string} errorMessage
   */

  /**
   * @typedef {Object} KeyValParam
   * @property {string} key
   * @property {string} value
   */

  /**
   * @typedef {Object} Order
   * @property {number} id
   * @property {string} timePlaced
   * @property {number} ebtSnapTaxSaved
   * @property {number} ebtWeightDeposit
   * @property {number} statusId
   * @property {number} totalAmount
   * @property {string} shippingTimeFrom
   * @property {string} shippingTimeFromDisplay
   * @property {string} shippingTimeTo
   * @property {number} orderTypeId
   * @property {number} branchDeliveryTypeId
   * @property {number} amountCharged
   * @property {number} overallCharged
   * @property {string} city
   * @property {string} addressText
   * @property {string} countryState
   * @property {string} addressText2
   * @property {string} street
   * @property {string} houseNumber
   * @property {string} entry
   * @property {string} floor
   * @property {string} apartment
   * @property {string} country
   * @property {string} zipCode
   * @property {string} shippingComments
   * @property {number} lat
   * @property {number} lng
   * @property {string} externalPlaceId
   * @property {number} itemsCount
   * @property {number} cartId
   * @property {number} branchAreaId
   * @property {number} substitutePreferences
   * @property {number} branchAreaTypeId
   * @property {number} branchId
   * @property {number} originalBranchId
   * @property {number} originalBranchAreaId
   * @property {number} userId
   * @property {number} userOrganizationId
   * @property {number} userOrganizationTypeId
   * @property {number} deliveryTimeTypeId
   * @property {string} deliveryTimeDaysRange
   * @property {number} initialCheckoutCharge
   * @property {Object[]} aisleStatuses
   * @property {Object} paymentData
   * @property {number} deliveryFee
   * @property {number} totalTax
   * @property {Object[]} lines
   * @property {string} fixedAddress
   * @property {boolean} isCustomerEditBlocked
   */

  app.controller("LaunchEditOrderCtrl", [
    "$scope",
    "Config",
    "$rootScope",
    "Util",
    "$filter",
    "$sce",
    "Orders",
    "BRANCH_DELIVERY_TYPES",
    controller,
  ]);

  function controller(
    $scope,
    Config,
    $rootScope,
    Util,
    $filter,
    $sce,
    Orders,
    BRANCH_DELIVERY_TYPES
  ) {
    var ctrl = this;
    var _translate = $filter("translate");
    var updateOrderV2Ctrl = $scope.updateOrderV2Ctrl;

    /** @type {OrderUpdatePopupDisplay} */
    ctrl.popup = null;
    ctrl.isUpdateSlotDisabled = false;

    $scope.lang = $rootScope.config.language.culture;

    angular.extend(ctrl, {
      onClickCancel: onClickCancel,
      onClickUpdateTimeslot: onClickUpdateTimeslot,
      onClickUpdateOrderItems: onClickUpdateOrderItems,
    });

    // ----------------------------------

    _init();

    function _init() {
      Orders.getOrderDetails(updateOrderV2Ctrl.orderId).then(initData);
      _bindWindowClickCancelEditPopup();
    }

    /**
     * @param {Order} order
     */
    function initData(order) {
      ctrl.isUpdateSlotDisabled = !Util.checkCanUpdateTimeSlot(order);

      ctrl.popup = initOrderUpdatePopup(Config.retailer.settings, order);
    }

    /**
     * @param {Order} order
     * @return {KeyValParam[]}
     */
    function getAllTextParam(order) {
      var lastTimeUpdate = Util.calculateLastUpdateOrderDateTime(order.shippingTimeFrom);

      var allParams = [
        {
          key: "{delivery method}",
          value: _translate("delivery_type_" + order.branchDeliveryTypeId).toLowerCase(),
        },

        {
          key: "{time calculate}",
          value: Util.getTranslatedFullDateTime(lastTimeUpdate),
        },
      ];

      return allParams;
    }

    /**
     * Open in dialog -> assume can't change language half-way through
     *
     * @param {RetailerSettings} retailerSettings
     * @param {Order} order
     * @return {OrderUpdatePopupDisplay}
     */
    function initOrderUpdatePopup(retailerSettings, order) {
      /** @type {OrderUpdatePopup} */
      var updateOrderPopup = angular.copy(retailerSettings.changeTimeSlot.orderUpdatePopup);
      var allParams = getAllTextParam(order);

      var updateSlotBtn =
        order.branchDeliveryTypeId === BRANCH_DELIVERY_TYPES.DELIVERY
          ? updateOrderPopup.updateDeliveryBtn
          : updateOrderPopup.updatePickupBtn;

      var deliveryType =
        order.branchDeliveryTypeId === BRANCH_DELIVERY_TYPES.DELIVERY ? "delivery" : "pickup";

      var updateSlotDefKey = "update_order_popup_" + deliveryType + "_time_def";

      /** @type {OrderUpdatePopupDisplay} */
      var popupDisplay = {
        icon: updateOrderPopup.icon,

        title: Util.getTextByLangAndReplaceParams(
          updateOrderPopup.title,
          "update_order_popup_title_def",
          allParams
        ),

        latestTimeToUpdateText: Util.getTextByLangAndReplaceParams(
          updateOrderPopup.latestTimeToUpdateText,
          "update_order_popup_lastest_time_def",
          allParams
        ),

        cancelOrderText: initCancelOrderText(updateOrderPopup.cancelOrderText),

        updateOrderBtn: initConfigButtonDisplay(
          updateOrderPopup.updateOrderBtn,
          "update_order_popup_order_item_def"
        ),

        updateSlotBtn: initConfigButtonDisplay(updateSlotBtn, updateSlotDefKey, allParams),

        errorMessage: Util.getTextByLangAndReplaceParams(
          {},
          "update_order_popup_err_cant_update_time",
          allParams
        ),
      };

      return popupDisplay;
    }

    /**
     * @param {ConfigButton} configButton
     * @param {string} defTxtKey
     * @param {KeyValParam[]=} keyValParams
     * @return {ConfigButtonDisplay}
     */
    function initConfigButtonDisplay(configButton, defTxtKey, keyValParams) {
      var text = Util.getTextByLangAndReplaceParams(configButton.text, defTxtKey, keyValParams);

      return {
        text: text,
        icon: configButton.icon,
      };
    }

    /**
     * @param {ConfigText} text
     * @return {string}
     */
    function initCancelOrderText(text) {
      var params = [
        {
          key: "{cancel order}",
          value:[
            '<button class="cancel-btn no-design" onclick="bindWindowClickCancelEditPopup()">',
            _translate("cancel order"),
            "</button>",
          ].join('')
        },
      ];

      var newText = Util.getTextByLangAndReplaceParams(
        text,
        "update_order_popup_cancel_def",
        params
      );

      return $sce.trustAsHtml(newText);
    }

    function _bindWindowClickCancelEditPopup(){
      window.bindWindowClickCancelEditPopup = function(){
        onClickCancel()
      }
    }

    function onClickCancel() {
      updateOrderV2Ctrl.close({ shouldKeepEditMode: true });
      return Orders.getOrderDetails(updateOrderV2Ctrl.orderId).then(function (order) {
        return Orders.cancelOrder(order);
      });
    }

    function onClickUpdateOrderItems() {
      updateOrderV2Ctrl.close({ shouldKeepEditMode: true });
      Orders.editOrder(updateOrderV2Ctrl.orderId);
    }

    function onClickUpdateTimeslot() {
      updateOrderV2Ctrl.next();
    }
  }
})(angular, app);
