(function (angular, app) {
    'use strict';

    var SPECIAL_TYPES = {
        REGULAR_SPECIALS: 'regular',
        LOYALTY_SPECIALS: 'loyalty'
    };

    var specialsData = {
        specials: [],
        filters: undefined,
        total: undefined
    };

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.specials', {
                url: '/specials?{filters:json}&{sort:json}',
                data: {
                    name: 'specials'
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Specials'));
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/specials/index.html',
                        controller: 'SpecialsCtrl'
                    }
                }
            });
        }])
        .run(['$rootScope', function($rootScope) {
            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState) {
                if (!toState.name.startsWith('app.specials') || toState.name === fromState.name) {
                    specialsData.specials = [];
                    specialsData.filters = specialsData.total = undefined;
                }
            });
        }])
        .controller('SpecialsCtrl', [
            '$scope', '$rootScope', '$q', '$state', '$stateParams', 'Api', 'Config', 'Util', 'Items', 'Cart', 'Specials', 'FilterManager', 'DataLayer',
            function ($scope, $rootScope, $q, $state, $stateParams, api, config, util, items, cart, specials, FilterManager, DataLayer) {
                var LOYALTY_SPECIAL_DRIVERS = {},
                    specialsESFilters = {
                    must: {
                        lessThan: {
                            startDate: new Date()
                        },
                        greaterThan: {
                            endDate: new Date()
                        },
                        term: {
                            displayOnWeb: true
                        }
                    }
                };
                if (!config.retailer.settings.showCouponsSpecials) {
                    specialsESFilters.must.term.isCoupon = false;
                }

                $scope.total = specialsData.total || 0;
                $scope.specials = specialsData.specials;
                util.setCurrentCategory({id: 'specials'}, $scope);

                function _addChunkToSpecials(isInit) {
                    var totalItems = items.getItemsLength();
                    if (!isInit && totalItems == $scope.total) return;

                    return items.getChunkAmount().then(function(size) {
                        var params = {
                            from: totalItems,
                            size: size,
                            filters: angular.copy(specialsESFilters),
                            sort: [{priority:"desc"}, {sort: 'asc'}, {id:"desc"}]
                        };

                        FilterManager.parseFilters(params, [$scope.productTagsFilter, $scope.brandFilter, $scope.categoriesFilter, $scope.specialsTypeFilter]);

                        return items.getItems({
                            url: '/v2/retailers/:rid/branches/:bid/specials',
                            params: params
                        });
                    }).then(function (specials) {
                        $scope.total = specials.total;
                        util.pushAll($scope.specials, setSpecialsItem(specials.specials));
                        util.setProductIndexPosition(specials.specials);
                        DataLayer.push(DataLayer.EVENTS.VIEW_ITEM_LIST, {products: specials.specials, data: {productsStartingPosition: specials.total}});
                    });
                }

                function onChange() {
                    FilterManager.setFiltersToUrl({
                        productTag: $scope.productTagsFilter,
                        category: $scope.categoriesFilter,
                        brand: $scope.brandFilter,
                        type: $scope.specialsTypeFilter
                    });

                    items.resetItems();
                    return _addChunkToSpecials(true);
                }

                function parseSpecialTypeFilter(params) {
                    var filterValues = this.value;
                    if (!filterValues || !filterValues.length) {
                        return;
                    }

                    var isMissing = false,
                        isExists = false,
                        loyaltyClubIds = [];
                    angular.forEach(filterValues, function(valItem) {
                        if (valItem.value === SPECIAL_TYPES.REGULAR_SPECIALS) {
                            isMissing = true;
                        } else if (!!LOYALTY_SPECIAL_DRIVERS[valItem.value]) {
                            isExists = true;
                            Array.prototype.push.apply(loyaltyClubIds, LOYALTY_SPECIAL_DRIVERS[valItem.value]);
                        }
                    });
                    if (isMissing !== isExists) {
                        params.filters = params.filters || {};
                        params.filters.must = params.filters.must || {};
                        params.filters.must[isExists ? 'exists' : 'missing'] = ['loyaltyClubsIds'];
                    }

                    if (loyaltyClubIds.length) {
                        params.filters.must = params.filters.must || {};
                        params.filters.must.term = params.filters.must.term || {};
                        params.filters.must.term.loyaltyClubsIds = loyaltyClubIds;
                    }
                }

                $scope.productTagsFilter = FilterManager.getProductTagsFilter(onChange, $scope, true);
                $scope.brandFilter = FilterManager.getBrandsFilter(onChange, 'branch.productsData.brands.id');
                $scope.categoriesFilter = FilterManager.getCategoriesFilter(onChange, 'branch.productsData.categories.id');
                var specialTypeItems = [{
                    value: SPECIAL_TYPES.REGULAR_SPECIALS,
                    name: {
                        1: 'מבצעי הרשת',
                        2: 'Specials',
                        3: 'ofertas',
                        4: 'Акции сети',
                        5: 'Promotions',
                        6: 'العروض الخاصة',
                    }
                }];


                if ($rootScope.config.retailer.loyaltyClubDrivers) {
                    angular.forEach($rootScope.config.retailer.loyaltyClubDrivers || [], function (driver) {
                        if (driver.loyaltyClubIds && driver.loyaltyClubIds.length) {
                            LOYALTY_SPECIAL_DRIVERS[driver.id] = driver.loyaltyClubIds;
                            specialTypeItems.push({
                                value: driver.id,
                                name: {
                                    1: 'מבצעי ' + driver.name,
                                    2: driver.name + ' Specials',
                                    3: 'Promociones de ' + driver.name,
                                    4: 'Акции ' + driver.name,
                                    5: 'Promos Club',
                                    6: 'الصفقات الخاصة للنادي',
                                }
                            })
                        }
                    });
                }
                $scope.specialsTypeFilter = new FilterManager.SPFrontendViewFilter({
                    notFinished: false,
                    title: 'Special type',
                    options: {
                        items: specialTypeItems,
                        orderBy: '-value'

                    },
                    onChange: onChange,
                    parse: parseSpecialTypeFilter
                });

                $scope.spItemsFilters = [$scope.productTagsFilter, $scope.categoriesFilter, $scope.brandFilter];

                if (!!config.retailer.loyaltyClubDrivers &&
                    !!config.retailer.loyaltyClubDrivers.find(function (driver) { return driver.isActive; })) {
                    $scope.spItemsFilters.splice(0, 0, $scope.specialsTypeFilter);
                }

                function setSpecialsItem(specials) {
                    angular.forEach(specials, function (special) {
                        if (!special.item) return;
                        special.item = cart.getProduct(special.item);
                    });

                    return specials;
                }

                util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function () {
                    setSpecialsItem(specialsData.specials);
                }));

                function getNextProduct(index, toAdd) {
                    var product, next, nextTwo, selectedIndex;
                    for (var i = index + toAdd; i < items.items.length && i >= 0; i += toAdd) {
                        if (items.items[i].item) {
                            if (!product) {
                                product = items.items[i].item;
                                selectedIndex = i;
                            } else if (!next) {
                                next = items.items[i].item;
                            } else if (!nextTwo) {
                                nextTwo = items.items[i].item;
                            } else {
                                break;
                            }
                        }
                    }
                    var siblings = [];
                    if (next) {
                        siblings.push(next);
                    }
                    if (nextTwo) {
                        siblings.push(nextTwo);
                    }
                    return {
                        total: $scope.total,
                        index: selectedIndex,
                        product: product,
                        siblings: siblings
                    };
                }

                var newChunkPromise;

                function nextProduct(index) {
                    index = !angular.isUndefined(index) ? index : $scope.total;
                    var productData = getNextProduct(index, 1);
                    if (productData.product || productData.index >= productData.total) {
                        var defer = $q.defer();
                        defer.resolve(productData);
                        return defer.promise;
                    } else {
                        if (newChunkPromise) {
                            return newChunkPromise;
                        }

                        var prom = _addChunkToSpecials();
                        if (prom) {
                            return newChunkPromise = prom.then(function () {
                                newChunkPromise = null;
                                return nextProduct(index);
                            });
                        }
                    }
                }

                function previousProduct(index) {
                    index = !angular.isUndefined(index) ? index : 0;
                    var defer = $q.defer();
                    defer.resolve(getNextProduct(index, -1));
                    return defer.promise;
                }

                $scope.openSpecial = function (special, index) {
                    if (util.isSingleItemSpecial(special)) {
                        var siblings = [];
                        util.pushAll(siblings, getNextProduct(index, 1).siblings);
                        util.pushAll(siblings, getNextProduct(index, -1).siblings);
                        return util.openProduct(special.item, {
                            next: nextProduct,
                            previous: previousProduct,
                            siblings: siblings,
                            total: $scope.total,
                            index: index
                        });
                    }
                    if($rootScope.config.retailer.settings.isNewPromotionDesignEnabled === 'true') {
                        util.showSpecialDetailsDialog(special.id);
                    } else {
                        $state.go('app.specials.special', {sid: special.id});
                    }
                };

                util.currentScopeListener($scope, $rootScope.$on('items.initiated', function () {
                    if ($scope.specials.length) {
                        return;
                    }

                    _addChunkToSpecials(true);
                }));

                util.currentScopeListener($scope, $rootScope.$on('document.scroll.end', function (data, event) {
                    event.start();
                    var promise = _addChunkToSpecials();
                    if (promise) {
                        promise.finally(function () {
                            event.done();
                        });
                    } else {
                        event.done();
                    }
                }));
                util.currentScopeListener($scope, $rootScope.$on('items.add', _addChunkToSpecials));

                FilterManager.setFiltersFromUrl({
                    productTag: $scope.productTagsFilter,
                    category: $scope.categoriesFilter,
                    brand: $scope.brandFilter,
                    type: $scope.specialsTypeFilter
                }, $stateParams.filters);

                $q.resolve().then(function() {
                    if (specialsData.filters) {
                        return specialsData.filters;
                    }

                    return specials.getFilters();
                }).then(function (results) {
                    specialsData.filters = results;


                    FilterManager.addProductTagsFilterOptions($scope.productTagsFilter, results.productTags);
                    FilterManager.addBrandsFilterOptions($scope.brandFilter, results.brands);
                    FilterManager.addCategoriesFilterOptions($scope.categoriesFilter, results.categories);
                }).finally(function () {
                    FilterManager.markFiltersAsFinished([$scope.productTagsFilter, $scope.brandFilter, $scope.categoriesFilter]);
                });
            }])

        //those controllers should move to their template
        .controller('PrutahTemplateSpecialsCtrl', ['$rootScope', 'Items', function($rootScope, Items) {
            Items.initPage($rootScope.config.retailer.settings.isNewPromotionDesignEnabled === 'true' ? {} : {
                defaultWidth: $rootScope.windowSize.width <= 768 ? 260 : 310
            });
        }])
        .controller('KikarTemplateSpecialsCtrl', ['Items', function(Items) {
            Items.initPage({
                defaultWidth: 235 // 235
            });
        }]);

})(angular, app);
