
(function (angular, app) {
    'use strict';

    app.directive('productImage', [
        function () {
        return {
            restrict: 'E',
            controller: ['$scope', '$filter', '$timeout', 'Util', 'SP_SERVICES', 'Dialog', function ($scope, $filter, $timeout, util, SP_SERVICES, Dialog) {
                var hasImage = !$scope.imageKey || $scope.imageKey === 'images';
                
                if ($scope.product && $scope.product.isPseudo) {
                    $scope.imageUrl = 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/cant-find-item-in-cart.png';
                } else if ($scope.product && (($scope.product.singleLine && $scope.product.singleLine.type == SP_SERVICES.CART_LINE_TYPES.COUPON)|| ($scope.product.isCoupon && !hasImage))) {
                    if ($scope.imageUrl) {
                        $scope.imageUrl = $filter('image')($scope.imageUrl, $scope.size);
                        //don't mark this image as coupon - this is NOT a COUPON image
                        // (rather it's considered a 'product' image)
                    } else {
                        $scope.imageUrl = 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/cant-find-item-in-cart.png';
                        $scope.isCoupon = true;
                    }
                } else if (hasImage) {
                    $scope.imageUrl = $filter('productImage')($scope.product, $scope.size);
                } else {
                    $scope.imageUrl = $filter('image')($scope.product[$scope.imageKey] ||
                        'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/missing-image.png', $scope.size);
                }

                $scope.openSuggestions = function (event) {
                    $scope.$root.$emit('suggestions.opened');
                    if (event) {
                        event.stopImmediatePropagation();
                    }

                    var getProduct = !!($scope.inCartSuggestion || $scope.showReplacementLabel),
                        productToOpen = !getProduct && $scope.line && $scope.line.product ? $scope.line.product : $scope.product;

                    if ($scope.$root.triggerSuggestionsClose) {
                        $timeout(function() {
                            // !$scope.$root.navOrderHistoryOpen:
                            // make sure the navbar is closed for edge cases - when opened again
                            if (!$scope.$root.navOrderHistoryOpen) {
                                util.openSuggestionsDialog(productToOpen);
                            }
                        }, 1000);
                    } else {
                        util.openSuggestionsDialog(productToOpen);
                    }
                }
            }],
            scope: {
                line: '=',
                product: '=',
                imageKey: '@?',
                details: '@',
                size: '@',
                imageUrl: '<?',
                simpleImage: '<?',
                isProductOutOfStock: '@',
                isNeedToShowOutOfStockLabel: '@',
                inCartSuggestion: '=?',
                showReplacementLabel: '=?'
            },
            replace: true,
            templateUrl: 'template/directives/product-image/index.html' 
        };
    }]);

})(angular, app);

