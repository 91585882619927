(function (angular, app) {
    'use strict';

    app.service('UserMigrationDialog', ['Dialog', 'LocalStorage', function (Dialog, LocalStorage) {
        this.show = show;
        this.hide = hide;

        function show(email) {
            return Dialog.show({
                templateUrl: 'template/dialogs/user-migration/index.html',
                controller: 'UserMigrationCtrl as userMigrationCtrl',
                styleClass: 'user-migration-dialog',
                clickOutsideToClose: false,
                locals: {
                    email: email
                }
            }).then(function() {
                _storeMigrationData();
            });
        }

        function hide() {
            Dialog.hide();
        }

        function _storeMigrationData() {
            var migration = (LocalStorage.getItem('migration') && JSON.parse(LocalStorage.getItem('migration'))) || {};
            if (migration && migration.initialTime) {
                delete migration.initialTime;
                migration.hide = true;
            } else {
                migration.initialTime =  new Date();
            }
            LocalStorage.setItem('migration', JSON.stringify(migration));
        }
    }]);

    app.run(['$rootScope', 'UserMigrationDialog', function ($rootScope, UserMigrationDialog) {
        $rootScope.UserMigrationDialog = UserMigrationDialog;
    }]);

    app.service('UserMigrationService', ['Api', 'Config', function(Api, Config) {
        this.verifyEmail = verifyEmail;
        this.verifyCode = verifyCode;

        function verifyEmail(email) {
            return Api.request({
                method: 'POST',
                url: '/v2/retailers/:rid/migration/verify-email',
                data: {
                    email: email,
                    language: Config.language.culture
                }
            }).then(function(response) {
                return {
                    success: true,
                    response: response
                }
            }).catch(function(err) {
                return {
                    success: false,
                    error: err,
                    email: (err.response || {}).email || ''
                }
            });
        }

        function verifyCode(email, code) {
            return Api.request({
                method: 'POST',
                url: '/v2/retailers/:rid/migration/verify-code',
                data: {
                    email: email,
                    code: code
                }
            }).then(function(response) {
                return {
                    success: true,
                    response: response
                }
            }).catch(function(err) {
                return {
                    success: false,
                    error: err,
                    email: (err.response || {}).email || ''
                }
            });
        }
    }]);

    app.controller('UserMigrationCtrl', ['$rootScope', '$timeout', 'Util', 'Config', 'User', 'SpDialogUrlManager', 'UserMigrationService', 'email', 'SpCaptcha',
        function ($rootScope, $timeout, Util, Config, User, SpDialogUrlManager, UserMigrationService, email, SpCaptcha) {
            var userMigrationCtrl = this;
            angular.extend(userMigrationCtrl, {
                entranceFlow: !email,
                currentStep: 'verifyEmail',
                email: email,
                code: {},
                password: {},
                showFormError: false,
                user: User,
                allowSendPromotions: !!Config.retailer.allowSendPromotionsDefault,
                verifyEmail: verifyEmail,
                verifyCode: verifyCode,
                openLoginOrRegisterDialog: openLoginOrRegisterDialog,
                onKeyDown: onKeyDown,
                onKeyUp: onKeyUp,
                setPassword: setPassword,
                closeDialog: closeDialog,
                customerSupportPhone: ($rootScope.config.retailer.settings['migrationPhonePrefix' + $rootScope.config.language.culture.toUpperCase()] || '') +
                    '-' + ($rootScope.config.retailer.settings['migrationPhoneNumber' + $rootScope.config.language.culture.toUpperCase()] || ''),
            });

            function verifyEmail(resend) {
                if (!userMigrationCtrl.email || !userMigrationCtrl.email.length) {
                    userMigrationCtrl.showFormError = true;
                }

                UserMigrationService.verifyEmail(userMigrationCtrl.email).then(function(response) {
                    if (!resend) {
                        if (response.success) {
                            userMigrationCtrl.showFormError = false;
                            userMigrationCtrl.currentStep = 'verifyCode';
                        } else {
                            userMigrationCtrl.showFormError = true;
                        }
                    }
                });
            }

            function verifyCode() {
                userMigrationCtrl.codeToSend = userMigrationCtrl.code.code1 + userMigrationCtrl.code.code2 + 
                    userMigrationCtrl.code.code3 + userMigrationCtrl.code.code4 + userMigrationCtrl.code.code5;

                UserMigrationService.verifyCode(userMigrationCtrl.email, userMigrationCtrl.codeToSend).then(function(response) {
                    if (response.success) {
                        userMigrationCtrl.showFormError = false;
                        userMigrationCtrl.currentStep = 'setPassword';
                    } else {
                        userMigrationCtrl.showFormError = true;
                    }
                });
            }

            function openLoginOrRegisterDialog(param) {
                userMigrationCtrl.closeDialog();
                $timeout(function() {
                    return SpDialogUrlManager.setDialogParams({
                        loginOrRegister: param
                    });
                });
            }
            
            function onKeyDown(event) {
                if (event.keyCode === 17) {
                    return userMigrationCtrl.ctrlDown = true;
                }

                var element = event.srcElement;
                if (event.keyCode === 86 && userMigrationCtrl.ctrlDown === true) {
                    navigator.clipboard.readText().then(
                        function(cliptext) {
                            if (cliptext.trim().length === 9) {
                                cliptext.trim().split(" ").forEach(function(code, i) {
                                    userMigrationCtrl.code['code' + (i + 1)] = code;
                                    document.getElementById('code' + (i + 1)).value = code;
                                })
                            }
                        }, function (err) {
                            console.log(err);
                        });
                    element.blur();
                } else {
                    $timeout(function() {
                        element.value = element.value.replace(/[^0-9]/g,'');
                        if (element.value.length > 0) {
                            if (element.nextElementSibling) {
                                element.nextElementSibling.focus();
                                element.nextElementSibling.select();
                            } else {
                                element.blur();
                            }
                        }
                    });
                }                
            }

            function onKeyUp(event) {
                if (event.keyCode == 17) {
                    userMigrationCtrl.ctrlDown = false;
                }
            }

            function setPassword(form, event) {
                var formElement = event.target || event.srcElement || event.path[0];
                if (form.$invalid) {
                    return;
                } else if (userMigrationCtrl.password.newPassword !== userMigrationCtrl.password.confirmPassword) {
                    return Util.setServerErrorToForm(form, formElement, {
                        response: {
                            errors: [{
                                param: 'confirmPassword',
                                msg: 'Passwords do not match'
                            }]
                        }
                    });
                }

                var recaptchaHash = userMigrationCtrl.showSaveCaptcha ? SpCaptcha.getLastCaptchaVerifyHash() : null;

                var setNewPassword = userMigrationCtrl.showSaveCaptcha ?
                    Promise.resolve() : 
                    User.setNewPassword(userMigrationCtrl.email, userMigrationCtrl.codeToSend, userMigrationCtrl.password.newPassword, userMigrationCtrl.user);
                
                setNewPassword.then(function () {
                    return User.login(userMigrationCtrl.email, userMigrationCtrl.password.newPassword, recaptchaHash);
                }).then(function() {
                    userMigrationCtrl.currentStep = 'migrateSuccess'; 
                }).catch(function (err) {
                    if (err && err.response && err.response.status === 'captcha_required') {
                        userMigrationCtrl.showSaveCaptcha = true;
                    } else {
                        userMigrationCtrl.showFormError = true;
                        userMigrationCtrl.passwordError = err.response.error;
                    }
                });
            }

            function closeDialog() {
                $rootScope.UserMigrationDialog.hide();
            }
        }]);
})(angular, app);
