(function (angular, app) {
    'use strict';

    app.directive("spTooltip", [
        '$compile', 'Util',
        function ($compile, util) {
        return {
            restrict: "A",
            scope: {
                content: "@?spTooltip"
            },
            link: function($scope, $element){
                function _bind() {
                    var template = $scope.content && "<div class='tooltip-content'>" + $scope.content +"</div>";
                    if (template) {
                        $element.addClass('tooltip');
                        $element.append(template);
                        $compile($element.contents())($scope.$parent);
                    }
                }

                util.currentScopeListener($scope, $scope.$watch('content', _bind));
            }
        };
    }]);
})(angular, app);