(function (angular, app) {
    'use strict';

    app.controller('CheckoutDidYouMeanCtrl', [
        '$scope', 'SpDeliveryAreasService', 'addresses', 'currentAddress',
        function ($scope, SpDeliveryAreasService, addresses, currentAddress) {
            var didYouMeanCtrl = this;
            didYouMeanCtrl.data = {};
            didYouMeanCtrl.addresses = addresses;

            didYouMeanCtrl.ok = function () {
                var convertedAddress = SpDeliveryAreasService.addressComponentsToSpAddress(didYouMeanCtrl.address.address_components);
                $scope.$dialog.hide({
                    address: angular.extend(currentAddress, convertedAddress),
                    geoCoordinates: didYouMeanCtrl.address.geometry && didYouMeanCtrl.address.geometry.location,
                    externalPlaceId: didYouMeanCtrl.address.place_id
                });
            };

            didYouMeanCtrl.cancel = function () {
                $scope.$dialog.hide();
            };
        }]);
})(angular, app);
