
(function (angular, app) {
    'use strict';

    app.directive('spOutOfView', [
        '$window',
        function ($window) {
            return {
                restrict: 'A',
                scope: {
                    spOutOfViewAction: '&',
                    spOutOfViewMeasurements: '='
                },
                link: function ($scope, $element) {
                    $scope.spOutOfViewMeasurements = $scope.spOutOfViewMeasurements || {};
                    var positionedElement = $element[0];
                    
                    angular.element($window).bind("scroll", function() {
                        var rect = positionedElement.getBoundingClientRect();
                        if (rect.top < ($scope.spOutOfViewMeasurements.topHeight || 0) || rect.bottom > ($window.innerHeight - ($scope.spOutOfViewMeasurements.bottomHeight || 0))) {
                            $scope.spOutOfViewAction();
                            $scope.$applyAsync();
                        }
                    });
                }
            };
        }]);

})(angular, app);