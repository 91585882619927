(function (angular, app) {
    'use strict';

    var ITEM_DEFAULT_WIDTH = 210,
        ROWS_BETWEEN_BUMPERS = 1.4;

    app.service('Items', [
        '$q', '$rootScope', '$timeout', '$location', '$stateParams', '$window', 'Api', 'Config', 'Util', 'Dialog', 'SP_ITEMS_DESIGNS', 'DataLayer',
        function ($q, $rootScope, $timeout, $location, $stateParams, $window, api, config, util, dialog, SP_ITEMS_DESIGNS, DataLayer) {
            var self = this,
                _itemsDesign = SP_ITEMS_DESIGNS.REGULAR,
                _viewSection = document.querySelector('section.view'),
                _processByLocation = {},
                _itemDefaultWidth = ITEM_DEFAULT_WIDTH,
                _checkWidthCount = 0,
                _rowsBetweenBumpers = ROWS_BETWEEN_BUMPERS,
                _lastBumperIndex = -1,
                _bumpers = {},
                _itemsAddedTimeOut,
                _isSingleBumper = false,
                _checkClientWidthTimeOut;

            angular.extend(self, {
                width: {itemCurrent: _itemDefaultWidth},
                items: [],

                enums: {
                    bumperType: {
                        recipe: {bumperKey: 'recipe', arrayName: 'recipes'},
                        video: {bumperKey: 'video', arrayName: 'videos'},
                        banner: {bumperKey: 'banner', arrayName: 'promotionBanners'}
                    }
                },

                getChunkAmount: getChunkAmount,
                getItemsInRow: getItemsInRow,
                setItemWidth: setItemWidth,
                isChunkInProcess: isChunkInProcess,
                getNextProductData: getNextProductData,
                getItems: getItems,
                getItemsLength: getItemsLength,
                initPage: initPage,
                resetItems: resetItems,
                insertBumpersToChunk: insertBumpersToChunk
            });

            self.cantFindItem = {
                text: {
                    show: function () {
                        angular.element(_viewSection.querySelector('.cant-find-item')).addClass('open');
                    },
                    hide: function () {
                        angular.element(_viewSection.querySelector('.cant-find-item')).removeClass('open');
                    }
                },
                openDialog: function () {
                    dialog.show({
                        controller: ['$scope', '$rootScope', 'Cart', function ($scope, $rootScope, cart) {
                            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Can\'t Find Item'}});

                            $scope.categories = config.tree.categories;
                            $scope.line = {quantity: 1, isPseudo: true, product: {}};

                            $scope.changeQuantity = function (toAdd) {
                                if ($scope.line.quantity + toAdd > 0) {
                                    $scope.line.quantity += toAdd;
                                }
                                return true;
                            };

                            $scope.addToCart = function (form, event) {
                                var formElement = event.target || event.srcElement || event.path[0];
                                if (form.$invalid) {
                                    util.setFirstErrorInput(formElement);
                                } else if (!$scope.line.quantity || $scope.line.quantity < 0) {
                                    util.setServerErrorToForm(form, formElement, {response: {error: 'Please enter an amount'}});
                                } else {
									$scope.line.product.name = $scope.line.product.name && $scope.line.product.name.replace(/[<>=]/g, '');
                                    $scope.line.product.id = $scope.line.product.name;
                                    $scope.line.product.categories = [$scope.line.product.category];
                                    delete $scope.line.product.category;
                                    $scope.line.product.names = {};
                                    $scope.line.product.names[config.language.id] = {
                                        short: $scope.line.product.name,
                                        long: $scope.line.product.name
                                    };

                                    var isLimitPassed = cart.checkHeavyLimitBeforeAddLine($scope.line);
                                    if (isLimitPassed === false) {
                                        if (cart.lines && Object.keys(cart.lines).length === 0 &&
                                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                                            cart.addDeliveryFeeLineIfNeeded();
                                        }
                                        cart.addLine($scope.line);

                                        var cartLine = cart.lines[cart.keyByLine($scope.line)];
                                        if (cartLine && cartLine != $scope.line) {
                                            cartLine.comments = $scope.line.comments;
                                            cartLine.quantity = $scope.line.quantity;
                                            cartLine.product = $scope.line.product;
                                        }
                                    }
                                    dialog.hide();
                                }
                            };
                        }],
                        styleClass: 'cant-find-item',
                        templateUrl: 'template/dialogs/cant-find-item/index.html',
                        ariaLabelledby: 'cant_find_item_dialog_title'
                    });
                }
            };

            _setViewWidth();
            _onScroll();

            function getChunkAmount() {
                return _getRowsInView().then(function(rows) {
                    var existItems = self.items.length,
                        inRow = getItemsInRow(),
                        chunk = inRow * (rows + 2),
                        leftOvers = existItems - (parseInt(existItems / inRow) * inRow);
                    return chunk - leftOvers;
                });
            }

            function _getRowsInView() {
                return _waitForReadyState().then(function() {
                    var fakeItem = angular.element('<div class="item fake' + (_itemsDesign == SP_ITEMS_DESIGNS.MENU ? ' menu-item' : '') + '"></div>');
                    angular.element(_viewSection).append(fakeItem);
                    var rowHeight = fakeItem.prop('offsetHeight');
                    fakeItem.remove();
                    var viewSectionTop = 0;
                    for (var current = _viewSection; !!current; current = current.offsetParent) {
                        viewSectionTop += current.offsetTop;
                    }
                    return Math.floor(($rootScope.windowSize.height - viewSectionTop - 1) / rowHeight);
                });
            }

            function getItemsInRow() {
                // menu category can have a maximum of 2 products in a row
                if (_itemsDesign == SP_ITEMS_DESIGNS.MENU) {
                    return 2;
                }

                return parseInt(self.width.view / _itemDefaultWidth) || 1;
            }

            function setItemWidth() {
                _setViewWidth();
                var itemsInView = getItemsInRow(),
                    leftInRow = self.width.view - (_itemDefaultWidth * itemsInView),
                    toAdd = (leftInRow - (leftInRow % 2)) / itemsInView;

                self.width.itemCurrent = _itemDefaultWidth + toAdd;
                //if (!$rootScope.$$phase) {
                    $rootScope.$applyAsync();
                //}
                _checkClientWidth();
            }

            function isChunkInProcess(isAdd) {
                _processByLocation[$location.path()] = (_processByLocation[$location.path()] || 0) + (isAdd || 0);
                _processByLocation[$location.path()] = _processByLocation[$location.path()] < 0 ? 0 : _processByLocation[$location.path()];
                return !!_processByLocation[$location.path()];
            }

            /**
             * Init the page properties
             * @param {Object} options
             * @param {Number} options.defaultWidth
             * @param {Number} options.design
             */
            function initPage(options) {
                self._randomSeed = parseInt(Math.random() * 10);
                self.items = [];
                resetItems();
                setDefaultWidth(options && options.defaultWidth);
                setDesign(options && options.design);
                setItemWidth();
                _setBumperSize(options && options.isSingleBumper);
                $timeout(function() {
                    $rootScope.$emit('items.initiated');
                });
            }

            function _setBumperSize(isSingleBumper) {
                _isSingleBumper = isSingleBumper || false;
            }

            /**
             * Resets the items in the service
             * @public
             */
            function resetItems() {
                _bumpers = {};
                _rowsBetweenBumpers = ROWS_BETWEEN_BUMPERS;
                _lastBumperIndex = -1;
                self.items = self.items || [];
                self.items.splice(0);
                $rootScope.$emit('items.reset');
                document.documentElement.scrollTop = 0;
            }

            function setDefaultWidth(width) {
                _itemDefaultWidth = width || ITEM_DEFAULT_WIDTH;
            }

            function setDesign(design) {
                _itemsDesign = design || SP_ITEMS_DESIGNS.REGULAR;
            }

            function getItems(options, addedEventDelay) {
                if (isChunkInProcess()) {
                    return $q.reject('chunk in progress');
                }

                var defer = $q.defer();
                isChunkInProcess(1);

                options = options || {};

                api.request(options, {
                    loadingElement: {
                        element: document.querySelector('sp-items .items-wrapper .loading-wrapper'),
                        activeClass: 'active'
                    }
                }).then(function (resp) {
                    isChunkInProcess(-1);
                    _itemsAddedTimeOut && $timeout.cancel(_itemsAddedTimeOut);

                    _itemsAddedTimeOut = $timeout(function () {
                        $rootScope.$emit('items.added', {chunk: resp});
                    }, addedEventDelay || 0);
                    defer.resolve(resp);
                }).catch(function (err) {
                    isChunkInProcess(-1);
                    defer.reject(err);
                });

                return defer.promise;
            }

            function getItemsLength() {
                //== When Large Items mode turned On for category - an advertising item covers only one cell
                var bumperCells = _isSingleBumper ? 1 : 2;
                return self.items.length - Object.keys(_bumpers).length * bumperCells;
            }

            function getNextProductData(index, toAdd) {
                var product, next, nextTwo, newIndex;
                for (var i = index + toAdd; i < self.items.length && i >= 0; i += toAdd) {
                    if (self.items[i].isBumper) continue;

                    if (!product) {
                        product = self.items[i];
                        newIndex = i;
                        continue;
                    }
                    if (!next) {
                        next = self.items[i];
                        continue;
                    }
                    if (!nextTwo) {
                        nextTwo = self.items[i];
                        continue;
                    }

                    break;
                }
                var siblings = [];
                next && siblings.push(next);
                nextTwo && siblings.push(nextTwo);

                return {
                    index: newIndex,
                    product: product,
                    siblings: siblings
                };
            }

            function insertBumpersToChunk(chunk, rowsBetween) {
                return _insertBumpersToChunk(chunk, rowsBetween);
            }

            function _insertBumpersToChunk(chunk, rowsBetween, isItems) {
                _rowsBetweenBumpers = rowsBetween || ROWS_BETWEEN_BUMPERS;
                var newChunk = [],
                    bumpersDetails = {
                        itemsBetween: _rowsBetweenBumpers * getItemsInRow(),
                        lastExistBumperAt: -1,
                        lastExistBumperType: null,
                        prevChunksLength: isItems ? 0 : (self.items || []).length
                    };

                bumpersDetails.randomIndex = _getNextRandomIndex(bumpersDetails.itemsBetween * parseInt(_seededRandom(0, 2)), chunk.length, bumpersDetails.itemsBetween);

                angular.forEach(chunk, function (product, index) {
                    var newIndex = newChunk.length;
                    newChunk.push(product);

                    _filterBumperType(self.enums.bumperType.banner, product, chunk, newChunk, bumpersDetails, index, newIndex);

                    _filterBumperType(self.enums.bumperType.recipe, product, chunk, newChunk, bumpersDetails, index, newIndex);

                    _filterBumperType(self.enums.bumperType.video, product, chunk, newChunk, bumpersDetails, index, newIndex);

                });
                chunk.splice(0, chunk.length);
                util.pushAll(chunk, newChunk);
                return chunk;
            }

            function _getNextRandomIndex(index, itemsLeft, itemsBetween) {
                return index + parseInt(_seededRandom(0, (itemsLeft > itemsBetween ? itemsBetween : itemsLeft)));
            }

            function _filterBumperType(bumperType, product, chunk, newChunk, bumpersDetails, index, newIndex) {
                var bumpers = _filterCurrentLanguageBumper(bumperType, product);
                if (!bumpers.length || (_lastBumperIndex > -1 && bumpersDetails.prevChunksLength - 1 + newIndex <= _lastBumperIndex + bumpersDetails.itemsBetween)) {
                    if (newIndex >= bumpersDetails.randomIndex) {
                        bumpersDetails.randomIndex = _getNextRandomIndex(newIndex, chunk.length - 1 - index, bumpersDetails.itemsBetween);
                        if (bumpersDetails.lastExistBumperAt != -1) {
                            _addBumper(newChunk, bumpersDetails.lastExistBumperAt, bumpersDetails.prevChunksLength, bumpersDetails.lastExistBumperType);
                            bumpersDetails.lastExistBumperAt = -1;
                            bumpersDetails.lastExistBumperType = null;
                        }
                    }
                    return;
                }

                var bumper = bumpers[parseInt(_seededRandom(0, bumpers.length))],
                    bumperKey = bumperType.bumperKey + '-' + bumper.id;
                if (!!_bumpers[bumperKey] || newIndex < bumpersDetails.randomIndex) {
                    if (!_bumpers[bumperKey] && bumperType.bumperKey != self.enums.bumperType.video.bumperKey) {
                        bumpersDetails.lastExistBumperAt = newIndex;
                        bumpersDetails.lastExistBumperType = bumperType;
                        return;
                    }

                }

                bumpersDetails.randomIndex = _getNextRandomIndex(newIndex + bumpersDetails.itemsBetween, chunk.length - 1 - index, bumpersDetails.itemsBetween);
                bumpersDetails.lastExistBumperAt = -1;
                bumpersDetails.lastExistBumperType = null;
                _addBumper(newChunk, newIndex, bumpersDetails.prevChunksLength, bumperType, bumper);
            }


            function _filterCurrentLanguageBumper(bumperType, product) {
                var bumpers = [];
                angular.forEach(product[bumperType.arrayName] || [], function (bumper) {
                    if (!_validLanguage(bumperType, bumper)) return;
                    if (bumper.retailerId && bumper.retailerId !== config.retailer.id && bumper.retailerId !== (config.hubRetailer || {}).id) return;
                    bumpers.push(bumper);
                });
                return bumpers;
            }

            function _validLanguage(bumperType, bumper) {
                if (bumperType.bumperKey === self.enums.bumperType.video.bumperKey) {
                    return bumper.languageId == config.language.id;
                }

                if (bumperType.bumperKey === self.enums.bumperType.banner.bumperKey && !bumper.languageId) {
                    return true;
                }

                return bumper.languageId == config.language.id;
            }

            function _addBumper(items, atIndex, prevChunksLength, bumperType, bumper) {
                if (!bumper) {
                    var bumpers = _filterCurrentLanguageBumper(bumperType, items[atIndex]);
                    bumper = bumpers[parseInt(_seededRandom(bumpers.length - 1))];
                }

                var bumperKey = bumperType.bumperKey + '-' + bumper.id;
                if (_bumpers[bumperKey]) return;

                _lastBumperIndex = prevChunksLength + atIndex + 1;
                _bumpers[bumperKey] = {
                    initIndex: _lastBumperIndex
                };
                _bumpers[bumperKey][bumperType.bumperKey] = bumper;

                var first = {
                        first: true,
                        isBumper: true,
                        width: self.width.itemCurrent
                    },
                    second = {
                        second: true,
                        isBumper: true,
                        width: self.width.itemCurrent
                    };

                first[bumperType.bumperKey] = second[bumperType.bumperKey] = bumper;

                _isSingleBumper ? items.splice(atIndex + 1, 0, first) : items.splice(atIndex + 1, 0, first, second);

                DataLayer.push(DataLayer.EVENTS.VIEW_PROMOTION, {promotion: bumper, data: {type: bumperType.bumperKey}});
            }

            function _setBumpersLocation(from, to) {
                var itemsInRow = getItemsInRow();
                from = angular.isDefined(from) && from > -1 ? from : 0;
                to = angular.isDefined(to) && to <= (self.items || []).length ? to : (self.items || []).length;
                for (var i = from; i < to; i++) {
                    if (!self.items[i].isBumper || !self.items[i].first) continue;
                    var bumper, correctIndex, bumperKey;
                    if (self.items[i].video) {
                        bumperKey = self.enums.bumperType.video.bumperKey;
                    }
                    else if (self.items[i].recipe) {
                        bumperKey = self.enums.bumperType.recipe.bumperKey;
                    }
                    else if (self.items[i].banner) {
                        bumperKey = self.enums.bumperType.banner.bumperKey;
                    }
                    bumper = _bumpers[bumperKey + '-' + self.items[i][bumperKey].id];
                    correctIndex = bumper.initIndex - (!((bumper.initIndex + 1) % itemsInRow) ? 1 : 0);

                    if (correctIndex == i) continue;
                    var bumperItems = self.items.splice(i, 2);
                    self.items.splice(correctIndex, 0, bumperItems[0], bumperItems[1]);
                }
            }

            function _getWidthElement() {
                return _viewSection.querySelector('sp-items > div.items-wrapper > div.items') || _viewSection;
            }

            function _setViewWidth() {
                self.width.view = parseInt(util.getCurrentStyleProp(_getWidthElement(), 'width', true));
            }

            function _checkClientWidth() {
                var clientWidth = parseInt(util.getCurrentStyleProp(_getWidthElement(), 'width', true));
                if (clientWidth != self.width.view) {
                    setItemWidth(true);
                    _setBumpersLocation();
                } else {
                    if (_checkWidthCount < 3) {
                        _checkWidthCount++;
                        _checkClientWidthTimeOut && $timeout.cancel(_checkClientWidthTimeOut);
                        _checkClientWidthTimeOut = $timeout(_checkClientWidth, 100);
                    } else {
                        _checkWidthCount = 0;
                    }
                }
            }

            function _onPageResize() {
                if (angular.element(document.documentElement).hasClass('dialog-open')) {
                    return;
                }

                _setViewWidth();
                _getRowsInView().then(function(rows) {
                    var existItems = self.items && self.items.length,
                        inRow = getItemsInRow(),
                        chunk = inRow * (rows + 2);
                    if (!isChunkInProcess() && existItems < chunk) {
                        $rootScope.$emit('items.add');
                    }
                    setItemWidth();
                    _setBumpersLocation();
                });
            }

            function _onScroll() {
                $timeout.cancel(self.cantFindItem.timeoutId);
                self.cantFindItem.text.hide();

                self.cantFindItem.timeoutId = $timeout(self.cantFindItem.text.show, 3000);
            }

            function _seededRandom(min, max) {
                max = max || 1;
                min = min || 0;
                self._randomSeed = (self._randomSeed * 9301 + 49297) % 233280;
                var rnd = self._randomSeed / 233280;

                return min + rnd * (max - min);
            }

            function _waitForReadyState() {
                return new $q(function(resolve) {
                    var $document = angular.element(document);

                    function _listener() {
                        if (document.readyState !== 'complete') {
                            return;
                        }

                        $document.unbind('readystatechange', _listener);
                        resolve();
                        return true;
                    }

                    if (!_listener()) {
                        $document.bind('readystatechange', _listener);
                    }
                });
            }

            $rootScope.$on('document.scroll', _onScroll);
            $rootScope.$on('resize', _onPageResize);
            $rootScope.$on('route.change', function () {
                _itemDefaultWidth = ITEM_DEFAULT_WIDTH;
                _checkClientWidth();
            });
            $rootScope.$on('config.language.change', function () {
                for (var i = self.items.length - 1; i >= 0; i--) {
                    if (!self.items[i].isBumper) continue;
                    self.items.splice(i, 1);
                }
                _bumpers = {};
                _lastBumperIndex = -1;
                _insertBumpersToChunk(self.items, _rowsBetweenBumpers, true);
            });
            $rootScope.$watch(function () {
                return (self.items || []).length;
            }, function () {
                _setBumpersLocation();
            });
        }]);
})(angular, app);