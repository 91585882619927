(function (angular, app) {
    'use strict';

    app.service('MultiPassDiscount', ['$q', '$rootScope', 'Config', 'LocalStorage', 'Api',
        function ($q, $rootScope, Config, LocalStorage, Api) {
            var self = this;

            angular.extend(self, {
                connect: connect,
                getUser: getUser
            });

            /**
             * Check on server is cardId and pin are match, connect MultiPass user to Company user and get this user data from MultiPass
             * @public
             *
             * @param {Number} personalId
             *
             * @returns {Promise<Object>}
             */
            function connect(personalId) {
                return Api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/special-discount/connect-user',
                    params: {
                        personalId: personalId,
                    }
                });
            }

            /**
             * Get MultiPass user details from server
             * @public
             *
             * @returns {Promise<Object>}
             */
            function getUser() {
                return Api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/special-discount/get-user'
                });
            }
        }]);

})(angular, app);