(function (angular, app) {
    'use strict';
    app.directive('spProductPricingMethod', ['$rootScope', 'Util', '$filter', function ($rootScope, Util, $filter) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                isCase: '=',
                isPack: '=',
                product: '=',
                onToggle: '&?'
            },
            templateUrl: 'template/directives/sp-product-pricing-method/index.html',
            controller: ['$scope', function ($scope) {
                $scope.toggle = toggle;
                $scope.packPrice = null;
                _init();

                function toggle(val, $event) {
                    if (val === 1) {
                        $scope.isPack = false;
                        $scope.isCase = false;
                    }
                    if (val === 2) {
                        $scope.isPack = true;
                        $scope.isCase = false;
                    }
                    if (val === 3) {
                        $scope.isCase = true;
                        $scope.isPack = false;
                    }

                    if($scope.onToggle){
                        setTimeout(function(){
                            $scope.onToggle();
                        },500);
                        
                    }
                    $event && $event.stopPropagation();
                }

                function _init() {
                    $scope.count = 0;
                    if ($scope.product.branch) {
                        if ($scope.product.branch.case && $scope.product.branch.regularPrice != $scope.product.branch.case.price || !$scope.product.branch.case) {
                            $scope.count++;
                        }
                        $scope.packPrice = $scope.product.branch.packPrice;
                        if($scope.product.branch.packQuantity) {
                            $scope.isPack = true;
                            $scope.count++;
                        }
                        if ($scope.product.branch.case && !!$scope.product.branch.case.price) {
                            $scope.count++;
                        }
                    }
                }

                $scope.$watch('product', _init);
            }],
            controllerAs: 'productPricingMethod'
        };
    }]);
})(angular, app);