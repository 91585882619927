(function (angular, app) {
    'use strict';

    app.controller('LoadLastCartCtrl', ['$filter', 'Dialog', 'Cart', 'Api', 'fields', function ($filter, Dialog, Cart, Api, fields) {
        var loadLastCartCtrl = this,
            _translate = $filter('translate');

        loadLastCartCtrl.fields = fields;

        loadLastCartCtrl.load = load;

        function load() {
            loadLastCartCtrl.error = null;

            return Cart.createCart().then(function() {
                return Api.request({
                    method: 'POST',
                    url: '/retailers/:rid/branches/:bid/loyalty/_load-last-cart',
                    data: {
                        cartId: Cart.serverCartId,
                        lookupData: loadLastCartCtrl.lookupData
                    }
                });
            }).then(function () {
                Cart.close(false);
                return Cart.save();
            }).then(function () {
                sessionStorage.setItem('loyaltyLookupData', JSON.stringify(loadLastCartCtrl.lookupData));
                return Dialog.hide();
            }).catch(function (err) {
                switch ((err.data && err.data.error || '').toLowerCase()) {
                    case 'user not found':
                        loadLastCartCtrl.error = _translate('We did not find your membership club account');
                        break;

                    case 'cart not found':
                        loadLastCartCtrl.error = _translate('Unfortunately, your cart cannot be uploaded') + '.<br/>' + _translate('Please try again later or continue shopping');
                        break;

                    default:
                        loadLastCartCtrl.error = 'Unknown error';
                        break;
                }
            });
        }
    }]);
})(angular, app);