(function (angular, app) {
    'use strict';

    app.controller('ProcessOrganizationUsersCtrl', ['$scope', '$rootScope', 'Config', 'Cart', 'Util', 'Dialog', 'mode', 'organizationId', 'Api',
        function ($scope, $rootScope, config, cart, util, dialog, mode, organizationId, api) {
            var processOrganizationUsersCtrl = this;
            processOrganizationUsersCtrl.mode = mode;
            processOrganizationUsersCtrl.fileContent = null;
            processOrganizationUsersCtrl.users = null;
            processOrganizationUsersCtrl.isThereOneValidUser = true;
            processOrganizationUsersCtrl.isThereOneValidUser = true;

            $scope.$watch('processOrganizationUsersCtrl.fileContent', function() {
                if (processOrganizationUsersCtrl.fileContent && processOrganizationUsersCtrl.fileContent.users) {
                    var data = processOrganizationUsersCtrl.processCsvData(processOrganizationUsersCtrl.fileContent.users);
                    if (!data.isThereOneValidUser) {
                        processOrganizationUsersCtrl.fileContent = null;
                        processOrganizationUsersCtrl.isThereOneValidUser = false;
                    } else {
                        processOrganizationUsersCtrl.users = data.users;
                        processOrganizationUsersCtrl.fileNameCsv = processOrganizationUsersCtrl.fileContent.file.name;
                        processOrganizationUsersCtrl.isThereOneValidUser = true;
                    }
                }
            });

            processOrganizationUsersCtrl.upload = function () {
                if (!processOrganizationUsersCtrl.users) {
                    return;
                }

                var currentUrlPath = processOrganizationUsersCtrl.isAddMode() ? 'csv-users-add': 'csv-users-remove';

                api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/organizations/' + organizationId + '/users/' + currentUrlPath,
                    data: {
                        users: processOrganizationUsersCtrl.users,
                        fileNameCsv: processOrganizationUsersCtrl.fileNameCsv
                    }
                }).then(function(registerData) {
                    // init next step
                    dialog.hide();
                    processOrganizationUsersCtrl.showCompliteDialog();   
                }).catch(function(err) {
                    console.error(err);
                });
            };

            function validateEmail(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            }

            processOrganizationUsersCtrl.processCsvData = function (parsedCsv) {
                var users = [];
                var isThereOneValidUser = false;
                angular.forEach(parsedCsv, function (userFromCsv, key) {
                    var email = userFromCsv[0];
                    var phone = userFromCsv[1];
                    users.push({
                        phone: phone,
                        email: email
                    });
                    if (phone && phone.length > 5 && email && validateEmail(email)) {
                        isThereOneValidUser = true;
                    }
                });
                return {
                    users: users,
                    isThereOneValidUser: isThereOneValidUser
                };
            };

            processOrganizationUsersCtrl.isAddMode = function () {
                return this.mode === 'add';
            };

            processOrganizationUsersCtrl.close = function () {
                dialog.hide();
            };

            processOrganizationUsersCtrl.showCompliteDialog = function () {
                return util.showUsersUploadCompleteDialog(processOrganizationUsersCtrl.mode, organizationId);
            };
            
        }]);
})(angular, app);
