(function (angular, app) {
    'use strict';

    app.directive('spTouchstart', [
        'Util',
        function (Util) {
            return {
                restrict: 'A',
                scope: {
                    callback: '&spTouchstart'
                },
                link: function($scope, $element) {
                    function _onTouchStart(event) {
                        var res = $scope.callback({$event: event});
                        $scope.$applyAsync();
                        return res;
                    }

                    $element.bind('touchstart', _onTouchStart);
                    Util.currentScopeListener($scope, function() {
                        $element.unbind('touchstart', _onTouchStart);
                    });
                }
            };
        }]);

})(angular, app);

