(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.shopLists', {
                url: '/shop-lists',
                data: {
                    routeAccess: ROUTE_ACCESS.LOGIN,
                    name: 'shop-lists'
                },
                metaTags: {
                    title: [ '$filter', function ($filter) {
                        return $filter('translate')('Shopping lists');
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/shop-lists/index.html',
                        controller: 'ShopListsCtrl as shopListsCtrl',
                        resolve: {
                            shopLists: ['ShopListService', function (shopList) {
                                return shopList.get();
                            }],
                            smartListData: ['Api', function (Api) {
                                return Api.request({
                                    method: 'GET',
                                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/smart-list/data'
                                });
                            }]
                        }
                    }
                }
            });
        }])
        .controller('ShopListsCtrl', [
            '$rootScope', '$scope', '$state', '$filter', 'Util', 'Dialog', 'ShopListService', 'Cart', 'shopLists', 'smartListData', 'SP_SERVICES',
            function ($rootScope, $scope, $state, $filter, util, dialog, shopListService, cart, shopLists, smartListData, SP_SERVICES) {
                var shopListsCtrl = this;

                util.setCurrentCategory({id: 'myItems'}, $scope);

                var _productNameFilter = $filter('productName');
                shopListsCtrl.shopLists = shopLists && shopLists.shopLists || [];

                //== Add smart list as the first element in list
                if(smartListData && smartListData.enabled === true && smartListData.total) {
                    if(!shopListsCtrl.shopLists[0] || shopListsCtrl.shopLists[0].id) {
                        shopListsCtrl.shopLists.unshift({
                            id: 0,
                            isSmartList: true,
                            creationDate: "",
                            itemsCount: smartListData.total,
                            name: 'The products I usually purchase'
                        })
                    }

                }

                // var getSelectedProductsLineFromShopListLines = function (shopList) {
                //     var toAdd = [];
                //     angular.forEach(shopList.items, function (item) {
                //         var newProduct = angular.extend({}, item.product);
                //         newProduct.quantity = item.quantity;
                //         newProduct.comments = item.comments;
                //         toAdd.push(newProduct);
                //     });
                //     return toAdd;
                // };

                var shopListActions = {
                    openShopList: function (shopList) {
                        if(shopList.id) {
                            $state.go('app.shopLists.shopList', {
                                slid: shopList.id
                            });
                        } else if(shopList.isSmartList) {
                            $state.go('app.smartList');
                        }
                    },
                    createNewCart: function (shopList, event) {
                        event.stopPropagation();
                        event.stopImmediatePropagation();
                        event.preventDefault();
                        shopListService.get(shopList.id).then(function (shopList) {
                            var items = {};
                            angular.forEach(shopList.items, function (item) {
                                items[item.product.id + (item.isCase ? '1' : '0')] = item;
                            })
                            var toAdd = util.getActiveLines(shopList.items);
                            angular.forEach(toAdd, function (item) {
                                item.quantity = items[item.product.id + (item.isCase ? '1' : '0')].quantity;
                            });

                            var linesWithoutPropertySelected = [];

                            _checkForEmptyProductProperties(toAdd, linesWithoutPropertySelected)

                            if(linesWithoutPropertySelected.length !== 0) {
                                util.showCommonDialog(_setParams(linesWithoutPropertySelected, shopList.id));
                                return;
                            }

                            if (toAdd.length) {
                                cart.clear();
                                var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(toAdd);
                                if (isLimitPassed === false) {
                                    if (cart.lines && Object.keys(cart.lines).length === 0 &&
                                        $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                                        cart.addDeliveryFeeLineIfNeeded();
                                    }
                                    cart.addLines(toAdd, SP_SERVICES.SOURCES.SHOP_LIST);
                                }
                            }
                        });
                    },
                    addToCart: function (shopList, event) {
                        event.stopPropagation();
                        event.stopImmediatePropagation();
                        event.preventDefault();
                        shopListService.get(shopList.id).then(function (shopList) {
                            var lines = angular.forEach(shopList.items, function(item) {
                                item.weighableProductUnits = item.quantity;
                            });

                            var linesWithoutPropertySelected = [];

                            _checkForEmptyProductProperties(lines, linesWithoutPropertySelected)

                            if(linesWithoutPropertySelected.length !== 0) {
                                util.showCommonDialog(_setParams(linesWithoutPropertySelected, shopList.id));
                                return;
                            }

                            var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(lines);
                            if (isLimitPassed === false) {
                                if (cart.lines && Object.keys(cart.lines).length === 0 &&
                                    $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                                    cart.addDeliveryFeeLineIfNeeded();
                                }
                                cart.addLines(util.getActiveLines(lines), SP_SERVICES.SOURCES.SHOP_LIST);
                            }
                        });
                    },
                    remove: function (shopList, event) {
                        event.stopPropagation();
                        event.stopImmediatePropagation();
                        event.preventDefault();
                        util.showCommonDialog({
                            title: '{{\'Remove Shopping List\' | translate}}',
                            content: '{{\'Are you sure you want to remove the shopping list\' | translate}} \'{{shopList.name}}\'?',
                            controller: ['$scope', function (innerScope) {
                                innerScope.shopList = shopList;
                                innerScope.remove = function () {
                                    shopListService.remove(shopList.id).then(function () {
                                        angular.forEach(shopListsCtrl.shopLists, function (scopeShopList, index) {
                                            if (shopList == scopeShopList) {
                                                shopListsCtrl.shopLists.splice(index, 1);
                                            }
                                        });
                                    });
                                    dialog.hide();
                                };
                            }],
                            buttons: {
                                yes: {click: 'remove()', text: '{{\'Remove\' | translate}}'},
                                no: {click: '$dialog.hide()', text: '{{\'Cancel\' | translate}}'}
                            }
                        });
                    }
                };

                shopListsCtrl.shopListActions = shopListActions;

                function _checkForEmptyProductProperties(lines, linesWithoutPropertySelectedArr) {
                    angular.forEach(lines, function (line) {
                        if(line.product && line.product.productProperties && line.product.productProperties.length && !line.productPropertyValue) {
                            linesWithoutPropertySelectedArr.push(_productNameFilter(line.product, line.product.isCaseMode));
                        }
                    });
                }

                function _setParams(itemsWithNoPropertySelected, shopListId) {
                    return {
                        content: '{{\'Product properties need to be selected for\' | translate}}' + ': ' + itemsWithNoPropertySelected.join(', '),
                        buttons: [{
                            text: '{{\'View details\' | translate}}',
                            click: 'onViewDetailsClicked()'
                        }, {
                            text: '{{\'Cancel\' | translate}}',
                            click: '$dialog.hide()'
                        }],
                        controller: ['$scope', function ($dialogScope) {
                            $dialogScope.onViewDetailsClicked = function () {
                                dialog.hide();
                                $state.go('app.shopLists.shopList', {slid: shopListId});
                            };
                        }]
                    }
                }

                function _refreshLists() {
                    shopListService.get().then(function(lists) {
                        shopLists.shopLists = lists.shopLists;
                        shopListsCtrl.shopLists = lists.shopLists;
                    });
                }

                shopListsCtrl.createShopList = function () {
                    dialog.show({
                        controller: function () {
                            var createShopListCtrl = this;

                            createShopListCtrl.addCartProducts = true;
                            createShopListCtrl.create = create;
                            var cartLines = cart.getLines();

                            function create() {
                                shopListService.create(createShopListCtrl.shopListName, !createShopListCtrl.addCartProducts || cartLines).then(function (data) {
                                    dialog.hide();
                                    var arr = [];
                                    angular.forEach(cartLines, function (line) {
                                        arr.push(line.id);
                                    });
                                    $rootScope.$emit('cart.lines.added.to.mylist', arr);
                                    _refreshLists();
                                    shopListActions.openShopList(data);
                                });
                            }
                        },
                        controllerAs: 'createShopListCtrl',
                        styleClass: 'create-shop-list',
                        templateUrl: 'template/dialogs/create-shop-list/index.html'
                    });
                };
            }]);
})(angular, app);
