(function (angular, app) {
    'use strict';

    app.directive('ellipsis', [function() {
        return {
            restrict: 'A',
            scope: {
                text: '@title',
                maxHeightEllipsis: '<'
            },
            link: function($scope, $element) {
                var initialElementSize = $element[0].clientHeight + '-' + $element[0].clientWidth,
                    initiated = false;

				_watcher();

				function _watcher(newVal, oldVal) {
                    if (initiated && newVal === oldVal) {
				        return;
                    }

                    initiated = true;

                    var element = $element[0],
                        text = $scope.text,
                        initialClientHeight = element.clientHeight;
                    if (text) {
                        $element.html(text);
                    }

                    // empty the html content and return
                    // 1.) when there is no content to add
                    // 2.) when the element does not have a permanent height yet
                    //     (if its height is different before the text was added and after)
                    if (!text || !$scope.maxHeightEllipsis && element.clientHeight !== initialClientHeight) {
                        return $element.html('');
                    }

                    while (element.clientHeight < (element.scrollHeight - 1)) {
                        // when there is only one word left, do no cut it anymore
                        if ((text || '').split(' ').length <= 1) {
                            break;
                        }

                        // cut the letter at the end
                        text = text.trim().substring(0, text.length-1);
                        $element.html(text.trim() + '...');
                    }
                }

                $scope.$watch('text', _watcher);
                $scope.$watch(function() {
                    return $element[0].clientHeight + '-' + $element[0].clientWidth;
                }, function (newVal, oldVal) {
                    _watcher(newVal, newVal === oldVal ? initialElementSize : oldVal);
                });
            }
        };
    }]);
})(angular, app);
