(function (angular, app) {
    'use strict';

    app.service('ExternalRegistrationIFrameDialog', ['Dialog', function (Dialog) {
        this.show = show;
        this.hide = hide;

        function show(url, data) {
            setTimeout(function() {
                document.getElementById('iframe-not-loaded-external').style.display = 'none';
            }, 1500)
            return Dialog.show({
                templateUrl: 'template/dialogs/external-registration-iframe/index.html',
                controller: 'ExternalRegistrationIFrameCtrl as externalRegistrationIFrameCtrl',
                styleClass: 'loyalty-iframe-dialog',
                showClose: false,
                disableClosing: true,
                locals: {
                    url: url,
                    data: data
                }
            });
        }

        function hide() {
            Dialog.hide();
        }
    }]);

    app.directive('iframeOnload', [function() {
        return {
            scope: {
                callBack: '&iframeOnload'
            },
            link: function(scope, element) {
                element.on('load', function(){
                    return scope.callBack();
                })
            }
        }
    }]);

    app.run(['$rootScope', 'ExternalRegistrationIFrameDialog', function ($rootScope, ExternalRegistrationIFrameDialog) {
        $rootScope.ExternalRegistrationIFrameDialog = ExternalRegistrationIFrameDialog;
    }]);

    app.controller('ExternalRegistrationIFrameCtrl', ['$rootScope', '$sce', 'User', 'url', 'data',
        function ($rootScope, $sce, User, iframeUrl, data) {
            var externalRegistrationIFrameCtrl = this;
            angular.extend(externalRegistrationIFrameCtrl, {
                iframeUrl: $sce.trustAsResourceUrl(iframeUrl),
                data: data,
                iframeLoaded: iframeLoaded,
                closeDialog: closeDialog
            });

            window.addEventListener("message", function(evt) {
                if (evt.data === 'ready') {
                    $rootScope.iframeIsReady = true;
                    iframeLoaded();

                    setTimeout(function() {
                        $rootScope.isLoading = false;
                    }, 500);
                } else if (evt.data.isSuccess && evt.data.data && evt.data.data.foreignId && evt.data.data.token) {
                    var url = '';
                    if(window.location.search.indexOf('?') > -1) {
                        url = window.location.href + '&loginOrRegister=1&id=' + evt.data.data.foreignId + '&token=' + evt.data.data.token;
                    } else {
                        url = window.location.href + '?loginOrRegister=1&id=' + evt.data.data.foreignId + '&token=' + evt.data.data.token;
                    }
                    window.location.href = url;
                }
                if (!evt.data.isSuccess && evt.data.isAbortedByUser) {
                    closeDialog();
                }
            });
            function iframeLoaded() {
                document.getElementById('external_login').contentWindow.postMessage(data, iframeUrl);
            }

            function closeDialog() {
                $rootScope.isLoading = false;
                $rootScope.ExternalRegistrationIFrameDialog.hide();
            }
        }]);
})(angular, app);
