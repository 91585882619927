
(function (angular, app) {
    'use strict';

    app.directive('confirmPassword', function () {
        return {
            require: "ngModel",
            scope: {
                confirmPassword: "=confirmPassword"
            },
            link: function($scope, $element, $attrs, ngModel) {
                if($attrs.name === 'confirmPassword' || $attrs.name === 'Confirm Password' || $attrs.id === 'confirmPassword') {
                    ngModel.$validators.equal = function(val) {
                        return $scope.confirmPassword && val == $scope.confirmPassword;
                    };
        
                    $scope.$watch("confirmPassword", function() {
                        ngModel.$validate();
                    });
                }
            }
        };
    });

})(angular, app);