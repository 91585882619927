(function (angular, app) {
    'use strict';

    app.directive('spArrowsButton', [function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                color: '@',
                label: '@'
            },
            templateUrl: 'template/directives/sp-arrows-button/index.html'
        };
    }]);
})(angular, app);