(function (angular, app) {
    'use strict';

    app
        .constant('CONTACT_US_ENUM', {
            BACKGROUND:'background',
            MAIN_TEXT:'main-text',
            COMMUNICATIONS_TYPES: 'communications-types',
            LEAVE_A_MESSAGE: 'leave-a-message',
        })
        .controller('ContactUsCtrl', [
        '$rootScope', '$scope', 'Dialog', 'Api', 'User', 'Util', '$timeout', 'Config', 'SpCaptcha', 'DataLayer','CONTACT_US_ENUM',
        function ($rootScope, $scope, dialog, api, user, util, $timeout, Config, SpCaptcha, DataLayer, CONTACT_US_ENUM) {
            var contactUsCtrl = this,
                _ticketTimeout;
            $scope.ENUM = CONTACT_US_ENUM;
            $scope.LANGUAGE = $rootScope.config.language.culture;
            contactUsCtrl.content = Config.contactUsPageDesign.content ? Config.contactUsPageDesign.content  : {};
            contactUsCtrl.isSent = false;
            contactUsCtrl.layout = contactUsCtrl.content && contactUsCtrl.content.layout && contactUsCtrl.content.layout.length ? contactUsCtrl.content.layout.filter(function(item){
                return item.able || item.type === CONTACT_US_ENUM.MAIN_TEXT
            }).map(function(item){
                return Object.assign(item, {
                    templateUrl: 'template/views/user-edit/contact-us/' + item.type + '.html'
                });
            }) : [];

            _init();
            
            var countLanguageCommTypes = (contactUsCtrl.content && contactUsCtrl.content[CONTACT_US_ENUM.COMMUNICATIONS_TYPES]) ? 
                contactUsCtrl.content[CONTACT_US_ENUM.COMMUNICATIONS_TYPES].reduce(function(count, obj) {
                    for (var key in $rootScope.config.languages) {
                        count[key] = count[key] || 0; // Initialize count[key] if not exist
                        count[key] += obj[key] ? 1 : 0;
                    }
                    return count;
            }, {}) : {};


            contactUsCtrl.lengthCommunicationType = countLanguageCommTypes[$scope.LANGUAGE] < 5 ? countLanguageCommTypes[$scope.LANGUAGE] : 3;
            contactUsCtrl.styleCommunicationType = styleCommunicationType;

            function styleCommunicationType(item) {
                if (contactUsCtrl.lengthCommunicationType > 2) {
                    return {
                        'flex': '1 0 calc(' + (100 / contactUsCtrl.lengthCommunicationType) + '% - 10px)',
                        'width': 'calc(' + (100 / contactUsCtrl.lengthCommunicationType) + '% - 10px)', 
                        'background-color': item[$scope.LANGUAGE].backgroundColor
                    };
                } else {
                    return {
                        'flex': '0 0 33%', 
                        'background-color': item[$scope.LANGUAGE].backgroundColor, 
                        'max-width': 'calc(' + (100 / 3) + '% - 10px)'
                    };
                }
            }            

            contactUsCtrl.details = {
                fullName: '',
                email: '',
                phone: '',
                message: '',
            };
            contactUsCtrl.showCaptcha = true;
            contactUsCtrl.captchaIsInvalid = false;

            DataLayer.push(DataLayer.EVENTS.VIEW_CONTACT_US);

            if (user.session.userId) {
                contactUsCtrl.details.email = user.session.username;
            }

            function pickPhoneNumber(phones) {
                var mobileNumber = phones.filter(function (phone) {
                    return phone.typeVal === 3;
                });
                if (mobileNumber.length > 0) {
                    return mobileNumber[0].phoneNumber;
                } else {
                    return phones[0].phoneNumber;
                }
            }

            contactUsCtrl.retailerDetails = Config.retailer;

            contactUsCtrl.send = function (form, event) {
                Config.initPromise.then(function() {
                    var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
                    contactUsCtrl.details.recaptchaHash = recaptchaHash;
                    contactUsCtrl.details.branchId = Config.branch.id;
                    var formElement = event.target || event.srcElement || event.path[0];
                    if (form.$invalid) {
                        util.setFirstErrorInput(formElement);
                        angular.forEach(form.$error.required, function(field) {
                            if (field.$$attr.id === 'captcha_hidden_input') {
                                contactUsCtrl.captchaIsInvalid = true;
                            }
                        });
                    } else {
                        contactUsCtrl.showCaptcha = false;
                        api.request({
                            method: 'POST',
                            url: '/retailers/:rid/contactUs',
                            data: contactUsCtrl.details
                        }).then(function () {
                            contactUsCtrl.isSent = true;
                            $rootScope.$emit('new-ticket', {event: event, getLastData: true});
                            $rootScope.$emit('contactUs', contactUsCtrl.details.email, contactUsCtrl.details.fullName);

                            DataLayer.push(DataLayer.EVENTS.CONTACT_US);

                            var bodyElement = document.querySelector('.dialog.contact-us > .dialog-body');
                            bodyElement && bodyElement.focus()
                         /*   $timeout(function () {
                                dialog.hide();
                            }, 5000);*/
                        }).catch(function (res) {
                            contactUsCtrl.showCaptcha = true;
                            DataLayer.push(DataLayer.EVENTS.ERROR_CONTACT_US, {data: {error: res}});
                            util.setServerErrorToForm(form, formElement, res);
                        });
                    }
                });
            };

            function _init() {
                user.getData().then(function() {
                    contactUsCtrl.details.fullName = (user.data.firstName ? user.data.firstName + ' ' : '') + (user.data.lastName || '');
                    contactUsCtrl.details.phone = user.data.phones && user.data.phones.length ? pickPhoneNumber(user.data.phones) : '';
                });
            }

            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Contact Us'}});

            util.currentScopeListener($scope, function () {
                _ticketTimeout && $timeout.cancel(_ticketTimeout);
            });
        }]);
})(angular, app);
