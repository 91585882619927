
(function (angular, app) {
    'use strict';

    app.directive('spFocusIn', ['$timeout', 'Util', function ($timeout, Util) {
            return {
                restrict: 'A',
                scope: {
                    spFocusInAction: '=?',
                    spOnFocusIn: '&?',
                    spOnFocusOut: '&?'
                },
                link: function ($scope, $element) {
                    $element.bind('focusin', _onFocusIn);
                    $element.bind('focusout', _onFocusOut);
                    
                    function _onFocusIn() {
                        $scope.spFocusInAction = true;
                        $element.addClass('focus-mode');
                        if ($scope.spOnFocusIn) {
                            $scope.spOnFocusIn();
                        }
                    }

                    function _onFocusOut() {
                        $scope.spFocusInAction = false;
                        $timeout(function () {
                            if (!$scope.spFocusInAction) {
                                $element.removeClass('focus-mode');
                                if ($scope.spOnFocusOut) {
                                    $scope.spOnFocusOut();
                                }
                            }
                        }, 0);
                    }

                    Util.currentScopeListener($scope, function() {
                        $element.unbind('focusin', _onFocusIn);
                        $element.unbind('focusout', _onFocusOut);
                    });
                }
            };
        }]);

})(angular, app);