(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.shopLists.shopList', {
                url: '/:slid',
                data: {
                    routeAccess: ROUTE_ACCESS.LOGIN,
                    name: 'shop-list'
                },
                metaTags: {
                    title: [ '$filter', function ($filter) {
                        return $filter('translate')('Shopping list');
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/shop-list/index.html',
                        controller: 'ShopListCtrl as shopListCtrl',
                        resolve: {
                            shopList: ['$stateParams', 'ShopListService', function ($stateParams, shopList) {
                                return shopList.get($stateParams.slid);
                            }]
                        }
                    }
                }
            });
        }])
        .controller('ShopListCtrl', [
            '$rootScope', '$scope', '$state', '$filter', 'Dialog', 'ShopListService', 'Cart', 'Util', 'shopList', 'SP_SERVICES', 'Config', 'DataLayer',
            function ($rootScope, $scope, $state, $filter, dialog, shopListService, cart, util, shopList, SP_SERVICES, Config, DataLayer) {
                var _productNameFilter = $filter('productName');
                var shopListCtrl = this;

                shopListCtrl.shopList = shopList;

                shopListCtrl.actions = {
                    setQuantity: function (item, toAdd) {
                        shopListCtrl.hasChanges = true;
                        var newQuantity = item.quantity + (toAdd || 0);
                        item.quantity = newQuantity > 0 ? newQuantity : 0;
                    },
                    openSuggestions: function (item) {
                        util.openSuggestionsDialog(item.product);
                    }
                };

                shopListCtrl.save = save;
                shopListCtrl.addToCart = addToCart;
                shopListCtrl.createNewCart = createNewCart;
                shopListCtrl.checkAll = checkAll;
                shopListCtrl.sortByCategories = Config.retailer.settings.sortCartByCategories === 'true';
                shopListCtrl.sortedListByTree = [];

                if(shopListCtrl.sortByCategories) {
                    var sortedCartByCategoriesData = cart.sortCartByCategories(shopListCtrl.shopList.items, [], null, true);
                    shopListCtrl.sortedListByTree = cart.sortByTree(sortedCartByCategoriesData.sortedObjByCategories);
                }

                function _setTotalAmount () {
                    shopListCtrl.shopList.totalAmount = 0;
                    var _replacementLines = [];
                    angular.forEach(shopListCtrl.shopList.items, function (item) {
                        item.type = SP_SERVICES.CART_LINE_TYPES.PRODUCT;
                        item.isProductOutOfStock = util.isProductOutOfStock(item);
                        item.isNeedToShowOutOfStockLabel = util.isNeedToShowOutOfStockLabel(item);
                        if (cart.isEligibleForReplacementSuggestions(item)) {
                            _replacementLines.push(item);
                        }
                        item.actionsDisabled = item.isProductOutOfStock;
                        shopListCtrl.shopList.totalAmount += (item.quantity || 0) * (item.product && util.getRegularPrice(item.product, item.isCase));
                    });
                    cart.setReplacements(_replacementLines);
                }
                _setTotalAmount();

                function _getSelectedProducts() {
                    var lines = angular.filterCollection(shopListCtrl.shopList.items, function(item) {
                        item.weighableProductUnits = item.quantity;
                        return item.actionsChecked;
                    });

                    if (!lines || lines && !lines.length) {
                        util.showCommonDialog('{{\'No products were selected\' | translate}}');
                    }

                    return lines;
                }

                function addToCart() {
                    var lines = _getSelectedProducts();
                    if(!lines)return;

                    var linesWithoutPropertySelected = [];
                    _checkForEmptyProductProperties(lines, linesWithoutPropertySelected);

                    if(linesWithoutPropertySelected.length !== 0) {
                        util.showCommonDialog(
                            '{{\'Product properties need to be selected for\' | translate}}' + ': ' + linesWithoutPropertySelected.join(', ')
                        );

                        return;
                    }

                    var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(lines);
                    if (isLimitPassed === false) {
                        if (cart.lines && Object.keys(cart.lines).length === 0 &&
                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                            cart.addDeliveryFeeLineIfNeeded();
                        }
                        cart.addLines(util.getActiveLines(lines), SP_SERVICES.SOURCES.SHOP_LIST);
                    }
                }

                function _checkForEmptyProductProperties(lines, linesWithoutPropertySelectedArr) {
                    angular.forEach(lines, function (line) {
                        if(line.product && line.product.productProperties && line.product.productProperties.length && !line.productPropertyValue) {
                            linesWithoutPropertySelectedArr.push(_productNameFilter(line.product, line.product.isCaseMode));
                        }
                    });
                }

                function createNewCart () {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Clear Cart'}});
                    var lines = _getSelectedProducts();

                    if (!lines) return;

                    var linesWithoutPropertySelected = [];

                    _checkForEmptyProductProperties(lines, linesWithoutPropertySelected);

                    if (linesWithoutPropertySelected.length !== 0) {
                        util.showCommonDialog(
                            '{{\'Product properties need to be selected for\' | translate}}' + ': ' + linesWithoutPropertySelected.join(', ')
                        );
                        return;
                    }

                    cart.clear();
                    var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(lines);
                    if (isLimitPassed === false) {
                        if (cart.lines && Object.keys(cart.lines).length === 0 &&
                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                            cart.addDeliveryFeeLineIfNeeded();
                        }
                        cart.addLines(util.getActiveLines(lines), SP_SERVICES.SOURCES.SHOP_LIST);
                    }
                }

                function save () {
                    return shopListService.update(shopList.id, shopList.name, shopList.items).then(function () {
                        shopListCtrl.hasChanges = false;
                        return util.showCommonDialog({
                            title: '{{\'Successful Update\' | translate}}',
                            content: '{{\'Shopping list was successfully updated\' | translate}}.'
                        });
                    });
                }

                function checkAll() {
                    angular.forEach(shopListCtrl.shopList.items, function (item) {
                        item.actionsChecked = shopListCtrl.checkAllIsActive;
                    });
                }
                var alreadyAsked = false;
                util.currentScopeListener($scope, $scope.$root.$on('$stateChangeStart', function ($event, to) {
                    if (shopListCtrl.hasChanges && !alreadyAsked) {
                    $event.preventDefault();
                        util.showCommonDialog({
                            title: '{{\'Save changes before leaving page?\' | translate}}',
                            buttons: [{
                                text: '{{\'Save\' | translate}}',
                                click: '$dialog.hide(true)'
                            }, {
                                text: '{{\"Don\'t Save\" | translate}}',
                                click: '$dialog.hide()'
                            }]
                        }).then(function (save) {
                            alreadyAsked = true;
                            if (!save) {
                                return $state.go(to.name);
                            }

                            return shopListCtrl.save().then(function(){
                                return $state.go(to.name);
                            });
                        });
                    }

                }));

            }]);
})(angular, app);
