(function (angular, app) {
    'use strict';

    app.controller('HomePageBubbleCtrl', [
        '$scope', '$rootScope', '$interval', '$q', '$sce', 'hide', 'hpMessages', 'hasOrder', 'Util',
        function ($scope, $rootScope, $interval, $q, $sce, hide, hpMessages, hasOrder, util) {
            var homePageBubbleCtrl = this,
                swapInterval = 6000,
                swapTimerObj;

            homePageBubbleCtrl.hasOrder = hasOrder || false;
            homePageBubbleCtrl.hpMessages = hpMessages || {};
            homePageBubbleCtrl.hide = hide;
            homePageBubbleCtrl.trustedHtml = trustedHtml;
            homePageBubbleCtrl.pagePrev = pagePrev;
            homePageBubbleCtrl.pageNext = pageNext;
            homePageBubbleCtrl.close = close;
            homePageBubbleCtrl.currentNum = 0;

            _init();

            function _init() {
                _setTimeout();
            }

            //== show HTML from backend's CKEditor
            function trustedHtml(text) {
                return $sce.trustAsHtml(text);
            }

            //== Currently there can be only two messages so Prev and Next makes same action
            function pageNext() {
                homePageBubbleCtrl.currentNum = (homePageBubbleCtrl.currentNum + 1) % 2;
                _setTimeout();
            }

            //== Should be changed it if there will be more than two pages
            function pagePrev() {
                homePageBubbleCtrl.currentNum = (homePageBubbleCtrl.currentNum + 1) % 2;
                _setTimeout();
            }

            //== close bubble and save it until page refresh
            function close() {
                $rootScope.homePageBubbleClosed = true;
                hide();
            }

            //== timeout is for automatic messages switching, applied only when there are both last order and message
            function _setTimeout() {
                //== destroy previous if exists
                swapTimerObj && $interval.cancel(swapTimerObj);

                if(homePageBubbleCtrl.hasOrder && Object.keys(homePageBubbleCtrl.hpMessages)) {
                    swapTimerObj = $interval(pageNext, swapInterval);
                }
            }

            //== when logged out there can't be a last order
            util.currentScopeListener($scope, $rootScope.$on('logout', function () {
                homePageBubbleCtrl.hasOrder = false;
                _init();
            }));

            //== hiding bubble on navigating away from home page
            util.currentScopeListener($scope, $rootScope.$on('$stateChangeStart', function () {
                hide();
            }));

            //== remove timer on scope destroy
            util.currentScopeListener($scope, function () {
                swapTimerObj && $interval.cancel(swapTimerObj);
            });

        }]);
})(angular, app);

