(function (angular, app) {
    'use strict';

    app.filter('orderStatus', [
        'ORDER_STATUSES',
        function (ORDER_STATUSES) {
            var statuses = {};
            statuses[ORDER_STATUSES.NEW] = 'Received';
            statuses[ORDER_STATUSES.CHARGED] = 'Charged';
            statuses[ORDER_STATUSES.DELIVERED] = 'Delivered';
            statuses[ORDER_STATUSES.CANCELLED] = 'Cancelled';
            statuses[ORDER_STATUSES.SUSPENDED] = 'Processing';
            statuses[ORDER_STATUSES.PROCESSING] = 'Processing';
            statuses[ORDER_STATUSES.COLLECTED] = 'Collected';
            statuses[ORDER_STATUSES.REGISTERED] = 'Registered';
            statuses[ORDER_STATUSES.WAITING_FOR_CONFIRMATION] = 'Waiting for confirmation';
            statuses[ORDER_STATUSES.USER_CONFIRMED] = 'Processing';
            return function (input) {
                return statuses[input];
            };
        }]);

})(angular, app);