(function (angular, app) {
    'use strict';

    var ADD_EDIT_ORDER_LINES_STORAGE_KEY = 'addEditOrderLines';

    app.service('Orders', [
        '$rootScope', '$state', '$q', 'Config', 'Api', 'Cart', 'User', 'Util', 'Dialog', 'LocalStorage',
        function ($rootScope, $state, $q, Config, Api, Cart, User, Util, Dialog, LocalStorage) {
            var self = this;

            angular.extend(self, {
                getOrders: getOrders,
                getLastOrder: getLastOrder,
                getOrderStatus: getOrderStatus,
                getOrderDetails: getOrderDetails,
                cancelOrder: cancelOrder,
                editOrder: editOrder,
                cancelEdit: cancelEdit,
                checkIfUserCanUpdateOrder: checkIfUserCanUpdateOrder,

                lastOrder: null,
                orderInEdit: null
            });

            function getOrders(from, size, getLiveResults) {
                var params = {
                    from: from,
                    size: size,
                    getLiveResults: getLiveResults,
                    orderBy: [{id: 'desc'}]
                };

                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/users/:uid/orders',
                    params: params
                }, {
                    fireAndForgot: true
                });
            }

            function getLastOrder() {
                return Api.request({
                    url: '/retailers/:rid/users/:uid/lastOrder'
                });
            }

            function checkIfUserCanUpdateOrder(orderId) {
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/orders/' + orderId + '/isCustomerEditBlocked'
                });
            }

            function getOrderStatus(orderId) {
                return Api.request({
                    method: 'GET',
                    url: '/retailers/:rid/users/:uid/orders/' + orderId + '/status'
                }, {
                    fireAndForgot: true
                });
            }

            function getOrderDetails(orderId, apiOptions) {
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/orders/' + orderId
                }, apiOptions);
            }

            function cancelOrder(order, apiOptions) {
                return Dialog.show({
                    templateUrl: 'template/dialogs/cancel-order/index.html',
                    styleClass: 'cancel-order-dialog',
                    ariaLabelledby: 'cancel_order_dialog_title',
                    controller: ['$scope', function($scope) {
                        $scope.order = order;
                        $scope.user = User.session;

                        $scope.cancelOrder = cancelOrder;

                        function cancelOrder() {
                            return Api.request({
                                method: 'POST',
                                url: '/v2/retailers/:rid/branches/:bid/users/:uid/orders/' + order.id + '/_cancel'
                            }, apiOptions).then(function(additionalData) {
                                $rootScope.$emit('orders.cancelOrder', order);
                                $rootScope.$emit('orderCanceled', order.id);
                                $scope.orderCancelled = true;
                                order.additionalData = additionalData;
                            });
                        }
                    }]
                });
            }

            function editOrder(orderId) {
                return Cart.createCart().then(function() {
                    return Api.request({
                        method: 'PATCH',
                        url: '/retailers/:rid/users/:uid/orders/' + orderId + '/reopen/' + Cart.serverCartId
                    });
                }).then(function () {
                    return getOrderDetails(orderId, {
                        fireAndForgot: true
                    });
                }).then(function (order) {
                    self.orderInEdit = order;
                    $rootScope.$emit('orderEdit', order);
                    return _copyOrderToNewCart(order);
                }).then(function () {
                    Cart.init({skipActiveProductsValidation: true});
                });
            }

            function cancelEdit() {
                if (!self.orderInEdit || !Cart.serverCartId) {
                    return $q.resolve();
                }

                return Api.request({
                    method: 'PATCH',
                    url: '/retailers/:rid/users/:uid/orders/' + self.orderInEdit.id + '/cancelEdit/' + Cart.serverCartId
                }).then(function () {
                    self.orderInEdit = null;
                });
            }

            function _copyOrderToNewCart(order) {

                var categoryPromises = [];
                angular.forEach(order.lines, function (line) {
                    if (!line.isPseudo) {
                        if (!!self.orderInEdit && (line.weighableProductUnits || line.product && !line.product.productDisplayModeId && Util.isUnitsWeighable(line.product))) {
                            line.quantity = line.weighableProductUnits || Math.round(line.quantity / line.product.weight);
                        }
                        return;
                    }

                    var category = line.categoryId && {id: line.categoryId, names: {}};
                    if (category) {
                        category.names[Config.language.id] = line.categoryName;
                        categoryPromises.push(Util.getCategory(category.id).then(function(results) {
                            if (results && results.length) {
                                category.names = results[0].names;
                            }
                        }));
                    }

                    line.text = line.name;
                    line.product = {
                        id: line.name,
                        name: line.name,
                        categories: [category],
                        names: {}
                    };
                    line.product.names[Config.language.id] = {
                        short: line.name,
                        long: line.name
                    };
                });
                return $q.all(categoryPromises).then(function() {
                    Cart.clear();
                    return Config.changeBranch(order.branchId, order.branchAreaId, {
                        forceBranchChange: true
                    });
                }).then(function (isRefresh) {
                    if (isRefresh) {
                        LocalStorage.setItem(ADD_EDIT_ORDER_LINES_STORAGE_KEY, 1);
                        return $q.resolve();
                    } else {
                        if (Cart.lines && Object.keys(Cart.lines).length === 0 &&
                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                            Cart.addDeliveryFeeLineIfNeeded();
                        }
                        return Cart.addLines(Util.getActiveLines(order.lines, undefined, false, true, true), 'order');
                    }
                });
            }

            var _isCartAlreadyUpdated = false;

            $rootScope.$on('cart.update.complete', function () {
                _isCartAlreadyUpdated = true;
            });

            $rootScope.$on('cart.editOrderId.change', function () {
                if (!User.session.userId) {
                    return;
                }

                if (Cart.editOrderId && Cart.editOrderId !== (self.orderInEdit && self.orderInEdit.id)) {
                    if ($state.includes('app.checkout') || $state.includes('app.cartSummary')) {
                        return self.cancelEdit();
                    } else {
                        if (_isCartAlreadyUpdated) {
                            var contentHeader = 'It seems you are editing your order on a different device/browser.',
                                contentTitle = 'Do you want to add the product to this new order or to the order that is being updated?';

                            Util.showCommonDialog({
                                title: '{{\'Edit Order\' | translate}}',
                                content: '<div style="font-size: 0.9em;">{{contentHeader | translate}}<br/>' + '{{contentTitle | translate}}?</div>',
                                controller: ['$scope', function ($scope) {
                                    $scope.contentHeader = contentHeader;
                                    $scope.contentTitle = contentTitle;
                                }],
                                buttons: [{
                                    text: '{{\'Add to New Order\' | translate}}',
                                    click: '$dialog.hide(true)'
                                }, {
                                    text: '{{\'Add to updated Order\' | translate}}',
                                    click: '$dialog.hide()'
                                }]
                            }).then(function (is) {
                                if (is) {
                                    return self.cancelEdit();
                                } else {
                                    return self.getOrderDetails(Cart.editOrderId, {fireAndForgot: true}).then(function (order) {
                                        self.orderInEdit = order;
                                        _copyOrderToNewCart(order);
                                    });
                                }
                            });
                        } else {
                            self.getOrderDetails(Cart.editOrderId, {fireAndForgot: true}).then(function (order) {
                                self.orderInEdit = order;
                               $rootScope.$emit('orders.orderInEdit', order);

                                var isAddEditOrderLines = !!LocalStorage.getItem(ADD_EDIT_ORDER_LINES_STORAGE_KEY);
                                if (isAddEditOrderLines) {
                                    _copyOrderToNewCart(order);
                                    LocalStorage.removeItem(ADD_EDIT_ORDER_LINES_STORAGE_KEY);
                                }
                            });

                            if (!Config.preventDialogsOnLoad) {
                                Util.showCommonDialog({
                                    title: '{{\'Edit Order\' | translate}}',
                                    content: '{{\'Note! You in the middle of editing order\' | translate}}.',
                                });
                            }
                        }
                    }
                } else if (!Cart.editOrderId && self.orderInEdit && self.orderInEdit.id) {
                    self.orderInEdit = null;

                    if (_isCartAlreadyUpdated) {
                        Util.showCommonDialog({
                            title: '{{\'Edit Order\' | translate}}',
                            content: '{{\'Note! It seems you have finished editing your order on a different device/browser.\' | translate}}.'
                        });
                    }
                }
            });
        }

    ]);

})(angular, app);
