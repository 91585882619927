(function (angular, app) {
    'use strict';

    app.service('UserVerificationDialog', ['Dialog', function(Dialog) {
        this.show = show;

        function show(isPostRegistration, classes) {
            return Dialog.show({
                templateUrl: 'template/dialogs/user-verification/index.html',
                controller: 'UserVerificationCtrl as userVerificationCtrl',
                ariaLabelledby: 'user_verification_dialog_title',
                styleClass: 'user-verification-dialog',
                locals: {
                    isPostRegistration: !!isPostRegistration
                },
                classes: classes
            });
        }
    }]);

    app.run(['$rootScope', 'UserVerificationDialog', function ($rootScope, UserVerificationDialog) {
        $rootScope.UserVerificationDialog = UserVerificationDialog;
    }]);

    app.controller('UserVerificationCtrl', [
        'User', 'Api', 'isPostRegistration', 'USER_VERIFICATION_STATUS', 'PHONE_TYPES',
        function(User, Api, isPostRegistration, USER_VERIFICATION_STATUS, PHONE_TYPES) {
            var userVerificationCtrl = this;

            userVerificationCtrl.isPostRegistration = isPostRegistration;
            userVerificationCtrl.send = send;
            userVerificationCtrl.verify = verify;

            _init();

            function _init(setFocus) {
                return User.getData(true).then(function(userData){
                    userVerificationCtrl.userData = userData;
                    userVerificationCtrl.isVerified = userData.verificationStatusId === USER_VERIFICATION_STATUS.VERIFIED;
                    userVerificationCtrl.codeSent = userData.verificationStatusId === USER_VERIFICATION_STATUS.SENT &&
                        userData.verificationCodeExpiration && (new Date(userData.verificationCodeExpiration)) > (new Date());

                    var mobilePhone = angular.filterCollection(userData.phones, function(phone) {
                        return phone.typeVal === PHONE_TYPES.MOBILE || phone.typeVal === PHONE_TYPES.VERIFY;
                    })[0];
                    if (mobilePhone) {
                        userVerificationCtrl.phoneNumber = mobilePhone.phoneNumber;
                    }

                    if (setFocus) {
                        _setFocusOnBody();
                    }
                });
            }

            function send(phoneNumber) {
                return _request({
                    url: '_send',
                    data: {
                        phoneNumber: phoneNumber
                    }
                }).then(function() {
                    userVerificationCtrl.verifyError = false;
                    userVerificationCtrl.uniqPhoneError = false;
                    userVerificationCtrl.codeSent = true;
                    userVerificationCtrl.code = '';
                    _setFocusOnBody();
                }).catch(function(e) {
                    var errorName = e.data.error;
                    if (errorName === 'The given phone number already exists in the system') {
                        userVerificationCtrl.uniqPhoneError = true;
                    }
                });
            }

            function verify() {
                return _request({
                    url: '_verify',
                    data: {
                        code: userVerificationCtrl.code
                    }
                }, {
                    withoutToast: true
                }).then(function() {
                    return _init(true);
                }).catch(function() {
                    userVerificationCtrl.verifyError = true;
                });
            }

            function _request(options, apiOptions) {
                userVerificationCtrl.inPrgress = true;

                options.url = '/v2/retailers/:rid/users/:uid/verification/' + options.url;
                options.method = 'POST';
                return Api.request(options, apiOptions).finally(function() {
                    userVerificationCtrl.inPrgress = false;
                });
            }

            /**
             * Accessibility support
             *
             * @private
             */
            function _setFocusOnBody() {
                var dialogBody = document.querySelector('.dialog-wrapper .dialog > .dialog-body');
                dialogBody && dialogBody.focus();
            }
        }
    ]);
})(angular, app);