(function (angular, app) {
    'use strict';

    app.filter('retailerNameReplacement', ['Config', function (config) {
        return function (input) {
            if (!input) {
                return '';
            }

            var language = config.language;

            var specialNameReplacement = config.retailer.settings.specialNameReplacement;
            if (specialNameReplacement && specialNameReplacement[language.id]) {
                var mapObj = {
                    'specials': specialNameReplacement[language.id].toLowerCase(),
                    'Specials': specialNameReplacement[language.id][0].toUpperCase() + specialNameReplacement[language.id].substring(1, specialNameReplacement[language.id].length),
                    'акции': specialNameReplacement[language.id].toLowerCase(),
                    'Акции': specialNameReplacement[language.id][0].toUpperCase() + specialNameReplacement[language.id].substring(1, specialNameReplacement[language.id].length),
                    'מבצעים והטבות': specialNameReplacement[language.id],
                    'promotions': specialNameReplacement[language.id].toLowerCase(),
                    'Promotions': specialNameReplacement[language.id][0].toUpperCase() + specialNameReplacement[language.id].substring(1, specialNameReplacement[language.id].length),
                    'ofertas': specialNameReplacement[language.id].toLowerCase(),
                    'Ofertas': specialNameReplacement[language.id][0].toUpperCase() + specialNameReplacement[language.id].substring(1, specialNameReplacement[language.id].length),
                    'العروض الخاصة': specialNameReplacement[language.id]
                };
                input = input.replace(/specials|Specials|акции|Акции|מבצעים והטבות|promotions|Promotions|ofertas|Ofertas|العروض الخاصة/gi, function (matched) {
                    return mapObj[matched];
                }).replace(/מבצעים/gi, specialNameReplacement[language.id]);
            }

            var cityNameReplacement = config.retailer.settings.specificAddressDetails && config.retailer.settings.specificAddressDetails.cityDescription;
            if (cityNameReplacement && cityNameReplacement[language.id]) {
                input = input.replace(/City|city|עיר|Ciudad|ciudad|Город|город|Ville|ville|المدينة/gi, cityNameReplacement[language.id]);
            }

            var addressNameReplacement = config.retailer.settings.specificAddressDetails && config.retailer.settings.specificAddressDetails.addressDescription;
            if (addressNameReplacement && addressNameReplacement[language.id]) {
                input = input.replace(/address line 1|Address line 1|Address 1|address 1|Address|address|Street Number and Street Name|כתובת|רחוב \+ מספר בית|רחוב \(אם קיים\) ומספר בית|Dirección 1|dirección 1|Dirección|dirección|Nombre y número de la calle|Улица \+ номер дома|улица \+ номер дома|Улица и номер дома|Адрес|адрес|Rue \+ numéro|rue \+ numéro|Adresse|adresse|Rue et numéro de rue|العنوان|شارع\+رقم البيت|شارع \(إن وجد\) ورقم منزل/gi, addressNameReplacement[language.id]);
            }

            var zipNameReplacement = config.retailer.settings.specificAddressDetails && config.retailer.settings.specificAddressDetails.zipCodeDescription;
            if (zipNameReplacement && zipNameReplacement[language.id]) {
                input = input.replace(/Zip code|zip code|Zip|zip|מיקוד|Código postal|código postal|Индекс|индекс|Code postal|code postal|الرمز البريدي/gi, zipNameReplacement[language.id]);
            }

            var detailsNameReplacement = config.retailer.settings.specificAddressDetails && config.retailer.settings.specificAddressDetails.moreDetailsDescription;
            if (detailsNameReplacement && detailsNameReplacement[language.id]) {
                input = input.replace(/More details|more details|פרטים נוספים|Más detalles|más detalles|Доп. информация|доп. информация|Plus de détails|plus de détails|المزيد من التفاصيل/gi, detailsNameReplacement[language.id]);
            }

            var joinConnectTextReplacement = config.retailer.settings.joinConnectTextReplacement;
            if (joinConnectTextReplacement && joinConnectTextReplacement[language.id]) {
                input = input.replace(/Join \/ Connect to club|הזדהות \/ הצטרפות למועדון|Присоединиться к клубу|Unirse \/ conectarse al club|Identification\/Adhésion au club|انضم إلى \/ سجل الدخول إلى النادي/gi, joinConnectTextReplacement[language.id]);
            }

            var cvvTextReplacement = config.retailer.settings.cvvTextReplacement;
            if (cvvTextReplacement && cvvTextReplacement[language.id]) {
                input = input.replace(/CVV|Cvv|cvv/gi, cvvTextReplacement[language.id]);
            }

            return input
        };
    }]);


})(angular, app);
