(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.loyaltyClub', {
                url: '/loyalty-club',
                redirectTo:'app.userEdit.loyalty'
            });
            $stateProvider.state('app.userEdit.loyalty', {
                url: '/loyalty',
                data: {
                    routeAccess: [ROUTE_ACCESS.NOT_ORGANIZATION_MEMBER, ROUTE_ACCESS.LOGIN]
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Membership club'));
                    }]
                },
                views: {
                    'tab': {
                        templateUrl: 'template/views/user-edit/loyalty-club/index.html',
                        controller: 'UserLoyaltyCtrl',
                        controllerAs: 'userLoyaltyCtrl'
                    }
                }
            });
        }])
        .controller('UserLoyaltyCtrl', [
            '$scope', '$rootScope', '$state', 'Config', 'User', 'LocalStorage', 'Specials', 'PermanentFilters',
            'Cart', 'Util', '$location', '$timeout', '$window', 'SP_SERVICES', 'LOYALTY_CLUB_DRIVERS', 'LOYALTY_PAGE_METHODS',
            function ($scope, $rootScope, $state, Config, User, LocalStorage, Specials, PermanentFilters,
                      Cart, Util, $location, $timeout, $window, SP_SERVICES, LOYALTY_CLUB_DRIVERS, LOYALTY_PAGE_METHODS) {
                var userLoyaltyCtrl = this,
                    userEditCtrl = $scope.userEditCtrl;

                userLoyaltyCtrl.LOYALTY_CLUB_DRIVERS = LOYALTY_CLUB_DRIVERS;
                userLoyaltyCtrl.LOYALTY_PAGE_METHODS = LOYALTY_PAGE_METHODS;

                userLoyaltyCtrl.loadSpecials = loadSpecials;
                userLoyaltyCtrl.showLoyaltyClubDialog = showLoyaltyClubDialog;
                userLoyaltyCtrl.generateGS1CheckDigit = generateGS1CheckDigit;
                userLoyaltyCtrl.goTo = goToBannerHref;
                userLoyaltyCtrl.specialsActions = {
                    openCarouselItem: openSpecial
                };
                userLoyaltyCtrl.specialsCarouselWatchers = {
                    'cart.lines.add': onCartLinesAdd
                };

                _init();
                _verifyLoyaltyRegistration();
                _initBannerMap();

                function _init() {
                    userLoyaltyCtrl.isMultiLoyalty = (Config.retailer.loyaltyClubDrivers|| []).filter(function (driver) {
                        return driver.isActive
                    }).length > 1;

                    if (!userLoyaltyCtrl.isMultiLoyalty) {
                        userLoyaltyCtrl.loyaltyClubDriver = Config.retailer.loyaltyClubDrivers[0];
                        userLoyaltyCtrl.userLoyaltyClub = !!userEditCtrl.user && userEditCtrl.user.loyaltyClubs && userEditCtrl.user.loyaltyClubs[0];
                    }

                    angular.forEach((userEditCtrl.user && userEditCtrl.user.loyaltyClubs|| []), function (loyaltyClub) {
                        loyaltyClub.isExpired = !!loyaltyClub.loyaltyClubExpiration && new Date(loyaltyClub.loyaltyClubExpiration) < new Date();
                    });
                }

                function _verifyLoyaltyRegistration() {
                    var maxLoyaltyClubDriver = (Config.retailer.loyaltyClubDrivers|| []).find(function (driver) {
                        return driver.loyaltyClubDriverId === LOYALTY_CLUB_DRIVERS.CLUB_365;
                    });

                    if(maxLoyaltyClubDriver && maxLoyaltyClubDriver.clientConfig &&
                        maxLoyaltyClubDriver.clientConfig.url &&
                        maxLoyaltyClubDriver.clientConfig.url.registrationUrl) {
                        var userMexLoyaltyClub = userEditCtrl.user && userEditCtrl.user.loyaltyClubs.find(function (loyaltyClub) {
                            return loyaltyClub.loyaltyClubDriverId === LOYALTY_CLUB_DRIVERS.CLUB_365;
                        });

                        if ( !!userMexLoyaltyClub &&
                            !userMexLoyaltyClub.loyaltyVerificationStatus) {
                            Cart.createCart().then(function() {
                                User.verifyLoyaltyRegistration(Cart.serverCartId, userMexLoyaltyClub.id);
                            });
                        }
                    }
                }

                function _initBannerMap() {
                    if (userLoyaltyCtrl.isMultiLoyalty) {
                        return;
                    }

                    $timeout(function () {
                        var registrationBannerElement = document.querySelector('.registrationBanner img');
                        userLoyaltyCtrl.registrationBannerImgOffsetLeft = registrationBannerElement && registrationBannerElement.offsetLeft;
                        userLoyaltyCtrl.registrationBannerImgOffsetTop = registrationBannerElement && registrationBannerElement.offsetTop;

                        var connectBannerElement = document.querySelector('.verificationBanner img');
                        userLoyaltyCtrl.connectBannerImgOffsetLeft = connectBannerElement && connectBannerElement.offsetLeft;
                        userLoyaltyCtrl.connectBannerImgOffsetTop = connectBannerElement && connectBannerElement.offsetTop;
                    },1000 );
                }

                function loadSpecials(from, size, callback) {
                    Specials.getSpecials(Specials.prepareSearchParams({
                        from: from,
                        size: size,
                        filters: {
                            must: {
                                exists: ['loyaltyClubsIds']
                            }
                        }
                    })).then(function (specials) {
                        angular.forEach(specials.specials, function (special) {
                            if (special.item) {
                                special.item = Cart.getProduct(special.item);
                            }
                        });

                        callback(null, specials.total, specials.specials);
                    }).catch(function (err) {
                        callback(err);
                    });
                }

                function openSpecial(special) {
                    if (special.item) {
                        return Util.openProduct(special.item);
                    }

                    $state.go('app.specials.special', {sid: special.id});
                }

                function onCartLinesAdd(carouselSpecials) {
                    angular.forEach(carouselSpecials, function (special) {
                        special.item && (special.item = Cart.getProduct(special.item));
                    });
                }

                function showLoyaltyClubDialog() {
                    return Util.showLoyaltyClubDialog();
                }

                function generateGS1CheckDigit(input) {
                    var array = input.split('').reverse();
                    var total = 0;
                    var i = 1;
                    array.forEach(function(number){
                        number = parseInt(number);
                        if (i % 2 === 0) {
                            total = total + number;
                        } else {
                            total = total + (number * 3);
                        }
                        i++;
                    });
                    return (Math.ceil(total / 10) * 10) - total;
                }

                function goToBannerHref(href) {
                    if (!href) return;

                    if (href.indexOf('/') === 0) {
                        return $location.url(href);
                    }

                    $window.open(href, '_blank');
                }


                PermanentFilters.subscribe(function() {
                    if (userLoyaltyCtrl.specialsActions.reset) {
                        userLoyaltyCtrl.specialsActions.reset();
                    }
                }, $scope);

                Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function (event, data) {
                    if (data.lines[0].type === SP_SERVICES.CART_LINE_TYPES.REGISTER_LOYALTY) {
                        userLoyaltyCtrl.loyaltyDriversAtProcess = userLoyaltyCtrl.loyaltyDriversAtProcess || {};
                        var metaData = data.lines[0].metaData && JSON.parse(data.lines[0].metaData);
                        userLoyaltyCtrl.loyaltyDriversAtProcess[metaData.loyaltyClubDriverId] = true;
                    }
                }));
            }
        ]);
})(angular, app);
