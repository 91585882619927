(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.userEdit.specialDiscount', {
                url: '/special-discount',
                data: {
                    routeAccess: ROUTE_ACCESS.NOT_ORGANIZATION_MEMBER
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return $filter('translate')('Employee Discount');
                    }]
                },
                views: {
                    'tab': {
                        templateUrl: 'template/views/user-edit/special-discount/index.html',
                        controller: 'SpecialDiscountCtrl',
                        controllerAs: 'specialDiscountCtrl',
                        resolve: {
                            user: ['User', function (user) {
                                return user.getData();
                            }]
                        }
                    }
                }
            });
        }])
        .controller('SpecialDiscountCtrl', ['$scope', '$rootScope', '$state', '$filter', 'User', 'Cart', 'Util', 'Config', 'SPECIAL_DISCOUNT_TYPES', 'MultiPassDiscount',
            function ($scope, $rootScope, $state, $filter, User, Cart, Util, Config, SPECIAL_DISCOUNT_TYPES, MultiPassDiscount) {
                var specialDiscountCtrl = this,
                    discountClubDriver = MultiPassDiscount;

                //== check if Special Discounts active for this Retailer
                if(!(Config.retailer.settings.discountClubActive)) {
                    return $state.go('app.userEdit.accountInfo');
                }

                //== currently there is only one type SPECIAL_DISCOUNT_TYPES.MULTIPASS
                specialDiscountCtrl.received = false;
                specialDiscountCtrl.type = SPECIAL_DISCOUNT_TYPES.MULTIPASS;
                specialDiscountCtrl.template = 'multiPass.html';
                specialDiscountCtrl.error = '';
                specialDiscountCtrl.userData = {};
                specialDiscountCtrl.connectData = {};
                specialDiscountCtrl.discountClubCardId = User.data.discountClubCardId;
                specialDiscountCtrl.connect = connect;

                _init();

                /**
                 * Init all
                 * @private
                 */
                function _init() {
                    _getUserData(specialDiscountCtrl.discountClubCardId);
                }

                /**
                 * On connect form submit button press - Send connect request to server
                 * @public
                 */
                function connect() {
                    if (specialDiscountCtrl.connectData.personalId) {
                        discountClubDriver.connect(specialDiscountCtrl.connectData.personalId).then(function (response) {
                            if (response.cardId) {
                                specialDiscountCtrl.userData = response;
                                specialDiscountCtrl.discountClubCardId = response.cardId;
                                try{
                                    Cart.forceUpdate(); //== get cart with discount data
                                } catch (err) {}
                            } else {
                                specialDiscountCtrl.error = response.error ? $filter('translate')(response.error) : $filter('translate')('Employee discount account not found');
                            }
                        });
                    } else {
                        specialDiscountCtrl.connectData.personalIdInvalid = !specialDiscountCtrl.connectData.personalId;
                    }
                }

                /**
                 * Get updated user data including obligo from server
                 * @private
                 *
                 * @param {Number} cardId
                 */
                function _getUserData(cardId) {
                    if (!cardId) {
                        //== User not connected to MultiPass
                        specialDiscountCtrl.received = true;
                    } else {
                        discountClubDriver.getUser().then(function (response) {
                            specialDiscountCtrl.received = true;
                            if (response && response.cardId) {
                                specialDiscountCtrl.userData = response;
                                specialDiscountCtrl.discountClubCardId = response.cardId;
                            } else {
                                specialDiscountCtrl.discountClubCardId = 0;
                                specialDiscountCtrl.error = response && response.error ? $filter('translate')(response.error) : $filter('translate')('Employee discount account not found');
                            }
                        });
                    }
                }
            }
        ]);
})(angular, app);