(function (angular, app) {
    'use strict';

    function loadOrdersFromPosCtrl($q, util, api, user, dialog, posUserFields, POS_USER_FIELD_TYPES, PHONE_TYPES) {
        var self = this;

        self.user = {};
        self.activeField = posUserFields.fields && posUserFields.fields.length ? posUserFields.fields[0] : {};
        self.sendCode = sendConfirmationCode;
        self.confirmCode = confirmConfirmationCode;

        /**
         * Get user data
         */
        user.getData().then(function(data) {
            self.user = data;

            if (self.activeField.type == POS_USER_FIELD_TYPES.PHONE) {
                var phone = angular.filterCollection(data.phones, function(phone) {
                    return phone.typeVal == PHONE_TYPES.MOBILE;
                })[0];
                self.value = phone ? phone.phoneNumber : self.value;
            }
        });

        /**
         * @returns {Promise}
         * @private
         */
        function _request() {
            self.calling = true;
            return api.request.apply(null, arguments).finally(function() {
                self.calling = false;
            });
        }

        /**
         * Send phone or email confirmation code
         * @param {object} form
         * @param {Event} event
         */
        function sendConfirmationCode(form, event) {
            if (form && event && form.$invalid) {
                return util.setFirstErrorInput(event.target || event.srcElement || event.path[0]);
            }

            var defer = $q.defer(),
                promise = defer.promise;
            defer.resolve();
            if (self.activeField.type == POS_USER_FIELD_TYPES.PHONE) {
                promise = promise.then(_sendPhoneConfirmationCode);
            }

            promise.then(function() {
                self.confirmationCodeSent = true;
            });
        }

        /**
         * Send phone confirmation code SMS
         * @returns {Promise}
         * @private
         */
        function _sendPhoneConfirmationCode() {
            return _request({
                method: 'POST',
                url: '/retailers/:rid/users/:uid/phones/mobile/confirm',
                data: {
                    phone: {
                        phoneNumber: self.value
                    }
                }
            });
        }

        /**
         * Confirm email or phone confirmation code
         * @param {object} form
         * @param {Event} event
         */
        function confirmConfirmationCode(form, event) {
            if (form.$invalid) {
                return util.setFirstErrorInput(event.target || event.srcElement || event.path[0]);
            }

            var defer = $q.defer(),
                promise = defer.promise;
            defer.resolve();
            if (self.activeField.type == POS_USER_FIELD_TYPES.PHONE) {
                promise = promise.then(_confirmPhoneConfirmationCode);
            }

            promise.then(function() {
                return _addOrderFromPosTask();
            }).then(function() {
                dialog.hide();
                dialog.common().content('{{\'Successfully Sent\' | translate}}').show();
            });
        }

        /**
         * Confirm phone confirmation code
         * @returns {Promise}
         * @private
         */
        function _confirmPhoneConfirmationCode() {
            return _request({
                method: 'PATCH',
                url: '/retailers/:rid/users/:uid/phones/mobile/confirm',
                data: {
                    code: self.confirmationCode
                }
            });
        }

        /**
         * Call server to add 'user purchases to retrieve' task
         * @returns {Promise}
         * @private
         */
        function _addOrderFromPosTask() {
            return _request({
                method: 'POST',
                url: '/retailers/:rid/users/:uid/orders/fromPos',
                data: {
                    value: self.value,
                    type: self.activeField.type
                }
            });
        }
    }

    app.controller('LoadOrdersFromPOSCtrl', ['$q', 'Util', 'Api', 'User', 'Dialog', 'posUserFields', 'POS_USER_FIELD_TYPES', 'PHONE_TYPES', loadOrdersFromPosCtrl]);
})(angular, app);