(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.update-order.ebt-payment-finish', {
                url: '/ebt-payment-finish',
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Summary Payment Details'));
                    }]
                },
                views: {
                    updateOrderTab: {
                        templateUrl: 'template/views/update-order/ebt-payment-finish/index.html',
                        controller: 'EBTFinishPaymentCtrl as ebtFinishPaymentCtrl',
                    }
                }
            });
        }])
        .controller('EBTFinishPaymentCtrl', ['$scope', '$rootScope', '$location', 'Util', 'Cart', 'SP_SERVICES', function ($scope, $rootScope, $location, util, cart, SP_SERVICES) {
            var updateOrderCtrl = $scope.updateOrderCtrl;
            updateOrderCtrl.withoutContent = true;

            var ebtFinishPaymentCtrl = this;

            ebtFinishPaymentCtrl.toHomePage = toHomePage;
            ebtFinishPaymentCtrl.onEditOrder = onEditOrder;
            $rootScope.preventCartUpdate = true;

            init();

            // EXTERNAL FUNCTION

            function init() {
                _initialFinalSummary();

                $scope.$on('$destroy', _reset);
            }

            function toHomePage() {
                _reset();

                $location.path('/');
            }

            // if product for edit order button
            function onEditOrder() {
                util.editOrderWIthValidationIfOrderEditable(ebtFinishPaymentCtrl.orderId).then(function (editable) {
                    if (editable) {
                        toHomePage(true);
                    }
                })
            }

            // INTERNAL FUNCTION
            function _reset() {
                cart.setTimeTravel();
                cart.clear(true);
                $rootScope.$emit('editOrder.updated');
                $rootScope.preventCartUpdate = false;
            }

            function _initialFinalSummary() {
                ebtFinishPaymentCtrl.orderId = updateOrderCtrl.updatedOrderId;
                ebtFinishPaymentCtrl.deliveryType = updateOrderCtrl.paymentsData.deliveryInfo.deliveryMethod.label;
                ebtFinishPaymentCtrl.deliveryTimes = updateOrderCtrl.paymentsData.deliveryInfo.deliveryTimeRage;
                ebtFinishPaymentCtrl.columns = [];

                var generaColumn = [
                    [
                        _columnObj('Items', _countCartLinesUnitsItems()),
                        _columnObj('Sub total', _toFloatNum(updateOrderCtrl.paymentsData.summary.subTotal + (updateOrderCtrl.paymentsData.summary.deliveryFee || 0) + (updateOrderCtrl.paymentsData.summary.ebtWeightDeposit || 0)), true),
                        _columnObj('Delivery fee', updateOrderCtrl.paymentsData.summary.deliveryFee, true),
                        _columnObj('Service fee', updateOrderCtrl.paymentsData.summary.serviceFee, true),
                        _columnObj('Tax', updateOrderCtrl.paymentsData.summary.finalTax, true),
                        _columnObj('Total', updateOrderCtrl.paymentsData.summary.finalTotal, true, ['bold', 'colored']),
                    ]
                ];
                var ebtColumn = [
                    [
                        _columnObj('EBT Card', ('**** ' + updateOrderCtrl.paymentsData.ebtCard.lastFourDigits)),
                    ],
                    [
                        _columnObj('EBT SNAP Payment', null, null, 'bold', true, true),
                        _columnObj('Previous Charge',
                            (updateOrderCtrl.paymentsData.ebtInfo.snap.previousPayment && updateOrderCtrl.paymentsData.ebtInfo.snap.previousPayment.initialAmount ? _toFloatNum(updateOrderCtrl.paymentsData.ebtInfo.snap.previousPayment.initialAmount) : ''),
                            true),
                        _columnObj(
                            _getChargeType('snap'),
                            _getChargeAmount('snap'),
                            true,
                            'colored',
                            !_getChargeAmount('snap'),
                            true)
                    ],
                    [ // todo do this in mobile as well
                        _columnObj((updateOrderCtrl.ebtBranchSettings[updateOrderCtrl.ebtBranchSettings.branchId].eBTCashAllowed ? 'EBT CASH Payment' : ''), null, null, 'bold', true, true),
                        _columnObj('Previous Charge',
                            (updateOrderCtrl.paymentsData.ebtInfo.cash.previousPayment && updateOrderCtrl.paymentsData.ebtInfo.cash.previousPayment.initialAmount ? _toFloatNum(updateOrderCtrl.paymentsData.ebtInfo.cash.previousPayment.initialAmount) : ''),
                            true),
                        _columnObj(
                            (updateOrderCtrl.ebtBranchSettings[updateOrderCtrl.ebtBranchSettings.branchId].eBTCashAllowed ? _getChargeType('cash') : ''),
                            _getChargeAmount('cash'),
                            true,
                            'colored',
                            !_getChargeAmount('cash'),
                            true)
                    ],
                    _balanceColumnsBuilder()
                ];
                var creditCardColumn = [
                    [
                        _columnObj('Credit Card', ('**** ' + updateOrderCtrl.paymentsData.mainLastDigits)),
                        _columnObj('Credit Card Charge', updateOrderCtrl.paymentsData.summary.creditCardReminder, true, 'colored')
                    ]
                ];

                ebtFinishPaymentCtrl.columns = [generaColumn, ebtColumn, creditCardColumn];
            }

            function _columnObj(text, value, hasCurrency, classes, hideColon, withoutValue) {
                return {
                    text: text,
                    hasCurrency: hasCurrency,
                    value: value,
                    classes: classes,
                    hideColon: hideColon,
                    withoutValue: withoutValue
                }
            }

            function _getChargeType(type) {
                if (updateOrderCtrl.finalUpdatePayments[type]) {
                    return updateOrderCtrl.finalUpdatePayments[type].chargeType === 'charge' ? 'Current Charge' : 'Refund';
                }

                return 'No Charged/Refund';
            }

            function _getChargeAmount(type) {
                return updateOrderCtrl.finalUpdatePayments[type] && updateOrderCtrl.finalUpdatePayments[type].amount;
            }

            function _balanceColumnsBuilder() {
                var balances = [];

                if (updateOrderCtrl.paymentsData.ebtBalance) {
                    angular.forEach(updateOrderCtrl.paymentsData.ebtBalance, function (val, key) {
                        if(key.toLowerCase().indexOf('updated')> -1){
                            var text = 'Updated ' + ((key.toLowerCase().indexOf('fsbalance') > -1)  ? 'SNAP' : 'CASH') + ' balance';

                            balances.push(_columnObj(text, val, true));
                        }

                    });
                }

                return balances;
            }

            function _toFloatNum(v, decimalNum) {
                return parseFloat(v.toFixed((decimalNum || 2)));
            }

            function _countCartLinesUnitsItems() {
                return updateOrderCtrl.paymentsData.cartLines ? updateOrderCtrl.paymentsData.cartLines.reduce(function(sum, line) {
                    if (line.type === SP_SERVICES.CART_LINE_TYPES.PRODUCT) {
                        sum += line.quantity;
                    }

                    return _toFloatNum(sum);
                }, 0) : 0;
            }
        }]);
})(angular, app);
