(function (angular, app) {
    'use strict';

    app.directive('specialDescriptionSplit', [function() {
        return {
            restrict: 'A',
            scope: {
                description: '@specialDescriptionSplit',
                isEllipsis: '<useEllipsis'
            },
            template:
                '<span class="special-description-title with-ellipsis" ellipsis ng-if="splitCtrl.title && isEllipsis && !splitCtrl.description" title="{{splitCtrl.title}}" ng-class="{\'has-description\': splitCtrl.description}">{{splitCtrl.title}}</span>' +
                '<span class="special-description-title" ng-if="splitCtrl.title && (!isEllipsis || splitCtrl.description)" title="{{splitCtrl.title}}" ng-class="{\'has-description\': splitCtrl.description}">{{splitCtrl.title}}</span>' +
                '<span class="special-description-content" ng-if="splitCtrl.description" title="{{splitCtrl.description}}">{{splitCtrl.description}}</span>',
            controllerAs: 'splitCtrl',
            controller: ['$scope', function($scope) {
                var splitCtrl = this;

                $scope.$watch('description', function(newVal) {
                    var parts = newVal.split('\n');
                    splitCtrl.title = parts.shift().trim();
                    splitCtrl.description = '';

                    //== If after getting the title there are still parts in array, all of them going to description
                    if (parts.length > 0) {
                        angular.forEach(parts, function(part, index) {
                            if(part && part.trim()) {
                                parts[index] = part.trim();
                            }
                        });
                        splitCtrl.description = parts.join(', ');
                    }
                });
            }]
        };
    }]);
})(angular, app);
