
(function (angular, app) {
    'use strict';

    app.directive('spBindHtml', [
        '$compile', 'Util',
        function ($compile, util) {
            return {
                restrict: 'A',
                scope: {
                    html: '=spBindHtml'
                },
                link: function ($scope, $element) {
                    function _bind() {
                        $element.html($scope.html);
                        $compile($element.contents())($scope.$parent);
                    }

                    util.currentScopeListener($scope, $scope.$watch('html', _bind));
                }
            };
        }]);

})(angular, app);