(function (angular, app) {
    'use strict';

    app.controller('DeliveryFeeInfoCtrl', [
        '$scope', 'displayText',
        function ($scope, displayText) {
            $scope.displayText = displayText;
            $scope.hide = function() {
                $scope.$dialog.hide();
            };
        }]);
})(angular, app);