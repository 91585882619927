(function (angular, app) {
    'use strict';

    app.directive('spEscape', function () {
        return function (scope, element, attrs) {
            element.bind('keydown keypress', function (event) {
                if(event.which === 27) {
                    scope.$apply(function (){
                        scope.$eval(attrs.spEscape);
                        switch (attrs.spEscape) {
                            case '':
                                element.parent().children()[0].focus()
                                break;
                            case 'headerCtrl.closeList($event)':
                                element.parent().children()[2].focus()
                                break;
                            default:

                        }
                    });

                    event.preventDefault();
                }
            });
            scope.$on('$destroy', function() {
                element.unbind('keydown keypress')
            })
        };
    });
})(angular, app);