(function (angular, app) {
    'use strict';

    app.controller('LimitHeavyPackageCtrl', [
        '$scope', 'maxQuantity', 'displayName', 'iconUrl',
        function ($scope, maxQuantity, displayName, iconUrl) {
            $scope.maxQuantity = maxQuantity;
            $scope.displayName = displayName;
            $scope.iconUrl = iconUrl;
            $scope.hide = function() {
                $scope.$dialog.hide();
            };
        }]);
})(angular, app);