(function (angular, app) {
    'use strict';

    app.controller('EditOrderBubbleCtrl', [
        '$scope', '$rootScope', '$timeout', '$q', 'Api', 'Util', 'Cart', 'Orders', 'ORDER_STATUS_STAGES', 'SP_SERVICES', 'DataLayer',
        function ($scope, $rootScope, $timeout, $q, api, util, cart, orders, ORDER_STATUS_STAGES, SP_SERVICES, DataLayer) {
            var editOrderCtrl = this,
                _orderStatusTimer,
                _orderStatusTimeout = 240000;

            editOrderCtrl.editOrder = editOrder
            editOrderCtrl.onClickUpdateOrder = onClickUpdateOrder;
            editOrderCtrl.ordersService = orders;
            editOrderCtrl.deliveryTypeText = util.getDeliveryTypeText(orders.lastOrder.branchDeliveryTypeId)

            function editOrder(event) {
                event && event.stopPropagation();

                if (util.isOrderEditable(editOrderCtrl.order)) {
                    _editOrder();
                } else {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Monitor Window - Edit Order Disabled'}});

                    var contentHeader = '',
                        contentTitle = 'Your order can no longer be changed';
                    if (util.isOrderInStatuses(editOrderCtrl.order, ORDER_STATUS_STAGES.IN_PROCESS)) {
                        contentHeader = 'We have started collecting your original order.';
                    } else if (util.isOrderInStatuses(editOrderCtrl.order, ORDER_STATUS_STAGES.READY)) {
                        contentHeader = 'We have finished collecting your original order.';
                    } else if (util.isOrderInStatuses(editOrderCtrl.order, ORDER_STATUS_STAGES.CANCELLED)) {
                        contentHeader = 'This is a canceled order.';
                    }  else if (editOrderCtrl.order.branchDeliveryTypeId == SP_SERVICES.DELIVERY_TYPES.PICK_AND_GO) {
                        contentHeader = 'This is a pick and go order';
                    } else {
                        contentHeader = 'We have finished collecting your original order.';
                    }
                    util.showCommonDialog({
                        title: '{{\'Edit Order\' | translate}}',
                        content: '<div style="font-size: 0.9em;">{{contentHeader | translate}}<br/>' + '{{contentTitle | translate}}.</div>',
                        controller: ['$scope', function ($scope) {
                            $scope.contentHeader = contentHeader;
                            $scope.contentTitle = contentTitle;
                        }],
                        buttons: [{
                            text: '{{\'OK\' | translate}}',
                            click: '$dialog.hide()'
                        }]
                    });
                }
            }
            function _editOrder() {
                return util.editOrderWIthValidationIfOrderEditable(editOrderCtrl.order.id).catch(function() {
                    return _setOrder();
                });
            }

            function _setOrder(order, isFromResetEvent) {
                return $q.resolve(order || orders.getOrderStatus(editOrderCtrl.order.id)).then(function (order) {
                    editOrderCtrl.order = order;
                    if (!isFromResetEvent) {
                        $rootScope.$emit('editOrder.polling', order);
                    }

                    _setOrderStatusTimeout();
                });
            }

            _setOrder(orders.lastOrder);

            function _setOrderStatusTimeout() {
                _cancelOrderStatusTimeout();
                _orderStatusTimer = $timeout(function () {
                    _orderStatusTimer = null;

                    _setOrder();
                }, _orderStatusTimeout);
            }

            function _cancelOrderStatusTimeout() {
                _orderStatusTimer && $timeout.cancel(_orderStatusTimer);
                _orderStatusTimer = null;
            }

            function onClickUpdateOrder() {
              util.editOrder(orders.lastOrder.id);
            }

            util.currentScopeListener($scope, function () {
                _orderStatusTimer && $timeout.cancel(_orderStatusTimer);
            });

            util.currentScopeListener($scope, $rootScope.$on('editOrder.reset', function () {
                _setOrder(null, true);
            }));

            $rootScope.$on('order.update.refresh', function (event, data) {
                if (data.order) {
                    editOrderCtrl.order.shippingTimeTo = data.order.shippingTimeTo;
                    editOrderCtrl.order.shippingTimeFrom = data.order.shippingTimeFrom;
                    editOrderCtrl.order.shippingTimeFromDisplay = data.order.shippingTimeFromDisplay;
                }
            });
        }]);
})(angular, app);

