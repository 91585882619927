(function (angular, app) {
    'use strict';

    app.filter('removeHtmlTags', [
        function () {
            return function (input) {
                return  input ? String(input).replace(/<[^>]+>/gm, '').replace(/&nbsp;/g, ' ').trim() : '';
            };
        }]);

})(angular, app);